import { postJson, putJson, getJson } from './httpClientCentauro';
import { apiDomain } from '../settings';

const addressesUrl = `${apiDomain}/v4.2/endereco`;

const getAddressesList = ({ cvId, clientToken }) =>
  getJson(addressesUrl, {
    'x-cv-id': cvId,
    'x-client-token': clientToken,
  });

const createAddress = ({ addressData, cvId, clientToken }) =>
  postJson(addressesUrl, addressData, {
    'x-cv-id': cvId,
    'x-client-token': clientToken,
  });

const updateAddress = ({ addressData, cvId, clientToken }) =>
  putJson(addressesUrl, addressData, {
    'x-cv-id': cvId,
    'x-client-token': clientToken,
  });

export { createAddress, updateAddress, getAddressesList };
