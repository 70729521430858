/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useCallback, InputHTMLAttributes } from 'react';
import { css } from 'aphrodite';
import UserIcon from '../../../common/icons/User';
import { grayK } from '../../../common/colors';

import styles from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  handleOnInput(value: string): void;
  error?: string;
}

const InputUsername: React.FC<InputProps> = ({
  name,
  handleOnInput,
  error,
  ...rest
}: InputProps) => {
  const _handleOnInput = useCallback(
    event => {
      handleOnInput(event.target.value);
    },
    [handleOnInput],
  );

  return (
    <div className={css(styles.loginInputContainer)}>
      <UserIcon styling={css(styles.loginIcon)} innerFill={grayK} />
      <input
        name={name}
        onInput={_handleOnInput}
        className={css(styles.loginUserInput)}
        {...rest}
      />
      {error && (
        <div data-testid="errorMessageLogin" className={css(styles.errorMessage)}>
          {error}
        </div>
      )}
    </div>
  );
};

export default InputUsername;
