import React from 'react';
import PropTypes from 'prop-types';

const UserIcon = props => {
  const { outterFill, innerFill, styling } = props;

  return (
    <svg
      className={styling}
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <title>Artboard</title>
      <desc>Created with Sketch.</desc>
      <g id="Artboard" stroke="none" strokeWidth="1" fill={outterFill} fillRule="evenodd">
        <g
          id="man-user"
          transform="translate(3.000000, 2.000000)"
          fill={innerFill}
          fillRule="nonzero"
        >
          <path
            d="M7.084,7.869 C8.854,7.869 10.289,6.108 10.289,3.935 C10.289,1.762 9.818,0 7.084,0 C4.35,0 3.879,1.761 3.879,3.934 C3.879,6.107 5.314,7.869 7.084,7.869 Z"
            id="Path_47"
          />
          <path d="" id="Path_48" />
          <path d="" id="Path_49" />
          <path
            d="M13.162,13.627 C13.102,9.842 12.611,8.763 8.849,8.081 C7.84704123,8.98581487 6.32295877,8.98581487 5.321,8.081 C1.6,8.756 1.079,9.819 1.01,13.504 C1.004,13.804 1.002,13.821 1.001,13.786 C1.001,13.851 1.001,13.973 1.001,14.186 C1.001,14.186 1.901,16.002 7.085,16.002 C12.269,16.002 13.169,14.186 13.169,14.186 C13.169,14.05 13.169,13.956 13.169,13.892 C13.1618919,13.8038574 13.1595551,13.7153949 13.162,13.627 L13.162,13.627 Z"
            id="Path_50"
          />
        </g>
      </g>
    </svg>
  );
};

UserIcon.propTypes = {
  styling: PropTypes.string,
  outterFill: PropTypes.string,
  innerFill: PropTypes.string,
};

UserIcon.defaultProps = {
  styling: '',
  outterFill: 'none',
  innerFill: '#CDCDCD',
};

export default UserIcon;
