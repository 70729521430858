import { getJson, postJson } from './httpClientCentauro';
import { apiDomain } from '../settings';
import { getCookie } from '../utils/utils';
import SellerRepository from '../hooks/sessionSeller/repository/SellerRepository';

const paymentUrl = `${apiDomain}/v5.0/checkout/pagamentos?habilitarCompraRapida=true`;
const postPaymentUrl = `${apiDomain}/v5.0/checkout/processamentopedido`;
const accumulationUrl = `${apiDomain}/v5/pedidos/acumulo`;

const getPaymentData = ({ cvId, xljId, clientToken, televendas }) => {
  const headers = {
    'x-cv-id': cvId,
    ...(xljId ? { 'x-lj-id': xljId } : {}),
    'x-client-token': clientToken,
  };
  if (televendas) {
    headers['x-televendas'] = televendas;
  }
  return getJson(`${paymentUrl}`, headers);
};

const createAccumulation = (numeroPedido, headers) => {
  const accumulationCampaignOrigin =
    document.cookie.match('siteMetadata.origem=acumulo') != null ||
    document.cookie.match('siteMetadata.origem=b2b') != null;
  if (!accumulationCampaignOrigin) {
    return;
  }
  const utmSource = getCookie('GTMUtmSource');
  const utmMedium = getCookie('GTMUtmMedium');
  const utmCampaign = getCookie('GTMUtmCampaign');
  const origem = getCookie('siteMetadata.origem');

  const attrCampaign = {
    numeroPedido,
    origem,
    utmSource,
    utmMedium,
    utmCampaign,
  };

  return postJson(accumulationUrl, attrCampaign, headers);
};

const createPaymentData = async (body, { cvId, clientToken, televendas, xljId }) => {
  const headers = {
    'x-cv-id': cvId,
    ...(xljId ? { 'x-lj-id': xljId } : {}),
    'x-client-token': clientToken,
  };
  if (televendas) {
    headers['x-televendas'] = televendas;
  }

  const seller = SellerRepository.getActiveSeller();
  if (seller) {
    headers['x-matricula'] = `${seller.matricula}`;
  }

  const { data, error } = await postJson(`${postPaymentUrl}`, body, headers);

  if (!error) {
    await createAccumulation(data.numeroPedido, headers);
  }

  return {
    data,
    error,
  };
};

export { getPaymentData, createPaymentData };
