import {
  pushDatalayer,
  addLatLongAccuracyToDataLayer,
  currencyToNumber,
  isNumeric,
  getSellerInfo,
  checkPageType,
} from './utils';

export const SESSION_INTERTACTION_PDP = 'interaction-count-pdp';
export const SESSION_INTERTACTION_NAV = 'interaction-count-navigation';
export const SESSION_INTERTACTION_CART = 'interaction-count-cart';
export const SESSION_INTERTACTION_SHARE_ITEM = 'interaction-count-share-item';
export const SESSION_INTERTACTION_BANNER_EVENTS = 'interaction-count-banner';

const DELIVERY_CODES = {
  1: 'home_delivery',
  2: 'home_delivery',
  3: 'ENTREGA EMERGENCIAL',
  4: 'COLETA NORMAL',
  5: 'COLETA AGENDADA',
  6: 'COLETA EMERGENCIAL',
  7: 'INTERNO',
  8: 'B2B',
  9: 'home_delivery',
  10: 'home_delivery',
  11: 'in_store',
  14: 'home_delivery',
  15: 'home_delivery',
};

const sessions = {
  pdp: SESSION_INTERTACTION_PDP,
  nav: SESSION_INTERTACTION_NAV,
  cart: SESSION_INTERTACTION_CART,
};

/* Auxiliar Tracking Events */

export const getItemListOrigin = () => {
  const url = checkPageType();
  const origin = {
    nav: 'list_results',
    search: 'search_results',
    hotsite: 'hotsite_results',
  };
  return origin[url] || 'list_results';
};
const getCreativeSlot = (creativeName, index) => {
  const CreativeSlot = {
    Banner: `Banner ${isNumeric(index) ? index + 1 : ''}`,
    Showcase: `Showcase ${isNumeric(index) ? index + 1 : ''}`,
    Page: 0,
  };

  return CreativeSlot[creativeName] || 'N/A';
};

const getLocationId = (creativeName, index) => {
  if (creativeName === 'Showcase' || creativeName === 'Banner') {
    return isNumeric(index) ? index + 1 : 'N/A';
  }

  if (creativeName === 'Page') {
    return 0;
  }

  return 'N/A';
};

const getPageType = url => {
  const isPage = url.includes('/sc/');
  const isHotsite = url.includes('/hotsite/');

  if (isHotsite) {
    return 'Showcase';
  }

  if (isPage) {
    return 'Page';
  }
};

const hasPriceArray = product => {
  return product.precos && product.precos.length >= 1;
};

const handlerPromotionalCampaign = (product, promotionalCampaignObj) => {
  const creativeName = getPageType(promotionalCampaignObj.pathname);

  return {
    item: {
      promotion_id: `${
        promotionalCampaignObj.pathname.split('/hotsite/')[1]
      } ${new Date().getFullYear()}`,
      promotion_name: promotionalCampaignObj.pathname.split('/hotsite/')[1],
      creative_name: promotionalCampaignObj.pathname.split('/hotsite/')[1] + ' ' + creativeName,
      creative_slot: getCreativeSlot(creativeName, product.index),
      location_id: getLocationId(creativeName, product.index),
      promotion_date: {
        start_date: 'N/A',
        end_date: 'N/A',
        promotion_category: 'N/A',
        promotion_sponsors: 'N/A',
      },
    },
    promoClick: {
      promotions: {
        id: `${promotionalCampaignObj.pathname.split('/hotsite/')[1]} ${new Date().getFullYear()}`,
        name: promotionalCampaignObj.pathname.split('/hotsite/')[1],
        creative: getCreativeSlot(creativeName, product.index),
        position: getLocationId(creativeName, product.index),
      },
    },
  };
};

const getProductId = productId => {
  const url = window.location.href;

  if (url.includes('google') || url.includes('gclid')) {
    return '';
  }

  return productId ? productId : 'N/A';
};

const getProductColors = colors => {
  const colorsNames = [];
  colors?.map(color => colorsNames.push(color.nomeCor));

  return colorsNames;
};

const getSelectedFilters = () => {
  let filters = window.location.pathname;
  let filtersList = filters.substring(5, filters.length).split('/');
  let selectedFilters = [];

  filtersList?.map((filter, index) => {
    if (index % 2 === 0 && filter !== '') {
      selectedFilters.push({
        key: filter,
        value: filtersList[index + 1],
      });
    }
  });
  return selectedFilters;
};

const getCurrentPageNumber = currentPage => {
  const arrayCurrentPage = currentPage.split('&');
  const pageStr = arrayCurrentPage[1].split('=');

  return pageStr[1];
};

const getProductsId = products => {
  const idsProducts = [];
  products?.map(product => idsProducts.push(product.id));

  return idsProducts;
};

const getProductSizes = sizes => {
  const productSizes = [];
  sizes?.map(size => productSizes.push(size.tamanho));

  return productSizes;
};

const getAvailableFilters = filters => {
  const productTypes = [];
  filters?.map(filter => {
    productTypes.push({
      attribute: filter.attribute,
      values: filter.values,
    });
  });

  return productTypes;
};

const getProductInformation = product => {
  const seller =
    product?.nomeSeller || product?.informacoes?.nomeSeller || product?.sellerName || '';
  return {
    item_name: product.nome ? product.nome : product.disponibilidade.nome,
    item_id: product.sku || product.codModelo || product.disponibilidade?.skuSelecionado,
    price:
      product.valorTotal ||
      product.valorUnitarioPor ||
      product.valorUnitario ||
      product.precos[0]?.valor ||
      product.price,
    item_brand: product.informacoes?.marca ? product.informacoes.marca : 'N/A',
    item_category: product.informacoes?.categoria ? product.informacoes.categoria : 'N/A',
    item_custom_product: product.personalizado,
    item_seller: seller,
    item_seller_type: seller === 'Centauro' ? 'first_party' : 'third_party',
    item_variant: product.cor || product.precos.codigoCor || 'N/A',
    item_list_name: product.nome || product.informacoes.nome || 'N/A',
    item_list_id: product.sku || product.codModelo || product.disponibilidade?.skuSelecionado,
    index: product?.index ? product.index : 0,
    quantity: product.quantidade ? Number(product.quantidade) : 1,
    rating_value: product?.rating?.[0] ? Number(product.rating[0]) : 0,
    rating_count: product?.reviews?.[0] ? Number(product.reviews[0]) : 0,
    discount_percentage: hasPriceArray(product) ? product.precos[0].porcentagem : 0,
    interest_free_installments_count: hasPriceArray(product)
      ? Number(product.precos[0].parcelamentos?.length)
      : 0,
    interest_free_installments_value: hasPriceArray(product)
      ? currencyToNumber(product.precos[0].quantidadePorParcelaPadrao)
      : 0,
    available_tags: 'N/A',
    available_colors: product.disponibilidade?.cores
      ? getProductColors(product.disponibilidade.cores)
      : 'N/A',
    available_sizes: product.avaiableSizes || product.disponibilidade?.codigosTamanhos || 'N/A',
    available_appeals: 'N/A',
  };
};

const getInteractionCount = sessionKey => {
  const interactionCount = sessionStorage.getItem(sessionKey);

  if (!interactionCount) {
    sessionStorage.setItem(sessionKey, '1');
    return 1;
  }

  sessionStorage.setItem(sessionKey, parseInt(interactionCount) + 1);
  return parseInt(sessionStorage.getItem(sessionKey));
};

const getLoginOrigin = lastPageBeforeLogin => {
  if (lastPageBeforeLogin.indexOf('/checkouts/iniciar') === -1) {
    return 'menu';
  }
  return 'cart';
};
const getNavigationHistory = () => {
  let historyNavigation = JSON.parse(sessionStorage.getItem('urlOrigin'));
  let lastPage = historyNavigation.slice(-1);
  let origin = '';
  let lastPageBeforeLogin = '';
  if (lastPage.indexOf('/registro') != -1) {
    lastPageBeforeLogin = historyNavigation.slice(-3);
    origin = getLoginOrigin(lastPageBeforeLogin);
  } else {
    lastPageBeforeLogin = historyNavigation.slice(-2);
    origin = getLoginOrigin(lastPageBeforeLogin);
  }
  return origin;
};

const formatBannerValues = text => {
  const lowerCaseText = text?.toLowerCase();
  const formatedText = lowerCaseText?.replace(/\s+/g, '_');

  return formatedText;
};
export const getSellerInformation = sku => {
  const sellerFromStorage = sessionStorage.getItem('sellerInformation');
  const sellerObj = JSON.parse(sellerFromStorage)?.find(({ item_id }) => sku === item_id);

  if (!sellerObj) {
    return {
      item_id: '',
      seller: [''],
      seller_type: '',
    };
  }

  return sellerObj;
};

/* Tracking Events */

export const sendLoginEventsToDatalayer = (method, event) => {
  const eventObj = {
    event_type: 'click',
    lat_long: addLatLongAccuracyToDataLayer(),
    timestamp: new Date(),
    interaction_count: 1,
    method: method,
    origin: getNavigationHistory(),
    event: event,
  };

  pushDatalayer({ ecommerce: null }, 'newDataLayer');
  pushDatalayer(eventObj, 'newDataLayer');
};

export const sendCheckoutFunnelEventToDataLayer = (event, obj) => {
  const eventDictionary = {
    begin_checkout: 1,
    add_shipping_info: 2,
    add_payment_info: 3,
  };

  const interactionCount =
    eventDictionary[event] === 1 ? getInteractionCount(SESSION_INTERTACTION_CART) : 1;

  const shipping_tier =
    obj.deliveryGroups.map(group => {
      return DELIVERY_CODES[group.TipoEntrega];
    }) || [];

  const eventObj = {
    event_type: 'click',
    event: event,
    lat_long: addLatLongAccuracyToDataLayer(),
    timestamp: new Date(),
    interaction_count: interactionCount,
    ecommerce: {
      currency: 'BRL',
      coupon: obj.coupon,
      value: obj.value,
      shipping: obj.shipping,
      shipping_tier: shipping_tier.toString(),
      payment_type: obj.payment_type,
      items: obj.items,
      items_count: obj.items.length,
      checkout: {
        actionField: {
          step: eventDictionary[event],
          option: shipping_tier.toString(),
          products: obj.products,
        },
      },
    },
  };
  pushDatalayer({ ecommerce: null }, 'newDataLayer');
  pushDatalayer(eventObj, 'newDataLayer');
};
export const sendViewCartEventToDataLayer = obj => {
  const eventObj = {
    event_type: 'item_load',
    event: 'view_cart',
    lat_long: addLatLongAccuracyToDataLayer(),
    timestamp: new Date(),
    interaction_count: getInteractionCount(SESSION_INTERTACTION_CART),
    ecommerce: {
      items_count: obj.quantidadeTotalItens,
      currency: 'BRL',
      coupon: obj.promocode || '',
      value: currencyToNumber(obj.subTotal),
      shipping: currencyToNumber(obj.frete),
      shipping_tier: '',
      tax: '',
      items: obj.itens.map(item => {
        return {
          item_name: item.nome,
          item_custom_product: item.personalizado,
          item_custom_product_error: item.erros?.slice(),
          item_id: item.sku,
          item_variant: item.cor,
          quantity: item.quantidade,
          price: Number(item.valorUnitarioPor.replace(',', '.')),
          seller: item.nomeSeller,
          seller_type: item.nomeSeller === 'Centauro' ? 'first_party' : 'third_party',
        };
      }),
    },
  };
  pushDatalayer({ ecommerce: null }, 'newDataLayer');
  pushDatalayer(eventObj, 'newDataLayer');
};

export const sendEventClickPdpToDataLayer = product => {
  const objEvent = {
    event_type: 'item_view',
    event: 'view_item',
    lat_long: addLatLongAccuracyToDataLayer(),
    timestamp: new Date(),
    interaction_count: getInteractionCount(SESSION_INTERTACTION_PDP),
    ecommerce: {
      items: [
        {
          item_name: product.informacoes.nome,
          item_id: product.informacoes.codigo,
          price: currencyToNumber(product.price),
          item_brand: product.informacoes.marca,
          item_category: product.informacoes.categoria,
          item_variant: product.tagColor,
          item_list_name: product.origin ? product.origin : '',
          item_list_id: getProductId(product.id),
          index: !isNumeric(product.index) ? 0 : product.index + 1,
          quantity: 1,
          rating_value: product.rating ? Number(product.rating[0]) : 0,
          rating_count: product.reviews ? Number(product.reviews[0]) : 0,
          discount_percentage: product.discount,
          interest_free_installments_count: Number(product.conditionNum),
          interest_free_installments_value: currencyToNumber(product.conditionPrice),
          available_tags: 'N/A',
          available_colors: getProductColors(product.disponibilidade.cores),
          available_sizes: getProductSizes(product.availableSizes),
          available_appeals: product.disponibilidade.cores[0].modeloCorSelos?.selos,
          media: {
            images_count: product.imagens?.length,
            videos_count: product.videos?.length,
          },
          seller: product.seller,
          seller_type: product.sellerType,
        },
      ],
    },
    currencyCode: 'BRL',
    detail: [
      {
        name: product.informacoes.nome,
        id: product.informacoes.codigo,
        price: currencyToNumber(product.price),
        brand: product.informacoes.marca,
        category: product.informacoes.categoria,
        variant: product.tagColor,
        list: product.origin ? product.origin : 'self',
        position: !isNumeric(product.index) ? 'N/A' : product.index + 1,
      },
    ],
  };

  const promotionalCampaignObj = JSON.parse(localStorage.getItem('promotionsCampaignsAnalytics'));

  if (!promotionalCampaignObj) {
    pushDatalayer({ ecommerce: null, detail: null }, 'newDataLayer');
    pushDatalayer(objEvent, 'newDataLayer');
    return;
  }

  const promotionalCampaign = handlerPromotionalCampaign(product, promotionalCampaignObj);
  pushDatalayer({ ecommerce: null, detail: null }, 'newDataLayer');
  pushDatalayer(
    {
      ...objEvent,
      ecommerce: {
        ...objEvent.ecommerce,
        promoClick: {
          ...promotionalCampaign.promoClick,
        },
        items: [
          {
            ...objEvent.ecommerce.items[0],
            ...promotionalCampaign.item,
          },
        ],
      },
    },
    'newDataLayer',
  );
};

export const sendEventFromCartToDataLayer = (product, event, origin) => {
  const productInformation = getProductInformation(product);

  const price =
    typeof productInformation.price === 'string'
      ? currencyToNumber(productInformation.price)
      : productInformation.price;

  const sessionKey = sessions[origin] || 'generic';

  const objEvent = {
    event_type: 'click',
    event: event,
    lat_long: addLatLongAccuracyToDataLayer(),
    timestamp: new Date(),
    interaction_count: sessionKey === 'generic' ? 1 : getInteractionCount(sessionKey),
    ecommerce: {
      items: [
        {
          item_name: productInformation.item_name,
          item_id: productInformation.item_id,
          price: price,
          item_brand: productInformation.item_brand,
          item_category: productInformation.item_category,
          item_custom_product: productInformation.item_custom_product,
          seller: [productInformation.item_seller],
          seller_type: productInformation.item_seller_type,
          item_variant: productInformation.item_variant,
          item_list_name: productInformation.item_list_name,
          item_list_id: productInformation.item_list_id,
          index: productInformation.index,
          quantity: productInformation.quantity,
          rating_value: productInformation.rating_value,
          rating_count: Number(productInformation.rating_count),
          discount_percentage: productInformation.discount_percentage,
          interest_free_installments_count: productInformation.interest_free_installments_count,
          interest_free_installments_value: productInformation.interest_free_installments_value,
          available_tags: productInformation.available_tags,
          available_colors: productInformation.available_colors,
          available_sizes: productInformation.available_sizes,
          available_appeals: productInformation.available_appeals,
        },
      ],
    },
    currencyCode: 'BRL',
    detail: [
      {
        name: productInformation.item_name,
        id: productInformation.item_id,
        price: price,
        brand: productInformation.item_brand,
        category: productInformation.item_category,
        variant: productInformation.item_variant,
        quantity: productInformation.quantity,
        seller: [productInformation.item_seller],
        seller_type: productInformation.item_seller_type,
      },
    ],
  };

  pushDatalayer({ ecommerce: null, detail: null }, 'newDataLayer');
  pushDatalayer(objEvent, 'newDataLayer');
};

export const sendSelectProductToDataLayerDesktop = product => {
  const hasProduct = () => {
    return product && product.productDetails && Object.keys(product.productDetails).length > 0;
  };
  const listOrigin = getItemListOrigin();

  const eventObj = {
    event_type: 'click',
    event: 'select_item',
    lat_long: addLatLongAccuracyToDataLayer(),
    timestamp: new Date(),
    interaction_count: getInteractionCount(SESSION_INTERTACTION_NAV),
    ecommerce: {
      items: [
        {
          item_name: hasProduct() ? product.productDetails.informacoes.nome : 'N/A',
          item_id: hasProduct() ? product.productDetails.informacoes.codigo : 'N/A',
          price: hasProduct() ? currencyToNumber(product.price) : 0,
          item_brand: hasProduct() ? product.productDetails.informacoes.marca : 'N/A',
          item_category: hasProduct() ? product.productDetails.informacoes.categoria : 'N/A',
          item_variant: hasProduct() ? product.selectedColor : 'N/A',
          item_list_name: hasProduct() ? product.itemList.origin : 'N/A',
          item_list_id: window.location.pathname,
          index: hasProduct() ? product.itemList.index + 1 : 'N/A',
          quantity: 1,
          rating_value: hasProduct() ? product.rate : 'N/A',
          rating_count: hasProduct() ? Number(product.reviews) : 0,
          discount_percentage: hasProduct() ? product.discount : 0,
          interest_free_installments_count: hasProduct() ? product.installment.count : 0,
          interest_free_installments_value: hasProduct() ? product.installment.price : 0,
          available_tags: 'N/A',
          available_colors: hasProduct()
            ? getProductColors(product.productDetails.disponibilidade.cores)
            : 'N/A',
          available_sizes: hasProduct() ? getProductSizes(product.aSizes) : 'N/A',
          available_appeals: hasProduct()
            ? product.productDetails.disponibilidade.cores[0].modeloCorSelos.selos
            : 'N/A',
          seller: product.seller,
          seller_type: product.sellerType,
        },
      ],
      actionField: {
        list: hasProduct() ? listOrigin : 'N/A',
      },
      products: [
        {
          name: hasProduct() ? product.productDetails.informacoes.nome : 'N/A',
          id: hasProduct() ? product.productDetails.informacoes.codigo : 'N/A',
          price: hasProduct() ? currencyToNumber(product.price) : 'N/A',
          brand: hasProduct() ? product.productDetails.informacoes.marca : 'N/A',
          category: hasProduct() ? product.productDetails.informacoes.categoria : 'N/A',
          variant: hasProduct() ? product.selectedColor : 'N/A',
          list: hasProduct() ? listOrigin : 'N/A',
          position: hasProduct() ? product.itemList.index + 1 : 'N/A',
        },
      ],
    },
    item_list: {
      type: hasProduct() ? listOrigin : 'N/A',
      id: window.location.pathname,
      format: hasProduct() ? product.itemList.desktopColumnsSize : 'N/A',
      items_cta: 'Adicionar ao carrinho',
      items_order: hasProduct() ? product.itemList.sort.iSort : 'N/A',
      available_filters: hasProduct() ? getAvailableFilters(product.itemList.filters) : 'N/A',
      selected_filters: hasProduct() ? getSelectedFilters() : 'N/A',
      slot_index: 0,
      pagination: {
        pages_count: hasProduct() ? product.itemList.pages.total : 'N/A',
        current_page: hasProduct() ? getCurrentPageNumber(product.itemList.pages.Current) : 'N/A',
        page_max_size: hasProduct() ? product.itemList.pages.productsPerPage : 'N/A',
        page_size: hasProduct() ? product.itemList.pages.productsOnPage : 'N/A',
        page_items: hasProduct() ? [product.productDetails.informacoes.codigo] : 'N/A',
      },
    },
  };

  pushDatalayer({ ecommerce: null }, 'newDataLayer');
  pushDatalayer(eventObj, 'newDataLayer');
  pushDatalayer({ ...eventObj, event: 'productClick' }, 'newDataLayer');
};

export const sendSelectProductToDataLayerMobile = product => {
  const hasProduct = () => {
    return product && product.details && Object.keys(product.details).length > 0;
  };
  const listOrigin = getItemListOrigin();
  const eventObj = {
    event_type: 'click',
    event: 'select_item',
    lat_long: addLatLongAccuracyToDataLayer(),
    timestamp: new Date(),
    interaction_count: getInteractionCount(SESSION_INTERTACTION_NAV),
    ecommerce: {
      items: [
        {
          item_name: hasProduct() ? product.name : 'N/A',
          item_id: hasProduct() ? product.id : 'N/A',
          price: hasProduct() ? product.price : 0,
          item_brand: hasProduct() ? product.details.Marca[0] : 'N/A',
          item_category: hasProduct() ? product.details.Categoria[0] : 'N/A',
          item_variant: hasProduct() ? product.details.id_cor[0] : 'N/A',
          item_list_name: hasProduct() ? listOrigin : 'N/A',
          item_list_id: window.location.pathname,
          index: hasProduct() ? product.itemList.index + 1 : 'N/A',
          quantity: 1,
          rating_value: hasProduct() ? product.details.rate[0] : 0,
          rating_count: hasProduct() ? Number(product.details.reviews[0]) : 0,
          discount_percentage: hasProduct() ? product.discount : 'N/A',
          interest_free_installments_count: hasProduct() ? product.installment.count : 0,
          interest_free_installments_value: hasProduct() ? product.installment.price : 0,
          available_tags: 'N/A',
          available_colors: 'N/A',
          available_sizes: 'N/A',
          available_appeals: hasProduct() ? product.details.UrlSeloCampanha : 'N/A',
          seller: product.seller,
          seller_type: product.sellerType,
        },
      ],
      actionField: {
        list: hasProduct() ? product.itemList.origin : 'N/A',
      },
      products: [
        {
          name: hasProduct() ? product.name : 'N/A',
          id: hasProduct() ? product.id : 'N/A',
          price: hasProduct() ? product.price : 'N/A',
          brand: hasProduct() ? product.details.Marca[0] : 'N/A',
          category: hasProduct() ? product.details.Categoria[0] : 'N/A',
          variant: hasProduct() ? product.details.id_cor[0] : 'N/A',
          list: hasProduct() ? listOrigin : 'N/A',
          position: hasProduct() ? product.itemList.index + 1 : 'N/A',
        },
      ],
    },
    item_list: {
      type: hasProduct() ? listOrigin : 'N/A',
      id: window.location.pathname,
      format: hasProduct() ? product.itemList.desktopColumnsSize : 'N/A',
      items_cta: 'Adicionar ao carrinho',
      items_order: hasProduct() ? product.itemList.sort.iSort : 'N/A',
      available_filters: hasProduct() ? getAvailableFilters(product.itemList.filters) : 'N/A',
      selected_filters: hasProduct() ? getSelectedFilters() : 'N/A',
      slot_index: 0,
      pagination: {
        pages_count: hasProduct() ? product.itemList.pages.total : 'N/A',
        current_page: hasProduct() ? getCurrentPageNumber(product.itemList.pages.Current) : 'N/A',
        page_max_size: hasProduct() ? product.itemList.pages.productsPerPage : 'N/A',
        page_size: hasProduct() ? product.itemList.pages.productsOnPage : 'N/A',
        page_items: hasProduct() ? [product.id] : 'N/A',
      },
    },
  };

  pushDatalayer({ ecommerce: null }, 'newDataLayer');
  pushDatalayer(eventObj, 'newDataLayer');
  pushDatalayer({ ...eventObj, event: 'productClick' }, 'newDataLayer');
};

export const sendProductListToDataLayer = (
  productList,
  forAnalytcs = {},
  itemListType,
  itemsOrder,
  desktopColumnsSize,
  eventParam = {},
  searchTerm,
) => {
  const percentage = (num, per) => 100 - Math.ceil((num / per) * 100, 10);
  const isHotSite = window.location.pathname.includes('/hotsite/');
  const isShowCaseType = itemListType === 'showcase_results';

  const eventObj = {
    event_type: eventParam.type,
    event: eventParam.name,
    lat_long: addLatLongAccuracyToDataLayer(),
    timestamp: new Date(),
    interaction_count: getInteractionCount(SESSION_INTERTACTION_NAV),
    item_list: {
      type: itemListType,
      id:
        itemListType === 'showcase_results'
          ? `${window.location.pathname} position ${forAnalytcs.showId + 1}`
          : window.location.pathname,
      format: desktopColumnsSize,
      items_cta: itemListType === 'showcase_results' ? 'N/A' : 'Adicionar ao carrinho',
      items_order: itemsOrder,
      available_filters:
        itemListType === 'showcase_results' ? 'N/A' : getAvailableFilters(forAnalytcs.filters),
      selected_filters: itemListType === 'showcase_results' ? 'N/A' : getSelectedFilters(),
      slot_index:
        itemListType === 'showcase_results' ? forAnalytcs.showId + 1 : getSelectedFilters(),
      search_term: searchTerm,
      pagination: {
        pages_count: parseInt(forAnalytcs.pagination.size / forAnalytcs.pagination.productsPerPage),
        current_page:
          itemListType === 'showcase_results'
            ? forAnalytcs.pagination.currentPage
            : getCurrentPageNumber(forAnalytcs.pagination.Current),
        page_max_size: forAnalytcs.pagination.productsPerPage,
        page_size:
          itemListType === 'showcase_results'
            ? forAnalytcs.pagination.size
            : forAnalytcs.pagination.productsOnPage,
        page_items: getProductsId(productList),
      },
    },
    ecommerce: {
      items: productList.map((product, index) => {
        const sellerInfo = getSellerInfo(product);
        const price =
          typeof product.price === 'string' ? currencyToNumber(product.price) : product.price;
        const priceOld =
          typeof product.oldPrice === 'string'
            ? currencyToNumber(product.oldPrice)
            : product.oldPrice;
        const discountPercentage = !price || !priceOld ? 0 : percentage(price, priceOld);

        return {
          item_name: product.name,
          item_id: product.id,
          price: price,
          item_brand: isShowCaseType ? 'N/A' : product.details?.Marca[0],
          item_category: isShowCaseType ? 'N/A' : product.details?.Categoria[0],
          item_variant: isShowCaseType ? 'N/A' : product.details?.id_cor[0],
          item_list_name: itemListType,
          item_list_id: window.location.pathname,
          index: index + 1,
          quantity: 1,
          rating_value: isShowCaseType ? product?.reviews?.average : product.details.rate[0],
          rating_count: isShowCaseType
            ? product?.reviews?.count
            : Number(product.details.reviews[0]),
          discount_percentage: isShowCaseType ? discountPercentage : product.discount,
          interest_free_installments_count: product.installment?.count,
          interest_free_installments_value: product.installment?.price,
          available_tags: 'N/A',
          available_colors: 'N/A',
          available_sizes: 'N/A',
          available_appeals: 'N/A',
          seller: sellerInfo.seller,
          seller_type: sellerInfo.sellerType,
        };
      }),
      currencyCode: 'BRL',
      impressions: productList.map((product, index) => {
        const price =
          typeof product.price === 'string' ? currencyToNumber(product.price) : product.price;

        return {
          name: product.name,
          id: product.id,
          price: price,
          brand: isShowCaseType ? 'N/A' : product.details?.Marca[0],
          category: isShowCaseType ? 'N/A' : product.details?.Categoria[0],
          variant: isShowCaseType ? 'N/A' : product.details?.id_cor[0],
          list: itemListType,
          position: index + 1,
        };
      }),
    },
  };

  pushDatalayer({ ecommerce: null, impressions: null, item_list: null }, 'newDataLayer');
  pushDatalayer({ ...eventObj }, 'newDataLayer');
  pushDatalayer({ ...eventObj, event: 'impressions' }, 'newDataLayer');

  if (isHotSite && parseInt(sessionStorage.getItem('interaction-count-navigation')) === 1) {
    const ecommerce = {
      ...eventObj.ecommerce,
      promoView: {
        promotions: productList.map((product, index) => {
          return {
            id: product.id,
            name: product.name,
            creative: 'N/A',
            position: index + 1,
          };
        }),
      },
    };

    pushDatalayer({ ecommerce: null, impressions: null, item_list: null }, 'newDataLayer');
    pushDatalayer({ ...eventObj, ecommerce, event: 'promoView' }, 'newDataLayer');
  }

  if (isHotSite) {
    const items = {
      ...eventObj.ecommerce.items.map((items, index) => {
        return {
          ...eventObj.ecommerce.items[index],
          promotion_id:
            window.location.pathname.split('/hotsite/')[1] + ' ' + new Date().getFullYear(),
          promotion_name: window.location.pathname.split('/hotsite/')[1],
          creative_name: window.location.pathname.split('/hotsite/')[1] + ' Page',
          creative_slot: 'N/A',
          location_id: 'N/A',
          promotion_date: {
            start_date: 'N/A',
            end_date: 'N/A',
            promotion_category: 'N/A',
            promotion_sponsors: 'N/A',
          },
        };
      }),
    };

    pushDatalayer({ ecommerce: null, impressions: null, item_list: null }, 'newDataLayer');
    pushDatalayer({ ...eventObj, items, event: 'view_promotion' }, 'newDataLayer');
  }
};

export const sendPurchaseEventToDataLayer = (paymentDetails, orderDetails, identificationData) => {
  const items =
    !paymentDetails?.deliveryGroups || paymentDetails.deliveryGroups.length === 0
      ? []
      : paymentDetails.deliveryGroups
          .map(deliveryGroup => {
            return deliveryGroup.tiposDeEntrega[0].itens.map(
              ({
                descricao = '',
                sku = '',
                cor = '',
                quantidade = 0,
                valorUnitario = 0,
                valorFrete = 0,
              }) => {
                return {
                  item_name: descricao,
                  item_id: sku,
                  item_variant: cor,
                  quantity: quantidade,
                  price: valorUnitario,
                  shippingValue: valorFrete,
                  seller: getSellerInformation(sku).seller,
                  seller_type: getSellerInformation(sku).seller_type,
                };
              },
            );
          })
          .flat();

  const products =
    !paymentDetails?.deliveryGroups || paymentDetails.deliveryGroups.length === 0
      ? []
      : paymentDetails.deliveryGroups
          .map(deliveryGroup => {
            return deliveryGroup.tiposDeEntrega[0].itens.map(
              ({ descricao = '', sku = '', cor = '', quantidade = 0, valorUnitario = 0 }) => {
                return {
                  name: descricao,
                  id: sku,
                  variant: cor,
                  quantity: quantidade,
                  price: valorUnitario,
                  seller: getSellerInformation(sku).seller,
                  seller_type: getSellerInformation(sku).seller_type,
                };
              },
            );
          })
          .flat();

  const getPhoneNumber = () => {
    if (
      identificationData?.endereco?.telefoneAdicional?.ddd &&
      identificationData?.endereco?.telefoneAdicional?.numero
    ) {
      return `${identificationData.endereco.telefoneAdicional.ddd}${identificationData.endereco.telefoneAdicional.numero}`;
    }

    if (
      identificationData?.endereco?.telefoneFixo?.ddd &&
      identificationData?.endereco?.telefoneFixo?.numero
    ) {
      return `${identificationData.endereco.telefoneFixo.ddd}${identificationData.endereco.telefoneFixo.numero}`;
    }

    return '';
  };

  const coupon = paymentDetails?.discountCouponValue || '';
  const transactionId = paymentDetails?.transactionId || '';
  const totalWithoutDiscount = paymentDetails?.subTotal || '0';
  const shipping = orderDetails?.valorTotalFrete || 0;
  const shipping_tier =
    orderDetails?.gruposDeEntrega.map(grupo => {
      return DELIVERY_CODES[grupo.tipoEntregaId];
    }) || [];

  const email = identificationData?.email || '';
  const nome = identificationData?.nome || '';
  const sobrenome = identificationData?.sobrenome || '';
  const logradouro = orderDetails?.enderecoDeEntrega?.logradouro || '';
  const cidade = orderDetails?.enderecoDeEntrega?.cidade || '';
  const cep = orderDetails?.enderecoDeEntrega?.cep || '';
  const pais = orderDetails?.enderecoDeEntrega?.pais || '';
  const payment_type = paymentDetails?.paymentMethodSelected || '';

  const eventObj = {
    event_type: 'click',
    event: 'purchase',
    lat_long: addLatLongAccuracyToDataLayer(),
    interaction_count: 1,
    timestamp: new Date(),
    ecommerce: {
      currency: 'BRL',
      coupon: coupon,
      value: currencyToNumber(totalWithoutDiscount),
      shipping: shipping,
      shipping_tier: shipping_tier.toString(),
      tax: 0,
      transaction_id: transactionId,
      affiliation: 'Centauro',
      items_count: items.length,
      items: items,
      payment_type: payment_type,
      purchase: {
        actionField: {
          id: transactionId,
          revenue: currencyToNumber(totalWithoutDiscount),
          tax: 0,
          shipping: shipping,
          coupon: coupon,
          products: products,
        },
      },
    },
    google_ads_enhanced_conversion: {
      email: email,
      phone_number: getPhoneNumber(),
      first_name: nome,
      last_name: sobrenome,
      home_address: {
        street: logradouro,
        city: cidade,
        region: 'N/A',
        postal_code: cep,
        country: pais,
      },
    },
  };

  pushDatalayer({ ecommerce: null }, 'newDataLayer');
  pushDatalayer(eventObj, 'newDataLayer');
};

export const sendShareItemEventToDataLayer = obj => {
  const eventObj = {
    event_type: 'click',
    event: 'share_item',
    lat_long: addLatLongAccuracyToDataLayer(),
    timestamp: new Date(),
    interaction_count: getInteractionCount(SESSION_INTERTACTION_SHARE_ITEM),
    channel: 'whatsapp',
    ecommerce: {
      items: [
        {
          item_name: obj.informacoes.nome,
          item_id: obj.disponibilidade.skuSelecionado,
          item_category: obj.informacoes.categoria || '',
          price: currencyToNumber(obj?.precos[0]?.valorPadrao) || 0,
          seller: obj.informacoes.nomeSeller || '',
          seller_type: obj.informacoes.nomeSeller === 'Centauro' ? 'first_party' : 'third_party',
          quantity: 1,
          item_brand: obj.informacoes.marca || '',
          available_sizes: getProductSizes(obj.disponibilidade.cores[0]?.tamanhosDisponiveis) || [],
          avaiable_colors: getProductColors(obj.disponibilidade.cores) || [],
          media: {
            images_count: obj.imagens.length,
            videos_count: obj.videos.length,
          },
          interest_free_installments_count: obj.precos[0]?.numeroDeParcelas || 0,
          interest_free_installments_value:
            currencyToNumber(obj.precos[0]?.quantidadePorParcelaPadrao) || 0,
          discount_percentage: obj.precos[0]?.porcentagem || 0,
        },
      ],
    },
  };

  pushDatalayer({ ecommerce: null }, 'newDataLayer');
  pushDatalayer(eventObj, 'newDataLayer');
};

export const sendBannerEventsToDataLayer = (event, component, bannerInfo) => {
  const initialPosition = 1;

  const eventType = {
    load_creative: 'item_load',
    view_creative: 'view_item',
    select_creative: 'click',
  };

  const destinationUrl =
    bannerInfo?.data?.listaLinks[0]?.urlLink ||
    bannerInfo?.bannerLink ||
    bannerInfo?.itemLink?.urlLink ||
    bannerInfo?.urlLink ||
    '';

  const bannerImageUrl =
    bannerInfo?.data?.listaLinks[0]?.urlImagem ||
    bannerInfo?.bannerImg ||
    bannerInfo?.itemLink?.urlIcone ||
    bannerInfo?.urlImagem ||
    '';

  const getMicroComponent = () => {
    if (component === 'header') {
      return 'main_menu';
    } else if (bannerInfo?.position === initialPosition) {
      return 'carousel';
    } else {
      return formatBannerValues(
        bannerInfo?.atributoEvento ||
          bannerInfo?.itemLink?.atributoEvento ||
          bannerInfo?.data?.listaLinks[0]?.atributoEvento,
      );
    }
  };

  const getMicroComponentDetails = () => {
    if (component === 'header') {
      return formatBannerValues(bannerInfo?.title);
    } else if (bannerInfo?.position === initialPosition) {
      return bannerInfo?.data?.ordem || bannerInfo?.atributoEvento;
    } else {
      return formatBannerValues(bannerImageUrl);
    }
  };

  const getCreativeId = () => {
    const microComponent = getMicroComponent();
    const transformUrlInText = bannerImageUrl
      .replace(/^https?:\/\//, '')
      .replace(/[./-]/g, '_')
      .toLowerCase();

    const creativeId = `${component}_${microComponent}_${transformUrlInText}`;

    return creativeId;
  };

  const eventObj = {
    event_type: eventType[event],
    event: event,
    lat_long: addLatLongAccuracyToDataLayer(),
    timestamp: new Date(),
    interaction_count: getInteractionCount(SESSION_INTERTACTION_BANNER_EVENTS),
    destination_url: destinationUrl,
    component: component,
    micro_component: getMicroComponent(),
    micro_component_detail: getMicroComponentDetails(),
    creative_id: getCreativeId(),
  };

  pushDatalayer(eventObj, 'newDataLayer');
};
