import { action, observable, computed } from 'mobx';
import history from '../history';

import CustomProductService from '../services/customProductService';
import CustomProductServiceSeller from '../services/customProductServiceSeller';
import BuyBoxService from '../services/buyBoxService';
import ProductPricesService from '../services/productPricesService';
import ProductImageService from '../services/productImageService';

import { isFootballBoots, isShirt, isPrePersonalized } from '../helpers/customizationHelper';
import { sliderImageResolution, activeImageResolution } from '../helpers/resolutionHelper';

import { sendProductToDataLayer, isMobileFunc, pushDatalayer } from '../utils/utils';
import { sendEvent } from '../utils/Analytics';
import { isSPA } from '../helpers/ssrHelper';
import checkAvailableStoreId from '../utils/Product';

import { sendApiClick } from '../modules/apiCommons';
import { genNewProduct } from '../modules/cartLocalStorage';
import { getIsSellerNike } from '@/components/pdp/personalization/personalization.ts';
import { buyPersonalization3PEvent } from '@/components/pdp/personalization/trackingEvents.ts';

const sizeType = 'available';

const getSellerName = productDetails => {
  const isNotBuyBox = productDetails?.tipo !== 'BUYBOX';
  const isNotUnique = productDetails?.disponibilidade?.codigosTamanhos[0] !== 'ÚNICO';

  if (isNotBuyBox || isNotUnique) {
    return productDetails?.informacoes?.nomeSeller || 'MultiSeller';
  }

  return productDetails?.disponibilidade?.cores[0]?.tamanhosDisponiveis[0]?.nomeSeller || '';
};

const setCustomization = (objColorSelected, sizeSelected = '') => {
  const isSizeCustomizable = Boolean(
    objColorSelected?.tamanhosDisponiveis?.find(
      obj => obj.skuPersonalizavel && obj.tamanho === sizeSelected,
    ),
  );

  return {
    customizable: objColorSelected.customizavel,
    customizeType: objColorSelected.tipoPersonalizacao,
    idolValue: objColorSelected.valorIdolo,
    nameValue: objColorSelected.valorNome,
    numberValue: objColorSelected.valorNumero,
    rightExternalValue: objColorSelected.valorDireitoExterno,
    rightInternalValue: objColorSelected.valorDireitoInterno,
    leftExternalValue: objColorSelected.valorEsquerdoExterno,
    leftInternalValue: objColorSelected.valorEsquerdoInterno,
    externalFootEnabled: Boolean(objColorSelected.habilitaLadoExterno),
    internalFootEnabled: Boolean(objColorSelected.habilitaLadoInterno),
    leftExternalType: objColorSelected.peEsquerdoExterno,
    leftInternalType: objColorSelected.peEsquerdoInterno,
    rightExternalType: objColorSelected.peDireitoExterno,
    rightInternalType: objColorSelected.peDireitoInterno,
    isSizeCustomizable,
  };
};
class ProductStore {
  @observable productItemURL = '';

  @observable productItem = {};

  @observable availableColors = [];

  @observable colorSelected = '';

  @observable sizeSelected = '';

  @observable skuSelected = '';

  @observable updatedProductItem = {};

  @observable activeColorCode = '';

  @observable sku = '';

  @observable customItemProperties = {};

  @observable isPersonaSku = false;

  @observable customizeRequest = false;

  @observable productImages = {};

  @observable productImageSelectedIndex = -1;

  @observable loadedProduct = false;

  @observable videoId = '';

  @computed get backShirtCustomized() {
    return { ...this.productImages?.imageSlider?.find(obj => obj.custom) };
  }

  @computed get backShirt() {
    if (!this.productItem.disponibilidade) {
      return {};
    }

    const backShirtCustomSku = `${this.productItem.disponibilidade.skuSelecionado}A1`;

    const backShirt = this.productImages?.imageSlider?.find(obj =>
      obj.url.includes(backShirtCustomSku),
    );

    if (backShirt) {
      return { ...backShirt };
    }
    return this.backShirtCustomized;
  }

  @computed get hasShirtCustomization() {
    const { customName, customNumber } = this.customItemProperties;

    const hasShirtCustomization = customName || customNumber;

    return !!hasShirtCustomization;
  }

  @computed get hasBootsCustomization() {
    const {
      customNameRightExternalFoot,
      customColorRightExternalFoot,
      customFlagRightExternalFoot,
      customNameLeftExternalFoot,
      customColorLeftExternalFoot,
      customFlagLeftExternalFoot,
      customNameRightInternalFoot,
      customColorRightInternalFoot,
      customFlagRightInternalFoot,
      customNameLeftInternalFoot,
      customColorLeftInternalFoot,
      customFlagLeftInternalFoot,
    } = this.customItemProperties;

    const validRightExternalCustomBoot = Boolean(
      (customNameRightExternalFoot && customColorRightExternalFoot) || customFlagRightExternalFoot,
    );
    const validLeftExternalCustomBoot = Boolean(
      (customNameLeftExternalFoot && customColorLeftExternalFoot) || customFlagLeftExternalFoot,
    );
    const validRightInternalCustomBoot = Boolean(
      (customNameRightInternalFoot && customColorRightInternalFoot) || customFlagRightInternalFoot,
    );
    const validLeftInternalCustomBoot = Boolean(
      (customNameLeftInternalFoot && customColorLeftInternalFoot) || customFlagLeftInternalFoot,
    );

    const validOrEmptyRightExternalCustomBoot = Boolean(
      (!customNameRightExternalFoot &&
        !customColorRightExternalFoot &&
        !customFlagRightExternalFoot) ||
        validRightExternalCustomBoot,
    );
    const validOrEmptyLeftExternalCustomBoot = Boolean(
      (!customNameLeftExternalFoot &&
        !customColorLeftExternalFoot &&
        !customFlagLeftExternalFoot) ||
        validLeftExternalCustomBoot,
    );
    const validOrEmptyRightInternalCustomBoot = Boolean(
      (!customNameRightInternalFoot &&
        !customColorRightInternalFoot &&
        !customFlagRightInternalFoot) ||
        validRightInternalCustomBoot,
    );
    const validOrEmptyLeftInternalCustomBoot = Boolean(
      (!customNameLeftInternalFoot &&
        !customColorLeftInternalFoot &&
        !customFlagLeftInternalFoot) ||
        validLeftInternalCustomBoot,
    );

    const validCustomBoot = Boolean(
      validOrEmptyRightExternalCustomBoot &&
        validOrEmptyLeftExternalCustomBoot &&
        validOrEmptyRightInternalCustomBoot &&
        validOrEmptyLeftInternalCustomBoot,
    );
    const isCustomBootEmpty = Boolean(
      !validRightExternalCustomBoot &&
        !validLeftExternalCustomBoot &&
        !validRightInternalCustomBoot &&
        !validLeftInternalCustomBoot,
    );

    return !isCustomBootEmpty && validCustomBoot;
  }

  @action setLoadedProduct = value => (this.loadedProduct = value);

  @action initializePdp = (product, locationSearch, geolocationSelectedStoresList) => {
    this.setProductItem(product);
    this.setAvailableColors(product);
    this.setActiveColor(product?.imagens, locationSearch);
    this.setSelectedColor('', geolocationSelectedStoresList);
    this.updateActiveImage(this.productImages?.imageSlider[0].ordem);
    this.setLoadedProduct(true);
  };

  @action resetProductStore = () => {
    this.isPersonaSku = false;
    this.customizeRequest = false;
    this.updatedProductItem = {};
    this.customItemProperties = {};
    this.sizeSelected = '';
    this.colorSelected = '';
    this.setLoadedProduct(false);
  };

  @action setProductItem = (item, url = '') => {
    this.productItemURL = url;
    this.productItem = item;
  };

  @action setAvailableColors = objProducts => {
    const availableColors = objProducts?.disponibilidade?.cores
      // TODO: Retirar esse filtro quando a solução vier do back-end
      .filter(color => {
        if (Object.keys(color).length === 0) {
          return false;
        }

        const prices = objProducts?.precos.filter(price => price?.codigoCor === color.codigoCor);

        const availableSizes = color?.tamanhosDisponiveis;

        if (!availableSizes.length) {
          // TODO: Colocar esse evento no GA
          sendEvent(
            'PDP - Error - Sem tamanho disponível',
            'Receber uma cor sem tamanho disponível',
            `Produto com cor sem tamanho disponível: ${objProducts?.disponibilidade?.codigoModelo}`,
          );
        }

        if (!prices.length) {
          sendEvent(
            'PDP - Error - Preço zerado',
            'Receber uma cor sem preço',
            `Produto com cor sem preço: ${objProducts?.disponibilidade?.codigoModelo}`,
          );
        }

        return prices.length > 0 && availableSizes.length > 0;
      });

    this.availableColors = availableColors;
  };

  @action setActiveColor = (images, locationSearch) => {
    let cor = '';

    const colorCode = String(locationSearch).match(/cor=\w{2}/g);

    if (colorCode) {
      const listColor = colorCode.toString().split('=');
      cor = listColor[listColor.length - 1];
    }

    const queryColor = this.availableColors.find(color => color.codigoCor === cor);

    if (queryColor) {
      this.colorSelected = queryColor.codigoCor;
    } else if (this.availableColors.length > 0) {
      this.colorSelected = this.availableColors[0].codigoCor;
    } else {
      this.colorSelected = images[0].codigoCor;
    }
  };

  @action setActiveColorCode = activeColorCode => (this.activeColorCode = activeColorCode);

  @action setSku = sku => (this.sku = sku);

  @action setIsPersonaSku = isPersona => (this.isPersonaSku = isPersona);

  @action setCustomizeRequest = isRequesting => (this.customizeRequest = isRequesting);

  @action setProductImages = images => (this.productImages = images);

  @action setVideoId = id => (this.videoId = id);

  @action setProductImageSelectedIndex = index => (this.productImageSelectedIndex = index);

  @action updateActiveImage = imageIndex => {
    const activeImage = this.productImages.imageSlider.find(obj => obj.ordem === imageIndex);

    const isMobile = isMobileFunc();

    if (activeImage.video) {
      this.setVideoId(activeImage.video);
      sendEvent(
        'UI - PDP - Vídeo',
        'Click - Exibir Vídeo',
        `Seleção de Vídeo: ${this.skuSelected} ${this.colorSelected}`,
      );
    } else if (activeImage.custom) {
      this.setProductImages({
        ...this.productImages,
        activeImage: activeImage.url,
        metaImage: activeImage.url,
      });
      this.setVideoId('');
    } else {
      const productImageService = ProductImageService({
        page: 'pdp',
        images: this.productItem.imagens,
        colorSelected: this.colorSelected,
        videos: this.productItem.videos,
        tabSelected: imageIndex,
      });

      const imageObj = productImageService.generateProductImageObject();

      this.setVideoId('');

      this.setProductImages({
        ...this.productImages,
        ...imageObj,
      });
    }

    this.setProductImageSelectedIndex(imageIndex);

    sendEvent(
      isMobile ? 'UI - PDP - Zoom foto Mobile' : 'UI - PDP - Escolha de Foto',
      isMobile ? 'Click - Exibir Foto com zoom' : 'Click - Exibir Foto',
      `Seleciona Foto: ${this.productItem.disponibilidade.codigoModelo} ${
        this.colorSelected
      } - ${imageIndex + 1}`,
    );
  };

  @action changeCustomizationValue = fields => {
    this.customItemProperties = {
      ...this.customItemProperties,
      ...fields,
    };
  };

  @action updateCustomShirtImageSlider = imageSlider =>
    (this.productImages = {
      ...this.productImages,
      imageSlider,
    });

  @action resetImageSlider = () => {
    const { imageSlider } = this.productImages;

    const backShirtCustomized = this.backShirtCustomized;

    if (Object.keys(backShirtCustomized).length) {
      backShirtCustomized.resolucao = sliderImageResolution;
      backShirtCustomized.custom = false;
      backShirtCustomized.url = backShirtCustomized.oldUrl;

      const newImageSlider = imageSlider.map(image => {
        if (backShirtCustomized.ordem === image.ordem) {
          return backShirtCustomized;
        }
        return image;
      });

      this.updateCustomShirtImageSlider(newImageSlider);
      this.updateActiveImage(backShirtCustomized.ordem);
    }
  };

  @action setCustomImagePersona = customUrl => {
    const { imageSlider } = this.productImages;
    const backShirt = this.backShirt;

    if (Object.keys(backShirt).length) {
      let backShirtUrl = backShirt.url.split(sliderImageResolution);

      if (!backShirt.custom) {
        backShirt.oldUrl = backShirt.url;
      } else {
        backShirtUrl = backShirt.oldUrl.split(sliderImageResolution);
      }

      const backShirtHD = `${backShirtUrl[0]}${activeImageResolution}${backShirtUrl[1]}`;

      backShirt.custom = true;
      backShirt.resolucao = activeImageResolution;
      backShirt.url = customUrl || backShirtHD;

      const newImageSlider = imageSlider.map(image => {
        if (backShirt.ordem === image.ordem) {
          return backShirt;
        }
        return image;
      });

      this.updateCustomShirtImageSlider(newImageSlider);
      this.updateActiveImage(backShirt.ordem);
    }
  };

  @action requestCustomize = isRequesting => {
    const { customizeType } = this.customItemProperties;

    if (isFootballBoots(customizeType)) {
      if (isRequesting) {
        sendEvent(
          'UI - PDP - Customizar produto - Abrir',
          'Click - Abrir customização de chuteira',
          `Abrir - Customizar chuteira - ${this.productItem.disponibilidade.codigoModelo} ${this.colorSelected} ${this.sizeSelected}`,
        );
      }
    } else if (isRequesting) {
      sendEvent(
        'UI - PDP - Customizar produto - Abrir',
        'Click - Abrir customização de camiseta',
        `Abrir - Customizar camiseta - ${this.productItem.disponibilidade.codigoModelo} ${this.colorSelected} ${this.sizeSelected}`,
      );
    } else {
      if (isShirt(customizeType)) {
        this.resetImageSlider();
      }
    }

    this.setCustomizeRequest(isRequesting);
  };

  @action setSelectedSize = (
    sizeSelected,
    skuSelected,
    sizeStores,
    geolocationSelectedStoresList,
  ) => {
    const { availableSizes } = this.updatedProductItem;

    const infosSizeSelected = availableSizes.find(size => size.tamanho === sizeSelected);

    const buyBoxService = BuyBoxService({
      prices: this.productItem.precos,
      type: this.productItem.tipo,
      availableSizes,
      colorSelected: this.colorSelected,
      sizeSelected,
    });

    const buyBoxObj = buyBoxService.generateBuyBoxObject();

    const sizeAvailableStores = sizeStores
      ? geolocationSelectedStoresList.filter(store =>
          sizeStores.find(sizeStoreId =>
            checkAvailableStoreId(store.estabelecimentoId, sizeStoreId),
          ),
        )
      : [];

    this.sizeSelected = sizeSelected;

    this.skuSelected = skuSelected;

    this.updatedProductItem = {
      ...this.updatedProductItem,
      ...buyBoxObj,
      sizeAvailableStores,
    };

    this.customItemProperties = {
      ...this.customItemProperties,
      isSizeCustomizable: infosSizeSelected?.skuPersonalizavel,
    };

    const hasCustomizable = !!(
      this.customItemProperties.customName || this.customItemProperties.customNumber
    );

    this.requestCustomize(
      (this.customizeRequest || hasCustomizable) && infosSizeSelected?.skuPersonalizavel,
    );

    sendEvent(
      'UI - PDP - Escolha de tamanho',
      'Click - Alterar tamanho do produto',
      `Seleção de tamanho: ${this.productItem.disponibilidade.codigoModelo} ${this.colorSelected} - ${sizeSelected}`,
    );
  };

  @action setSelectedColor = (newColorSelected, geolocationSelectedStoresList) => {
    const hasAnyProductData = this.availableColors.length > 0;
    const colorSelected = newColorSelected || this.colorSelected;

    const productImageService = ProductImageService({
      page: 'pdp',
      images: this.productItem.imagens,
      colorSelected,
      videos: this.productItem.videos,
    });

    const imageObj = productImageService.generateProductImageObject();

    const colorHasVideoObj = imageObj?.imageSlider?.find(item => item.video);

    if (colorHasVideoObj) {
      sendEvent(
        'PDP - Carregar página - Vídeo',
        'Carregar a página',
        `Abrir uma PDP com vídeo: ${colorHasVideoObj.video}`,
        1,
        true,
      );
      this.setVideoId(colorHasVideoObj.video);
    } else {
      this.setVideoId('');
    }

    this.setProductImages(imageObj);

    if (hasAnyProductData) {
      const objColorSelected = this.productItem.disponibilidade.cores.find(
        obj => obj.codigoCor === colorSelected,
      );
      const isBuyBox = this.productItem?.tipo === 'BUYBOX';

      let nonBuyBoxObj = {};

      if (!isBuyBox) {
        const productPricesService = ProductPricesService({
          colorSelected,
          prices: this.productItem.precos,
        });

        nonBuyBoxObj = {
          ...productPricesService.generateProductPricesObject(),
          sellerName: getSellerName(this.productItem),
        };
      }

      // obtem tag da cor ativa
      const tagColor = objColorSelected.nomeCor.toLocaleLowerCase();

      // separa todos os tamanhos disponíveis
      let availableSizes = objColorSelected.tamanhosDisponiveis;
      if (sizeType === 'all') {
        availableSizes = objColorSelected.todosTamanhos;
      }
      if (isBuyBox) {
        availableSizes = availableSizes.filter(size => size.ordem === 1);
      }

      const objCustomization = setCustomization(objColorSelected, this.sizeSelected);

      if (objCustomization?.customizable) {
        sendEvent(
          'UI - PDP - Produto com personalização',
          'Vizualização de produto personalizado',
          `Modelo cor com personalização: ${this.productItem.disponibilidade.skuSelecionado}`,
          1,
          true,
        );

        setTimeout(() => pushDatalayer({ isCustomizableProductPDP: true }), 1000);
      }

      // verifica se o tamanho já selecionado existe na nova cor
      const sizeExists = availableSizes.find(
        value => value.tamanho === this.sizeSelected && value.quantidade > 0,
      );

      // Determina o nome do seller a ser mostrado
      let mainSellerName = availableSizes[0]?.nomeSeller;

      if (sizeExists) {
        mainSellerName = sizeExists.sellerName;
      }

      const skuAvailable = sizeExists?.sku || '';

      const buyBoxService = BuyBoxService({
        prices: this.productItem.precos,
        type: this.productItem.tipo,
        availableSizes,
        colorSelected,
        sizeSelected: sizeExists ? this.sizeSelected : null,
      });

      const buyBoxObj = buyBoxService.generateBuyBoxObject();

      // obtem sku ativo
      const activeSkuForTags = objColorSelected.sku;

      // Geolocation Stores
      let sizeSelectedStores = [];
      const availableStores = new Set();

      availableSizes?.forEach(size => {
        const lojasRetiraComEstoque = size?.lojasRetiraComEstoque || [];

        if (this.sizeSelected && skuAvailable && size.tamanho === this.sizeSelected.toString()) {
          sizeSelectedStores = lojasRetiraComEstoque;
        }
        size?.lojasRetiraComEstoque?.forEach(stores => availableStores.add(stores));
      });

      const colorAvailableStores = geolocationSelectedStoresList.filter(store =>
        availableStores.find(colorStoreId =>
          checkAvailableStoreId(store.estabelecimentoId, colorStoreId),
        ),
      );

      const sizeAvailableStores = geolocationSelectedStoresList.filter(store =>
        sizeSelectedStores.find(sizeStoreId =>
          checkAvailableStoreId(store.estabelecimentoId, sizeStoreId),
        ),
      );

      this.customItemProperties = {
        ...this.customItemProperties,
        ...objCustomization,
      };

      if (!sizeExists) {
        this.sizeSelected = '';
      }

      this.skuSelected = skuAvailable;

      this.updatedProductItem = {
        ...this.updatedProductItem,
        ...buyBoxObj,
        ...nonBuyBoxObj,
        colorAvailableStores,
        sizeAvailableStores,
        availableSizes,
        tagColor,
        activeSkuForTags,
        ...(mainSellerName && {
          sellerName: mainSellerName,
        }),
      };
    } else {
      this.updatedProductItem = {
        ...this.updatedProductItem,
        price: 'n/a',
      };
    }

    this.colorSelected = colorSelected;
    this.setProductImageSelectedIndex(this.productImages?.imageSlider[0].ordem);

    if (!hasAnyProductData) {
      sendEvent(
        'PDP - Produto sem estoque',
        'Carregar pdp',
        `PDP - Produto sem estoque - Entrar na página sem estoque. Produto: ${this.productItem.disponibilidade.codigoModelo}`,
        1,
        true,
      );
    } else {
      sendEvent(
        'UI - PDP - Escolha de cor',
        'Click - Exibir cor',
        `Seleção de cor: ${this.productItem.disponibilidade.codigoModelo} ${colorSelected}`,
      );
    }
  };

  @action sendClickApiData = () => {
    if (!isSPA) {
      return;
    }
    const objStore = localStorage.getItem('clickApiData');
    const clickApiData = JSON.parse(objStore);
    if (clickApiData?.clickUrl) {
      const { clickUrl, source, interactionType } = clickApiData;
      sendApiClick(clickUrl, source, interactionType);
    }
    localStorage.removeItem('clickApiData');
    // TODO: get from localStorage...
  };

  @action setClickApiData = data => {
    localStorage.setItem('clickApiData', JSON.stringify(data));
    // TODO: set to localStorage...
  };

  @action goToCart = (
    setCartData,
    path,
    withCustom = false,
    isFromFloatingButton = false,
    customObjectSeller,
  ) => {
    const { customName, customNumber, customizeType, customizable } = this.customItemProperties;

    let customObject = {};

    if (withCustom || isPrePersonalized(customizeType)) {
      const productCode = this.productItem?.informacoes && this.productItem?.informacoes?.codigo;

      const customProductService = CustomProductService({
        ...this.customItemProperties,
        productCode,
        ...customObjectSeller,
      });

      const customProductServiceSeller = CustomProductServiceSeller({
        customObjectSeller: { ...customObjectSeller },
        customizeType,
      });

      const isSellerNike = getIsSellerNike(this.skuSelected, this.updatedProductItem.sellerName);

      customObject = isSellerNike
        ? customProductServiceSeller.generateCustomObject()
        : customProductService.generateCustomObject();

      const customNike = isSellerNike && JSON.parse(customObject.valorPersonalizacao);

      const customNameSellerNike = customNike.parameters?.Customization?.find(
        item => item.DetalhesPersonalizacao.Tipo === 'Nome',
      )?.DetalhesPersonalizacao.Valor;

      const customNumberSellerNike = customNike.parameters?.Customization.find(
        item => item.DetalhesPersonalizacao.Tipo === 'Número',
      )?.DetalhesPersonalizacao.Valor;

      if (isFootballBoots(customizeType)) {
        sendEvent(
          'UI - PDP - Comprar com customização',
          `Click - Ir para o carrinho com customização${
            isFromFloatingButton ? ' (botão flutuante)' : ''
          }`,
          `Botão Comprar: ${this.skuSelected}.`,
        );
      } else if (isPrePersonalized(customizeType)) {
        sendEvent(
          'UI - PDP - Comprar pré personalizado',
          `Click - Ir para o carrinho pré personalização${
            isFromFloatingButton ? ' (botão flutuante)' : ''
          }`,
          `Botão Comprar: ${this.skuSelected}. Codigo Modelo Virtual: ${productCode}`,
        );
      } else if (isSellerNike) {
        buyPersonalization3PEvent(this.skuSelected, customNameSellerNike, customNumberSellerNike);
      } else {
        sendEvent(
          'UI - PDP - Comprar com customização',
          `Click - Ir para o carrinho com customização${
            isFromFloatingButton ? ' (botão flutuante)' : ''
          }`,
          `Botão Comprar: ${this.skuSelected}. Customização: ${customName} ${customNumber}`,
        );
      }
    } else {
      sendEvent(
        'UI - PDP - Comprar',
        `Click - Ir para o carrinho${isFromFloatingButton ? ' (botão flutuante)' : ''}`,
        `Botão Comprar: ${this.skuSelected}`,
      );
    }

    if (withCustom) {
      pushDatalayer({ isCustomizedProductOnCart: true });
    } else if (customizable) {
      pushDatalayer({ isCustomizedProductOnCart: false });
    }

    sendProductToDataLayer('add', this.skuSelected);

    const newCustomProduct = genNewProduct({
      skuSelected: this.skuSelected,
      productDetails: this.productItem,
      customized: withCustom,
      customObject,
      sellerName: this.updatedProductItem.sellerName,
    });

    setCartData(this.skuSelected, () => null, newCustomProduct, true).then(() => {
      setTimeout(() => {
        if (window && window.location.host.includes('esportes')) {
          window.location.href = 'https://www.centauro.com.br/checkouts/carrinho';
        } else {
          history.push(path);
        }
      }, 0);
    });
  };
}

export default ProductStore;
