export function getActiveExperimentFromQueryParameter(url = window.location) {
  const params = new URLSearchParams(url.search);
  const expid = params.get('utm_expid');
  if (!expid) {
    return null;
  }

  const expidParts = expid.split('.');
  return {
    experimentId: expidParts[1],
    variant: expidParts[2],
  };
}
