import { getJson, postJson, deleteJson } from './httpClientCentauro';
import { apiDomain } from '../settings';

const urlFavoriteV2 = `${apiDomain}/v2/produtofavorito`;
const urlFavoriteV4 = `${apiDomain}/v4/produtofavorito`;

const getFavoriteList = header => getJson(urlFavoriteV4, header);

const setFavorite = (dataObj, header) => postJson(urlFavoriteV2, dataObj, header, true);

const deleteFavorite = (modelId, header) => deleteJson(`${urlFavoriteV2}/${modelId}`, header, true);

export default {
  getFavoriteList,
  setFavorite,
  deleteFavorite,
};
