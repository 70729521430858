import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';
import LightBox from '../../common/lightBox/LightBox';
import TrocaSite from '../../../common/icons/TrocaSite';
import TrocaLoja from '../../../common/icons/TrocaLoja';
import { sendEvent } from '../../../utils/Analytics';
import styles from './styles';

const ExchangePolicyModal = ({ setShowExchangeModal }) => (
  <LightBox
    background="transparent"
    customStyle={styles.modalStyle}
    onClose={() => {
      setShowExchangeModal(false);
      sendEvent(
        'UI - Checkout - Política de Troca',
        'Click - Política de Troca - Fechar modal',
        'Clicou para fechar modal de Política de Troca',
      );
    }}
  >
    <div className={css(styles.lightBoxHeader)}>
      <legend className={css(styles.lightBoxTitle)}>Política de Troca e Devolução</legend>
    </div>
    <div className={css(styles.lightBoxBody)}>
      <div className={css(styles.rowContainer)}>
        <div className={css(styles.columnContainer)}>
          <TrocaSite className={css(styles.trocaSite)} />
          <div className={css(styles.picText)}>
            VOCÊ PODE
            <br />
            TROCAR PELO SITE
          </div>
        </div>
        <div className={css(styles.columnContainer)}>
          <TrocaLoja className={css(styles.trocaSite)} />
          <div className={css(styles.picText)}>
            E TAMBÉM
            <br />
            NA LOJA*
          </div>
        </div>
      </div>
      <p className={css(styles.termsText)}>
        A partir do recebimento do produto você tem{' '}
        <span className={css(styles.bold)}>30 dias para trocar ou devolver.</span>**
      </p>
      <p className={css(styles.termsText)}>
        Acesse os{' '}
        <a
          href="https://www.centauro.com.br/sc/politica-de-troca-no-site"
          rel="noopener noreferrer"
          target="_blank"
          className={css(styles.link)}
          onClick={() => {
            sendEvent(
              'UI - Checkout - Política de Troca',
              'Click - Detalhes Política de Troca',
              'Clicou no link Política de Troca',
            );
          }}
        >
          detalhes da política de troca
        </a>
        .
      </p>

      <p className={css(styles.termsDetails)}>
        *exceto para produtos vendidos por parceiros.
        <br />
        <br />
        **exceto para itens considerados grandes e/ou pesados, acessórios, equipamentos, eletrônicos
        e suplementos em que o prazo é de 7 dias e produtos personalizados que não têm direito a
        devolução por arrependimento.
      </p>
    </div>
  </LightBox>
);

ExchangePolicyModal.propTypes = {
  setShowExchangeModal: PropTypes.func.isRequired,
};

export default ExchangePolicyModal;
