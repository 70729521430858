import { observable, action } from 'mobx';

class ErrorHandlerStore {
  @observable errorMessage = '';

  @observable errorType = 'error';

  @observable hasError = false;

  @action setError = ({ message, type }) => {
    this.setErrorMessage(message);
    this.setErrorType(type);
    this.setHasError(true);

    setTimeout(() => this.setHasError(false), 8000);
  };

  @action clearErrors = () => {
    this.setErrorMessage('');
    this.setErrorType('');
    this.setHasError(false);
  };

  @action setHasError = hasError => (this.hasError = hasError);

  @action setErrorMessage = message => (this.errorMessage = message);

  @action setErrorType = type => (this.errorType = type);
}

export default ErrorHandlerStore;
