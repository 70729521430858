import { CSSProperties, StyleDeclarationMap, StyleSheet } from 'aphrodite';
import { centauro } from '@sbf/fairplay-tokens';

type styleType = {
  [key: string]: CSSProperties | StyleDeclarationMap;
};

const style = StyleSheet.create<styleType>({
  modalSellerOutStore: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paragraph: {
    fontFamily: centauro.fonts.primary,
    fontStyle: 'normal',
    fontWeight: 'lighter',
    fontSize: centauro.fontSizes.big,
    lineHeight: '150%',
    textAlign: 'left',
    color: centauro.colors.neutral[700],
    margin: `${centauro.spaces[200]} ${centauro.spaces[0]}`,
    '@media (max-width: 768px)': {
      padding: `${centauro.spaces[0]} ${centauro.spaces[300]}`,
    },
  },
  label: {
    display: 'block',
    fontFamily: centauro.fonts.primary,
    fontWeight: 'normal',
    fontSize: centauro.fontSizes.small,
    marginBottom: centauro.spaces[100],
    textAlign: 'left',
    color: centauro.colors.neutral[700],
    alignSelf: 'flex-start',
    paddingTop: centauro.spaces[300],
  },
  loginContainer: {
    width: '390rem',
    marginTop: centauro.spaces[1000],
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (min-width: 768px)': {
      backgroundColor: centauro.colors.neutral[100],
    },
    '@media (max-width: 768px)': {
      marginTop: centauro.spaces[800],
      width: '100%',
    },
  },
  errorDialog: {
    color: centauro.colors.negative[200],
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: centauro.spaces[200],
    border: `${centauro.borderRadius.small} solid ${centauro.colors.negative[200]}`,
    padding: centauro.spaces[200],
  },
  loginIcon: {
    boxSizing: 'border-box',
    height: '24rem',
    width: '24rem',
    borderRadius: '50%',
    position: 'absolute',
    top: '16rem',
    left: '16rem',
  },
  loginInput: {
    boxSizing: 'border-box',
    color: centauro.colors.neutral[300],
    width: '100%',
    height: '48rem',
    borderRadius: centauro.spaces[100],
    border: `solid ${centauro.spaces[0]} ${centauro.colors.neutral[200]}`,
    backgroundColor: centauro.colors.neutral[100],
    paddingLeft: centauro.spaces[700],
    paddingRight: centauro.spaces[700],
    fontSize: centauro.fontSizes.small,
    fontFamily: centauro.fonts.primary,
    fontWeight: 'normal',
    '-webkit-font-smoothing': 'antialiased',
    '::placeholder': {
      color: centauro.colors.neutral[300],
    },
  },
  formLogin: {
    boxSizing: 'border-box',
    minWidth: '0rem',
    width: '100%',
    '@media (max-width: 768px)': {
      padding: `${centauro.spaces[0]} ${centauro.spaces[300]}`,
      width: '100%',
      maxWidth: 'none',
      minWidth: '0rem',
    },
  },
  loginButton: {
    cursor: 'pointer',
    position: 'relative',
    height: '48rem',
    width: '100%',
    outline: 'transparent',
    color: centauro.colors.neutral[100],
    fontSize: centauro.fontSizes.medium,
    borderRadius: centauro.borderRadius.medium,
    fontFamily: centauro.fonts.primary,
    '-webkit-font-smoothing': 'antialiased',
  },
  buttonPrimary: {
    backgroundColor: centauro.colors.primary,
    marginBottom: centauro.spaces[200],
    marginTop: centauro.spaces[300],
    transition: 'all ease-in-out .3s',
    ':disabled': {
      cursor: 'wait',
      backgroundColor: centauro.colors.negative[200],
      pointerEvents: 'none',
    },
    ':hover': {
      backgroundColor: centauro.colors.primary,
    },
  },
  containerFooter: {
    position: 'fixed',
    bottom: '0rem',
    width: '100%',
  },
});

export default style;
