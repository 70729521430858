import React from 'react';
import PropTypes from 'prop-types';

const KeyIcon = ({ innerFill, width, height, styling }) => (
  <svg
    className={styling}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 17 16"
  >
    <path
      fill={innerFill}
      fillRule="nonzero"
      d="M15.451 1.507a5.154 5.154 0 0 0-8.548 5.234l-5.807 5.807a.468.468 0 0 0-.138.331v2.652a.468.468 0 0 0 .47.469H4.08c.124 0 .243-.05.331-.137l.663-.663a.469.469 0 0 0 .134-.385l-.082-.715.987-.093a.468.468 0 0 0 .423-.423l.092-.984.712.083a.47.47 0 0 0 .523-.465v-.873h.857c.124 0 .243-.05.331-.137l1.207-1.188a5.076 5.076 0 0 0 5.2-1.22 5.162 5.162 0 0 0-.007-7.293zm-1.326 3.315a1.406 1.406 0 1 1-1.988-1.989 1.406 1.406 0 0 1 1.988 1.989z"
    />
  </svg>
);

KeyIcon.propTypes = {
  styling: PropTypes.string,
  innerFill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

KeyIcon.defaultProps = {
  styling: '',
  innerFill: '#cdcdcd',
  width: '17',
  height: '16',
};

export default KeyIcon;
