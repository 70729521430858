import { action, computed, observable, toJS } from 'mobx';
import { window } from 'global';

import { equals } from 'ramda';

import { getSearchData, getNavData, getHotsiteData } from '../modules/navModule';
import { getAsyncPrice } from '../modules/pricesModule';
import { sendEvent } from '../utils/Analytics';
import {
  isMobileFunc,
  removeDiacriticsAndAccents,
  formatPriceNumbers,
  getCookie,
  setCookie,
} from '../utils/utils';
import { sendProductListToDataLayer, getItemListOrigin } from '@/utils/trackingEvents';
import { encrypt, decrypt } from '../utils/Cryptography';
import GlobalObjects from '../components/common/globalObjects/GlobalObjects';
import SellerRepository from '@/hooks/sessionSeller/repository/SellerRepository';

import { asyncPriceStackSize } from '../settings';

// TODO: Descomentar quando normalizar e apagar os de cima
// import { asyncPriceStackSize, geoNavABTest } from '../settings';
// import { injectGoogleApiIfNecessary, getGeocodeLatLng } from '../utils/Geolocation';

const COLUMNS_NAMES = {
  four: '4',
  five: '5',
  six: '6',
};
const DEFAULT_INITIAL_DESKTOP_COLUMNS_SIZE = COLUMNS_NAMES.six;

/*
NEEMU SORT PARAMS
---------------------------------
relevance: Relevância;
pid: Id de produto
ascPrice: Menor preço;
descPrice: Maior preço;
descDate: Lançamentos;
ascSold: Menor venda;
descSold: Maior venda;
ascReview: Menor avaliação;
descReview: Maior avaliação;
descDiscount: Maiores descontos.
*/

const SORT_KEYS = {
  relevance: 'relevance',
  discount: 'descDiscount',
  price: 'ascPrice',
  priceRev: 'descPrice',
};

const SORT_PARAMS = {
  [SORT_KEYS.relevance]: 'Relevância',
  [SORT_KEYS.discount]: 'Maior desconto',
  [SORT_KEYS.price]: 'Menor preço',
  [SORT_KEYS.priceRev]: 'Maior preço',
};

const FACETS_ALLOWLIST = [
  'categorias',
  'esportes',
  'produto',
  'preco',
  'tamanho',
  'genero',
  'cor',
  'marca',
  'idade',
  'modalidade',
  'avaliacao',
  'linha',
  // 'promocao',
  'promocoes',
  'vendidopor',
  'desconto',
  'time',
];

const FILTER_ORDER = {
  Cor: {
    order: 9,
  },
  Modalidade: {
    order: 7,
  },
  Esportes: {
    order: 6,
  },
  Avaliação: {
    order: 14,
  },
  Preço: {
    order: 5,
  },
  Desconto: {
    order: 10,
  },
  Idade: {
    order: 11,
  },
  Marca: {
    order: 1,
  },
  Gênero: {
    order: 3,
  },
  Promoções: {
    order: 13,
  },
  Produto: {
    order: 4,
  },
  Tamanho: {
    order: 2,
  },
  'Vendido por': {
    order: 15,
  },
  Categorias: {
    order: 8,
  },
  Linha: {
    order: 12,
  },
  Promoção: {
    order: 16,
  },
  Time: {
    order: 17,
  },
  Personalizado: {
    order: 18,
  },
  Lojas: {
    order: 19,
  },
};

const FILTER_LABEL = {
  Store: 'Lojas',
};

const checkIfAllowlistIncludesFacetName = facetName =>
  FACETS_ALLOWLIST.includes(removeDiacriticsAndAccents(facetName));

const filterRedirect = (filterValue, filterFacet) => {
  const filterApplyLink = filterValue.applyLink || filterValue.removeLink || '';

  const searchUrl = new URLSearchParams(filterApplyLink);
  const isHotsite = filterApplyLink.includes('suffix');
  const isSearch = filterApplyLink.includes('term');
  const isNav = !isHotsite && !isSearch;
  const cleanFacetType = removeDiacriticsAndAccents(filterFacet.attribute);
  const cleanFacetValue = removeDiacriticsAndAccents(filterValue.label);
  const allFields = searchUrl.getAll('fields').map(el => el.replace(':', '/'));

  const isSelected = Boolean(filterValue.selected);

  if (isSelected) {
    const removeIndex = allFields.findIndex(el => el === `${cleanFacetType}/${cleanFacetValue}`);

    if (removeIndex > -1) {
      allFields.splice(removeIndex, 1);
    }
  }

  if (!isNav) {
    const filtersParams = allFields
      .map(el => {
        const splittedValue = el.split('/');

        return `&common_filter[${splittedValue[0]}]=${splittedValue[1]}`;
      })
      .join('');
    const terms = encodeURI(searchUrl.get('term'));
    const suffix = encodeURI(searchUrl.get('suffix'));
    const pageParams = `&page=1`;

    return isHotsite
      ? `/hotsite/${suffix}?${filtersParams}${pageParams}`
      : `/busca?q=${terms}${filtersParams}${pageParams}`;
  }

  if (allFields.length === 0) {
    return '/nav/0';
  }

  // O Allfilter está ao contrário do que deveria ser. Provavelmente pode ser por causa do resultado do endpoint
  return `/nav/${allFields.reverse().join('/')}?page=1`;
};

const getOrderedFacetsValues = (pathname, facetsValues, isNav) => {
  if (!isNav) {
    return [];
  }

  const orderedValues = [];
  const splittedPath = pathname
    .substr(5)
    .split('/')
    .filter(el => el !== '');
  let i = 0;
  const len = splittedPath.length;
  const clear = removeDiacriticsAndAccents;

  for (; i < len; i += 2) {
    let type = splittedPath[i];
    if (type) {
      type = type.replace(' ', '').replace('_', '');
    }
    let value = splittedPath[i + 1];
    if (value) {
      value = value.replace(' ', '');
    }
    const facetsValuesObj = toJS(facetsValues);

    let facetValue =
      facetsValuesObj &&
      facetsValuesObj.find(
        el => clear(el.label) === clear(value) && clear(el.facetName) === clear(type),
      );
    facetValue =
      facetValue ||
      (facetsValuesObj &&
        facetsValuesObj.find(
          el =>
            (type === 'preco' &&
              el.max &&
              el.max.unity === 'rs' &&
              value === `${el.min.value}-${el.max.value}`) ||
            (type === 'desconto' &&
              el.max &&
              el.max.unity === '%' &&
              value === `${el.min.value}-${el.max.value}`) ||
            (clear(el.label) === value && clear(el.facetName) === clear(type)),
        ));

    if (facetValue) {
      orderedValues.push(facetValue);
    }
  }

  return orderedValues;
};

const getFacetsValues = (currentFacets = []) => {
  const filteredFacets = currentFacets.filter(({ attribute }) =>
    checkIfAllowlistIncludesFacetName(attribute),
  );

  let facetsValues = [];

  filteredFacets.forEach(product => {
    const { values } = product;

    const resultValues = values
      .filter(value => value.selected)
      .map(value => ({
        ...value,
        name: value.label,
        facetName: product.attribute,
      }));

    facetsValues = facetsValues.concat(resultValues);
  });

  return facetsValues;
};

const getOrderedFilters = filters => {
  const arrayToBeSorted = [...filters];

  arrayToBeSorted.sort((filterA, filterB) => {
    const orderA = FILTER_ORDER[filterA.attribute].order;
    const orderB = FILTER_ORDER[filterB.attribute].order;

    if (orderA > orderB) {
      return 1;
    }

    if (orderA < orderB) {
      return -1;
    }

    return 0;
  });

  return arrayToBeSorted;
};

const sortFilterValuesBySelectedFirst = (first, second) => {
  if (first.selected && !second.selected) {
    return -1;
  }

  if (second.selected && !first.selected) {
    return 1;
  }

  return 0;
};

class NavStore {
  userGeolocationAkamaiCookie = getCookie('ulocation');

  objectForAnalytics = null;

  userLocationCookie = window && getCookie('uloc') ? decrypt(getCookie('uloc')) : null;

  userSessionLatLng =
    window && sessionStorage.getItem('ulocation')
      ? decrypt(sessionStorage.getItem('ulocation'))
      : null;

  @observable searchResponse = {
    requestId: '',
    searchId: '',
    queries: {},
    suggestions: [],
    filters: [],
    size: 0,
    pagination: {},
    banners: {},
    products: [],
    metaData: {},
  };

  @observable iSort = SORT_KEYS.relevance;

  @observable term = '';

  @observable loading = false;

  @observable error = '';

  @observable mobileListGridAspect =
    window && sessionStorage.getItem('mobileListGridAspect') === 'Coluna';

  @observable desktopColumnsSize = this.getInitialDesktopColumnsSize();

  @observable itemsQuantityPerPage = 66;

  @observable lastUpdatePayload = {};

  @observable hoverProduct = '';

  @observable isFreeShippingModalOpen = false;

  @action toggleFreeShippingModal = newValue => (this.isFreeShippingModalOpen = newValue);

  @observable globalObjectLoaded = false;

  @observable pageLoaded = false;

  @observable facetsValues = [];

  @observable pickupFilter = {
    filteredStoreName: '',
    normalizedFilteredStoreName: '',
    isFilterApplied: false,
  };

  @observable isScrollToTopNeeded = false;

  @computed get numberOfSelectedFilters() {
    const filters = toJS(this.searchResponse.filters);

    return filters
      ? filters
          .filter(product => checkIfAllowlistIncludesFacetName(product.attribute))
          .reduce((acc, product) => acc + product.values.filter(value => value.selected).length, 0)
      : 0;
  }

  @computed get pickupFilterData() {
    return this.pickupFilter;
  }

  @computed get hasResult() {
    return this.searchResponse.products ? this.searchResponse.products.length > 0 : false;
  }

  @computed get hasMetaData() {
    return toJS(this.searchResponse.metaData);
  }

  @action clearSearchResponse = () => {
    this.searchResponse = {
      requestId: '',
      searchId: '',
      queries: {},
      suggestions: [],
      filters: [],
      size: 0,
      pagination: {},
      banners: {},
      products: [],
      metaData: {},
    };
  };

  @action resetStore = payload => {
    const lastPayloadTerm = this.lastUpdatePayload?.term;
    const payloadTerm = payload?.term;
    const sameSearch = lastPayloadTerm === payloadTerm;

    const lastPayloadHotsite = this.lastUpdatePayload?.hotsite;
    const payloadHotsite = payload?.hotsite;
    const sameHotsite = lastPayloadHotsite === payloadHotsite;

    const lastPayloadNav = this.lastUpdatePayload?.termPairs && this.lastUpdatePayload.termPairs[0];
    const payloadNav = payload?.termPairs && payload.termPairs[0];
    const sameNav = equals(lastPayloadNav, payloadNav);

    // Resetar ordenação
    if (!(sameSearch && sameHotsite && sameNav)) {
      this.iSort = SORT_KEYS.relevance;
    }

    // Resetar propriedades de nav/busca/hotsite
    if (lastPayloadHotsite && !payloadHotsite) {
      delete this.lastUpdatePayload.hotsite;
      this.clearSearchResponse();
    } else if (lastPayloadTerm && !payloadTerm) {
      delete this.lastUpdatePayload.term;
      this.clearSearchResponse();
    } else if (lastPayloadNav && !payloadNav) {
      delete this.lastUpdatePayload.termPairs;
      this.clearSearchResponse();
    }
  };

  @action updateScrollToTop = value => (this.isScrollToTopNeeded = value);

  @action updatePageLoad = isPageLoaded => (this.pageLoaded = isPageLoaded);

  @action getInitialDesktopColumnsSize = () => {
    if (window && sessionStorage.getItem('desktopColumnsSize')) {
      return sessionStorage.getItem('desktopColumnsSize');
    }
    if (!window || window.innerWidth >= 1024) {
      return DEFAULT_INITIAL_DESKTOP_COLUMNS_SIZE;
    }
    return COLUMNS_NAMES.six;
  };

  @action setQuantityItemsPerPage = quantity => {
    this.itemsQuantityPerPage = quantity;
  };

  @action setMobileListGridAspect = newValue => {
    let layoutName;

    if (newValue) {
      layoutName = 'Coluna';
      if (window) {
        sessionStorage.setItem('mobileListGridAspect', 'Coluna');
      }
    } else {
      layoutName = 'Linha';
      if (window) {
        sessionStorage.setItem('mobileListGridAspect', 'Linha');
      }
    }

    sendEvent(
      'UI - Nav/Busca - Layout mobile',
      'Alterou - Layout da exibição de resultados',
      `Nav/Busca - Alterou o layout de exibição de resultados mobile para: ${layoutName}`,
    );

    this.mobileListGridAspect = newValue;
  };

  @action updateISort = sortValue => {
    this.iSort = sortValue;

    sendEvent(
      'UI - Nav - Ordenação',
      'Click - Ordenação',
      `Nav - Ordenação - Ordena lista por: ${SORT_PARAMS[sortValue]}`,
    );
  };

  @action getGeolocationParams = async (geolocationStoresIds, isort) => {
    // Início da ordenação geolocalizada

    // const abValue = setABTest('dimension25', 'geolocationSortA', geoNavABTest);
    // sendEvent(
    //   'Event - Teste AB',
    //   'Enviou - geolocationSort',
    //   `Nav/Busca - Enviou dados do teste AB: ${abValue}`,
    // );

    const userLocation = this.userGeolocationAkamaiCookie
      ? JSON.parse(atob(this.userGeolocationAkamaiCookie))
      : null;

    const userLocationCookie = this.userLocationCookie ? JSON.parse(this.userLocationCookie) : null;

    if (window && isort === 'relevance' && userLocation) {
      if (userLocationCookie) {
        sendEvent(
          'Test - User Location',
          'With cookie',
          'Usuário acessou nav/busca possuindo o cookie uLocation da Akamai',
          1,
          true,
        );
      } else {
        sendEvent(
          'Test - User Location',
          'Without cookie',
          'Usuário acessou nav/busca sem ter o cookie uLocation da Akamai',
          1,
          true,
        );

        // TODO: Salvar lat e lng no cookie e descomentar codigo quando normalizar
        const cryptoULoc = encrypt(JSON.stringify(userLocation));
        setCookie('uloc', cryptoULoc, 30);

        // if (!this.userSessionLatLng) {
        //   await injectGoogleApiIfNecessary();

        //   try {
        //     const geocodeLatLng = await getGeocodeLatLng(
        //       `${userLocation.city}, ${userLocation.region_code}, ${userLocation.country_code}`,
        //     );

        //     const locationObj = {
        //       latitude: geocodeLatLng[0].geometry.location.lat(),
        //       longitude: geocodeLatLng[0].geometry.location.lng(),
        //     };

        //     this.userSessionLatLng = JSON.stringify(locationObj);

        //     const cryptoULocation = encrypt(this.userSessionLatLng);
        //     sessionStorage.setItem('ulocation', cryptoULocation);
        //   } catch (_) {
        //     this.userSessionLatLng = null;
        //   }
        // }
      }

      // const uLocation = JSON.parse(this.userSessionLatLng);

      // const fellWithinABTest = abValue === 'piloto';
      // const coordinatesParam =
      //   fellWithinABTest && this.userSessionLatLng
      //     ? `&coordinates=${uLocation.latitude}:${uLocation.longitude}`
      //     : '';

      // // Fim da ordenação geolocalizada
      // const hasStoresInformedForGeoNav = geolocationStoresIds && geolocationStoresIds.length > 0;
      // if (!hasStoresInformedForGeoNav)
      //   return {
      //     geolocationParams: coordinatesParam,
      //   };

      // const storeFilterParams = geolocationStoresIds
      //   .map(id => `&filter=d:StoreId:${id}`)
      //   .join('')
      //   .concat('&filter=d:StoreId:Estoque em CD');

      // return {
      //   geolocationParams: [storeFilterParams, coordinatesParam].join(''),
      // };
    }
    return {
      geolocationParams: '',
    };
  };

  @action updateTerm = async (payload, forceAddBehaviour = true) => {
    this.resetStore(payload);

    const mergedPayload = {
      ...toJS(this.lastUpdatePayload),
      ...payload,
      isort: this.iSort,
    };

    const geolocationParams = await this.getGeolocationParams(
      mergedPayload.geolocationStoresIds,
      mergedPayload.isort,
    );

    const lastPayload = {
      ...mergedPayload,
      ...geolocationParams,
    };

    this.setTerm(lastPayload);
    this.startLoading();

    const isHotsite = !!payload?.hotsite;
    const isSearch = !!payload?.term;

    const moduleMethod = isHotsite ? getHotsiteData : isSearch ? getSearchData : getNavData;

    this.clearError();

    const { data, error } = await moduleMethod(lastPayload);

    this.objectForAnalytics = {
      pagination: {
        ...data.pagination,
        productsOnPage: data.productsOnPage,
        productsPerPage: data.productsPerPage,
        size: data.size,
      },
      filters: data.filters,
    };

    let isRedirect = false;

    if (error) {
      isRedirect = error.status === 302 && error.data && error.data.queryType === 'redirect';

      if (!isRedirect) {
        console.error('ERRO: ', error); // eslint-disable-line no-console
        if (error.origin === 'nav' && error.termParam) {
          sendEvent(
            'UI - NAV - Erro 404',
            'Click - Vendido e Entregue por',
            `Linx retornou 404 na nav/vendido_por/${error.termParam}`,
            1,
            true,
          );
        }
        if (error.axiosMessage) {
          this.setError({
            error: error.axiosMessage,
            originalError: error,
          });
        }
        this.finishLoading();
        return;
      }
    } else if (data.products.length === 0 && data.size === 0) {
      sendEvent(
        `${isSearch ? 'Search' : 'Nav'} - Sem Resultados`,
        `Acessou ${isSearch ? 'search' : 'nav'} sem resultados`,
        `${
          isSearch
            ? `search: ${lastPayload.term}`
            : `nav: ${
                lastPayload.termPairs !== undefined
                  ? lastPayload.termPairs.map(term => `${term.type}/${term.value}`)
                  : ''
              }`
        }`,
        1,
        true,
      );

      this.setError({
        error: 'Nenhum resultado encontrado',
        originalError: {},
      });

      if (!data.filters || data.filters.length === 0) {
        this.finishLoading();
        return;
      }
    }

    const mergedData = isRedirect
      ? {
          ...data,
          ...error.data,
        }
      : data;

    mergedData.filters = getOrderedFilters(mergedData.filters);

    mergedData.filters = mergedData.filters.map(filter => {
      filter.values = filter.values.sort(sortFilterValuesBySelectedFirst);
      return filter;
    });

    this.lastUpdatePayload = lastPayload;

    const dataBanner = {};

    const finalData = {
      ...mergedData,
      banners: dataBanner,
    };

    this.setSearchResponse(finalData, forceAddBehaviour && isMobileFunc());

    this.getSkus(mergedData);

    const desktopColumnsSize = sessionStorage.getItem('desktopColumnsSize');

    sendProductListToDataLayer(
      this.searchResponse.products,
      this.objectForAnalytics,
      getItemListOrigin(),
      `${SORT_PARAMS[this.iSort]}`,
      desktopColumnsSize ? desktopColumnsSize : '4',
      { type: 'item_load', name: 'load_item_list' },
      this.lastUpdatePayload.term,
    );
  };

  @action toggleGlobalObjectLoaded = newValue => {
    this.globalObjectLoaded = newValue;
  };

  @action setSearchResponse = (searchResponse, addBehaviour = false) => {
    delete this.searchResponse.message;

    const mergedResponse = {
      ...searchResponse,
    };
    const oldResponse = this.searchResponse;

    if (addBehaviour) {
      mergedResponse.products = oldResponse.products.concat(searchResponse.products);
    }

    this.searchResponse = {
      ...oldResponse,
      ...mergedResponse,
    };

    this.finishLoading();

    if (searchResponse.pagination) {
      setTimeout(() => {
        const pageUrl = searchResponse
          ? searchResponse.pagination &&
            searchResponse.pagination.next &&
            new URLSearchParams(searchResponse.pagination.next)
          : '';
        const page = pageUrl && pageUrl.get('page') - 1;

        sendEvent(
          'UI - Nav - Paginação',
          'Alterou - Mudou de página',
          `Nav - Paginação - Alterou para a página ${page}`,
        );
      }, 0);
    }

    this.setPickupFilterFromResponse(mergedResponse.filters);

    this.setFacetsValues(mergedResponse.filters);
  };

  @action setFacetsValues = (currentFacets = []) => {
    this.facetsValues = getFacetsValues(currentFacets);
  };

  @action setPickupFilterFromResponse = responseFilters => {
    const filteredStores = responseFilters.find(filter => filter.attribute === FILTER_LABEL.Store);
    const filteredStoresValues = (filteredStores && filteredStores.values) || [];

    if (filteredStoresValues.length === 0) {
      this.clearPickupFilter();
      return;
    }

    const selectedPickupFilter = filteredStoresValues.find(filter => filter.selected);

    if (!selectedPickupFilter) {
      this.clearPickupFilter();
      return;
    }

    const [_, filterValue] = selectedPickupFilter.FacetFilter.split(':');

    this.setPickupFilter({
      filteredStoreName: selectedPickupFilter.label,
      normalizedFilteredStoreName: filterValue,
      isFilterApplied: true,
    });
  };

  @action setPickupFilter = (pickupFilter = {}) => {
    this.pickupFilter = pickupFilter;
  };

  @action clearPickupFilter = () => {
    this.pickupFilter = {
      filteredStoreName: '',
      normalizedFilteredStoreName: '',
      isFilterApplied: false,
    };
  };

  @action clearError = () => {
    this.error = '';
  };

  @action setError = ({ error, originalError }) => {
    this.error = originalError && originalError.data ? toJS(originalError.data) : error;
  };

  @action setTerm = ({ term }) => {
    this.term = term;
  };

  @action startLoading = () => {
    this.loading = true;
  };

  @action finishLoading = () => {
    this.loading = false;
  };

  @action handleDesktopColumnsSize = numberOfColumns => {
    if (window) {
      sessionStorage.setItem('desktopColumnsSize', numberOfColumns);
    }
    this.desktopColumnsSize = numberOfColumns;

    sendEvent(
      'UI - Nav/Busca - Colunas',
      'Alterou - Mudou quantidade de colunas',
      `Nav/Busca - Alterou quantidade de colunas. Quantidade de colunas: ${numberOfColumns}`,
    );

    this.setQuantityItemsPerPage(66);

    this.updateTerm({
      cnt: this.itemsQuantityPerPage,
      ...this.lastUpdatePayload,
    });
  };

  @action setHoverProduct = hoverProduct => {
    this.hoverProduct = hoverProduct;
  };

  @action checkIfSkuNeedAsyncPrice = sku => {
    const stacks = toJS(this.asyncPricesStacks);

    if (stacks.length === 0) {
      return;
    }

    const index = stacks.findIndex(values => values.filter(value => value === sku).length > 0);

    if (index === -1) {
      return;
    }

    const stacktoGet = stacks[index];
    const preIndexSlice = stacks.slice(0, index);
    const posIndexSlice = stacks.slice(index + 1);

    this.updateAsyncPricesStacks(preIndexSlice.concat(posIndexSlice));

    this.addAsyncPrices(stacktoGet);
  };

  @observable asyncPricesStacks = [];

  @action updateAsyncPricesStacks = stacks => (this.asyncPricesStacks = stacks);

  @action getSkus = data => {
    const { products } = data;

    const skusList = products
      ? products.map(product => `${product.details.original_id[0]}${product.details.id_cor[0]}`)
      : [];
    const apiCallsQuantity = Math.ceil(skusList.length / asyncPriceStackSize);
    const firstSplicedList = skusList.slice(0, asyncPriceStackSize);

    const activeSeller = SellerRepository.getActiveSeller();
    if (this.lastUpdatePayload.variantWithoutPPA === '0' || activeSeller) {
      this.addAsyncPrices(firstSplicedList);
    }

    const splicedLists = [];

    for (let i = 1; i < apiCallsQuantity; i += 1) {
      const splicedList = skusList.slice(i * asyncPriceStackSize, (i + 1) * asyncPriceStackSize);

      splicedLists.push(splicedList);
    }

    this.updateAsyncPricesStacks(splicedLists);
  };

  @action addAsyncPrices = skuList => {
    getAsyncPrice(skuList).then(response => {
      const responseData = response && response.data;

      if (!responseData) {
        return;
      }
      const newResultMap = responseData.map(updatedProduct => {
        const oldData = toJS(this.searchResponse.products);
        const productToUpdate = oldData.find(
          result => result.skus[0].sku.substr(0, 8) === updatedProduct.codigoModeloCor,
        );

        const precos = updatedProduct.preco
          ? {
              price: updatedProduct.preco.valor,
              oldPrice: updatedProduct.preco.valorDe,
              tipoBuyBox: updatedProduct.preco.tipoBuyBox,
              totalOfertas: updatedProduct.preco.totalOfertas,
              installment: {
                price: updatedProduct.preco.quantidadePorParcela,
                count: updatedProduct.preco.numeroDeParcelas,
              },
              origin: 'sync',
            }
          : { origin: 'sync' };
        const seloCampanha = updatedProduct.selos
          ? updatedProduct.selos.find(selo => selo.chaveNomeSelo === 'campanha')
          : null;

        const utilUpdatedProduct = {
          ...precos,
          details: {
            ...(productToUpdate && productToUpdate.details), // TODO: Otimizar isso aqui...
            UrlSeloCampanha: seloCampanha ? [seloCampanha.url] : [],
          },
        };

        const newResult = {
          ...productToUpdate,
          ...utilUpdatedProduct,
        };

        if (!seloCampanha) {
          delete newResult.details.UrlSeloCampanha;
        }

        return newResult;
      });

      this.updateSearchResponseResults(newResultMap);
    });
  };

  @action updateSearchResponseResults = resultList => {
    const mergedResults = toJS(this.searchResponse.products);
    const desktopColumnsSize = sessionStorage.getItem('desktopColumnsSize');

    sendProductListToDataLayer(
      resultList,
      this.objectForAnalytics,
      getItemListOrigin(),
      `${SORT_PARAMS[this.iSort]}`,
      desktopColumnsSize ? desktopColumnsSize : '4',
      { type: 'item_view', name: 'view_item_list' },
      this.lastUpdatePayload.term,
    );

    resultList.forEach(resultItem => {
      const currentIndex = mergedResults.findIndex(
        oldItem => oldItem.skus[0].sku.substr(0, 8) === resultItem.skus[0].sku.substr(0, 8),
      );
      mergedResults.splice(currentIndex, 1, resultItem);
    });

    this.searchResponse.products = mergedResults;
  };

  @action callGlobalObjects = (data, isNav, isInvalid) => {
    const payload = this.lastUpdatePayload;

    const { term, hotsite } = payload;
    const termQuery = term || hotsite;
    const id = data ? data.searchId : '';
    const pageUrl = data
      ? data.pagination && data.pagination.next && new URLSearchParams(data.pagination.next)
      : '';
    const page = pageUrl && pageUrl.get('page') - 1;
    const totalResult = data ? data.size : 0;
    let objGlobalInfo = {};

    if (!window) {
      return;
    }

    delete window.chaordic_meta;
    delete window.siteMetadata;
    delete window.metadados_recsys;

    if (isInvalid) {
      // busca/nav vazia

      objGlobalInfo = {
        page: 'emptysearch',
        query: termQuery,
        searchId: id,
        name: 'category',
        type: 'Neemu',
        objMetaSearch: {
          aObjPageInfo: [],
        },
      };
    } else if (isNav) {
      const selectedFiltersNames = [];
      const selectedFilters = data && data.filters && data.filters.filter(el => el.selected);
      selectedFilters.forEach(filter =>
        filter.values
          .filter(value => value.selected)
          .forEach(value => selectedFiltersNames.push(value.label)),
      );

      // navegação

      objGlobalInfo = {
        page: 'category',
        searchId: id,
        type: 'Neemu',
        buscaInfo: this._getBuscaInfo(),
        categories: selectedFiltersNames,
      };
    } else {
      // busca

      const aObjPageInfo =
        data &&
        data.products &&
        data.products.map(item => {
          const productId = item.id;
          const productSku = item.id.substr(0, 6);
          const itemPrice = formatPriceNumbers(item.price);

          return {
            items: {
              id: productId,
              sku: productSku,
              price: itemPrice,
            },
          };
        });

      objGlobalInfo = {
        page: 'search',
        query: termQuery,
        searchId: id,
        name: 'category',
        type: 'Neemu',
        pageCurrent: page,
        results: totalResult,
        objMetaSearch: {
          aObjPageInfo,
        },
      };
    }

    GlobalObjects(objGlobalInfo);

    setTimeout(() => {
      this.toggleGlobalObjectLoaded(true);
    }, 0);
  };

  _getBuscaInfo = () => {
    const dataObj = {};

    this.facetsValues.forEach(({ facetName, name }) => {
      if (dataObj[facetName]) {
        const oldValue = dataObj[facetName];
        dataObj[facetName] = `${oldValue},${name}`;
      } else {
        dataObj[facetName] = name;
      }
    });

    return dataObj;
  };
}

export {
  COLUMNS_NAMES,
  SORT_PARAMS,
  SORT_KEYS,
  DEFAULT_INITIAL_DESKTOP_COLUMNS_SIZE,
  FACETS_ALLOWLIST,
  checkIfAllowlistIncludesFacetName,
  getOrderedFacetsValues,
  filterRedirect,
  getFacetsValues,
};

export default NavStore;
