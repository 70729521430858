import { loginPath } from '@/settings';
import { deleteCookiesLogin, setCookie } from '@/utils/utils';
import { isSPA } from '@/helpers/ssrHelper';

export const shouldDisableSession = (redirectLogin: boolean) => {
  if (!isSPA || !redirectLogin) {
    return;
  }

  const oneHourInDays = 0.041;
  setCookie('showDisableSessionMessage', true, oneHourInDays);
  deleteCookiesLogin();

  return new Promise(() => {
    window.location.href = loginPath;
  });
};
