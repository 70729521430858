import React, { useState, useCallback } from 'react';
import styles from './styles';
import { css } from 'aphrodite/no-important';
import InputUsername from '../inputUsername/InputUsername';
import InputPassword from '../inputPassword/InputPassword';
import FormValidator from '../validations/FormValidator';
import useSessionSeller from '@/hooks/sessionSeller/useSessionSeller';
import LoadingGif from '@/components/common/loadingGif/LoadingGif';
import Footer from '../../checkouts/both/footer/FooterCheckout';
import { homePath } from '@/settings';
import history from '@/history';

const LoginSeller = () => {
  const [login, setLogin] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<string | undefined>('');
  const [loginError, setLoginError] = useState<string | undefined>('');
  const [responseError, setResponseError] = useState<string | undefined>('');

  const { signIn } = useSessionSeller();

  const buildFormValidator = useCallback((): FormValidator => {
    return new FormValidator(login, password);
  }, [login, password]);

  const formValidator = buildFormValidator();

  const verifyIfFormInvalid = useCallback(async (): Promise<boolean> => {
    const isInvalid = await formValidator.verifyErrors();

    if (isInvalid) {
      setLoginError(formValidator.login.error);
      setPasswordError(formValidator.password.error);
    }
    return isInvalid;
  }, [formValidator]);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault();
      try {
        if (await verifyIfFormInvalid()) {
          return;
        }
        setIsLoading(true);

        await signIn({ login, password });
        history.push(homePath);
      } catch (exception) {
        const error = exception as Error;
        setResponseError(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [verifyIfFormInvalid, signIn, login, password],
  );

  const handleOnInputUserName = useCallback(
    (value: string): void => {
      setLogin(value);
      formValidator.login.onChange(value);
    },
    [formValidator, setLogin],
  );

  const handleOnInputPassword = useCallback(
    (value: string): void => {
      setPassword(value);
      formValidator.password.onChange(value);
    },
    [formValidator, setPassword],
  );
  return (
    <>
      <div className={css(styles.modalSellerOutStore)}>
        <div
          className={css(styles.modalContainer, styles.modalTitle)}
          title="Identificação do vendedor"
        >
          {isLoading ? (
            <LoadingGif />
          ) : (
            <section className={css(styles.loginContainer)}>
              <p className={css(styles.paragraph)}>Área exclusiva parceiros Centauro</p>
              <form
                data-testid="formLogin"
                onSubmit={handleSubmit}
                className={css(styles.formLogin)}
                noValidate
              >
                {responseError && (
                  <p data-testid="serverError" className={css(styles.errorDialog)}>
                    {responseError}
                  </p>
                )}

                <label className={css(styles.label)} htmlFor="login">
                  Usuário
                </label>
                <InputUsername
                  name="login"
                  handleOnInput={handleOnInputUserName}
                  placeholder="Digite a matrícula do vendedor"
                  id="login"
                  data-testid="inputLogin"
                  autoComplete="off"
                  error={loginError}
                  minLength={4}
                  maxLength={6}
                />
                <label className={css(styles.label)} htmlFor="password">
                  Senha
                </label>
                <InputPassword
                  placeholder="Digite sua senha"
                  handleOnInput={handleOnInputPassword}
                  data-testid="inputPassword"
                  name="password"
                  id="password"
                  error={passwordError}
                />
                <button
                  data-testid="btnSubmit"
                  type="submit"
                  className={css(styles.loginButton, styles.buttonPrimary)}
                >
                  Entrar
                </button>
              </form>
            </section>
          )}
        </div>
      </div>
      <section className={css(styles.containerFooter)}>
        <Footer />
      </section>
    </>
  );
};

export default LoginSeller;
