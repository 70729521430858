import { getCookie, setCookie } from '@/utils/utils';
import { SellerProps } from '@/modules/sellerSessionModule.d';
import { SELLER_KEY } from '../keySettings';
import { encrypt, decrypt } from '@/utils/Cryptography';
class SellerRepository {
  save(seller: SellerProps): void {
    if (!seller) {
      return;
    }
    setCookie(SELLER_KEY, encrypt(JSON.stringify(seller)));
  }

  getActiveSeller(): SellerProps | undefined {
    const seller = getCookie(SELLER_KEY);

    if (seller) {
      const decryptedModel = decrypt(seller);
      return JSON.parse(decryptedModel) as SellerProps;
    }

    return undefined;
  }
}

export default new SellerRepository();
