import { FormState, FieldState } from 'formstate';
import { checkEmail, checkCPF, checkCNPJ } from '../../../utils/Validator';
import { clearCPFMask } from '../../../utils/utils';

class LoginFormValidator {
  _maybeCpf = val => {
    const valueNoMask = clearCPFMask(val);
    const isInteger = !Number.isNaN(parseInt(valueNoMask, 10));
    return isInteger && valueNoMask.length === 11;
  };

  _maybeCnpj = val => {
    const valueNoMask = clearCPFMask(val);
    const isInteger = !Number.isNaN(parseInt(valueNoMask, 10));
    return isInteger && valueNoMask.length === 14;
  };

  _isValidCnpj = val => {
    const valueNoMask = clearCPFMask(val);
    if (!this._maybeCnpj(valueNoMask)) {
      return false;
    }
    return checkCNPJ(val);
  };

  _isValidCpf = val => {
    const valueNoMask = clearCPFMask(val);
    if (!this._maybeCpf(valueNoMask)) {
      return false;
    }
    return checkCPF(val);
  };

  username = new FieldState('').validators(val => {
    let error;

    if (val === '' || !val) {
      error = {
        field: 'username',
        message: 'Campo obrigatório',
      };
      return error;
    }

    if (!this._isValidCpf(val) && this._maybeCpf(val)) {
      error = {
        field: 'username',
        message: 'Ops, CPF inválido',
      };
      return error;
    }

    if (!this._isValidCnpj(val) && this._maybeCnpj(val)) {
      error = {
        field: 'username',
        message: 'Ops, CNPJ inválido',
      };
      return error;
    }

    if (!this._maybeCpf(val) && !this._maybeCnpj(val) && !checkEmail(val)) {
      error = {
        field: 'username',
        message: 'Ops, e-mail inválido',
      };
      return error;
    }

    return null;
  });

  password = new FieldState('').validators(
    val =>
      !val && {
        field: 'password',
        message: 'Campo obrigatório',
      },
  );

  form = new FormState({
    username: this.username,
    password: this.password,
  });

  isValid = async () => !this.form.hasError;

  validateForm = async () => {
    const res = await this.form.validate();

    return {
      ...res,
      ...this.form,
    };
  };
}

export default LoginFormValidator;
