import { configure, observable, action } from 'mobx';
import desktopHomeModule from '../modules/desktopHomeModule';

configure({ enforceActions: 'observed' });

class DesktopHomeStore {
  @observable data = [];

  @observable loading = true;

  @observable error = null;

  @observable pageLoaded = false;

  @observable isLoading = true;

  @observable contentRendered = false;

  @action setData = data => {
    this.data = data;
    this.isLoading = false;
    this.updateLoading(false);
  };

  @action setError = error => {
    this.error = error;
    this.updateLoading(false);
  };

  @action updateLoading = isLoading => (this.loading = isLoading);

  @action updatePageLoad = isPageLoaded => (this.pageLoaded = isPageLoaded);

  @action updateContentRendered = iscontentRendered => (this.contentRendered = iscontentRendered);

  @action getData = async () => {
    const { data, error } = await desktopHomeModule.getHomeData();

    this.updateLoading(true);

    if (error) {
      this.setError(error);
    } else if (data) {
      this.setData(data);
    }
  };
}

export default DesktopHomeStore;
