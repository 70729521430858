import { observable, action, toJS } from 'mobx';
import {
  getCookie,
  isMobileFunc,
  setCookie,
  deleteCookiesLogin,
  getCouponFromQueryStringOrCookie,
} from '../utils/utils';
import { sendLoginEventsToDatalayer } from '@/utils/trackingEvents';
import history from '../history';
import LoginModule from '../modules/loginModule';
import LoginFormValidator from '../components/login/loginForm/FormValidator';
import { linkPasswordToSocialLogin } from '../modules/socialLoginModule';
import { datadogLogs } from '@datadog/browser-logs';
import { sendEvent } from '../utils/Analytics';
import { cartPath } from '../settings';
import cartModule from '../modules/cartModule';
import { decodeToken } from '../helpers/loginSso';
class LoginStore {
  constructor({ headerStore }, appStore) {
    this.formValidator = new LoginFormValidator();
    this.formValidator.username.value = '';
    this.formValidator.password.value = '';
    this.headerStore = headerStore;
    this.appStore = appStore;
  }

  @observable password = '';

  @action setPassword = password => {
    if (this.password && password === '') {
      this.clearPasswordErrors();
      this.setPasswordErrorMsg('');
    }
    this.formValidator.password.value = password;
    this.password = password;
  };

  @observable username = '';

  @action setUsername = username => {
    this.formValidator.username.value = username;
    this.username = username;
    if (!username) {
      this.clearUsernameErrors();
    }
  };

  @action validateForm = async (shouldConsiderPassword = true) => {
    const formValidation = await this.formValidator.validateForm();

    if (formValidation.hasError) {
      const [usernameFormError, passwordFormError] = formValidation.getValues();

      if (usernameFormError.hasError) {
        this.setUsernameErrorMsg(usernameFormError.error.message);
        this.setUsernameError(usernameFormError.hasError);
      }

      if (shouldConsiderPassword && passwordFormError.hasError) {
        this.setPasswordErrorMsg(passwordFormError.error.message);
        this.setPasswordError(passwordFormError.hasError);
      }
    }

    return formValidation;
  };

  @observable isResponseLoading = false;

  @action setResponseLoading = isResponseLoading => (this.isResponseLoading = isResponseLoading);

  @observable usernameErrorMsg = '';

  @action setUsernameErrorMsg = usernameErrorMsg => (this.usernameErrorMsg = usernameErrorMsg);

  @observable usernameError = false;

  @action setUsernameError = usernameError => (this.usernameError = usernameError);

  @observable passwordError = false;

  @action setPasswordError = passwordError => (this.passwordError = passwordError);

  @observable passwordErrorMsg = '';

  @action setPasswordErrorMsg = passwordErrorMsg => (this.passwordErrorMsg = passwordErrorMsg);

  @observable isPasswordVisible = false;

  @action setPasswordVisible = isPasswordVisible => (this.isPasswordVisible = isPasswordVisible);

  @observable hasGeneralError = false;

  @action setHasGeneralError = hasGeneralError => (this.hasGeneralError = hasGeneralError);

  @observable hasNetworkError = false;

  @action setHasNetworkError = hasNetworkError => (this.hasNetworkError = hasNetworkError);

  @observable hasTermsAndPrivacyUpdate = false;

  @action setHasTermsAndPrivacyUpdate = value => (this.hasTermsAndPrivacyUpdate = value);

  @observable termsAndPrivacyUpdateToken = false;

  @action setTermsAndPrivacyUpdateToken = token => (this.termsAndPrivacyUpdateToken = token);

  @observable userDataBackup = false;

  @action setUserDataBackup = data => (this.userDataBackup = data);

  @observable isTermsAndPrivacyUpdateSocialLogin = false;

  @action setIsTermsAndPrivacyUpdateSocialLogin = value =>
    (this.isTermsAndPrivacyUpdateSocialLogin = value);

  @observable resetHashedPassword = {
    shouldReset: false,
    showModal: false,
  };

  @action setShouldResetHashedPassword = shouldReset => {
    this.resetHashedPassword.shouldReset = shouldReset;
  };

  @action setHasHashedPassword = () => {
    this.resetHashedPassword.shouldReset = true;
    this.resetHashedPassword.showModal = true;
    this.setNewPasswordMessage(
      'A nova senha não pode ser igual a senha anterior e precisa ter no mínimo 6 caracteres.',
    );
  };

  @action closeResetHashedPasswordModal = () => {
    this.resetHashedPassword.showModal = false;
  };

  @action clearUsernameErrors = () => {
    this.setUsernameErrorMsg('');
    this.setUsernameError(false);
  };

  @action clearPasswordErrors = () => {
    this.setPasswordErrorMsg('');
    this.setPasswordError(false);
  };

  @action clearErrors = () => {
    this.clearUsernameErrors();
    this.clearPasswordErrors();
    this.setHasGeneralError(false);
  };

  @action resetLoginStore = () => {
    this.setUsername('');
    this.setPassword('');
    this.clearErrors();
    this.setHasTermsAndPrivacyUpdate(false);
  };

  @observable scoobyLoginPages = [
    'cadastro',
    'meus-favoritos',
    'meus-enderecos',
    'alterar-endereco',
    'one-click-buy',
  ];

  @observable loginSubmitResponse = {
    hasError: false,
    errorMessage: null,
    userData: null,
  };

  @observable userData = '';

  @observable errorPool = [];

  @observable pendingRequest = false;

  @observable clientHeader = {};

  @observable isUserLogged = false;

  @observable tvBody = {};

  @observable isNewUser = false;

  @action setTvBody = objTV => (this.tvBody = objTV);

  @action setClientHeader = objHeader =>
    (this.clientHeader = {
      ...objHeader,
    });

  @action setUserData = data => {
    this.userData = typeof data === 'object' ? JSON.stringify(data) : data;
  };

  @action addErrorToPool = err => this.errorPool.push(err);

  @action updatePendingTo = pendingStatus => (this.pendingRequest = pendingStatus);

  @action setUserLogged = () => {
    const cookie = getCookie('siteMetadata.sessaoInfo.usuarioInfo');

    if (cookie) {
      this.isUserLogged = true;
      this.userData = cookie;
    } else {
      this.isUserLogged = false;
      this.userData = {};
    }
  };

  @action getUserLogged = () => {
    let headerNameGuest = '';
    let headerValueGuest = null;
    let headerNameGuestTelevendas = '';
    let headerValueGuestTelevendas = null;
    let headerNameCoupon = '';
    let headerValueCoupon = null;

    this.updatePendingTo(true);

    const cookieGuest = getCookie('centauro.customer');

    if (cookieGuest) {
      // TODO: Usar encodeURIComponent aqui ao invés da técnica chinesa...
      const suffixPos = cookieGuest.indexOf('&nome');
      const suffixCut = cookieGuest.substr(suffixPos);
      headerNameGuest = 'x-client-token';
      headerValueGuest = cookieGuest
        .split('codigo=')
        .join('')
        .split(suffixCut)
        .join('');
    }

    const cookieGuestTelevendas = getCookie('ECOMMERCE.CENTAURO.AUTHtv');

    if (cookieGuestTelevendas) {
      headerNameGuestTelevendas = 'x-televendas';
      headerValueGuestTelevendas = cookieGuestTelevendas;
    }

    const coupon = getCouponFromQueryStringOrCookie();

    headerNameCoupon = 'x-coupon-token';
    headerValueCoupon = coupon;

    const device = isMobileFunc() ? 7 : 1;
    const storeId = this.appStore.SessionSellerStore.storeId;
    const headers = {
      'x-cv-id': device,
      ...(storeId ? { 'x-cv-id': 28, 'x-lj-id': storeId } : {}),
    };

    if (headerNameCoupon.length > 1) {
      headers[headerNameCoupon] = headerValueCoupon;
    }

    if (headerNameGuest.length > 1) {
      headers[headerNameGuest] = headerValueGuest;
    }

    if (headerNameGuestTelevendas.length > 1) {
      headers[headerNameGuestTelevendas] = headerValueGuestTelevendas;
    }

    this.setClientHeader(headers);
    this.updatePendingTo(false);
  };

  @action setPasswordRecoveryStep = step => (this.passwordRecoveryStep = step);

  @action shouldRedirectToHome = redirectPath => {
    return this.scoobyLoginPages.some(scoobyPath => redirectPath.includes(scoobyPath));
  };

  @action redirectLoginPath = async () => {
    this.getUserLogged();

    const cartStatusMessage = await cartModule.syncCart(this.clientHeader);
    let verifyCart = !cartStatusMessage;

    const currentUrl = new URL(window.location.href);
    const returnUrl = currentUrl.searchParams.get('ReturnUrl');

    const loginRedirectPath = returnUrl || '';

    if (verifyCart) {
      verifyCart = await this.verifyPathRedirect();
    }
    if (loginRedirectPath === '/checkouts/iniciar' && verifyCart) {
      history.push(cartPath);
      return;
    }

    if (this.shouldRedirectToHome(loginRedirectPath)) {
      window.location.href = '/';
      return;
    }

    await this.updateMiniCart();

    if (loginRedirectPath) {
      history.push(loginRedirectPath);
      return;
    }
    history.push('/');
  };

  @action submitLogin = async () => {
    this.setResponseLoading(true);
    this.setPasswordVisible(false);
    this.clearErrors();
    this.setRecoveryUserLoginAttempt('');
    const hasSeller = this.appStore.SessionSellerStore.seller;
    const storeId = this.appStore.SessionSellerStore.storeId;

    let guestUserToken = null;

    if (getCookie('centauro.customer')) {
      [guestUserToken] = getCookie('centauro.customer')
        .split('=')[1]
        .split('&');
    }

    const formData = await this.validateForm();

    if (formData.hasError) {
      this.setResponseLoading(false);
      return;
    }

    let username;

    let cpfOrCnpj;
    let isCpf;

    const isCpfOrCnpj = !Number.isNaN(parseInt(this.username.substr(0, 3), 10));

    if (isCpfOrCnpj) {
      cpfOrCnpj = this.username.replace(/[() -/]/g, '');
      isCpf = cpfOrCnpj.length < 12;
      username = this.username
        .split('.')
        .join('')
        .split('-')
        .join('');
    }

    const userData = {
      usuario: username || this.username,
      senha: this.password,
    };

    const device = isMobileFunc() ? 7 : 1;

    const response = await LoginModule.loginUser(userData, device, guestUserToken);

    if (!response) {
      return;
    }

    if (response.error) {
      if (hasSeller && storeId) {
        datadogLogs.logger.error(`EE: Login - Loja[${storeId}] > ${response.error.data}`, {
          error: response.error.axiosMessage,
        });
      }

      if (response.error.status === 401 && response.error.data === 'Acesso não autorizado') {
        this.setUsername('');
        this.setPassword('');
        this.setHasGeneralError(true);
        datadogLogs.logger.error(`Login > ${response.error.data}`, {
          error: response.error.axiosMessage,
        });
      } else if (
        (response.error.status === 400 && response.error.data === 'E-mail não cadastrado.') ||
        response.error.data === 'CPF não cadastrado.' ||
        response.error.data === 'CNPJ não cadastrado.'
      ) {
        this.setUsernameError(true);
        this.setUsernameErrorMsg(response.error.data);
      } else if (response.error.status === 400 && response.error.data === 'Senha incorreta.') {
        this.setRecoveryUserLoginAttempt(username || this.username);
        this.setPasswordError(true);
        this.setPasswordErrorMsg(response.error.data);
        const forcedRequest = true;
        this.submitMaskedDataRequest(forcedRequest);
      } else if (
        response.error.status === 400 &&
        response.error.data ===
          'Sua senha expirou. Acesse “Esqueci minha senha” para criar uma nova senha.'
      ) {
        this.setHasHashedPassword();
      } else if (
        response.error.axiosMessage &&
        response.error.axiosMessage === 'Error: Network Error'
      ) {
        this.setHasNetworkError(true);
        datadogLogs.logger.error(`Login > ${response.error.axiosMessage}`);
      } else {
        this.setUsername('');
        this.setPassword('');
        this.setHasGeneralError(true);
        datadogLogs.logger.error(`Login > ${response.error.data}`, {
          error: response.error.axiosMessage,
        });
      }
      this.setLoginSubmitResponse({
        ...this.loginSubmitResponse,
        hasError: true,
        errorMessage: response.error.data,
      });
      this.setResponseLoading(false);
      return;
    }

    const loginType = isCpfOrCnpj ? (isCpf ? 'CPF' : 'CNPJ') : 'E-mail';

    sendEvent('UI - Login', 'API - Resposta Sucesso Login', loginType, 1, true);
    sendLoginEventsToDatalayer(loginType, 'login');

    let user;

    this.setTermsAndPrivacyUpdateToken(response.data.token);
    user = await this.getUserByToken(response.data.token);

    if (user.error) {
      if (user.error.axiosMessage && user.error.axiosMessage === 'Error: Network Error') {
        this.setHasNetworkError(true);
      } else {
        this.setHasGeneralError(true);
      }
      datadogLogs.logger.error(`Login > ${user.error.axiosMessage}`);
      this.setResponseLoading(false);
      return;
    }

    this.setLoginSubmitResponse({
      ...this.loginSubmitResponse,
      ...response,
      userData: response.data,
      hasError: false,
      errorMessage: '',
    });
    this.setUsername('');
    this.setUsernameError(false);
    this.setPassword('');
    this.setPasswordError(false);

    if (this.userDataBackup.versaoTermos || hasSeller) {
      this.setUserAfterAcceptTerms();
      await this.redirectLoginPath();
    } else {
      this.setResponseLoading(false);
      this.setIsTermsAndPrivacyUpdateSocialLogin(false);
      this.setHasTermsAndPrivacyUpdate(true);
    }
  };

  @action getGuestUserXClientToken = () => {
    let guestUserToken = null;
    if (getCookie('centauro.customer')) {
      [guestUserToken] = getCookie('centauro.customer')
        .split('=')[1]
        .split('&');
    }
    return guestUserToken;
  };

  @action loginType = isCpfOrCnpj => {
    const isCpf = this.username?.length < 12;
    if (isCpfOrCnpj && isCpf) return 'CPF'; // eslint-disable-line
    if (isCpfOrCnpj && !isCpf) return 'CNPJ'; // eslint-disable-line
    return 'E-mail';
  };

  @action isCpfOrCnpj = () => {
    return !Number.isNaN(parseInt(this.username?.substr(0, 3), 10)) && !this.username.includes('@');
  };

  @action formatIfCpfOrCnpj = isCpfOrCnpj => {
    if (isCpfOrCnpj) {
      this.username = this.username?.match(/\d/g, '').join('');
    }
  };

  @action sendLogWhenHasError = (seller, storeId, response) => {
    if (seller && storeId) {
      datadogLogs.logger.error(`EE: Login SSO - Loja[${storeId}] > ${response.error.data}`, {
        error: response.error.axiosMessage,
      });
    }
  };

  @action submitLoginSso = async () => {
    this.setResponseLoading(true);
    this.setPasswordVisible(false);
    this.clearErrors();
    this.setRecoveryUserLoginAttempt('');

    const formData = await this.validateForm();
    if (formData.hasError) {
      this.setResponseLoading(false);
      return;
    }

    const isCpfOrCnpj = this.isCpfOrCnpj();
    this.formatIfCpfOrCnpj(isCpfOrCnpj);
    const response = await LoginModule.loginWithSSO(this.username, this.password);

    const seller = this.appStore?.SessionSellerStore?.seller;
    const storeId = this.appStore?.SessionSellerStore?.storeId;

    if (response.status !== 200) {
      let errorMessage = 'Ocorreu algo inesperado! Tente novamente.';
      if (response.status === 401) {
        errorMessage = 'Usuário ou senha incorretos';
        this.sendLogWhenHasError(seller, storeId, response);
        this.setUsernameError(true);
        this.setPasswordError(true);
        this.setPasswordErrorMsg(errorMessage);
        datadogLogs.logger.error(`Login SSO > Usuário ou senha incorretos. Response: ${response}`, {
          error: response,
        });
      } else {
        this.setUsername('');
        this.setPassword('');
        this.setHasGeneralError(true);
        datadogLogs.logger.error(`Login SSO > ${response}`, {
          error: response,
        });
      }
      this.setLoginSubmitResponse({
        ...this.loginSubmitResponse,
        hasError: true,
        errorMessage: errorMessage,
      });
      this.setResponseLoading(false);
      return;
    }

    const access_token = response?.data?.access_token;
    const loggedUserXClientToken = decodeToken(access_token)?.['x-client-token'];

    const user = await this.getUserByToken(loggedUserXClientToken);
    this.setTermsAndPrivacyUpdateToken(loggedUserXClientToken);
    if (user?.error) {
      if (user.error.axiosMessage && user.error.axiosMessage === 'Error: Network Error') {
        this.setHasNetworkError(true);
      } else {
        this.setHasGeneralError(true);
      }
      datadogLogs.logger.error(`Login SSO > ${user.error.axiosMessage}`);
      this.setResponseLoading(false);
      return;
    }

    const loginType = this.loginType(isCpfOrCnpj);
    sendEvent('UI - Login', 'API - Resposta Sucesso Login', loginType, 1, true);
    sendLoginEventsToDatalayer(loginType, 'login');

    this.setLoginSubmitResponse({
      ...this.loginSubmitResponse,
      ...response,
      userData: user?.data,
      hasError: false,
      errorMessage: '',
    });
    this.setUsername('');
    this.setUsernameError(false);
    this.setPassword('');
    this.setPasswordError(false);

    if (this.userDataBackup.versaoTermos || seller) {
      this.setUserAfterAcceptTerms();
      await this.redirectLoginPath();
    } else {
      this.setResponseLoading(false);
      this.setIsTermsAndPrivacyUpdateSocialLogin(false);
      this.setHasTermsAndPrivacyUpdate(true);
    }
  };

  @action verifyPathRedirect = async () => {
    await this.appStore.CartStore.getCartData();
    return !this.appStore.CartStore.canContinue;
  };

  @action submitUpdateTermsAndPrivacy = async () => {
    this.setResponseLoading(true);

    const device = isMobileFunc() ? 7 : 1;

    const response = await LoginModule.updateTermsAndPrivacy(
      this.termsAndPrivacyUpdateToken,
      device,
    );

    if (response.error) {
      this.setResponseLoading(false);
      this.setHasGeneralError(true);
      datadogLogs.logger.error(
        `Consentimento Termos de Uso via Login > ${response.error.axiosMessage}`,
      );
      return;
    }

    this.setUserAfterAcceptTerms();
    await this.redirectLoginPath();
    this.setResponseLoading(false);
  };

  @action setUserByToken = async clientToken => {
    const user = await LoginModule.getCustomer(clientToken, this.clientHeader);

    if (user.error) {
      return user;
    }

    const {
      auth,
      cidade,
      cpf,
      deslogarAuto,
      doc,
      email,
      estado,
      id,
      idade,
      nome,
      ocb,
      optIn,
      sexo,
      sexoUnicoCaractere,
      tipoUsuario,
      token,
      versaoUsuarioInfo,
      viaTelevendas,
      versaoTermos,
    } = user.data;

    const firstName = nome.split(' ')[0];

    setCookie('ECOMMERCE.CENTAURO.AUTH', auth, 14);
    setCookie('centauro.customer', `codigo=${token}&nome=${firstName}&ocb=${ocb ? 1 : 0}`, 365);
    setCookie(
      'siteMetadata.sessaoInfo.usuarioInfo',
      `{"versaoUsuarioInfo":${versaoUsuarioInfo},"id":"${id}","sexo":"${
        sexo === 'Não Aplicável' ? 'naoAplicavel' : sexo
      }","sexoUnicoCaractere":"${sexoUnicoCaractere}","idade":${idade},"estado":"${estado}","cidade":"${cidade}","viaTelevendas":${viaTelevendas},"doc":"${doc}","nome":"${nome}","email":"${email}","optIn":${optIn},"ocb":${ocb},"versaoTermos":${versaoTermos},"cpf":"${cpf}","tipoUsuario":"${tipoUsuario}","token":"${token}","deslogarAuto":${deslogarAuto}}`,
      14,
    );

    this.setUserLogged();
    this.setUserData(user.data);

    return user;
  };

  @action getUserByToken = async clientToken => {
    const user = await LoginModule.getCustomer(clientToken, this.clientHeader);

    if (user.error) {
      return user;
    }

    this.setUserDataBackup(user.data);

    return user;
  };

  @action setUserAfterAcceptTerms = () => {
    const {
      auth,
      cidade,
      cpf,
      deslogarAuto,
      doc,
      email,
      estado,
      id,
      idade,
      nome,
      ocb,
      optIn,
      sexo,
      sexoUnicoCaractere,
      tipoUsuario,
      token,
      versaoUsuarioInfo,
      viaTelevendas,
      versaoTermos,
    } = this.userDataBackup;

    const firstName = nome.split(' ')[0];

    setCookie('ECOMMERCE.CENTAURO.AUTH', auth, 14);
    setCookie('centauro.customer', `codigo=${token}&nome=${firstName}&ocb=${ocb ? 1 : 0}`, 365);
    setCookie(
      'siteMetadata.sessaoInfo.usuarioInfo',
      `{"versaoUsuarioInfo":${versaoUsuarioInfo},"id":"${id}","sexo":"${
        sexo === 'Não Aplicável' ? 'naoAplicavel' : sexo
      }","sexoUnicoCaractere":"${sexoUnicoCaractere}","idade":${idade},"estado":"${estado}","cidade":"${cidade}","viaTelevendas":${viaTelevendas},"doc":"${doc}","nome":"${nome}","email":"${email}","optIn":${optIn},"ocb":${ocb},"versaoTermos":${versaoTermos},"cpf":"${cpf}","tipoUsuario":"${tipoUsuario}","token":"${token}","deslogarAuto":${deslogarAuto}}`,
      14,
    );

    this.setUserLogged();
    this.setUserData(this.userDataBackup);
  };

  @action logout = () => {
    deleteCookiesLogin();
    this.isUserLogged = false;
    this.userData = {};
    history.push('/');
  };

  // 'requestCode', 'codeValidation', 'newPassword', 'recoveryType', 'help'
  @observable passwordRecoveryStep = 'codeValidation';

  @action setPasswordRecoveryStep = step => (this.passwordRecoveryStep = step);

  @observable recoveryCodeError = false;

  @action setRecoveryCodeError = codeError => (this.recoveryCodeError = codeError);

  @observable recoveryCodeErrorMessage = '';

  @action setRecoveryCodeErrorMessage = codeErrorMessage =>
    (this.recoveryCodeErrorMessage = codeErrorMessage);

  @observable recoveryCode = ['', '', '', '', '', ''];

  @action setRecoveryCodeInput = (digit, value) => {
    this.setRecoveryCodeError(false);
    this.recoveryCode[digit] = value;
  };

  @action clearRecoveryCode = () => (this.recoveryCode = ['', '', '', '', '', '']);

  @observable maskedEmail = '';

  @action setMaskedEmail = maskedEmail => (this.maskedEmail = maskedEmail);

  @observable maskedPhone = '';

  @action setMaskedPhone = maskedPhone => (this.maskedPhone = maskedPhone);

  @observable recoveryUserLoginAttempt = '';

  @action setRecoveryUserLoginAttempt = recoveryUserLoginAttempt =>
    (this.recoveryUserLoginAttempt = recoveryUserLoginAttempt);

  @observable codeVerificationType = 0;

  @action setCodeVerificationType = type => (this.codeVerificationType = type);

  @observable isModalOpen = false;

  @action setModalVisibility = value => {
    this.isModalOpen = value;
  };

  @observable buttonSuccess = false;

  @action setButtonSuccess = value => {
    this.buttonSuccess = value;
  };

  @observable newPassword = '';

  @action setNewPassword = newPassword => {
    this.newPassword = newPassword;
    this.newPasswordError = false;
    const { length } = newPassword;

    if (length === 0) {
      this.newPasswordMessage = !this.resetHashedPassword.shouldReset
        ? 'Sua senha precisa ter no mínimo 6 caracteres.'
        : 'A nova senha não pode ser igual a senha anterior e precisa ter no mínimo 6 caracteres.';
    } else if (length < 6) {
      const plural = newPassword.length < 5;
      this.newPasswordMessage = `Falta${plural ? 'm' : ''} ${6 - newPassword.length} caracter${
        plural ? 'es.' : '.'
      }`;
      if (this.resetHashedPassword.shouldReset) {
        this.newPasswordMessage += ' A nova senha não pode ser igual a senha anterior.';
      }
    } else if (length >= 6) {
      this.newPasswordMessage = !this.resetHashedPassword.shouldReset
        ? 'Ótimo! Se quiser deixar sua senha mais forte, misture letras maiúsculas e minúsculas, números e caracteres especiais.'
        : 'Ótimo! A nova senha não pode ser igual a senha anterior. Se quiser deixar sua senha mais forte, misture letras maiúsculas e minúsculas, números e caracteres especiais.';
    }
  };

  @observable newPasswordError = false;

  @action setNewPasswordError = newPasswordError => (this.newPasswordError = newPasswordError);

  @observable newPasswordMessage = 'Sua senha precisa ter no mínimo 6 caracteres.';

  @action setNewPasswordMessage = message => (this.newPasswordMessage = message);

  @observable isResendButtonHighlighted = false;

  @action setResendButtonHighlight = value => (this.isResendButtonHighlighted = value);

  @observable redirectError = false;

  @action setRedirectError = value => (this.redirectError = value);

  @observable redirectErrorMessage = '';

  @action setRedirectErrorMessage = value => (this.redirectErrorMessage = value);

  @observable socialLoginPasswordRecovery = false;

  @action setSocialLoginPasswordRecovery = value => (this.socialLoginPasswordRecovery = value);

  @observable showSamePasswordMessage = false;

  @action setShowSamePasswordMessage = showMessage => {
    this.showSamePasswordMessage = showMessage;
  };

  @observable isPasswordStrong = false;

  @action setIsPasswordStrong = value => (this.isPasswordStrong = value);

  @action clearPasswordRecoveryData = () => {
    this.setPasswordRecoveryStep('requestCode');
    this.setRecoveryCodeError(false);
    this.setRecoveryCodeErrorMessage('');
    this.clearRecoveryCode();
    this.setMaskedEmail('');
    this.setMaskedPhone('');
    this.setRecoveryUserLoginAttempt('');
    this.setCodeVerificationType(0);
    this.setModalVisibility(false);
    this.setButtonSuccess(false);
    this.setNewPassword('');
    this.setNewPasswordError(false);
    this.setResendButtonHighlight(false);
    this.setSocialLoginPasswordRecovery(false);
    this.setShouldResetHashedPassword(false);
    this.setShowSamePasswordMessage(false);
  };

  @action resetCodeValidationState = () => {
    this.setRecoveryCodeError(false);
    this.setRecoveryCodeErrorMessage('');
    this.clearRecoveryCode();
  };

  @action checkFirstStep = () => {
    if (this.recoveryUserLoginAttempt && this.maskedEmail && this.maskedPhone) {
      this.setPasswordRecoveryStep('recoveryType');
    } else if (this.recoveryUserLoginAttempt && this.maskedEmail) {
      this.setPasswordRecoveryStep('codeValidation');
    } else {
      if (this.socialLoginPasswordRecovery) {
        this.setUsername('');
      }
      this.setPasswordRecoveryStep('requestCode');
    }
  };

  @action submitMaskedDataRequest = async (forcedRequest = false) => {
    const userData = this.recoveryUserLoginAttempt ? this.recoveryUserLoginAttempt : this.username;

    if (!userData) {
      this.setUsernameError(true);
      this.setUsernameErrorMsg('Ops, confira seus dados!');
      return;
    }

    this.setResponseLoading(true);

    let cpfOrCnpj;
    let isCpf;

    const isCpfOrCnpj = !Number.isNaN(parseInt(userData.substr(0, 3), 10));

    if (isCpfOrCnpj) {
      cpfOrCnpj = userData.replace(/[() -/]/g, '');
      isCpf = cpfOrCnpj.length < 12;
    }

    const queryString = `?cpf=${isCpfOrCnpj ? (isCpf ? cpfOrCnpj : '') : ''}&cnpj=${
      isCpfOrCnpj ? (!isCpf ? cpfOrCnpj : '') : ''
    }&email=${!isCpfOrCnpj ? userData : ''}`;

    const response = await LoginModule.requestMaskedData(queryString);

    if (response.error) {
      if (!forcedRequest) {
        if (response.error.status === 500) {
          this.setUsernameError(true);
          this.setIsNewUser(true);
          this.setUsernameErrorMsg('CPF/CNPJ ou e-mail não cadastrado');
        } else if (
          response.error.status === 400 &&
          response.error.data.message === 'E-mail ou CPF ou CNPJ está inválido'
        ) {
          this.setUsernameError(true);
          this.setUsernameErrorMsg('CPF/CNPJ ou e-mail inválido.');
        } else if (
          response.error.axiosMessage &&
          response.error.axiosMessage === 'Error: Network Error'
        ) {
          this.setHasNetworkError(true);
          datadogLogs.logger.error(`Login > ${response.error.axiosMessage}`);
        } else {
          this.setHasGeneralError(true);
          datadogLogs.logger.error(`Login > ${response.error.data.message}`, {
            error: response.error.axiosMessage,
          });
        }
      }
    } else if (response.status === 200 && response.data) {
      this.setMaskedEmail(response.data.emailMascarado);
      if (response.data.telefoneMascarado) {
        this.setMaskedPhone(response.data.telefoneMascarado);
        this.setPasswordRecoveryStep('recoveryType');
      } else {
        this.setCodeVerificationType(1);
        this.setPasswordRecoveryStep('codeValidation');
      }
    }
    this.setResponseLoading(false);
  };

  @action submitRequestRecoveryCode = async (redirected = false) => {
    this.setResponseLoading(true);
    this.clearErrors();

    const device = isMobileFunc() ? 7 : 1;

    const userData = this.recoveryUserLoginAttempt ? this.recoveryUserLoginAttempt : this.username;

    let cpfOrCnpj;
    let isCpf;

    const isCpfOrCnpj = !Number.isNaN(parseInt(userData.substr(0, 3), 10));

    if (isCpfOrCnpj) {
      cpfOrCnpj = userData.replace(/[() -/]/g, '');
      isCpf = cpfOrCnpj.length < 12;
    }

    const body = {
      cpf: isCpfOrCnpj ? (isCpf ? cpfOrCnpj : '') : '',
      cnpj: isCpfOrCnpj ? (!isCpf ? cpfOrCnpj : '') : '',
      email: !isCpfOrCnpj ? userData : '',
      tipoRecebimentoCodigoDeVerificacao: this.codeVerificationType,
    };

    const response = await LoginModule.requestRecoveryCode(body, device);

    if (response.headers?.['x-reset-senha'] === 'true') {
      this.setShouldResetHashedPassword(true);
    }

    if (response.error) {
      if (
        response.error.status === 500 &&
        response.error.data.message ===
          'O Email ou CPF ou CNPJ informado não está associado a nenhum cliente cadastrado'
      ) {
        this.setUsernameError(true);
        this.setIsNewUser(true);
        this.setUsernameErrorMsg('CPF/CNPJ ou e-mail não cadastrado');
      } else if (
        response.error.status === 400 &&
        response.error.data.message === 'E-mail ou CPF ou CNPJ está inválido'
      ) {
        this.setUsernameError(true);
        this.setUsernameErrorMsg('CPF/CNPJ ou e-mail inválido.');
      } else if (
        response.error.axiosMessage &&
        response.error.axiosMessage === 'Error: Network Error'
      ) {
        this.setHasNetworkError(true);
        datadogLogs.logger.error(
          `Recuperação de Senha > Código de Verificação > ${response.error.axiosMessage}`,
        );
        if (redirected) {
          this.setRedirectError(true);
          this.setRedirectErrorMessage('Parece que sua conexão caiu. Está tudo certo por aí?');
        }
      } else {
        if (redirected) {
          this.setRedirectError(true);
          this.setRedirectErrorMessage(
            'Tivemos um problema para enviar seu código. Tente novamente mais tarde!',
          );
        }
        this.setHasGeneralError(true);
        datadogLogs.logger.error(
          `Recuperação de Senha > Código de Verificação > ${response.error.data.message}`,
          {
            error: response.error.axiosMessage,
          },
        );
      }
    } else if (response.status === 200 && response.data.email) {
      this.setRedirectError(false);
      this.setMaskedEmail(response.data.email);
      this.setPasswordRecoveryStep('codeValidation');
    }

    this.setResponseLoading(false);
  };

  @action submitCodeValidation = async () => {
    this.setResponseLoading(true);

    const joinedCode = this.recoveryCode.join('');

    const userData = this.recoveryUserLoginAttempt ? this.recoveryUserLoginAttempt : this.username;

    let cpfOrCnpj;
    let isCpf;

    const isCpfOrCnpj = !Number.isNaN(parseInt(userData.substr(0, 3), 10));

    if (isCpfOrCnpj) {
      cpfOrCnpj = userData.replace(/[() -/]/g, '');
      isCpf = cpfOrCnpj.length < 12;
    }

    const body = {
      cpf: isCpfOrCnpj ? (isCpf ? cpfOrCnpj : '') : '',
      cnpj: isCpfOrCnpj ? (!isCpf ? cpfOrCnpj : '') : '',
      email: !isCpfOrCnpj ? userData : '',
      codigo: joinedCode,
    };

    const response = await LoginModule.validateCode(body);

    if (response.error) {
      if (
        response.error.status === 400 &&
        (response.error.data[0] === 'Código Verificador inválido!' ||
          response.error.data[0] === 'Validação está inválida!')
      ) {
        this.setRecoveryCodeError(true);
        this.setRecoveryCodeErrorMessage('Confira seu código!');
      } else if (
        response.error.status === 400 &&
        response.error.data[0] === 'Excedeu o tempo do código para validação!'
      ) {
        this.setRecoveryCodeError(true);
        this.setResendButtonHighlight(true);
        this.setRecoveryCodeErrorMessage(
          'Ops, esse código expirou. Clique em Reenviar e tente de novo!',
        );
      } else if (
        response.error.axiosMessage &&
        response.error.axiosMessage === 'Error: Network Error'
      ) {
        this.setHasNetworkError(true);
        datadogLogs.logger.error(
          `Recuperação de Senha > Código de Verificação > ${response.error.axiosMessage}`,
        );
      } else {
        this.setHasGeneralError(true);
        datadogLogs.logger.error(
          `Recuperação de Senha > Código de Verificação > ${response.error.axiosMessage}`,
        );
      }
    } else if (response.status === 200 && response.data) {
      this.setPasswordRecoveryStep('newPassword');
    }

    this.setResponseLoading(false);
  };

  @action submitNewPassword = async () => {
    this.setResponseLoading(true);
    this.setButtonSuccess(false);

    const joinedCode = this.recoveryCode.join('');

    const userData = this.recoveryUserLoginAttempt ? this.recoveryUserLoginAttempt : this.username;

    let cpfOrCnpj;
    let isCpf;

    const isCpfOrCnpj = !Number.isNaN(parseInt(userData.substr(0, 3), 10));

    if (isCpfOrCnpj) {
      cpfOrCnpj = userData.replace(/[() -/]/g, '');
      isCpf = cpfOrCnpj.length < 12;
    }

    if (this.newPassword.length < 6) {
      this.newPasswordError = true;
    } else {
      const body = {
        cpf: isCpfOrCnpj ? (isCpf ? cpfOrCnpj : '') : '',
        cnpj: isCpfOrCnpj ? (!isCpf ? cpfOrCnpj : '') : '',
        email: !isCpfOrCnpj ? userData : '',
        codigo: joinedCode,
        senha: this.newPassword,
        confirmacaoSenha: this.newPassword,
      };

      const response = await LoginModule.requestNewPassword(body);

      if (response.error) {
        if (response.error.axiosMessage && response.error.axiosMessage === 'Error: Network Error') {
          this.setHasNetworkError(true);
          datadogLogs.logger.error(
            `Recuperação de Senha > Nova Senha > ${response.error.axiosMessage}`,
          );
        } else if (
          response.error.status === 400 &&
          response.error.data === 'Senha não cumpri os requisitos de segurança.'
        ) {
          this.setNewPasswordError(true);
          this.setShouldResetHashedPassword(true);
          sendEvent(
            'UI - Recuperar Senha',
            'Submit - Recuperar Senha - Senha fraca',
            'Tentou submeter uma senha que não cumpre os padrões de segurança',
            1,
            true,
          );
        } else if (
          response.error.status === 400 &&
          response.error.data === 'A nova senha não pode ser igual a senha anterior.'
        ) {
          this.setNewPasswordError(true);
          this.setShowSamePasswordMessage(true);
          this.setNewPasswordMessage('A senha inserida é igual a anterior. Crie uma nova senha.');
          sendEvent(
            'UI - Recuperar Senha',
            'Submit - Recuperar Senha - Senha antiga',
            'Tentou utilizar a senha antiga na criação de uma nova senha',
            1,
            true,
          );
        } else {
          this.setHasGeneralError(true);
          datadogLogs.logger.error(`Recuperação de Senha > Nova Senha > ${response.error.data}`, {
            error: response.error.axiosMessage,
          });
        }
      } else if (response.status === 200 && response.data) {
        this.setButtonSuccess(true);
        this.setPassword(this.newPassword);

        if (this.socialLoginPasswordRecovery) {
          const linkResponse = this.linkPasswordSocialLoginViaPasswordRecovery();

          if (linkResponse.error) {
            if (
              linkResponse.error.axiosMessage &&
              linkResponse.error.axiosMessage === 'Error: Network Error'
            ) {
              this.setHasNetworkError(true);
              datadogLogs.logger.error(
                `Recuperação de Senha > Nova Senha > Login Social > ${linkResponse.error.axiosMessage}`,
              );
            } else {
              this.setHasGeneralError(true);
              datadogLogs.logger.error(
                `Recuperação de Senha > Nova Senha > Login Social > ${linkResponse.error.axiosMessage}`,
              );
            }
          } else {
            setTimeout(() => {
              this.returnToLoginAfterPasswordRecovery();
            }, 2000);
          }
        } else {
          setTimeout(() => {
            this.returnToLoginAfterPasswordRecovery();
          }, 2000);
        }
      }
    }

    this.setResponseLoading(false);
  };

  @action returnToLoginAfterPasswordRecovery = async () => {
    const currentUrl = new URL(window.location.href);
    const returnUrl = currentUrl.searchParams.get('ReturnUrl');
    const loginRedirectPath = returnUrl || '/slogin';
    history.push(loginRedirectPath);
  };

  @action setIsNewUser = userCondition => (this.isNewUser = userCondition);

  @action setLoginSubmitResponse = data => {
    this.loginSubmitResponse = {
      ...data,
    };
  };

  @action linkPasswordSocialLoginViaPasswordRecovery = async () => {
    const dataToSend = {
      ...toJS(this.socialLoginPasswordRecovery),
      senha: this.newPassword,
    };

    const response = await linkPasswordToSocialLogin(dataToSend, this.clientHeader);
    return response;
  };

  @action updateMiniCart = async () => {
    const cart = this.appStore.CartStore;
    await cart.getMiniCartData(true, true);
  };

  @action loginAfterRegister = async response => {
    await this.setUserByToken(response.data.token);

    this.setLoginSubmitResponse({
      ...this.loginSubmitResponse,
      ...response,
      userData: response.data,
      hasError: false,
      errorMessage: '',
    });

    await this.redirectLoginPath();
  };
}

export default LoginStore;
