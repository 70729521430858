/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import history from '@/history';

// This solution is for React Router v4
// when upgrading to v5 or v6 change this and use the useLocation hook

const useRouteChange = (callback: (arg0: any) => void) => {
  useEffect(() => {
    const unlisten = history.listen((location: any) => {
      callback(location);
    });

    return unlisten;
  }, [callback]);
};

export default useRouteChange;
