import { cpf, cnpj } from 'cpf-cnpj-validator';
import { validateCpf, validateCnpj, validateEmail } from '@/modules/registerModule';
import { sendEvent } from '@/utils/Analytics';

const DEFAULT_IDENTIFICATION_ERROR_MESSAGE = 'E-mail, CPF ou CNPJ já cadastrado';

const getAge = (dateString: Date) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

const splitDate = (value: string) => value.replaceAll('_', '').split('/');

const convertToDateObject = (value: string) => {
  const [day, month, year] = splitDate(value);

  return new Date(`${year}/${month}/${day}`);
};

const checkIsEmpty = (value: string) => value.trim() === '';

const checkValidCpf = (value: string) => (cpf.isValid(value, true) ? true : 'CPF inválido');

const checkValidCnpj = (value: string) => (cnpj.isValid(value, true) ? true : 'CNPJ inválido');

const checkCpfExists = async (value: string) => {
  try {
    await validateCpf(value.replace(/\.|-/g, ''));

    return true;
  } catch (error) {
    sendEvent('UI - Cadastro', 'API - Erro em validar CPF', 'CPF já cadastrado');
    return DEFAULT_IDENTIFICATION_ERROR_MESSAGE;
  }
};

const checkCnpjExists = async (value: string) => {
  try {
    await validateCnpj(value.replace(/\.|-/g, ''));

    return true;
  } catch (error) {
    sendEvent('UI - Cadastro', 'API - Erro em validar CNPJ', 'CNPJ já cadastrado');
    return DEFAULT_IDENTIFICATION_ERROR_MESSAGE;
  }
};

const checkEmailExists = async (value: string) => {
  try {
    await validateEmail(value);

    return true;
  } catch (error) {
    sendEvent('UI - Cadastro', 'API - Erro em validar Email', 'Email já cadastrado');
    return DEFAULT_IDENTIFICATION_ERROR_MESSAGE;
  }
};

const checkValidDate = (value: string) => {
  if (checkIsEmpty(value)) {
    return true;
  }
  const date = convertToDateObject(value);

  return date instanceof Date && !isNaN(date.getTime()) ? true : 'Data inválida';
};

const checkDateIsFilled = (value: string) => {
  if (checkIsEmpty(value)) {
    return true;
  }
  const [day, month, year] = splitDate(value);

  return day?.length != 2 || month?.length != 2 || year?.length != 4 ? 'Data inválida' : true;
};

const checkAge = (value: string) => {
  if (checkIsEmpty(value)) {
    return true;
  }
  const date = convertToDateObject(value);
  const age = getAge(date);

  return age >= 18 ? true : 'Você precisa ter mais de 18 anos';
};

const checkValidPhone = (value: string, size: number, message: string) => {
  const maskReplace = value.replace(/ |_|-|\(|\)/g, '');

  return maskReplace.length === size ? true : message;
};

const checkValidEmail = (value: string) => {
  const anyStringEmailRegex = /\S+@\S+/;

  return anyStringEmailRegex.test(value) ? true : 'E-mail inválido';
};

const checkLatinChars = (value: string, type: string) => {
  const regex = /^[a-záàâãéèêíïóôõöúüçñ ]+$/i;
  const test = regex.test(value);
  return test ? true : `Escreva seu ${type} utilizando apenas caracteres latinos.`;
};

const nameValidation = {
  required: { value: true, message: 'Insira seu nome' },
  minLength: { value: 2, message: 'Por favor, digite pelo menos 2 caracteres' },
  validate: {
    isLatin: (value: string) => checkLatinChars(value, 'nome'),
  },
};

const lastNameValidation = {
  ...nameValidation,
  required: { value: true, message: 'Insira seu sobrenome' },
  validate: {
    isLatin: (value: string) => checkLatinChars(value, 'sobrenome'),
  },
};

const cpfValidation = {
  required: { value: true, message: 'Insira seu CPF' },
  minLength: { value: 11, message: 'O CPF precisa ter 11 dígitos' },
  validate: {
    isValidCpf: (value: string) => checkValidCpf(value),
    cpfExists: (value: string) => checkCpfExists(value),
  },
};

const cnpjValidation = {
  required: { value: true, message: 'Insira seu CNPJ' },
  minLength: { value: 14, message: 'O CNPJ precisa ter 14 dígitos' },
  validate: {
    isValidCnpj: (value: string) => checkValidCnpj(value),
    cnpjExists: (value: string) => checkCnpjExists(value),
  },
};

const dateValidation = {
  required: { value: true, message: 'Informe a data de nascimento' },
  validate: {
    isValidDate: (value: string) => checkValidDate(value),
    isOlderThanEighteen: (value: string) => checkAge(value),
    isFilled: (value: string) => checkDateIsFilled(value),
  },
};

const cellphoneValidation = {
  validate: {
    isValidPhone: (value: string) => {
      const minimumValue = 11;
      const message = `O telefone celular precisa conter ${minimumValue} dígitos`;

      return checkIsEmpty(value) ? true : checkValidPhone(value, minimumValue, message);
    },
    isValidCellphone: (value: string) => {
      if (checkIsEmpty(value)) {
        return true;
      }

      const firstCellphoneNumber = value.replace(/ |_|-|\(|\)/g, '').substring(2, 3);
      const errorMessage = 'O telefone celular precisa iniciar com o número 9';

      return firstCellphoneNumber === '9' ? true : errorMessage;
    },
  },
};

const phoneValidation = {
  validate: {
    isValidPhone: (value: string) => {
      const minimumValue = 10;
      const message = `O telefone fixo precisa conter ${minimumValue} dígitos`;

      return checkIsEmpty(value) ? true : checkValidPhone(value, minimumValue, message);
    },
  },
};

const stateRegistrationValidation = {
  required: { value: true, message: 'Insira a inscrição estadual' },
};

const emailValidation = {
  required: { value: true, message: 'Insira o e-mail' },
  validate: {
    isValidEmail: (value: string) => checkValidEmail(value),
    emailExists: (value: string) => checkEmailExists(value),
  },
};

const passwordValidation = {
  required: { value: true, message: 'Insira a senha' },
  minLength: {
    value: 6,
    message: 'Tamanho mínino para cadastro de senha é de 6 caracteres',
  },
};

const addressNeighborhoodValidation = {
  required: { value: true, message: 'Insira o nome do bairro do endereço' },
};

const addressStreetValidation = {
  required: { value: true, message: 'Insira o nome do logradouro do endereço' },
};

const addressNumberValidation = {
  required: { value: true, message: 'Insira o número do endereço' },
};

const FIRST_NAME_MAX_LENGTH = 60;
const LAST_NAME_MAX_LENGTH = 60;
const NUMBER_MAX_LENGTH = 5;
const COMPLEMENT_MAX_LENGTH = 40;

const STREET_MAX_LENGTH = 60;
const CITY_MAX_LENGTH = 40;
const NEIGHBORHOOD_MAX_LENGTH = 40;
const UF_MAX_LENGTH = 3;

const EMAIL_MAX_LENGTH = 105;
const STATE_REGISTRATION_MAX_LENGTH = 18;

export {
  nameValidation,
  lastNameValidation,
  cpfValidation,
  cnpjValidation,
  dateValidation,
  cellphoneValidation,
  phoneValidation,
  emailValidation,
  passwordValidation,
  stateRegistrationValidation,
  addressNeighborhoodValidation,
  addressNumberValidation,
  addressStreetValidation,
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MAX_LENGTH,
  NUMBER_MAX_LENGTH,
  COMPLEMENT_MAX_LENGTH,
  EMAIL_MAX_LENGTH,
  STATE_REGISTRATION_MAX_LENGTH,
  STREET_MAX_LENGTH,
  CITY_MAX_LENGTH,
  NEIGHBORHOOD_MAX_LENGTH,
  UF_MAX_LENGTH,
};
