import { StyleSheet } from 'aphrodite/no-important';

import { grayC, grayNN, white, redA, grayD, grayA } from '@/common/colors';

const styles = StyleSheet.create({
  loginInputContainer: {
    width: '100%',
    boxSizing: 'border-box',
    position: 'relative',
    paddingBottom: '8px',
  },
  loginButton: {
    cursor: 'pointer',
    position: 'relative',
    height: '48px',
    width: '100%',
    outline: 'transparent',
    color: white,
    fontSize: '14px',
    borderRadius: '3px',
    fontFamily: 'Roboto',
  },
  buttonPrimary: {
    backgroundColor: redA,
    marginBottom: '8px',
    marginTop: '16px',
  },
  buttonOutline: {
    backgroundColor: 'transparent',
    border: `solid 1px ${grayC}`,
    color: grayC,
  },
  loginIcon: {
    boxSizing: 'border-box',
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    position: 'absolute',
    top: '13px',
    left: '15px',
  },
  loginUserInput: {
    boxSizing: 'border-box',
    color: grayC,
    width: '100%',
    height: '48px',
    borderRadius: '3px',
    border: `solid 1px ${grayD}`,
    backgroundColor: white,
    paddingLeft: '45px',
    paddingRight: '45px',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    '-webkit-font-smoothing': 'antialiased',
    '::placeholder': {
      color: grayNN,
      fontSize: '14px',
      fontFamily: 'Roboto',
      fontWeight: 'normal',
    },
  },
  inputPasswordDots: {
    fontFamily: 'small-caption',
    fontSize: '28px',
  },
  keyIcon: {
    position: 'absolute',
    top: '16px',
    left: '15px',
  },
  buttonPasswordVisible: {
    cursor: 'pointer',
    position: 'absolute',
    backgroundColor: 'transparent',
    top: 0,
    right: 0,
    height: '48px',
    width: '45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputError: {
    border: `1px solid ${redA}`,
    color: redA,
  },
  errorMessage: {
    fontSize: '14px',
    textAlign: 'left',
    paddingTop: '8px',
    color: redA,
  },
  messageArea: {
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '24px',
  },
  forgotPasswordAncor: {
    bottom: '9px',
    position: 'absolute',
    right: '0',
    color: grayA,
  },
});

export default styles;
