import { observable, action } from 'mobx';
import {
  postEmailServiceSubscription,
  postEmailServiceCancellation,
} from '../modules/emailServiceModule';
import { deviceId } from '../utils/utils';

class EmailServicesStore {
  constructor({ errorHandlerStore }) {
    this.errorHandlerStore = errorHandlerStore;
  }

  @observable subscribeServiceText =
    'Cadastre-se e receba as melhores ofertas, promoções e muito mais!';

  @observable cancelServiceText =
    'Descadastre seu e-mail para não receber nossas promoções e ofertas exclusivas.';

  @action postEmailServiceSubscription = async email => {
    this.setIsLoading(true);

    const formData = {
      email,
    };

    const { data: subscribed, error } = await postEmailServiceSubscription(formData, {
      cvId: deviceId,
    });

    const processSucceded = subscribed && !error;

    this.setIsLoading(false);

    return processSucceded;
  };

  @observable isLoading = false;

  @action setIsLoading = bool => (this.isLoading = bool);

  @action postEmailServiceCancellation = async email => {
    this.setIsLoading(true);
    // TODO: (Gustavo Fonseca) Eliminar as aspas do e-mail quando houver o ajuste no backend
    const { data: canceled, error } = await postEmailServiceCancellation(`"${email}"`, {
      cvId: deviceId,
    });

    const processSucceded = canceled && !error;

    this.setIsLoading(false);

    return processSucceded;
  };
}

export default EmailServicesStore;
