import { getJson, postJson } from './httpClientCentauro';
import { apiDomain, ssoDomain } from '../settings';
import { buildXformData } from '../helpers/loginSso';
import { datadogLogs } from '@datadog/browser-logs';
import SellerRepository from '../hooks/sessionSeller/repository/SellerRepository';
import axios from 'axios';

const TERMS_PRIVACY_VERSION = '1';

const urlLogin = `${apiDomain}/v2.2/clientes/login`;
const urlNewLogin = `${apiDomain}/v2.1/clientes/login`;
const urlAssistedSale = `${apiDomain}/v5.1/clientes/venda-assistida/login`;
// const routeGetClient = `${apiDomain}/v3/clientes`;
const routeGetClient = `${apiDomain}/v5.1/clientes/dados-acesso-cliente`;

const urlRequestRecoveryCode = `${apiDomain}/v5.0/clientes/redefinicao-senha`;
const urlValidateCode = `${apiDomain}/v5.0/clientes/validacao-redefinicao-senha`;
const urlNewPassword = `${apiDomain}/v5.0/clientes/validacao-codigo-redefinir-senha`;
const urlMaskedData = `${apiDomain}/v5.0/clientes/recuperacao-senha/dados`;
const urlUpdateTermsAndPrivacy = `${apiDomain}/v5.1/clientes/envio-aceite-termos/${TERMS_PRIVACY_VERSION}`;

const getLoginData = () => getJson(urlLogin, true);

const setLoginData = user => postJson(urlLogin, user, true);

// site 1; mobile 7
const loginUser = (userData, xCvId, token) => {
  const headers = token ? { 'x-cv-id': xCvId, 'x-client-token': token } : { 'x-cv-id': xCvId };
  const seller = SellerRepository.getActiveSeller();
  if (seller) {
    const seller = SellerRepository.getActiveSeller();
    const sellerHeaders = { ...headers, 'x-user-token': seller.token };

    return postJson(urlAssistedSale, userData, sellerHeaders);
  }

  return postJson(urlNewLogin, userData, headers);
};

const getCustomer = (token, clientHeader) =>
  getJson(routeGetClient, {
    ...clientHeader,
    'x-client-token': token,
  });

const requestRecoveryCode = (userData, xCvId) =>
  postJson(urlRequestRecoveryCode, userData, {
    'x-cv-id': xCvId,
  });

const validateCode = (userData, xCvId) =>
  postJson(urlValidateCode, userData, {
    'x-cv-id': xCvId,
  });

const requestNewPassword = (userData, xCvId) =>
  postJson(urlNewPassword, userData, {
    'x-cv-id': xCvId,
  });

const requestMaskedData = queryString => getJson(`${urlMaskedData}${queryString}`, {}, true);

const updateTermsAndPrivacy = (token, xCvId) =>
  getJson(urlUpdateTermsAndPrivacy, {
    'x-client-token': token,
    'x-cv-id': xCvId,
  });

const loginWithSSO = async (username, password) => {
  const params = buildXformData(username, password);
  const headers = window.bmak
    ? {
        'Akamai-BM-Telemetry': window.bmak.get_telemetry(),
      }
    : null;
  const { SSO_PASSWORD_FLOW_ENDPOINT } = process.env;
  const url = `${ssoDomain}${SSO_PASSWORD_FLOW_ENDPOINT}`;

  try {
    const response = await axios.post(url, params, {
      withCredentials: true,
      headers: headers,
    });
    return response;
  } catch (error) {
    datadogLogs.logger.error(`Login Sso Request [Password Flow] > ${error}`);
    return error?.response || { status: 500 };
  }
};

export default {
  getLoginData,
  setLoginData,
  loginUser,
  getCustomer,
  requestRecoveryCode,
  validateCode,
  requestNewPassword,
  requestMaskedData,
  updateTermsAndPrivacy,
  loginWithSSO,
};
