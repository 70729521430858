import { StyleSheet } from 'aphrodite';

import { grayA, grayNN, white, redA, grayD } from '@/common/colors';

const styles = StyleSheet.create({
  loginInputContainer: {
    width: '100%',
    boxSizing: 'border-box',
    position: 'relative',
    paddinTop: '16px',
    paddingBottom: '8px',
  },
  loginIcon: {
    boxSizing: 'border-box',
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    position: 'absolute',
    top: '13px',
    left: '15px',
  },
  loginUserInput: {
    boxSizing: 'border-box',
    color: grayA,
    width: '100%',
    height: '48px',
    borderRadius: '3px',
    border: `solid 1px ${grayD}`,
    backgroundColor: white,
    paddingLeft: '45px',
    paddingRight: '45px',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    '-webkit-font-smoothing': 'antialiased',
    '::placeholder': {
      color: grayNN,
    },
  },
  inputError: {
    border: `1px solid ${redA}`,
    color: redA,
  },
  errorMessage: {
    fontSize: '14px',
    textAlign: 'left',
    paddingTop: '8px',
    color: redA,
  },
  messageArea: {
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '24px',
  },
});

export default styles;
