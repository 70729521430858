import { getJson } from './httpClientCentauro';
import { apiDomain, homePathNoCache } from '../settings';
import history from '../history';

const urlHomeGet = `${apiDomain}/v3/homes/desktop`;

const urlHomeGetNoCache = `${apiDomain}/v3/homes/simulacao/`;

const getHomeData = () => {
  if (history.location.pathname === homePathNoCache) {
    const params = new URLSearchParams(history.location.search);

    return getJson(`${urlHomeGetNoCache}${params.get('token')}?furabolo=56`);
  }

  return getJson(urlHomeGet);
};

export default {
  getHomeData,
};
