import { currencyToNumber } from '@/utils/utils';
import { personalizationIframe } from '@/settings';
import { Color } from '@/viewControllers/pdpContainer/Pdp.interface';
import { PersonalizationMessage } from './PersonalizationModal/PersonalizationModal.types';

export enum PERSONALIZATION_TYPE_CENTAURO {
  UNDEFINED = 0,
  CLOTHES = 2,
  SHOES = 3,
  PRE_PERSONALIZED = 4,
}

/**
 * Returns if is product mktp
 * and seller is nike
 */
export const getIsSellerNike = (sku: string, sellerName: string) => {
  const isProductMkpt = sku?.includes('M');
  const isSellerNike = sellerName?.toLocaleLowerCase().includes('nike');

  if (isProductMkpt && isSellerNike) {
    return true;
  }
  return false;
};

type Skus = {
  sku: string;
  ean: string;
};

type GetPersonalizationProps = {
  /** product skus array */
  skus?: Skus[];
  colors?: Color[];

  /** selected product sku */
  currentSku: string;
};

/**
 * Returns the ean code of current sku
 * available sku inside the array
 * If the current sku isn't available, returns undefined
 */
export const getEan = ({ skus, currentSku }: GetPersonalizationProps) => {
  const selectedSku = skus?.find(sku => sku.sku === currentSku);

  if (!selectedSku) {
    return {
      ean: undefined,
    };
  }

  return {
    ean: selectedSku?.ean,
  };
};

/**
 * Remove extra string from ean,
 * because the api returns the ean with a extra number the beginning
 */
export const removeStringEan = (ean?: string) => {
  const eanExactLength = 12;

  if (!ean) {
    return;
  }

  if (ean.length > eanExactLength) {
    const arrayEan = Array.from(ean);
    arrayEan.shift();
    return arrayEan.join('');
  }

  return ean;
};

export const getPersonalizationInfos = ({ colors, currentSku }: GetPersonalizationProps) => {
  const color = colors?.find(color => {
    const modelColor = color.sku;

    return modelColor.slice(0, 8) === currentSku;
  });

  if (!color) {
    return {
      isPersonalizable: false,
      type: 0,
    };
  }

  return {
    isPersonalizable: color?.customizavel,
    type: color?.tipoPersonalizacao || PERSONALIZATION_TYPE_CENTAURO.UNDEFINED,
  };
};

const PERSONALIZATION_MAP: { [key: number]: string | undefined } = {
  [PERSONALIZATION_TYPE_CENTAURO.UNDEFINED]: undefined,
  [PERSONALIZATION_TYPE_CENTAURO.CLOTHES]: '/jersey',
  [PERSONALIZATION_TYPE_CENTAURO.SHOES]: '/futebol',
};

/**
 * Returns the personalization iframe url
 *
 * @param personalizationType - type of customization returned by the API
 * @param price - price of product
 * @param ean - ean (complete sku used by nike) of product
 */
export const getPersonalizationUrl = (
  personalizationType: number,
  ean: string | undefined,
  price: string | undefined,
) => {
  if (personalizationType === PERSONALIZATION_TYPE_CENTAURO.UNDEFINED) {
    return;
  }
  const personalizationPath = PERSONALIZATION_MAP[personalizationType];

  return `${personalizationIframe}/frontend/v2${personalizationPath}/?idSku=${removeStringEan(
    ean,
  )}&productPrice=${currencyToNumber(price)}&channel=centauro`;
};

export const getPersonalizationPrice = (customization: PersonalizationMessage) => {
  return customization.parameters.Customization.reduce(
    (prev, current) => prev + current.DetalhesPersonalizacao.Preco,
    0,
  );
};
