import { getJson } from '@/modules/httpClient';
import AppStore from '@/stores/ApplicationStore';
import { apiDomainGCPUS } from '@/settings';
import { BalanceData, TransactionsData } from './wallet.types';

const getBalance = async (): Promise<BalanceData | undefined> => {
  const response = await getJson<BalanceData>({
    url: `${apiDomainGCPUS}/wallet/api/v1/wallet/`,
    secondaryHeader: {
      'x-client-token': AppStore.LoginStore.clientHeader['x-client-token'],
    },
  });

  return response.data;
};

const getTransactions = async (): Promise<TransactionsData | undefined> => {
  const response = await getJson<TransactionsData>({
    url: `${apiDomainGCPUS}/wallet/api/v1/transaction/wallet/`,
    secondaryHeader: {
      'x-client-token': AppStore.LoginStore.clientHeader['x-client-token'],
    },
  });

  return response.data;
};

export { getBalance, getTransactions };
