import { StyleSheet } from 'aphrodite';

import { grayA, grayH, white, grayD } from '../../../common/colors';

const translateKeyframes = {
  '0%': {
    transform: 'translateY(-200px)',
  },
  '100%': {
    transform: 'translateY(0px)',
  },
};

const opacityKeyFrames = {
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
};

const styles = StyleSheet.create({
  lightBoxBg: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: grayH,
    zIndex: '100',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    animationName: opacityKeyFrames,
    animationDuration: '300ms',
  },
  lightBox: {
    background: white,
    margin: '0',
    minWidth: '300px',
    minHeight: '50px',
    animationName: [translateKeyframes, opacityKeyFrames],
    animationDuration: '300ms, 300ms',
    position: 'relative',
  },
  closeButton: {
    background: 'none',
    position: 'absolute',
    display: 'table',
    top: '16px',
    right: '17px',
    cursor: 'pointer',
    zIndex: '1',
  },
  closeOut: {
    top: '-40px',
    right: '12px',
  },
  lightBoxHeader: {
    minHeight: '62px',
    borderBottom: '2px solid',
    borderColor: grayD,
    display: 'table',
    width: '100%',
    textAlign: 'left',
  },
  lightBoxTitle: {
    color: grayA,
    fontSize: '24px',
    lineHeight: '1.5',
    fontWeight: '400',
    padding: '0 64px 0 32px',
    marginTop: '16px',
  },
  customHeader: {
    minHeight: '48px',
    borderBottom: '1px solid #eceff1',
    boxSizing: 'border-box',
  },
  centeredTitle: {
    padding: '0',
    fontSize: '20px',
    textAlign: 'center',
    lineHeight: '1',
    marginTop: '12px',
  },
});

export default styles;
