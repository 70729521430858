import { getCookie, setCookie, eraseCookie } from '@/utils/utils';
import { ModuleConfig } from '../interfaces/hookInterfaces';
import { CLIENT_KEY } from '../keySettings';
import { encrypt, decrypt } from '@/utils/Cryptography';

class ModuleRepository {
  save(moduleConfig: ModuleConfig): void {
    setCookie(CLIENT_KEY, encrypt(JSON.stringify(moduleConfig)));
  }

  delete(): void {
    eraseCookie(CLIENT_KEY);
  }

  getModuleConfig(): ModuleConfig | undefined {
    const moduleConfig = getCookie(CLIENT_KEY);

    if (moduleConfig) {
      return JSON.parse(decrypt(moduleConfig)) as ModuleConfig;
    }

    return undefined;
  }
}

export default new ModuleRepository();
