import { observable, action, computed, toJS } from 'mobx';

import {
  socialLoginUser,
  emailValidation,
  validateCPFinOurDatabase,
  linkPasswordToSocialLogin,
} from '../modules/socialLoginModule';

import { sendEvent } from '../utils/Analytics';
import { clearCPFMask } from '../utils/utils';
import { sendLoginEventsToDatalayer } from '../utils/trackingEvents';

const sendGAEventSuccess = socialNetwork => {
  sendEvent(
    `API - Login - ${socialNetwork} - Sucesso`,
    `Login com ${socialNetwork} Bem Sucedido`,
    `Resposta da API para logar com o ${socialNetwork} `,
  );
  sendLoginEventsToDatalayer(socialNetwork, 'login');
};

const sendGAEventFailure = socialNetwork => {
  sendEvent(
    `API - Login - ${socialNetwork} - Falha`,
    `Login com ${socialNetwork} Mal Sucedido`,
    `Resposta da API para logar com o ${socialNetwork}`,
  );
};

const sendGAEventFailureCNPJ = socialNetwork => {
  sendEvent(
    `API - Login - ${socialNetwork} - Falha`,
    `Login com ${socialNetwork} usando CNPJ`,
    `Resposta da API para logar com o ${socialNetwork}`,
  );
};

class SocialLoginStore {
  constructor({ loginStore }) {
    this.loginStore = loginStore;
  }

  @observable usernameError = false;

  @action setUsernameError = usernameError => (this.usernameError = usernameError);

  @observable usernameErrorMsg = '';

  @action setUsernameErrorMsg = usernameErrorMsg => (this.usernameErrorMsg = usernameErrorMsg);

  @observable passwordError = false;

  @action setPasswordError = passwordError => (this.passwordError = passwordError);

  @observable passwordErrorMsg = '';

  @action setPasswordErrorMsg = passwordErrorMsg => (this.passwordErrorMsg = passwordErrorMsg);

  @observable isValidatingCPF = true;

  @action setIsValidatingCPF = isValidatingCPF => (this.isValidatingCPF = isValidatingCPF);

  @observable usersCPFAlreadyExists = false;

  @action setUsersCPFAlreadyExists = usersCPFAlreadyExists =>
    (this.usersCPFAlreadyExists = usersCPFAlreadyExists);

  @observable headerTitle = 'acesso rápido';

  @action setHeaderTitle = headerTitle => (this.headerTitle = headerTitle);

  @observable headerSubtitle = 'PARA CONTINUAR INSIRA O CPF';

  @action setHeaderSubtitle = headerSubtitle => (this.headerSubtitle = headerSubtitle);

  @observable isOptinCheckboxVisible = false;

  @action setOptinCheckboxVisible = value => (this.isOptinCheckboxVisible = value);

  @observable isOptinChecked = false;

  @action setOptinCheck = checked => (this.isOptinChecked = checked);

  @computed get optin() {
    return this.isOptinCheckboxVisible ? this.isOptinChecked : null;
  }

  @action clearUsernameErrors = () => {
    this.setUsernameErrorMsg('');
    this.setUsernameError(false);
  };

  @action clearPasswordErrors = () => {
    this.setPasswordErrorMsg('');
    this.setPasswordError(false);
  };

  @action resetSocialLoginStore = () => {
    this.isValidatingCPF = true;
    this.usersCPFAlreadyExists = false;
    this.isSocialLogin = false;
  };

  @observable shouldOpenModalSocialLoginError = false;

  @action setShouldOpenModalSocialLoginError = shouldOpen =>
    (this.shouldOpenModalSocialLoginError = shouldOpen);

  @observable modalErrorSocialNetworkSelected = '';

  @action setModalErrorSocialNetworkSelected = socialNetwork =>
    (this.modalErrorSocialNetworkSelected = socialNetwork);

  @observable otherSocialNetwork = '';

  @action setOtherSocialNetworkTry = otherSocialNetwork =>
    (this.otherSocialNetwork = otherSocialNetwork);

  @observable isLoading = false;

  @action setIsLoading = isLoading => (this.isLoading = isLoading);

  @observable isNewUser = false;

  @action setIsNewUser = isNewUser => (this.isNewUser = isNewUser);

  @action onFacebookLogin = payload => this.onSocialLogin(payload, 'Facebook');

  @action onGoogleLogin = payload => this.onSocialLogin(payload, 'Google');

  @observable isSocialLogin = false;

  @action setIsSocialLogin = value => (this.isSocialLogin = value);

  @action onSocialLogin = async (payload, socialNetwork) => {
    this.loginStore.setSocialLoginPasswordRecovery(false);

    if (payload.accessToken) {
      const response = await this.submitSocialUserData(payload.accessToken, socialNetwork);

      if (response.status && response.status === 200) {
        sendGAEventSuccess(socialNetwork);

        this.loginStore.setTermsAndPrivacyUpdateToken(response.data.token);
        const user = await this.loginStore.getUserByToken(response.data.token);

        if (user.error) {
          if (user.error.axiosMessage && user.error.axiosMessage === 'Error: Network Error') {
            this.loginStore.setHasNetworkError(true);
          } else {
            this.loginStore.setHasGeneralError(true);
          }
          this.setIsLoading(false);
          return;
        }

        if (this.loginStore.userDataBackup.versaoTermos) {
          this.loginStore.setUserAfterAcceptTerms();
          await this.loginStore.redirectLoginPath();
        } else {
          this.setIsLoading(false);
          this.loginStore.setIsTermsAndPrivacyUpdateSocialLogin(true);
          this.loginStore.setHasTermsAndPrivacyUpdate(true);
        }

        return;
      }

      if (response.status && response.status === 202) {
        sendGAEventSuccess(socialNetwork);
        this.setUserProviderData(response.data);
        this.setHeaderTitle('você já tem uma conta!');
        this.setHeaderSubtitle('digite sua senha e ative o acesso rápido');
        this.setIsValidatingCPF(false);
        this.setUsersCPFAlreadyExists(true);
        this.loginStore.setUsername(response.data.email);
        this.setIsSocialLogin(true);

        const linkPasswordToSocialLoginData = {
          provedorDeLogin: this.userProviderData.provedorDeLogin,
          idDoClienteNoProvedorDeLogin: this.userProviderData.idDoClienteNoProvedorDeLogin,
        };

        this.loginStore.setSocialLoginPasswordRecovery(linkPasswordToSocialLoginData);
        this.setIsLoading(false);
        return;
      }

      if (response.error && response.error.status && response.error.status === 403) {
        sendGAEventFailureCNPJ(socialNetwork);
        this.setIsLoading(false);
        this.setCnpjSocialLoginMatchModal(true);
        return;
      }

      if (response.error && response.error.status && response.error.status === 401) {
        sendGAEventSuccess(socialNetwork);

        this.setUserProviderData(response.error.data);

        this.setOptinCheckboxVisible(response.error.data.email ? true : false);

        this.setIsSocialLogin(true);
      } else {
        sendGAEventFailure(socialNetwork);

        this.setShouldOpenModalSocialLoginError(true);
        this.setModalErrorSocialNetworkSelected(socialNetwork);
        this.setOtherSocialNetworkTry(socialNetwork === 'Facebook' ? 'Google' : 'Facebook');
      }
    }

    const linkPasswordToSocialLoginData = {
      provedorDeLogin: this.userProviderData.provedorDeLogin,
      idDoClienteNoProvedorDeLogin: this.userProviderData.idDoClienteNoProvedorDeLogin,
    };

    this.loginStore.setSocialLoginPasswordRecovery(linkPasswordToSocialLoginData);

    this.setIsLoading(false);
  };

  @action submitSocialUserData = async (userCredencials, socialMedia) => {
    const { clientHeader } = this.loginStore;

    const response = await socialLoginUser(userCredencials, socialMedia, clientHeader);

    return response;
  };

  @action validateForm = async () => {
    if (this.username === '') {
      return null;
    }

    const { formValidator } = this.loginStore;

    const formValidation = await formValidator.validateForm();

    if (formValidation.hasError) {
      const [usernameFormError, passwordFormError] = formValidation.getValues();

      if (usernameFormError.hasError) {
        this.setUsernameErrorMsg(usernameFormError.error.message);
        this.setUsernameError(usernameFormError.hasError);
      } else if (this.usernameError) {
        this.clearUsernameErrors();
      }

      if (passwordFormError.hasError) {
        this.setPasswordErrorMsg(passwordFormError.error.message);
        this.setPasswordError(passwordFormError.hasError);
      } else if (this.passwordError) {
        this.clearPasswordErrors();
      }
    }

    return formValidation;
  };

  @observable userProviderData = {
    cpf: this.loginStore.userData.cpf,
    email: this.loginStore.userData.email,
    provedorDeLogin: this.loginStore.userData.provider,
    idDoClienteNoProvedorDeLogin: this.loginStore.userData.idProvider,
  };

  @action setUserProviderData = userProviderData => {
    const loginData = this.loginStore.userData ? toJS(this.loginStore.userData) : {};

    const cpf = userProviderData.cpf
      ? userProviderData.cpf
      : loginData.cpf
      ? loginData.cpf
      : this.userProviderData.cpf;

    const email =
      userProviderData.email && !userProviderData.email.includes('*')
        ? userProviderData.email
        : loginData.email && !userProviderData.email.includes('*')
        ? loginData.email
        : this.userProviderData.email && !userProviderData.email.includes('*')
        ? this.userProviderData.email
        : null;

    const provedorDeLogin = userProviderData.provedorDeLogin
      ? userProviderData.provedorDeLogin
      : userProviderData.provider
      ? userProviderData.provider
      : loginData.provider
      ? loginData.provider
      : this.userProviderData.provedorDeLogin;

    const idDoClienteNoProvedorDeLogin = userProviderData.idDoClienteNoProvedorDeLogin
      ? userProviderData.idDoClienteNoProvedorDeLogin
      : userProviderData.idProvider
      ? userProviderData.idProvider
      : loginData.idProvider
      ? loginData.idProvider
      : this.userProviderData.idDoClienteNoProvedorDeLogin;

    this.userProviderData = {
      cpf,
      email,
      provedorDeLogin,
      idDoClienteNoProvedorDeLogin,
    };
  };

  @observable emailError = false;

  @action setEmailError = value => (this.emailError = value);

  @observable emailErrorMsg = '';

  @action setEmailErrorMsg = value => (this.emailErrorMsg = value);

  @observable cnpjSocialLoginMatchModal = false;

  @action setCnpjSocialLoginMatchModal = value => (this.cnpjSocialLoginMatchModal = value);

  @action submitEmailValidation = async () => {
    this.setIsLoading(true);
    this.clearUsernameErrors();
    this.clearPasswordErrors();

    const body = {
      ...toJS(this.userProviderData),
      habilitarOptIn: this.isOptinChecked,
    };

    const response = await emailValidation(body);

    if (response?.error) {
      if (response?.error?.status === 500) {
        this.setEmailError(true);
        this.setEmailErrorMsg(response.error.data);
        this.setIsLoading(false);

        return;
      }
      this.setPasswordError(true);
      this.setPasswordErrorMsg('Senha incorreta');
      this.setIsLoading(false);

      return;
    }

    if (response?.data?.token) {
      await this.completeLogin(response.data.token);

      this.setIsLoading(false);

      return;
    }

    if (response?.data?.email) {
      this.loginStore.setUsername(response.data.email);

      this.setUserProviderData({
        ...this.userProviderData,
        email: response.data.email,
      });
    }

    this.setIsLoading(false);
  };

  @action setInputUsername = usernameValue => {
    if (usernameValue === '' && this.usernameError) {
      this.clearUsernameErrors();
    }

    this.loginStore.setUsername(usernameValue);

    this.setUserProviderData({
      ...this.userProviderData,
      cpf: clearCPFMask(usernameValue),
    });
  };

  @action setInputEmail = userEmail => {
    if (userEmail === '' && this.usernameError) {
      this.clearUsernameErrors();
    }

    this.loginStore.setUsername(userEmail);

    this.setUserProviderData({
      ...this.userProviderData,
      email: userEmail,
    });
  };

  @action setInputPassword = evt => {
    const {
      target: { value },
    } = evt;

    if (this.usernameError) {
      this.clearUsernameErrors();
    }

    if (this.loginStore.password && value === '') {
      this.clearPasswordErrors();
    }

    this.loginStore.setPassword(value);
  };

  @action completeLogin = async token => {
    const user = await this.loginStore.setUserByToken(token);

    if (user.error) {
      if (user.error.axiosMessage && user.error.axiosMessage === 'Error: Network Error') {
        this.loginStore.setHasNetworkError(true);
      } else {
        this.loginStore.setHasGeneralError(true);
      }
      this.setIsLoading(false);
      return;
    }
    await this.loginStore.redirectLoginPath();

    this.setIsSocialLogin(false);
  };

  @action submitCpf = async () => {
    // TODO: Add GA

    const { clientHeader } = this.loginStore;

    this.setIsLoading(true);

    if (this.usernameError) {
      this.clearUsernameErrors();
    }

    const formValidated = await this.validateForm();
    const cpfField = formValidated.getValues()[0];

    if (cpfField.hasError) {
      this.setUsernameError(true);
      this.setUsernameErrorMsg(cpfField.error.message);
      this.setIsLoading(false);
      return;
    }

    const userProviderData = toJS(this.userProviderData);
    const body = {
      ...userProviderData,
      habilitarOptIn: this.optin,
    };

    const cpfProvider = await validateCPFinOurDatabase(body, clientHeader);

    if (cpfProvider) {
      if (cpfProvider.status === 200) {
        const { token } = cpfProvider.data;
        await this.completeLogin(token);
      } else if (cpfProvider?.error?.status === 401 || cpfProvider?.error?.status === 202) {
        // tela de login com email e senha

        this.setHeaderTitle('você já tem uma conta!');
        this.setHeaderSubtitle('digite sua senha e ative o acesso rápido');

        this.setIsValidatingCPF(false);
        this.setUsersCPFAlreadyExists(true);
        this.loginStore.setUsername(cpfProvider.error.data.email);
      } else if (cpfProvider?.status === 204) {
        // tela adicione email

        this.setHeaderSubtitle('adicione um e-mail para finalizar');

        this.setIsValidatingCPF(false);
        this.setUsersCPFAlreadyExists(false);
        this.loginStore.setUsername('');
      } else if (cpfProvider?.error?.status === 500) {
        this.setUsernameError(true);
        this.setUsernameErrorMsg(cpfProvider.error.data);
      }
    }

    this.setIsLoading(false);
  };

  @action linkPasswordSocialLogin = async () => {
    const { clientHeader, password } = this.loginStore;
    this.setIsLoading(true);
    const dataToSend = {
      senha: password,
      cpf: this.userProviderData.cpf,
      provedorDeLogin: this.userProviderData.provedorDeLogin,
      idDoClienteNoProvedorDeLogin: this.userProviderData.idDoClienteNoProvedorDeLogin,
      habilitarOptIn: this.optin,
    };

    const passwordToSocialLogin = await linkPasswordToSocialLogin(dataToSend, clientHeader);

    if (passwordToSocialLogin && passwordToSocialLogin.error) {
      this.loginStore.setPasswordError(true);
      this.loginStore.setPasswordErrorMsg('Senha incorreta');
    } else {
      // Já tem conta na Centauro, informou a senha e bateu!!!

      const { token } = passwordToSocialLogin.data;

      await this.completeLogin(token);
    }

    this.setIsLoading(false);
  };
}

export default SocialLoginStore;
