import { isShirt, isFootballBoots, isPrePersonalized } from '../helpers/customizationHelper';

const CustomProductService = ({
  customName,
  customNumber,
  customNameRightExternalFoot,
  customColorRightExternalFoot,
  customFlagRightExternalFoot,
  customNameLeftExternalFoot,
  customColorLeftExternalFoot,
  customFlagLeftExternalFoot,
  customNameRightInternalFoot,
  customColorRightInternalFoot,
  customFlagRightInternalFoot,
  customNameLeftInternalFoot,
  customColorLeftInternalFoot,
  customFlagLeftInternalFoot,
  customizeType,
  rightExternalValue,
  rightInternalValue,
  leftExternalValue,
  leftInternalValue,
  idolValue,
  nameValue,
  numberValue,
  productCode,
}) => {
  const getFootballBoots = () => {
    const rightExternalCustomValue =
      (customNameRightExternalFoot && customColorRightExternalFoot) || customFlagRightExternalFoot
        ? parseFloat(rightExternalValue)
        : 0;

    const rightInternalCustomValue =
      (customNameRightInternalFoot && customColorRightInternalFoot) || customFlagRightInternalFoot
        ? parseFloat(rightInternalValue)
        : 0;

    const leftExternalCustomValue =
      (customNameLeftExternalFoot && customColorLeftExternalFoot) || customFlagLeftExternalFoot
        ? parseFloat(leftExternalValue)
        : 0;

    const leftInternalCustomValue =
      (customNameLeftInternalFoot && customColorLeftInternalFoot) || customFlagLeftInternalFoot
        ? parseFloat(leftInternalValue)
        : 0;

    return `${rightExternalCustomValue +
      rightInternalCustomValue +
      leftExternalCustomValue +
      leftInternalCustomValue}`;
  };

  const getCustomValue = () => {
    let customValue = '';
    if (isFootballBoots(customizeType)) {
      customValue = getFootballBoots();
    } else {
      if (!customName) {
        customName = '';
      }
      if (!customNumber) {
        customNumber = '';
      }
      if (customName.length > 0 && customNumber.length > 0) {
        customValue = idolValue;
      } else if (customName.length > 0) {
        customValue = nameValue;
      } else if (customNumber.length > 0) {
        customValue = numberValue;
      }
    }

    return customValue.replace(',', '.');
  };

  const generateCustomObject = () => {
    const customValue = getCustomValue();
    let customObject = {};

    if (isShirt(customizeType)) {
      customObject = {
        valorPersonalizacao: `${customName || ''} - ${customNumber || ''}`,
        tipoPersonalizacao: customizeType,
        precoPersonalizacao: Number(customValue),
      };
    } else if (isFootballBoots(customizeType)) {
      customObject = {
        tipoPersonalizacao: customizeType,
        valorPersonalizacao: `Esquerdo Externo: ${customNameLeftExternalFoot ||
          ''}-${customColorLeftExternalFoot || ''}-${customFlagLeftExternalFoot ||
          ''} | Esquerdo Interno: ${customNameLeftInternalFoot ||
          ''}-${customColorLeftInternalFoot || ''}-${customFlagLeftInternalFoot ||
          ''} | Direito Interno: ${customNameRightInternalFoot ||
          ''}-${customColorRightInternalFoot || ''}-${customFlagRightInternalFoot ||
          ''} | Direito Externo: ${customNameRightExternalFoot ||
          ''}-${customColorRightExternalFoot || ''}-${customFlagRightExternalFoot || ''}`,
        precoPersonalizacao: Number(customValue),
      };
    } else if (isPrePersonalized(customizeType) && productCode) {
      customObject = {
        tipoPersonalizacao: customizeType,
        codigoModeloVirtual: productCode,
      };
    }

    return customObject;
  };

  return {
    generateCustomObject,
  };
};

export default CustomProductService;
