import { configure, observable, action, runInAction, computed, toJS } from 'mobx';
import cartModule from '../modules/cartModule';
import { setCookie, getCookie } from '../utils/utils';
import singularity from '../utils/Singularity';

import { hasPendingSync } from '../modules/cartLocalStorage';

configure({ enforceActions: 'observed' });

class CartStore {
  loginStore;

  @observable miniCartData = { quantidadeTotalItens: null };

  @observable cartData = { quantidadeTotalItens: null };

  @observable errorPool = [];

  @observable warningText = '';

  @observable canContinue = true;

  @observable cartLoading = true;

  @observable skuSelected = '';

  @observable idSelected = '';

  @observable isMinicartModalOpen = false;

  @observable exchangeCouponError = null;

  @observable discountCouponError = null;

  @observable specialCouponError = null;

  @observable giftCardError = null;

  @observable exchangeCouponLoading = false;

  @observable discountCouponLoading = false;

  @observable specialCouponLoading = false;

  @observable giftCardLoading = false;

  @observable globalObjLoaded = false;

  @observable isCartPageOpen = false;

  @observable resumeBoxPositionStatus = 'relative';

  @observable isFreightValid = false;

  @observable hasCartApiProblem = false;

  @observable discountCouponValue = '';

  @observable specialCouponValue = '';

  @observable selectedDiscountCoupon = '';

  @observable selectedSpecialCoupon = '';

  @observable exchangeCouponValue = '';

  @observable giftCardValue = '';

  @observable codigoModeloVirtual = '';

  cartApiTries = 0;

  maxCartApiTries = 1;

  miniCartShouldWait = false;

  miniCartTimeout = null;

  // Obs.: mini cart é inicializado no componente MiniCart
  // miniCartDataInited = false;

  constructor(loginStore, appStore) {
    this.loginStore = loginStore;
    this.appStore = appStore;
  }

  @action setSkuSelected = (sku, codigoModeloVirtual) => {
    // console.log(`setSkuSelected -> sku: ${sku}`);

    this.skuSelected = sku;

    this.codigoModeloVirtual = codigoModeloVirtual;
  };

  @action setResumeBoxFixed = fixedStatus => {
    this.resumeBoxPositionStatus = fixedStatus;
  };

  @action setCartPageOpen = async isOpen => {
    // console.log('setCartPageOpen -> isOpen: ', isOpen);

    this.isCartPageOpen = isOpen;
    if (isOpen) {
      this.getCartData(false);
    }
    // TODO: isto está correto?
    else {
      this.setShouldWaitBool(false);
      // this.updateMiniCartDisplay(false);
    }
  };

  @action setGlobalObjLoaded = () => {
    this.globalObjLoaded = true;
  };

  @action unsetGlobalObjLoaded = () => {
    this.globalObjLoaded = false;
  };

  @action setCartDataFromAPI = data => {
    // console.log('[action] setCartDataFromAPI -> data: ', data);

    const hasCartMessages = data?.mensagensCarrinhoTodos?.length > 0;
    if (hasCartMessages) {
      this.warningText = data.mensagensCarrinhoTodos;
    }

    const isCartEmpty = !data || Object.keys(data).length <= 1;
    if (isCartEmpty) {
      const emptyCartData = { quantidadeTotalItens: 0 };

      if (this.isCartPageOpen) {
        this.cartData = emptyCartData;
      } else {
        this.miniCartData = emptyCartData;
      }

      this.cartLoading = false;

      return;
    }

    this.canContinue = data.mensagensCarrinhoStatusSucesso;

    if (this.isCartPageOpen) {
      this.cartData = data;
    } else {
      this.miniCartData = data;
    }

    this.cartLoading = false;
  };

  @action setFreightValid = async isValid => {
    // console.log(`setFreightValid -> isValid: ${isValid}`);
    this.updateMinicartDataDirty(true);
    this.isFreightValid = isValid;
    if (isValid) {
      await this.getCartData(false);
    }
    // if (!isValid) this.getCartData(false);
  };

  @action addErrorToPool = error => {
    // console.log('[action] addErrorToPool! error: ', error);

    // TODO: Esta action não faz sentido. Não dá pra ler o errorPool depois de acontecer um erro mais sério.
    //       Refatorar tudo o que tem a ver com essa action e com o errorPool...

    this.errorPool.push(error);
  };

  @action setGiftData = async (itemId, auxArray, index) => {
    this.updateMinicartDataDirty(true);
    this.cartLoading = true;
    // this.giftLoading = true; // TODO: resolver esses loaders
    const header = await this.clientHeader();
    const { error } = await cartModule.setGiftData(
      { itemId, MarcaPresente: auxArray[index], MensagemPresente: '' },
      header,
    ); // .then((response) => {

    if (error) {
      // TODO: originalmente aqui SÓ está processando erro informado pela API.
      //       NÃO está sendo processado p.e. endpoint indisponível.
      //       Obs.: agora foi criado error.axiosMessage para este fim, se preciso

      runInAction(() => {
        this.addErrorToPool(error);
        this.cartLoading = false;
        // this.giftLoading = false; // TODO: resolver esses loaders
      });

      return;
    }

    await this.getCartData(false);

    runInAction(() => {
      // this.giftLoading = false; // TODO: resolver esses loaders
      this.cartLoading = false;
    });
  };

  @action setExchangeCoupon = async code => {
    this.updateMinicartDataDirty(true);
    this.exchangeCouponLoading = true;
    const header = await this.clientHeader();
    const { error } = await cartModule.addExchangeCoupon(code, header);
    runInAction(() => (this.exchangeCouponLoading = false));

    if (error) {
      runInAction(
        () =>
          (this.exchangeCouponError = {
            message: error.data.message,
            tag: singularity(),
          }),
      );

      return;
    }

    runInAction(() => {
      this.exchangeCouponValue = code;
      this.exchangeCouponError = null;
      this.getCartData(false);
    });
  };

  @action removeExchangeCoupon = async valeTrocas => {
    this.updateMinicartDataDirty(true);
    this.exchangeCouponLoading = true;
    const header = await this.clientHeader();
    const { error } = await cartModule.removeExchangeCoupon(valeTrocas, header);
    runInAction(() => (this.exchangeCouponLoading = false));

    if (error) {
      runInAction(
        () =>
          (this.exchangeCouponError = {
            message: error.data.message,
            tag: singularity(),
          }),
      );

      return;
    }

    runInAction(() => {
      this.exchangeCouponValue = '';
      this.exchangeCouponError = null;
      this.getCartData(false);
    });
  };

  @action setExchangeValue = code => {
    this.exchangeCouponValue = code;
  };

  @action setDiscountCoupon = async code => {
    this.updateMinicartDataDirty(true);
    this.discountCouponLoading = true;
    const header = await this.clientHeader();
    const { error } = await cartModule.addDiscountCoupon(code, header);
    runInAction(() => (this.discountCouponLoading = false));

    if (error) {
      runInAction(
        () =>
          (this.discountCouponError = {
            message: error.data.message,
            tag: singularity(),
          }),
      );

      return;
    }

    if (window && window.dataLayer) {
      window.dataLayer.push({
        couponCode: code,
      });
    }

    runInAction(() => {
      this.discountCouponValue = code;
      this.selectedDiscountCoupon = code;
      this.discountCouponError = null;
      this.getCartData(false);
    });
  };

  @action setSpecialCoupon = async code => {
    this.updateMinicartDataDirty(true);
    this.specialCouponLoading = true;
    const header = await this.clientHeader();
    const { error } = await cartModule.addNestleCoupon(code, header);
    runInAction(() => (this.specialCouponLoading = false));

    if (error) {
      runInAction(
        () =>
          (this.specialCouponError = {
            message: error.data.message,
            tag: singularity(),
          }),
      );

      return;
    }

    runInAction(() => {
      this.specialCouponValue = code;
      this.selectedSpecialCoupon = code;
      this.specialCouponError = null;
      this.getCartData(false);
    });
  };

  @action removeDiscountCoupon = async code => {
    this.updateMinicartDataDirty(true);
    this.discountCouponLoading = true;
    const header = await this.clientHeader();
    const { error } = await cartModule.removeDiscountCoupon(code, header);
    runInAction(() => (this.discountCouponLoading = false));

    if (error) {
      runInAction(
        () =>
          (this.discountCouponError = {
            message: error.data.message,
            tag: singularity(),
          }),
      );
      return;
    }

    runInAction(() => {
      this.selectedDiscountCoupon = '';
      this.discountCouponValue = '';
      this.discountCouponError = null;
      this.getCartData(false);
    });
  };

  @action setDiscountValue = code => {
    this.discountCouponValue = code;
  };

  @action setSpecialValue = code => {
    this.specialCouponValue = code;
  };

  @action setGiftCard = async (code, pin) => {
    this.updateMinicartDataDirty(true);
    this.giftCardLoading = true;
    const header = await this.clientHeader();
    const { error } = await cartModule.addGiftCard(code, pin, header);
    runInAction(() => (this.giftCardLoading = false));

    if (error) {
      runInAction(
        () =>
          (this.giftCardError = {
            message: error.data.message,
            tag: singularity(),
          }),
      );
      return;
    }

    runInAction(() => {
      this.giftCardValue = code;
      this.giftCardError = null;
      this.getCartData(false);
    });
  };

  @action removeGiftCard = async () => {
    this.updateMinicartDataDirty(true);
    this.giftCardLoading = true;
    const header = await this.clientHeader();
    const { error } = await cartModule.removeGiftCard(header);
    runInAction(() => (this.giftCardLoading = false));

    if (error) {
      runInAction(
        () =>
          (this.giftCardError = {
            message: error.data.message,
            tag: singularity(),
          }),
      );
      return;
    }

    runInAction(() => {
      this.giftCardValue = '';
      this.giftCardError = null;
      this.getCartData(false);
    });
  };

  @action setGiftCardValue = code => {
    this.giftCardValue = code;
  };

  @action clearCouponsErrors = errorType => {
    switch (errorType) {
      case 'exchangeCoupon':
        this.exchangeCouponError = null;
        break;

      case 'discountCoupon':
        this.discountCouponError = null;
        break;

      case 'giftCard':
        this.giftCardError = null;
        break;

      case 'all':
        this.exchangeCouponError = null;
        this.discountCouponError = null;
        this.giftCardError = null;
        break;

      default:
        break;
    }
  };

  @action removeCustomCart = async itemId => {
    // console.log('[action] removeCustomCart! itemId: ', itemId);
    this.updateMinicartDataDirty(true);
    const header = await this.clientHeader();
    const { error } = await cartModule.removeCustomCart(itemId, header);

    if (error) {
      // TODO: Ver como tratar a error.data.message
      return;
    }

    runInAction(() => {
      this.getCartData(false);
    });
  };

  /**
   * TODO: eliminar callback
   */
  @action setCartData = async (
    sku,
    callback,
    customObject = {},
    sendToCart = false,
    isRedirect = false,
  ) => {
    this.cartLoading = true;
    this.updateMinicartDataDirty(true);
    this.appStore.HeaderStore.setGetMiniCartData(true);
    this.setFreightValid(false);
    this.skuSelected = sku;

    const { alreadyOnCart, quantidade } = this.getCartQuantityAndAdded;

    const { tvBody } = this.loginStore;

    const header = await this.clientHeader();

    // TODO: nunca vai entrar na condição
    if (alreadyOnCart && customObject === {}) {
      this.setCartDataUpdate(sku, { Quantidade: quantidade }, header);
      if (callback) {
        callback('alreadyOnCart');
      }
      return;
    }

    const qttToAdd = customObject === {} ? quantidade : 1;

    const { addCartData } = cartModule;
    const { data, error } = await addCartData(
      {
        Quantidade: qttToAdd,
        Sku: isRedirect ? customObject.Sku : this.skuSelected,
        ...tvBody,
        ...customObject,
      },
      header,
    );

    if (error) {
      // console.log('ERROR: ... ', error);

      // TODO: originalmente aqui SÓ está processando erro informado pela API.
      //       NÃO está sendo processado p.e. endpoint indisponível.
      //       Obs.: agora foi criado error.axiosMessage para este fim, se preciso

      runInAction(() => {
        if (error.status === 400) {
          this.warningText = error.data.message;
        }

        this.addErrorToPool(error);
        this.setCartDataFromAPI(this.isCartPageOpen ? this.cartData : this.miniCartData);
      });

      if (callback) {
        callback('error');
      }

      return;
    }

    const hasNewToken =
      Object.keys(data).length > 0 &&
      Object.prototype.hasOwnProperty.call(data, 'tokenClienteAnonimo');

    if (hasNewToken) {
      await this.setGuestUser(data.tokenClienteAnonimo, header);
    }

    this.mapAddToCart(data, hasNewToken, sendToCart);

    if (callback) {
      callback('ok');
    }
  };

  @computed get getCartQuantityAndAdded() {
    const arr = (this.cartData.itens || []).slice();
    const arrLen = arr.length;
    const itens = { quantidade: 1, alreadyOnCart: false };
    for (let i = 0; i < arrLen; i += 1) {
      if (arr[i].sku === this.skuSelected) {
        runInAction(() => (this.idSelected = arr[i].id)); // reaproveitando a variavel já criada. // TODO: isso é necessário mesmo?

        itens.quantidade = parseInt(arr[i].quantidade, 10) + 1;
        itens.alreadyOnCart = true;
        break;
      }
    }
    return itens;
  }

  @computed get skus() {
    const out = [];
    const arr = (this.cartData.itens || []).slice();
    const arrLen = arr.length;

    for (let i = 0; i < arrLen; i += 1) {
      out.push(arr[i].sku);
    }
    return out;
  }

  @computed get isSkuOnCart() {
    // console.log('isSkuOnCart!!!');

    // console.log('this.cartData: ', this.cartData);
    // console.log('this.miniCartData: ', this.miniCartData);

    const cartItems = this.cartData.itens;
    const miniCartItems = this.miniCartData.itens;

    if (!cartItems && !miniCartItems) {
      return false;
    }

    const items = (cartItems || miniCartItems).slice();

    return (
      items.filter(
        item =>
          item.sku === this.skuSelected && item.codigoModeloVirtual === this.codigoModeloVirtual,
      ).length > 0
    );
  }

  @action checkIfIsOnCart = sku => {
    if (!this.cartData) {
      return false;
    }

    const items = this.cartData.itens;
    if (!items) {
      return false;
    }
    for (let i = 0; i < items.length; i += 1) {
      if (items[i].sku === sku) {
        return true;
      }
    }
    return false;
  };

  @action updateMiniCartDisplay = shouldOpen => (this.isMinicartModalOpen = shouldOpen);

  setGuestUser = async tokenGuest => {
    // console.log('[method] setGuestUser -> tokenGuest: ', tokenGuest);

    // TODO: Tratar cookie inválido (chamar api novamento quando cookie estiver inválido?)
    // TODO: Guardar novo token para recuperar de maneira sincrona

    let currentHeader;

    const header = await this.clientHeader();
    if (tokenGuest && getCookie('centauro.customer') !== tokenGuest) {
      setCookie('centauro.customer', `codigo=${tokenGuest}&nome=&ocb=0`, 365);
      const token = { 'x-client-token': tokenGuest };
      currentHeader = Object.assign({}, header, token);
    } else {
      currentHeader = header;
    }

    return currentHeader;
  };

  @action setShouldWaitBool = bool => {
    clearTimeout(this.miniCartTimeout);
    this.miniCartShouldWait = bool;
  };

  @action updateCartActions = checkMinicart => {
    // console.log('[action] updateCartActions! checkMinicart: ', checkMinicart);

    this.cartLoading = true;
    clearTimeout(this.miniCartTimeout);

    runInAction(() => {
      if (this.miniCartShouldWait) {
        this.miniCartTimeout = setTimeout(() => {
          runInAction(() => {
            this.setShouldWaitBool(false);
            this.isMinicartModalOpen = false;
          });
        }, 4000);
      }

      this.isMinicartModalOpen = !checkMinicart;
    });
  };

  /**
   * Obs.: mini cart é inicializado no componente MiniCart
    @action initMiniCartData = () => {

      if (this.miniCartDataInited) return;

      this.getMiniCartData(false);
      this.miniCartDataInited = true;

    }
   */

  @observable isMinicartDataDirty = false;

  @action updateMinicartDataDirty = value => (this.isMinicartDataDirty = value);

  @action getMiniCartData = async (updatePending = true, forceReload = false) => {
    // console.log('[action] getMiniCartData! updatePending: ', updatePending);
    // console.log('[action] getMiniCartData! this.isCartPageOpen: ', this.isCartPageOpen);

    const inited = toJS(this.miniCartData).quantidadeTotalItens !== null;

    if (!forceReload && !this.isMinicartDataDirty && inited) {
      return;
    }

    if (updatePending) {
      this.cartLoading = true;
    }

    const header = await this.clientHeader();

    if (hasPendingSync()) {
      await cartModule.syncCart(header);
    }

    const { error, data } = await cartModule.getMinicartData(header);

    this.updateMinicartDataDirty(false);

    if (error) {
      runInAction(() => {
        this.addErrorToPool(error);
        if (updatePending) {
          this.cartLoading = false;
        }
      });

      return;
    }

    this.setCartDataFromAPI(data);
  };

  emptyFunc = () => null;

  @action getCartData = async (updatePending = true, callBack = this.emptyFunc) => {
    // console.log('[action] getCartData! updatePending: ', updatePending);
    // console.log('[action] getCartData! this.cartApiTries: ', this.cartApiTries);

    if (updatePending) {
      this.cartLoading = true;
    }
    const header = await this.clientHeader();

    if (hasPendingSync()) {
      await cartModule.syncCart(header);
    }

    const { error, data } = await cartModule.getCartData(header);

    if (error) {
      runInAction(() => {
        // console.log('[action] getCartData -> error: ', error);

        if (error.status === 401) {
          this.setCartDataFromAPI({ quantidadeTotalItens: null });
        } else {
          runInAction(() => {
            this.cartApiTries += 1;

            if (this.cartApiTries < this.maxCartApiTries) {
              this.getCartData();
            } else {
              this.hasCartApiProblem = true;
            }
          });
        }
        if (updatePending) {
          this.cartLoading = false;
        }
      });

      return;
    }

    setTimeout(() => {
      callBack();
    }, 0);

    runInAction(() => {
      this.setCartDataFromAPI(data);
    });
  };

  mapAddToCart = async (response, hasNewToken, sendToCart = false) => {
    // console.log('[method] mapAddToCart! response: ', response, ', hasNewToken: ', hasNewToken);

    if (hasNewToken) {
      if (!sendToCart) {
        this.getMiniCartData(false);
      }
    } else {
      this.warningText = '';
    }

    this.updateCartActions(false);
  };

  @action setCartDataUpdate = async (
    itemId,
    payload,
    checkMinicart = false,
    callBack = this.emptyFunc,
  ) => {
    const { tvBody } = this.loginStore;
    this.appStore.HeaderStore.setGetMiniCartData(true);
    const customPayload = {
      ...payload,
      ...tvBody,
    };

    // console.log('[action] setCartDataUpdate! itemId: ', itemId, ', checkMinicart: ', checkMinicart);

    this.updateMinicartDataDirty(true);

    this.cartLoading = true;
    const header = await this.clientHeader();
    const { data, status, error } = await cartModule.updateCartData(itemId, customPayload, header);

    this.setFreightValid(false);

    if (error) {
      // console.log('[action] setCartDataUpdate! error: ', error);

      runInAction(() => {
        if (error.status === 400) {
          this.warningText = error.data.message;
          this.updateCartActions(checkMinicart);
          this.cartLoading = false;
        } else {
          this.addErrorToPool(error);
          this.setCartDataFromAPI(this.isCartPageOpen ? this.cartData : this.miniCartData);
          this.cartLoading = false;
        }
      });

      return;
    }

    if (data.tokenClienteAnonimo) {
      this.setGuestUser(data.tokenClienteAnonimo, header);
    }

    runInAction(() => {
      if (status === 204) {
        this.warningText = '';

        if (this.isCartPageOpen) {
          this.getCartData(false, callBack);
        } else {
          this.getMiniCartData(false);
        }
      } else {
        this.warningText = '';
      }

      this.updateCartActions(checkMinicart);
    });
  };

  @action setCartLoading = isLoading => (this.cartLoading = isLoading);

  clientHeader = async () => {
    const header = toJS(this.loginStore.clientHeader);

    return header;
  };

  @action setCartWarning = warningText => (this.warningText = warningText);
}

export default CartStore;
