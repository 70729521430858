import {} from 'global';

import { getJson, postJson } from './httpClientCentauro';

import {
  apiDomain,
  newsletterApiPath,
  neemuDomain,
  searchDefaultScoringProfile,
} from '../settings';

import { isMobileFunc, getCookie, cartLocalStorage } from '../utils/utils';
import { getFreightInfo, getFreightInfoPdp } from './cartLocalStorage';

const chaordicBrowserId = getCookie('chaordic_browserId');

/**
 * Centauro APIs
 */

const getApiExchangeCoupon = xClientToken => {
  const urlExchangeCoupon = `${apiDomain}/v4.2/carrinhos/valetrocas/`;

  return getJson(urlExchangeCoupon, { 'x-client-token': xClientToken });
};

const getApiMenu = () => {
  const urlMenu = `${apiDomain}/v4.2/menus`;

  return getJson(urlMenu);
};

const getApiStores = (lat, lng, uf) => {
  const url = `${apiDomain}/v4.2/lojasfisicas/permiteretirar/porgeolocalizacao?latitude=${lat}&longitude=${lng}&uf=${uf}`;

  return getJson(url, {
    'x-cv-id': 14,
  });
};

const getApiDeliveryTypes = (sku, cep, codigoModeloVirtual) => {
  const cepFormated = cep.replace('-', '');
  const header = {
    'x-cv-id': isMobileFunc() ? 7 : 1,
  };

  if (!cartLocalStorage()) {
    const isCodigoModeloVirtual = codigoModeloVirtual ? codigoModeloVirtual : '';
    const url = `${apiDomain}/v4.2/tiposentrega?sku=${sku}&cep=${cepFormated}&trazerTodasLojas=true&codigoModeloVirtual=${isCodigoModeloVirtual}`;
    return getJson(url, header);
  }

  return getFreightInfoPdp(sku, cepFormated, header);
};

const getApiCartDeliveryTypes = (cep, clientHeader) => {
  if (!cartLocalStorage()) {
    const url = `${apiDomain}/v4.2/carrinhos/tiposentrega/${cep.replace('-', '')}`;

    return postJson(url, null, clientHeader);
  }

  return getFreightInfo(cep.replace('-', ''), clientHeader);
};

const postNewsLetter = objPostFields => {
  const url = `${apiDomain}${newsletterApiPath}`;

  const { name, email, gender } = objPostFields;

  return postJson(url, {
    Nome: name,
    Email: email,
    SexoId: gender,
    OrigemId: 1,
  });
};

const sendApiClick = (clickUrl, source, interactionType = '') => {
  const url = `${neemuDomain}${clickUrl}&source=${source}&deviceId=${chaordicBrowserId}&interactionType=${interactionType}`;

  getJson(url, null, false, false);
};

const getApiStoresWithStock = (lat, lng, uf, sku, query, quant) => {
  const url = `${apiDomain}/v4.2/lojasfisicas/permiteretirar/porGeolocalizacaoComEstoque?latitude=${lat}&longitude=${lng}&uf=${uf}&sku=${sku}&query=${query}&quantidade=${quant}`;

  return getJson(url, {
    'x-cv-id': 14,
  });
};

const sendCESAnswer = (body, { cvId, clientToken }) => {
  const url = `${apiDomain}/v4.2/AvaliacaoAtendimento`;

  postJson(url, body, {
    'x-cv-id': cvId,
    'x-client-token': clientToken,
  });
};

const getSearchScoringProfile = () => searchDefaultScoringProfile;

export {
  getApiExchangeCoupon,
  getApiDeliveryTypes,
  getApiCartDeliveryTypes,
  getApiMenu,
  postNewsLetter,
  getApiStores,
  sendApiClick,
  getApiStoresWithStock,
  sendCESAnswer,
  getSearchScoringProfile,
};
