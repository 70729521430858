import { sendEvent } from '@/utils/Analytics';

export const openPersonalization3PEvent = (skuSelected: string) => {
  sendEvent(
    'UI - PDP - Customizar Produto - Abrir',
    'Click - Abrir customização de camiseta Nike 3P',
    `Abrir - Customizar camiseta Nike 3P - ${skuSelected}`,
  );
};

export const closePersonalization3PEvent = (skuSelected: string) => {
  sendEvent(
    'UI - PDP - Customizar Produto - Abrir',
    'Click - Fechar Customização De Camiseta Nike 3P',
    `Fechar - Customizar camiseta Nike 3P - ${skuSelected}`,
  );
};

export const confirmPersonalization3PEvent = (skuSelected: string) => {
  sendEvent(
    'UI - PDP - Customizar Produto - Abrir',
    'Click - Confirmar Customização De Camiseta Nike 3P',
    `Confirmar - Customizar camiseta Nike 3P - ${skuSelected}`,
  );
};

export const buyPersonalization3PEvent = (
  skuSelected: string,
  customName: string,
  customNumber: string,
) => {
  sendEvent(
    'UI - PDP - Comprar com customização',
    'Ir para o carrinho com customização 3P',
    `Comprar: ${skuSelected}. Customização: ${customName} ${customNumber}`,
  );
};
