import { getJson, postJson } from './httpClientCentauro';
import { apiDomain, apiDomainTicket } from '../settings';

const cancellationGetURI = `${apiDomain}/v4.2/cancelamento`;
const cancellationPostURI = `${apiDomainTicket}/Cancelamento/gerarprotocolo`;

const getItemsForCancellation = ({ cvId, clientToken }) =>
  getJson(cancellationGetURI, {
    'x-cv-id': cvId,
    'x-client-token': clientToken,
  });

const createCancellationRequest = (body, { cvId, clientToken }) =>
  postJson(cancellationPostURI, body, {
    'x-cv-id': cvId,
    'x-client-token': clientToken,
  });

export { getItemsForCancellation, createCancellationRequest };
