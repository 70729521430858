import React from 'react';
import { FlagProvider, UnleashClient, IConfig } from '@unleash/proxy-client-react';

type Props = {
  toggleConfig: IConfig;
  children: React.ReactNode;
};

export const ToggleProvider = ({ toggleConfig, children }: Props) => {
  const config = toggleConfig;
  const client = new UnleashClient(config);

  return <FlagProvider unleashClient={client}>{children}</FlagProvider>;
};
