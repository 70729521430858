import { hasMultiplusCookie } from '../utils/utils';
import ProductPricesService from './productPricesService';

const BuyBoxService = ({
  prices,
  type,
  colorSelected,
  availableSizes = null,
  sizeSelected = '',
}) => {
  const availableColorPrices = prices.filter(
    obj => obj.codigoCor === colorSelected && obj.ordem === 1,
  );

  const getBuyBoxValuesWithSelectedSize = () => {
    const infosSizeSelected = availableSizes.filter(size => size.tamanho === sizeSelected);

    // TODO: Rever regra de negócio para produtos com mesmo tamanho mas sellers diferentes (SKUs são diferentes). Por hora pega o primeiro
    const buyBoxPrices =
      availableColorPrices?.length > 0
        ? availableColorPrices.find(cp => {
            const infoSizeSelected = infosSizeSelected.find(info => info.sku === cp.sku);
            return infoSizeSelected && cp.sku === infoSizeSelected.sku;
          })
        : {};

    const buyBoxInfos = buyBoxPrices.parcelamentos[buyBoxPrices.parcelamentos.length - 1];

    return {
      sellerName: buyBoxPrices.nomeSeller,
      price: buyBoxPrices.valor,
      oldPrice: buyBoxPrices.valorDe,
      discount: parseInt(buyBoxPrices.porcentagem, 10),
      conditionNum: buyBoxInfos.quantidade,
      conditionPrice: buyBoxInfos.valor,
      conditionText: buyBoxInfos.valorDosJuros,
      multiplus: (hasMultiplusCookie() && buyBoxPrices.multiplus) || '',
      buyBoxDiffPrices: false,
    };
  };

  const getBuyBoxValuesWithoutSelectedSize = () => {
    const differentValues = [];

    let minimunPriceObj = {};
    let minimunPrice = null;
    let hasDifferentPrices = false;

    for (let iP = 0; iP < availableColorPrices.length; iP += 1) {
      if (!minimunPrice) {
        minimunPriceObj = availableColorPrices[iP];
        minimunPrice = availableColorPrices[iP].valor;
        differentValues.push(availableColorPrices[iP].valor);
      } else if (availableColorPrices[iP].valor !== minimunPriceObj.valor) {
        let alreadyHavePrice = false;

        for (let iD = 0; iD < differentValues.length; iD += 1) {
          if (differentValues[iD] === availableColorPrices[iP].valor) {
            alreadyHavePrice = true;
          }
        }

        if (!alreadyHavePrice) {
          differentValues.push(availableColorPrices[iP].valor);
        }

        hasDifferentPrices = true;
        if (availableColorPrices[iP].valor < minimunPriceObj.valor) {
          minimunPriceObj = availableColorPrices[iP];
        }
      }
    }

    minimunPrice = minimunPriceObj.valor;

    const buyBoxDiffPrices =
      hasDifferentPrices && differentValues.length > 1 && sizeSelected === '';

    if (differentValues.length < 2) {
      const productPricesService = ProductPricesService({ colorSelected, prices });
      return {
        ...productPricesService.generateProductPricesObject(),
        diffPricesCount: differentValues.length,
        minimunPrice,
        buyBoxDiffPrices,
        hasDifferentPrices,
      };
    }

    const infoCondition = minimunPriceObj.parcelamentos[minimunPriceObj.parcelamentos.length - 1];
    const conditionNum = infoCondition.quantidade;
    const conditionPrice = infoCondition.valor;
    const conditionText = infoCondition.valorDosJuros;
    const discount = parseInt(minimunPriceObj.porcentagem, 10);

    return {
      conditionNum,
      conditionPrice,
      conditionText,
      discount,
      diffPricesCount: differentValues.length,
      minimunPrice,
      price: minimunPrice,
      oldPrice: minimunPriceObj.valorDe,
      buyBoxDiffPrices,
      hasDifferentPrices,
    };
  };

  const generateBuyBoxObject = () => {
    if (!type || type !== 'BUYBOX') {
      return {};
    }
    if (sizeSelected) {
      if (!availableSizes) {
        return {};
      }
      return getBuyBoxValuesWithSelectedSize();
    }
    return getBuyBoxValuesWithoutSelectedSize();
  };

  return {
    generateBuyBoxObject,
  };
};

export default BuyBoxService;
