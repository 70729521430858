import { action, observable, toJS } from 'mobx';
import { window } from 'global';

class GeolocationStore {
  @observable geolocationSwitchState =
    (window &&
      JSON.parse(sessionStorage.getItem('geolocationSwitchState')) &&
      !!JSON.parse(sessionStorage.getItem('geolocationSelectedStoresList'))) ||
    false;

  @observable geolocationModalState = false;

  @observable geolocationStoreList =
    window &&
    JSON.parse(sessionStorage.getItem('geolocationStoreList')) &&
    !!JSON.parse(sessionStorage.getItem('geolocationSelectedStoresList'))
      ? JSON.parse(sessionStorage.getItem('geolocationStoreList'))
      : null;

  @observable geolocationSelectedStoresList =
    (window && JSON.parse(sessionStorage.getItem('geolocationSelectedStoresList'))) || [];

  @observable userAddress = '';

  @observable geoLocationMobileOn = false;

  @action setGeoLocationMobileOn = value => (this.geoLocationMobileOn = value);

  @action setGeolocationSwitchState = state => {
    if (!window) {
      return;
    }

    this.geolocationSwitchState = state;
    sessionStorage.setItem('geolocationSwitchState', state);
  };

  @action setGeolocationModalState = state => (this.geolocationModalState = state);

  @action addGeolocationStoreList = stores => {
    this.geolocationStoreList = stores;

    if (!window) {
      return;
    }

    sessionStorage.setItem('geolocationStoreList', JSON.stringify(stores));
  };

  @action setUserAddress = address => (this.userAddress = address);

  @action addSelectedStore = (value, isAddingAll = false) => {
    if (!window) {
      return;
    }

    if (!isAddingAll) {
      this.geolocationSelectedStoresList = [
        ...toJS(this.geolocationSelectedStoresList),
        value,
      ].sort((a, b) => {
        if (a.distanciaKm > b.distanciaKm) {
          return 1;
        }
        if (a.distanciaKm < b.distanciaKm) {
          return -1;
        }
        return 0;
      });

      sessionStorage.setItem(
        'geolocationSelectedStoresList',
        JSON.stringify(this.geolocationSelectedStoresList),
      );
    } else {
      this.geolocationSelectedStoresList = value;
      sessionStorage.setItem('geolocationSelectedStoresList', JSON.stringify(value));
    }
  };

  @action removeSelectedStore = (value, isRemovingAll = false) => {
    if (!window) {
      return;
    }

    if (!isRemovingAll) {
      this.geolocationSelectedStoresList = this.geolocationSelectedStoresList.filter(
        store => store.id !== value.id,
      );

      if (this.geolocationSelectedStoresList.length === 0) {
        sessionStorage.removeItem('geolocationSelectedStoresList');
      } else {
        sessionStorage.setItem(
          'geolocationSelectedStoresList',
          JSON.stringify(this.geolocationSelectedStoresList),
        );
      }
    } else {
      this.geolocationSelectedStoresList = [];
      sessionStorage.removeItem('geolocationSelectedStoresList');
    }
  };
}

export default GeolocationStore;
