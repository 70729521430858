import { getJson } from './httpClientCentauro';
import {
  apiDomain,
  homePathNoCache,
  chaordicRecommendations,
  chaordicApiKey,
  chaordicSecretKey,
} from '../settings';
import history from '../history';

import { getCookie } from '../utils/utils';

const urlMobileHomeGet = `${apiDomain}/v3/homes/mobile`;
const urlMobileHomeGetNoCache = `${apiDomain}/v3/homes/simulacao/`;
// const urlMobileHomeGet = '../src/modules/mocks/newM.json';
const urlProductGet = `${apiDomain}/v4.2/produtos?codigoModelo=`;
const urlAsyncPrice = `${apiDomain}/v4/precos?codigosmodelosCores=`;
const chaordicBrowserId = getCookie('chaordic_browserId');

const getHomeData = () => {
  if (history.location.pathname === homePathNoCache) {
    const params = new URLSearchParams(history.location.search);

    return getJson(`${urlMobileHomeGetNoCache}${params.get('token')}?furabolo=56`);
  }

  return getJson(urlMobileHomeGet);
};

const getProductData = productCode => getJson(`${urlProductGet}${productCode}`);

const getAsyncPrice = productCodeList => getJson(`${urlAsyncPrice}${productCodeList}`);

const getChaordicShowcases = async () => {
  const url = `${chaordicRecommendations}?apiKey=${chaordicApiKey}&secretKey=${chaordicSecretKey}&name=home&source=desktop&deviceId=${chaordicBrowserId}`;

  return getJson(url, null, false, false);
};

export default {
  getHomeData,
  getProductData,
  getAsyncPrice,
  getChaordicShowcases,
};
