import { getThirdPartyJson } from './httpClientCentauro';
import { getCouponFromQueryStringOrCookie } from '../utils/utils';
import StoreRepository from '../hooks/sessionSeller/repository/StoreRepository';
import { authHeaderAsyncPrice } from '@/settings';

const getAsyncPrice = productCodeList => {
  const coupon = getCouponFromQueryStringOrCookie();

  const discountCookieCoupon = coupon ? `&codigoCupomDesconto=${coupon}` : '';
  const storeId = StoreRepository.getStore();

  let customHeaders = {
    Authorization: authHeaderAsyncPrice,
  };

  if (storeId) {
    customHeaders = { ...customHeaders, 'x-lj-id': storeId, 'x-cv-id': 28 }; // TODO: mover x-cv-id para um enum ref PR#408
  }

  return getThirdPartyJson({
    url: `https://produtoproxyaggregator.azurewebsites.net/v2.0/produtos?codigosmodeloscores=${productCodeList}${discountCookieCoupon}`,
    headers: customHeaders,
  });
};

export { getAsyncPrice }; // eslint-disable-line
