import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import ApplicationStore from './ApplicationStore';

const ContextStore = createContext();

const useStores = () => useContext(ContextStore);

const ContextStoreProvider = ({ children }) => (
  <ContextStore.Provider value={{ ...ApplicationStore }}>{children}</ContextStore.Provider>
);

ContextStoreProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export { ContextStoreProvider, useStores };
