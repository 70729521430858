import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';

const styles = StyleSheet.create({ strongText: { fontWeight: 500 } });

const defaultMessage = () => (
  <span>
    <span className={css(styles.strongText)}>Nos próximos dias</span>, um de nossos atletas entrará
    em contato com você
    <span className={css(styles.strongText)}> via e-mail </span>
    para te orientar sobre os próximos passos.
  </span>
);

const messageStore = () => (
  <span>
    <p>
      Agora você já pode{' '}
      <span className={css(styles.strongText)}>levar seu produto até a loja escolhida</span> para
      fazer sua troca/devolução. O produto solicitado está sujeito a disponibilidade.
    </p>
    <p style={{ marginTop: '18px' }}>
      <span className={css(styles.strongText)}>
        Lembre-se para trocar ou devolver é obrigatória a apresentação do documento com foto
      </span>{' '}
      (RG, CNH, carteira de trabalho ou passaporte).
    </p>
    <p>
      <span className={css(styles.strongText)}>Em caso de terceiros,</span> deve ser apresentado o
      documento com foto de quem for retirar e da pessoa que efetuou a compra do pedido.
    </p>
    <p>
      <span className={css(styles.strongText)}>Para pessoa jurídica,</span> é obrigatória a
      apresentação da cópia autenticada do estatuto social da empresa e o documento com foto do
      representante legal.
    </p>
  </span>
);

const messageExchangeSize = () => (
  <span>
    <span className={css(styles.strongText)}>Nos próximos dias</span>, um de nossos atletas
    <span className={css(styles.strongText)}> verificará a disponibilidade</span> do tamanho
    solicitado e entrará em contato com você
    <span className={css(styles.strongText)}> via e-mail </span>
    para te orientar sobre os próximos passos.
  </span>
);

const messageExchangeVoucher = () => (
  <span>
    Assim que recebermos seu produto,
    <span className={css(styles.strongText)}> seu vale-troca será enviado via e-mail. </span>
    Fique atento(a)!
  </span>
);

const messageSlip = () => (
  <span>
    Assim que recebermos seu produto,
    <span className={css(styles.strongText)}> a restituição será feita meio de DOC</span>, na conta
    corrente indicada
    <span className={css(styles.strongText)}> em até 7 dias úteis. </span>É necessário que o titular
    da conta seja o mesmo que efetuou o pedido.
  </span>
);

const messageCreditCard = () => (
  <span>
    Assim que recebermos seu produto,
    <span className={css(styles.strongText)}>
      {' '}
      a solicitação de estorno para a administradora do seu cartão de crédito será feita{' '}
    </span>
    e ocorrerá em até duas faturas.
    <span className={css(styles.strongText)}>
      {' '}
      O procedimento de estorno é de responsabilidade da administradora do cartão utilizado e não da
      Centauro.
    </span>
  </span>
);

export {
  messageStore,
  messageExchangeSize,
  messageExchangeVoucher,
  messageSlip,
  messageCreditCard,
  defaultMessage,
};
