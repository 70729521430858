import React from 'react';
import loadingImage from '../../../assets/images/load.gif';

const LoadingGif = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div {...props}>
      <img src={loadingImage} width={30} alt="Carregando" loading="lazy" />
    </div>
  );
};

export default LoadingGif;
