import { observable, action } from 'mobx';
import SellerRepository from '../hooks/sessionSeller/repository/SellerRepository';
import StoreRepository from '../hooks/sessionSeller/repository/StoreRepository';
class SessionSellerStore {
  constructor() {
    this.seller = SellerRepository.getActiveSeller();
    this.storeId = StoreRepository.getStore();
  }

  @observable seller = undefined;
  @action setSeller = seller => {
    this.seller = seller;
    SellerRepository.save(seller);
  };

  @observable storeId = undefined;
  @action setStoreId = storeId => {
    this.storeId = storeId;
    StoreRepository.save(storeId);
  };

  @observable customerIdentify = undefined;
  @action setCustomerIdentify = customerIdentify => {
    this.customerIdentify = customerIdentify;
  };
}

export default SessionSellerStore;
