import { getJson, postJson } from './httpClientCentauro';
import { apiDomain, apiDomainTicket } from '../settings';

const exchangesGetURI = `${apiDomain}/v4.2/trocas`;
const exchangesPostURI = `${apiDomainTicket}/TrocaDevolucao/gerarprotocolo`;

const getExchangesData = ({ cvId, clientToken }) =>
  getJson(exchangesGetURI, {
    'x-cv-id': cvId,
    'x-client-token': clientToken,
  });

const createDataTicketCRM = (body, { cvId, clientToken }) =>
  postJson(exchangesPostURI, body, {
    'x-cv-id': cvId,
    'x-client-token': clientToken,
  });

export { getExchangesData, createDataTicketCRM };
