import { getJson } from './httpClientCentauro';
import { removeDiacriticsAndAccents } from '../utils/utils';

import { searchSbfAuth, searchSbfBaseUrl } from '../settings';
import { sendEvent } from '../utils/Analytics';

const prepareFilter = filters => {
  return filters.length > 0
    ? `&fields=${filters
        .map(filter => removeDiacriticsAndAccents(filter.replace('d:', '')))
        .join('&fields=')}`
    : '';
};

export const getSearchData = async payload => {
  const {
    term = '',
    cnt, // quantidade de resultados desejados
    page,
    filters = [],
    isort,
    scoringProfile,
  } = payload;

  sendEvent('Search', 'Acessou Search', `search: ${term}`, 1, true);
  sendEvent('Search', 'Search Scoring Profile', `scoringProfile: ${scoringProfile}`, 1, true);

  const filtersParam = prepareFilter(filters);
  const sorting = `&sorting=${isort}`;
  const _scoringProfile = `&scoringProfile=${scoringProfile}`;

  const url = `${searchSbfBaseUrl}/search?term=${term}${filtersParam}&resultsPerPage=${cnt}&page=${page}${sorting}${_scoringProfile}&restrictSearch=true&multiFilters=true`;
  const result = await getJson(url, null, false, false, searchSbfAuth);

  if (result.error) {
    result.error.termParam = 'mock';
    result.error.origin = 'search centauro';
  }

  return result;
};

export const getHotsiteData = async payload => {
  const { hotsite, cnt, page = 1, filters = [], isort, scoringProfile } = payload;

  sendEvent('Hotsite', 'Acessou Hotsite', `hotsite: ${hotsite}`, 1, true);
  sendEvent('Hotsite', 'Hotsite Scoring Profile', `scoringProfile: ${scoringProfile}`, 1, true);

  const filtersParam = prepareFilter(filters);
  const _hotsite = `?suffix=${hotsite}`;
  const sorting = `&sorting=${isort}`;
  const _scoringProfile = `&scoringProfile=${scoringProfile}`;

  const url = `${searchSbfBaseUrl}/hotsites${_hotsite}${filtersParam}&resultsPerPage=${cnt}&page=${page}${sorting}${_scoringProfile}&multiFilters=true`;

  const result = await getJson(url, null, false, false, searchSbfAuth);

  if (result.error) {
    result.error.termParam = 'mock';
    result.error.origin = 'hotsite centauro';
  }

  return result;
};

export const getNavData = async payload => {
  const {
    term = '',
    termPairs, // lista de pares de tipoDeTermo/termo (ex: [{type: 'categoria', value: 'jogosdemesa'}] ). Se usado, term passa a ser ignorado
    page, // nome da página. Se usado, o paramentro cnt passa a ser obrigatório e o srt passa a ser ignorado
    cnt, // quantidade de resultados desejados
    isort,
    scoringProfile,
  } = payload;

  const terms = termPairs?.filter(
    someTerm => someTerm.type !== '12' && someTerm.type && someTerm.value,
  );

  const termParam = terms
    ? terms.map(({ type, value }) => `fields=${type}:${value}`).join('&')
    : term;

  sendEvent('Nav', 'Acessou Nav', `nav: ${termParam}`, 1, true);
  sendEvent('Nav', 'Nav Scoring Profile', `scoringProfile: ${scoringProfile}`, 1, true);

  const srtParam = `&page=${page}`;
  const cntParam = cnt ? `&resultsPerPage=${cnt}` : '';
  const isortParam = isort ? `&sorting=${isort}` : '';
  const _scoringProfile = `&scoringProfile=${scoringProfile}`;

  const url = `${searchSbfBaseUrl}/navigation?${termParam}${cntParam}${srtParam}${isortParam}${_scoringProfile}`;

  const result = await getJson(url, null, false, false, searchSbfAuth);

  if (result.error) {
    result.error.termParam = 'mock';
    result.error.origin = 'nav centauro';
  }

  return result;
};
