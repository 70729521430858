import { window } from 'global';

import { getCurrentISOTime, formatPriceNumbers, getCookie } from '../../../utils/utils';

const GlobalObjects = objGlobalInfo => {
  if (!window) {
    return;
  }

  delete window.chaordic_meta;
  delete window.siteMetadata;
  delete window.metadados_recsys;

  // sets e definições comuns

  let metaProd = {};
  let metaInfo = {};
  let metaDisp = {};
  let metaImg = [];
  let metaPrice = [];
  let metaColor = [];
  let tagsBase = {};
  let tagValues = [];
  let urlParams = '';
  let urlProduct = '';

  const colorValues = [];
  const sizeValues = [];
  const skuValues = [];

  let idSize = 0;
  let siteOldPrice = 0;
  let statusValue = 'available';

  const userInfo = JSON.parse(getCookie('siteMetadata.sessaoInfo.usuarioInfo'));
  const userLogged = Boolean(userInfo);

  /* Preparação do corpo dos objetos (diferente página à página) */

  let chaordicPageExtends = {};
  let chaordicSection = {};

  // smd = SiteMetaData
  let smdPageInfoSection = {};
  let smdPageInfoExtends = {};

  switch (objGlobalInfo.page) {
    case 'home':
      smdPageInfoExtends = {
        nome: `Produto: ${metaDisp.nome}`,
        etapa: 'DetalheProduto',
        tipoModelo: 'IndexModel',
      };
      smdPageInfoSection = {
        etapa: 'Vitrine',
        nome: 'Home',
        tipoModelo: 'n/a',
      };
      chaordicPageExtends = {};
      chaordicSection = {};
      break;

    case 'exchange':
      smdPageInfoExtends = {
        nome: 'Trocas e devoluções',
        etapa: 'TrocasEDevoluções',
      };
      smdPageInfoSection = {};
      chaordicPageExtends = {};
      chaordicSection = {};
      break;

    case 'login':
      smdPageInfoExtends = {
        nome: 'Efetue seu login',
        etapa: 'Cliente',
      };
      smdPageInfoSection = {};
      chaordicPageExtends = {};
      chaordicSection = {};
      break;

    case 'register':
      smdPageInfoExtends = {
        nome: 'Efetue seu cadastro',
        etapa: 'Cadastro',
      };
      smdPageInfoSection = {};
      chaordicPageExtends = {};
      chaordicSection = {};
      break;

    case 'password-recovery':
      smdPageInfoExtends = {
        nome: 'Perdeu a senha',
        etapa: 'Cliente',
      };
      smdPageInfoSection = {};
      chaordicPageExtends = {};
      chaordicSection = {};
      break;

    case 'product':
      metaProd = objGlobalInfo?.objMetaProducts;
      metaInfo = metaProd?.informacoes;
      metaDisp = metaProd?.disponibilidade;
      metaImg = metaProd?.imagens;
      metaPrice = metaProd?.precos;
      metaColor = metaDisp?.cores;
      tagsBase = metaInfo || {};

      tagValues = [
        {
          id: tagsBase.grupo,
          name: tagsBase.grupo,
        },
        {
          id: tagsBase.subGrupo,
          name: tagsBase.subGrupo,
        },
        {
          id: tagsBase.marca,
          name: tagsBase.marca,
        },
        {
          id: tagsBase.categoria,
          name: tagsBase.categoria,
        },
        {
          id: tagsBase.faixaEtaria,
          name: tagsBase.faixaEtaria,
        },
        {
          id: tagsBase.genero,
          name: tagsBase.genero,
        },
      ];

      if (metaDisp.produtoDisponivel === true) {
        statusValue = 'available';
      } else {
        statusValue = 'unavailable';
      }

      if (formatPriceNumbers(metaProd.oldPrice) === 0) {
        siteOldPrice = '';
      } else {
        siteOldPrice = String(formatPriceNumbers(metaProd.oldPrice));
      }

      for (let i = 0; i < metaImg.length; i += 1) {
        colorValues.push({
          id: metaImg[i].codigoCor,

          label: metaInfo.skus && ((metaInfo.skus[i] || {}).cor || {}).descricao,
          url: `https://www.centauro.com.br${tagsBase.url}?cor=${metaImg[i].codigoCor}`,
          images: {
            default: `/180x180/${metaImg[i].codigoModeloCor}.jpg`,
            large: `/900x900/${metaImg[i].codigoModeloCor}.jpg`,
          },
        });
      }

      for (let i = 0; i < metaColor.length; i += 1) {
        for (let j = 0; j < metaColor[i].tamanhosDisponiveis.length; j += 1) {
          idSize = metaColor[i].tamanhosDisponiveis[j].sku.substr(
            metaColor[i].tamanhosDisponiveis[j].sku.length - 3,
          );

          sizeValues.push({
            id: idSize,
            label: metaColor[i].tamanhosDisponiveis[j].tamanho,
          });
        }
      }

      for (let i = 0; i < metaPrice.length; i += 1) {
        let objExtrainfo = {};

        if (metaColor.length > 0 && metaColor[i]) {
          objExtrainfo = {
            new: metaColor[i].novo,
            personalizacao: metaColor[i].customizavel,
            personalizacaoGratis: metaColor[i].valorPersonalizacao,
          };
        }

        skuValues.push({
          sku: metaPrice[i].sku,
          specs: {
            tamanho: metaPrice[i].sku.slice(8, -1),
            cor: metaPrice[i].sku.slice(6, -4),
          },
          status: statusValue,
          oldPrice: formatPriceNumbers(metaPrice[i].valorDe),
          price: formatPriceNumbers(metaPrice[i].valor),
          installment: {
            count: metaPrice[i].numeroDeParcelas,
            price: formatPriceNumbers(metaPrice[i].quantidadePorParcela),
          },
          extraInfo: {
            ...objExtrainfo,
          },
          images: {
            default: `/180x180/${metaPrice[i].sku.slice(0, 8)}.jpg`,
          },
        });
      }

      smdPageInfoExtends = {
        nome: `Produto: ${metaDisp.nome}`,
        etapa: 'DetalheProduto',
        tipoModelo: 'IndexModel',
      };
      smdPageInfoSection = {
        produtoInfo: {
          id: metaDisp.codigoModelo,
          nome: metaDisp.nome,
          categoria: metaInfo.categoria,
          grupo: metaInfo.grupo,
          subGrupo: metaInfo.subGrupo,
          linha: metaInfo.linha,
          precoOriginalUSA: siteOldPrice,
          precoOriginalBRA: String(metaProd.oldPrice),
          precoUSA: String(formatPriceNumbers(metaProd.price)),
          precoBRA: String(metaProd.price),
          modeloCor: metaDisp.skuSelecionado,
        },
      };
      chaordicPageExtends = {
        tags: tagValues,
        selectedSpecs: {
          cor: metaProd.colorSelected,
        },
        selected_specs: {
          cor: metaProd.colorSelected,
        },
      };
      urlParams = new URLSearchParams(window.location.search);
      urlProduct = urlParams.has('cor')
        ? `${window.location.host}${window.location.pathname}?cor=${urlParams.get('cor')}`
        : `${window.location.host}${window.location.pathname}`;
      chaordicSection = {
        product: {
          id: metaDisp.codigoModelo || '',
          name: metaDisp.nome,
          description: metaInfo.descricaoSEO,
          url: urlProduct,
          images: {
            default: `/180x180/${metaDisp.codigoModelo + metaProd.colorSelected}.jpg`,
            defaultWebp: `/180x180/${metaDisp.codigoModelo + metaProd.colorSelected}.webp`,
            large: `/900x900/${metaDisp.codigoModelo + metaProd.colorSelected}.jpg`,
          },
          status: statusValue,
          oldPrice: formatPriceNumbers(metaProd.oldPrice),
          price: formatPriceNumbers(metaProd.price),
          installment: {
            count: metaProd.numConditionNum,
            price: formatPriceNumbers(metaProd.numConditionPrice),
          },
          tags: tagValues,
          details: {
            marca: tagsBase.marca,
            genero: tagsBase.genero,
          },
          specs: {
            cor: colorValues,
            tamanho: sizeValues,
          },
          skus: skuValues,
          categories: [
            {
              id: metaInfo.categoria,
              name: metaInfo.categoria,
            },
            {
              id: metaInfo.grupo,
              name: metaInfo.grupo,
              parents: [metaInfo.categoria],
            },
            {
              id: metaInfo.subGrupo,
              name: metaInfo.subGrupo,
              parents: [metaInfo.grupo],
            },
          ],
        },
      };
      break;

    case 'cart':
      smdPageInfoExtends = {};
      smdPageInfoSection = {
        etapa: 'Carrinho',
        nome: objGlobalInfo.name,
        tipoModelo: objGlobalInfo.type,
        carrinhoInfo: objGlobalInfo.objMetaCart.smdCartInfo,
      };
      chaordicPageExtends = {};
      chaordicSection = {
        cart: {
          items: objGlobalInfo.objMetaCart.aCartItemsChaordic,
        },
      };
      break;

    case 'category':
      smdPageInfoExtends = {
        ...objGlobalInfo.categories,
        etapa: 'Navegação',
        nome: 'Neemu',
        tipoModelo: 'n/a',
        buscaInfo: objGlobalInfo.buscaInfo,
      };
      smdPageInfoSection = {};
      chaordicPageExtends = {
        info: {
          usuarioDOO: '4d367e08facd0b17.1532019755.87.1536802397.1536780699.',
          searchPgType: 'nav_filter',
        },
        searchId: objGlobalInfo.searchId,
        tags: objGlobalInfo ? (objGlobalInfo.categories ? objGlobalInfo.categories : []) : [],
      };
      chaordicSection = {
        host: 'www.centauro.com.br',
      };
      break;

    case 'search':
      smdPageInfoExtends = {
        buscaInfo: {
          ModelosIds: ((objGlobalInfo.objMetaSearch || {}).aObjPageInfo || []).map(
            item => item.items.id,
          ),
          paginaAtual: objGlobalInfo.pageCurrent,
          resultados: objGlobalInfo.results,
          termoBusca: objGlobalInfo.query,
        },
        etapa: 'Busca',
        nome: `Pesquisar resultados para ${objGlobalInfo.query}`,
        tipoModelo: 'LNC',
      };
      smdPageInfoSection = {};
      chaordicPageExtends = {
        searchId: objGlobalInfo.searchId,
      };
      chaordicSection = {
        host: 'www.centauro.com.br', // TODO: confirmar o dominio
        search: {
          items: ((objGlobalInfo.objMetaSearch || {}).aObjPageInfo || []).map(item => ({
            id: item.items.id,
            price: item.items.price,
          })),
          query: objGlobalInfo.query,
        },
      };
      break;

    case 'emptysearch':
      smdPageInfoExtends = {
        etapa: 'Navegação',
        nome: 'Neemu',
        tipoModelo: 'n/a',
      };
      smdPageInfoSection = {};
      chaordicPageExtends = {
        searchId: objGlobalInfo.searchId,
      };
      chaordicSection = {
        host: 'www.centauro.com.br',
        search: {
          items: [],
          query: objGlobalInfo.query,
        },
      };
      break;

    case 'checkout-shipping':
      smdPageInfoExtends = objGlobalInfo.shippingInfo;
      smdPageInfoSection = {};
      chaordicPageExtends = {};
      chaordicSection = objGlobalInfo.inh;
      break;

    case 'checkout-payment':
      smdPageInfoExtends = objGlobalInfo.paymentInfo;
      smdPageInfoSection = {};
      chaordicPageExtends = {};
      chaordicSection = objGlobalInfo.inh;
      break;

    case 'confirmation':
      smdPageInfoExtends = {};
      smdPageInfoSection = objGlobalInfo.paginaInfo;
      chaordicPageExtends = {};
      chaordicSection = {
        transaction: objGlobalInfo.transaction,
      };
      break;

    case 'staticPage':
      smdPageInfoExtends = {
        nome: 'Página estática',
        etapa: 'PaginaEstatica',
      };
      smdPageInfoSection = {};
      chaordicPageExtends = {};
      chaordicSection = {};
      break;

    case 'cancellation':
      smdPageInfoExtends = {
        nome: 'Cancelamento de pedido',
        etapa: 'CancelamentoDePedido',
      };
      smdPageInfoSection = {};
      chaordicPageExtends = {};
      chaordicSection = {};
      break;

    case 'exchangeVoucher':
      smdPageInfoExtends = {
        nome: 'Vale-troca',
        etapa: 'ValeTroca',
      };
      smdPageInfoSection = {};
      chaordicPageExtends = {};
      chaordicSection = {};
      break;

    case 'exchangeVoucherCancellation':
      smdPageInfoExtends = {
        nome: 'Cancelamento de vale-troca',
        etapa: 'CancelamentoDeValeTroca',
      };
      smdPageInfoSection = {};
      chaordicPageExtends = {};
      chaordicSection = {};
      break;

    case 'emailServices':
      smdPageInfoExtends = {
        nome: 'Serviços',
        etapa: 'Servicos',
      };
      smdPageInfoSection = {};
      chaordicPageExtends = {};
      chaordicSection = {};
      break;

    case 'customerPortal':
      smdPageInfoExtends = {
        nome: 'Portal do Cliente',
        etapa: 'PortalDoCliente',
      };
      smdPageInfoSection = {};
      chaordicPageExtends = {};
      chaordicSection = {};
      break;

    case 'myOrders':
      smdPageInfoExtends = {
        nome: 'Meus Pedidos',
        etapa: 'MeusPedidos',
      };
      smdPageInfoSection = {};
      chaordicPageExtends = {};
      chaordicSection = {};
      break;

    default:
      smdPageInfoExtends = {};
      smdPageInfoSection = {};
      chaordicPageExtends = {};
      chaordicSection = {};
  }

  /* #### INÍCIO DOS OBJETOS GLOBAIS */
  /* #### INÍCIO DOS OBJETOS GLOBAIS */
  /* #### INÍCIO DOS OBJETOS GLOBAIS */

  const sexCharacter = userInfo ? userInfo.sexoUnicoCaractere : '';

  const recsysUserInfo =
    userInfo && userInfo.nome
      ? {
          name: userInfo.nome,
          language: 'pt-BR',
          country: 'BR',
          gender: sexCharacter.toUpperCase(),
          allow_mail_marketing: userInfo.optIn,
        }
      : {};

  // chaordic_meta
  window.chaordic_meta = {
    host: 'www.centauro.com.br',
    page: {
      info: {
        usuarioDOO: '4d367e08facd0b17.1532019755.87.1536802397.1536780699.',
        // referrer: `https://www.centauro.com.br${tagsBase.url}?cor=${metaProd.colorSelected}`, // TODO: makes sense?
      },
      name: objGlobalInfo.page,
      timestamp: getCurrentISOTime(),
      url: window.location.href,
      ...chaordicPageExtends,
    },
    ...chaordicSection,
  };

  if (
    (userInfo && Object.keys(userInfo).length > 0) ||
    (recsysUserInfo && Object.keys(recsysUserInfo).length > 0)
  ) {
    window.chaordic_meta.user = {
      ...userInfo,
      ...recsysUserInfo,
    };
  }

  // site metadata
  window.siteMetadata = {
    siteInfo: {
      nome: 'centauro.com.br',
      ambiente: 'producao',
    },
    sessaoInfo: {
      usuarioInfo: {
        ...userInfo,
        ...recsysUserInfo,
      },
      usuarioLogado: userLogged,
    },
    paginaInfo: {
      ...smdPageInfoExtends,
      ...smdPageInfoSection,
    },
  };

  // recsys
  window.metadados_recsys = window.chaordic_meta;

  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: 'changeRoute',
    });
  }

  console.log('REC-GL01');
};

export default GlobalObjects;
