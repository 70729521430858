import { cpf, cnpj } from 'cpf-cnpj-validator';

import { clearCPFMask as clearMask } from './utils';

const validate = (value, validationType, compareValue) => validationType(value, compareValue);

const checkIfIsEmpty = value => {
  return !(value === undefined || value === 'undefined' || value === null || value === 'null');
};

const checkName = value => {
  return !(
    value === undefined ||
    value === 'undefined' ||
    value === null ||
    value === 'null' ||
    value.length < 2
  );
};

const checkIfIsDifferentFrom = (value, compareValue) => {
  return !(
    value === compareValue ||
    value === undefined ||
    value.length === 0 ||
    value === ' ' ||
    value === undefined ||
    value === 'undefined' ||
    value === null ||
    value === 'null'
  );
};

const checkEmail = value => {
  const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return re.test(value.trim());
};

const checkCPF = value => cpf.isValid(clearMask(value));

const checkCNPJ = value => cnpj.isValid(clearMask(value));

const checkCEP = value => clearMask(value).length === 8;

const defaultError = validationType => {
  const defaultMsgArray = {
    checkIfIsEmpty: 'Este campo é obrigatório. Por favor preencher.',
    checkIfIsDifferentFrom: '',
    checkEmail: 'E-mail com formato inválido.',
    checkCPF: 'CPF inválido. Por favor, verifique o número e tente novamente.',
  };

  if (Object.prototype.hasOwnProperty.call(defaultMsgArray, validationType.name)) {
    return defaultMsgArray[validationType.name];
  }

  return 'Este campo foi preenchido incorretamente. Por favor preencher novamente usando dados válidos.';
};

export {
  validate,
  checkIfIsEmpty,
  checkIfIsDifferentFrom,
  checkEmail,
  checkCPF,
  defaultError,
  checkCNPJ,
  checkCEP,
  checkName,
};
