import { action, computed, toJS, observable } from 'mobx';
import { createTransformer } from 'mobx-utils';
import dayjs from 'dayjs';
import { isEmpty } from 'ramda';
import { datadogLogs } from '@datadog/browser-logs';

import { getClientData } from '../modules/clientModule';
import { getPaymentData, createPaymentData } from '../modules/paymentModule';
import { getOrderById } from '../modules/orderModule';
import cartModule from '../modules/cartModule';
import { putPdvAttributesAsync } from '../modules/pdvPaymentModule';
import { createAddress, updateAddress, getAddressesList } from '../modules/addressesModule';
import { getDeliveriesList, setDeliveries, getDeliveriesLocation } from '../modules/deliveryModule';
import StoreRepository from '../hooks/sessionSeller/repository/StoreRepository';
import { cloneObject, objectIsEmpty, currencyToNumber } from '../utils/utils';
import {
  sendCheckoutFunnelEventToDataLayer,
  getSellerInformation,
} from '@/utils/trackingEvents.js';
import { setABTestFromBackEnd } from '../utils/abtest';

import history from '../history';
import { thanksPagePath, cartPath } from '../settings';
import { sendEvent } from '../utils/Analytics';
import CartStore from './CartStore';

import {
  STREET_MAX_LENGTH,
  CITY_MAX_LENGTH,
  NEIGHBORHOOD_MAX_LENGTH,
  UF_MAX_LENGTH,
} from '@/components/register/validations';

const NEW_CREDIT_CARD_ERROR_MESSAGES = {
  expiry: 'Por favor digite a data de validade do cartão',
  name: 'Por favor digite o nome do titular',
  number: 'Por favor digite o número do cartão',
  cvc: 'Por favor digite o código de segurança do cartão',
};

const CREDIT_CARD_ERROR_MESSAGES = {
  cvc: 'Por favor digite o código de segurança do cartão',
  installments: 'Por favor informe a quantidade de parcelas',
};

const PAYMENT_LINK_ERROR_MESSAGES = {
  installments: 'Por favor informe a quantidade de parcelas',
};

const STEP_STATUS_TYPE = {
  dirty: 'dirty',
  success: 'success',
  pending: 'pending',
};

const STEP_NAMES = {
  identification: 'identification',
  shipping: 'shipping',
  payment: 'payment',
};

const INITIAL_ADDRESS_DATA = {
  Padrao: false,
  Nome: '',
  Logradouro: '',
  Numero: '',
  Complemento: '',
  Bairro: '',
  Referencia: '',
  Cep: '',
  Cidade: '',
  UF: '',
  Pais: 'Brasil',
  NomeDeQuemIraReceber: '',
  SobrenomeDeQuemIraReceber: '',
  Telefone: {
    DDD: '',
    Numero: '',
  },
  Celular: {
    DDD: '',
    Numero: '',
  },
};

const PAYMENT_METHODS = {
  creditCard: 'creditCard',
  caixaVirtualCard: 'caixaVirtualCard',
  newCreditCard: 'newCreditCard',
  bankSlip: 'bankSlip',
  paymentLink: 'paymentLink',
  paymentInStore: 'paymentInStore',
  pix: 'pix',
};

const ISSUERS_DICTIONARY = {
  amex: 'american-express',
  'american express': 'american-express',
  hipercard: 'hipercard',
  diners: 'diners',
  dinersclub: 'diners',
  mastercard: 'mastercard',
  elo: 'elo',
  visa: 'visa',
};

const ISSUERS_DICTIONARY_DEBIT = {
  elo: 'elo',
};

const ELO_DEBIT_ACCEPTED_BINS = ['5067228', '5067229', '509023', '509105', '509030'];

const ACCEPTED_CARDS = Object.keys(ISSUERS_DICTIONARY);

class CheckoutStore {
  constructor({ loginStore, sessionSellerStore, errorHandlerStore, cartStore }) {
    this.loginStore = loginStore;
    this.errorHandlerStore = errorHandlerStore;
    this.cartStore = cartStore;
    this.sessionSellerStore = sessionSellerStore;
  }

  @observable receivedAllInitialData = true;

  @observable userLocation = {
    lat: 0,
    lng: 0,
  };

  @observable currentShippingStep = 1;

  @observable scheduledDelivery = {
    selectedPeriod: '',
    deliveryDay: '',
    dayOfTheWeek: 0,
  };

  @observable isLoading = false;

  @observable stepStatus = {
    [STEP_NAMES.identification]: STEP_STATUS_TYPE.success,
    [STEP_NAMES.shipping]: STEP_STATUS_TYPE.dirty,
    [STEP_NAMES.payment]: STEP_STATUS_TYPE.pending,
  };

  // identification, shipping, payment
  @observable openStep = STEP_NAMES.shipping;

  @observable newAddressData = { ...INITIAL_ADDRESS_DATA, ...{} };

  @observable initialZipcodeData = {};

  identificationDataBackup = {};

  @observable identificationData = {
    id: null,
    codigo: '',
    tipoCliente: '',
    email: '',
    endereco: {
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      referencia: null,
      cep: '',
      cidade: '',
      estado: '',
      pais: '',
      telefoneFixo: {
        ddd: '',
        numero: '',
      },
      telefoneAdicional: {
        ddd: '',
        numero: '',
      },
      nomeDeQuemIraReceber: '',
      sobrenomeDeQuemIraReceber: '',
    },
    criadoEm: '',
    atualizadoEm: '',
    possuiCompraRapidaHabilitada: false,
    timeCoracaoID: null,
    listaEsportesFavoritosDisponiveis: [],
    listaEsportesFavoritosSelecionados: [],
    listaTimesDisponiveis: [],
    nome: '',
    sobrenome: '',
    sexo: '',
    cpf: '',
    rg: '',
    dataDeNascimento: '',
    mensagemErroValidacaoCadastro: [],
    cnpj: null,
    inscricaoEstadual: null,
    razaoSocial: null,
    nomeFantasia: null,
    cargo: null,
    setor: null,
    ramo: null,
    newsletter: false,
  };

  @observable clientAddresses = [];

  @observable clientDeliveries = {};

  @observable clientPayment = {};

  @observable temporaryNewPaymentDataCreditCard = {
    name: '',
    number: '',
    expiry: '',
    cvc: '',
    issuer: '',
  };

  @observable newPaymentDataCreditCard = {
    name: '',
    number: '',
    expiry: '',
    cvc: '',
    issuer: '',
  };

  @observable newPaymentDataCaixaDebitCard = {
    name: '',
    number: '',
    expiry: '',
    cvc: '',
    issuer: 'elo',
    isBinValid: '',
  };

  @action newPaymentDataCreditCardNumber = () => this.newPaymentDataCreditCard.number;

  // { creditCardId: { installments: x, cvc: yyy } }
  @observable creditCardsAlreadyAdded = {};

  @observable selectedAddress = null;

  @observable currentSelectedAddress = null;

  @observable homeDeliveryOption = {};

  @observable initialShippingOption = {};

  @observable paymentMethodSelected = '';

  @observable creditCardSelected = 0;

  @observable installmentSelected = {};

  @observable prevStoreInstallment = {};

  @observable giftCardData = {
    CodigoCartao: '',
    Pin: '',
  };

  @observable giftCardError = null;

  @observable orderDetails = {};

  @observable orderId = '';

  @observable storeForPickup = {};

  @observable prevStoreForPickup = {};

  @observable newCreditCardErrors = {};

  @observable debitCardErrors = {};

  @observable creditCardErrors = {};

  @observable additionalDataToProcessPayment = {};

  @observable deliveriesLocation = [];

  @observable sessionId = '';

  @observable paymentLinkModal = false;

  @action setPaymentLinkModal = value => (this.paymentLinkModal = value);

  @observable paymentLinkWarning = false;

  @action setPaymentLinkWarning = value => (this.paymentLinkWarning = value);

  @observable paymentLinkModalWarning = false;

  @action setPaymentLinkModalWarning = value => (this.paymentLinkModalWarning = value);

  @computed get thePurchaseIsFree() {
    return parseInt(this.clientPayment.valorTotalCarrinho, 10) === 0;
  }

  @computed get hasScheduledDeliveryValid() {
    const scheduledDelivery = toJS(this.scheduledDelivery);

    return createTransformer(
      deliveryId =>
        Boolean((scheduledDelivery[deliveryId] || {}).selectedPeriod) &&
        Boolean((scheduledDelivery[deliveryId] || {}).deliveryDay) &&
        Boolean((scheduledDelivery[deliveryId] || {}).dayOfTheWeek),
    );
  }

  @computed get hasShippingStepValid() {
    return createTransformer(platform => {
      const storeForPickup = toJS(this.storeForPickup);
      const homeDeliveryOption = toJS(this.homeDeliveryOption);
      const hasScheduledDeliveryValid = toJS(this.hasScheduledDeliveryValid);
      const deliveryGroups = toJS(this.deliveryGroups);
      const currentShippingStep = toJS(this.currentShippingStep);

      if (platform === 'mobile') {
        return (
          (Boolean(homeDeliveryOption[currentShippingStep]) &&
            homeDeliveryOption[currentShippingStep] !== 2 &&
            homeDeliveryOption[currentShippingStep] !== 11) ||
          (homeDeliveryOption[currentShippingStep] === 2 &&
            hasScheduledDeliveryValid(currentShippingStep) &&
            homeDeliveryOption[currentShippingStep] !== 11) ||
          (homeDeliveryOption[currentShippingStep] === 11 &&
            storeForPickup[currentShippingStep] &&
            !objectIsEmpty(storeForPickup[currentShippingStep]))
        );
      }

      return deliveryGroups.every(
        (_, index) =>
          (Boolean(homeDeliveryOption[index + 1]) &&
            homeDeliveryOption[index + 1] !== 2 &&
            homeDeliveryOption[index] !== 11) ||
          (homeDeliveryOption[index + 1] === 2 &&
            hasScheduledDeliveryValid(index + 1) &&
            homeDeliveryOption[index] !== 11) ||
          (homeDeliveryOption[index] === 11 &&
            storeForPickup[index] &&
            !objectIsEmpty(storeForPickup[index])),
      );
    });
  }

  @computed get deliveryGroups() {
    if (!this.mainAddress) {
      return [];
    }

    const { id } = this.mainAddress;

    if (id) {
      const currentAddressIndex =
        this.clientDeliveries && this.clientDeliveries.enderecos
          ? this.clientDeliveries.enderecos.findIndex(value => value.id === id)
          : -1;

      if (currentAddressIndex !== -1) {
        return toJS(this.clientDeliveries.enderecos[currentAddressIndex].grupoEntrega);
      }

      // TODO: remove this: start
      if (
        this.clientDeliveries &&
        this.clientDeliveries.enderecos &&
        this.clientDeliveries.enderecos[0]
      ) {
        return toJS(this.clientDeliveries.enderecos[0].grupoEntrega);
      }
      // TODO: remove this: end
    }

    return [];
  }

  @computed get hasSelectedDeliveries() {
    return this.selectedDeliveries.length !== 0;
  }

  @computed get selectedDeliveries() {
    const homeDeliveryOption = toJS(this.homeDeliveryOption);
    const deliveryGroups = toJS(this.deliveryGroups);

    return Object.keys(homeDeliveryOption).map(key => {
      const hasDeliveryGroups = deliveryGroups.find(
        deliveryGroup => deliveryGroup.id === Number(key),
      );

      if (!hasDeliveryGroups) {
        sendEvent(
          'UI - Trocar endereço - Error',
          'Click - Erro ao escolher endereço cadastrado',
          'Erro ao clicar para trocar para um endereço cadastrado',
        );
        return hasDeliveryGroups;
      }

      return hasDeliveryGroups.tiposDeEntrega.find(
        deliveryType => deliveryType.codigoTipoServicoEntrega === homeDeliveryOption[key],
      );
    });
  }

  @computed get isOpen() {
    return createTransformer(stepName => this.openStep === STEP_NAMES[stepName]);
  }

  @computed get checkGender() {
    return createTransformer(gender => this.identificationData.sexo === gender);
  }

  getFirstAddress = addressesList => Math.min(...addressesList.map(address => address.id));

  @computed get mainAddress() {
    if (this.selectedAddress) {
      return this.clientAddresses.find(client => client.id === this.selectedAddress);
    }
    const firstAddressId = this.getFirstAddress(this.clientAddresses);

    return this.clientAddresses.find(client => client.id === firstAddressId);
  }

  @action getStoresLocation = async requestData => {
    this.setIsLoading(true);
    const header = await this.clientHeader();

    const { data, error } = await getDeliveriesLocation({
      cvId: header['x-cv-id'],
      clientToken: header['x-client-token'],
      requestData,
    });

    this.setIsLoading(false);
    // TODO: Tratar error
    if (!error) {
      this.setDeliveriesLocation(data);
    }
  };

  @action resetDeliveriesLocation = () => (this.deliveriesLocation = []);

  @action setDeliveriesLocation = deliveriesLocation =>
    (this.deliveriesLocation = deliveriesLocation);

  @action resetAdditionalDataToProcessPayment = () => (this.additionalDataToProcessPayment = {});

  @action addAdditionalDataToProcessPayment = ({
    temporaryOrderId,
    clientId,
    numberOfAttempts,
  }) => {
    this.additionalDataToProcessPayment = {
      temporaryOrderId,
      clientId,
      numberOfAttempts,
    };
  };

  @action removeCreditCardErrors = ({ creditCardId, target }) => {
    const creditCardErrors = toJS(this.creditCardErrors);

    delete creditCardErrors[creditCardId][target];

    this.creditCardErrors = cloneObject(creditCardErrors);
  };

  @action addCreditCardErrors = () => {
    const newCreditCardErrors = {};
    const creditCardErrors = toJS(this.creditCardErrors);
    const creditCardsAlreadyAdded = toJS(this.creditCardsAlreadyAdded);
    const creditCardSelected = toJS(this.creditCardSelected);
    const selectedCreditCard = creditCardsAlreadyAdded[creditCardSelected];

    newCreditCardErrors[creditCardSelected] = Object.keys(selectedCreditCard)
      .filter(key => !selectedCreditCard[key])
      .reduce(
        (itemList, key) => ({
          ...itemList,
          [key]: CREDIT_CARD_ERROR_MESSAGES[key],
        }),
        {},
      );

    this.creditCardErrors = cloneObject({
      ...creditCardErrors,
      ...newCreditCardErrors,
    });
  };

  @action removeNewCreditCardError = target => {
    const newCreditCardErrors = toJS(this.newCreditCardErrors);

    delete newCreditCardErrors[target];

    this.newCreditCardErrors = newCreditCardErrors;
  };

  @action removeDebitCardError = target => {
    const debitCardErrors = toJS(this.debitCardErrors);

    delete debitCardErrors[target];

    this.debitCardErrors = debitCardErrors;
  };

  @action addNewCreditCardErrors = () => {
    const newPaymentDataCreditCard = toJS(this.newPaymentDataCreditCard);

    const newCreditCardErrors = Object.keys(newPaymentDataCreditCard)
      .filter(key => !newPaymentDataCreditCard[key])
      .reduce(
        (itemsList, key) => ({
          ...itemsList,
          [key]: NEW_CREDIT_CARD_ERROR_MESSAGES[key],
        }),
        {},
      );

    if (!toJS(this.installmentSelected).null) {
      newCreditCardErrors.installments = 'Por favor informe a quantidade de parcelas';
    }

    this.newCreditCardErrors = cloneObject(newCreditCardErrors);
  };

  @action addDebitCardErrors = () => {
    const newPaymentDataCaixaDebitCard = toJS(this.newPaymentDataCaixaDebitCard);

    const debitCardErrors = Object.keys(newPaymentDataCaixaDebitCard)
      .filter(key => !newPaymentDataCaixaDebitCard[key])
      .reduce(
        (itemsList, key) => ({
          ...itemsList,
          [key]: NEW_CREDIT_CARD_ERROR_MESSAGES[key],
        }),
        {},
      );

    if (!newPaymentDataCaixaDebitCard.isBinValid && newPaymentDataCaixaDebitCard.number !== '') {
      debitCardErrors.number = 'informe um cartão de débito virtual elo emitido pela caixa';
    }

    this.debitCardErrors = cloneObject(debitCardErrors);
  };

  @action setCreditCardsAlreadyAdded = ({ creditCardId, newCreditCardsAlreadyAdded }) => {
    const creditCardsAlreadyAdded = toJS(this.creditCardsAlreadyAdded);

    this.creditCardsAlreadyAdded = {
      ...creditCardsAlreadyAdded,
      [creditCardId]: {
        ...(creditCardsAlreadyAdded[creditCardId] || { cvc: '', installments: null }),
        ...newCreditCardsAlreadyAdded,
      },
    };

    this.resetAdditionalDataToProcessPayment();
  };

  @action saveShipping = async () => {
    this.setIsLoading(true);

    const deliveryGroups = this.resolveDeliveryGroups();

    const header = await this.clientHeader();

    const { error } = await setDeliveries({
      cvId: header['x-cv-id'],
      clientToken: header['x-client-token'],
      deliveryData: {
        GruposEndereco: deliveryGroups,
        EnderecoId: this.mainAddress.id,
      },
    });

    await this.getUserPaymentData();

    sendCheckoutFunnelEventToDataLayer('add_shipping_info', {
      coupon: this.cartStore.discountCouponValue,
      value: currencyToNumber(this.clientPayment?.valorSubTotalCarrinho),
      shipping: currencyToNumber(this.clientPayment?.valorFreteCarrinho),
      deliveryGroups: deliveryGroups,
      items: this.deliveryGroups
        .map(deliveryGroup => {
          return deliveryGroup.tiposDeEntrega[0].itens.map(item => {
            return {
              item_name: item.descricao,
              item_id: item.sku,
              item_variant: item.cor,
              quantity: item.quantidade,
              price: item.valorUnitario,
              seller: getSellerInformation(item.sku).seller,
              seller_type: getSellerInformation(item.sku).seller_type,
            };
          });
        })
        .flat(),
      step: 2,
      products: this.deliveryGroups
        .map(deliveryGroup => {
          return deliveryGroup.tiposDeEntrega[0].itens.map(item => {
            return {
              name: item.descricao,
              id: item.sku,
              variant: item.cor,
              quantity: item.quantidade,
              price: item.valorUnitario,
              seller: getSellerInformation(item.sku).seller,
              seller_type: getSellerInformation(item.sku).seller_type,
            };
          });
        })
        .flat(),
    });

    this.setIsLoading(false);

    if (!error) {
      this.completeStep(STEP_NAMES.shipping);
    } else {
      this.addErrorMessage('Não foi possível seguir para pagamento.', 'error');
    }
  };

  @action saveTemporaryNewPaymentDataCreditCard = () => {
    this.temporaryNewPaymentDataCreditCard = cloneObject(this.newPaymentDataCreditCard);
  };

  @action resetNewPaymentDataCreditCard = () => {
    this.newPaymentDataCreditCard = cloneObject(this.temporaryNewPaymentDataCreditCard);
  };

  @action updateCreditCardInfo = ({ target, value }) => {
    const newPaymentDataCreditCard = toJS(this.newPaymentDataCreditCard);

    this.newPaymentDataCreditCard = {
      ...newPaymentDataCreditCard,
      [target]: value,
    };

    this.resetAdditionalDataToProcessPayment();
  };

  @action updateCaixaDebitCardInfo = ({ target, value }) => {
    this.newPaymentDataCaixaDebitCard = {
      ...toJS(this.newPaymentDataCaixaDebitCard),
      [target]: value,
    };

    let isBinValid = false;
    if (target === 'number') {
      const inputedDebitCardNumber = toJS(this.newPaymentDataCaixaDebitCard.number)
        .replace(' ', '')
        .substring(0, 8);
      isBinValid = ELO_DEBIT_ACCEPTED_BINS.some(bin => inputedDebitCardNumber.includes(bin));

      this.newPaymentDataCaixaDebitCard = {
        ...toJS(this.newPaymentDataCaixaDebitCard),
        isBinValid,
      };
    }

    this.resetAdditionalDataToProcessPayment();
  };

  @action processPayment = async () => {
    try {
      this.setIsLoading(true);

      await this.createNewPayment();

      if (window && window.dataLayer) {
        window.dataLayer.push({
          event: 'ProcessPayment',
        });
      }

      const orderId = toJS(this.orderId);

      history.push(`${thanksPagePath}/${orderId}`);
      const deliveryGroups = this.resolveDeliveryGroups();

      sendCheckoutFunnelEventToDataLayer('add_payment_info', {
        coupon: this.cartStore.discountCouponValue,
        value: currencyToNumber(this.clientPayment.valorSubTotalCarrinho),
        shipping: currencyToNumber(this.clientPayment.valorFreteCarrinho),
        deliveryGroups: deliveryGroups,
        payment_type: this.paymentMethodSelected,
        items: this.deliveryGroups
          .map(deliveryGroup => {
            return deliveryGroup.tiposDeEntrega[0].itens.map(item => {
              return {
                item_name: item.descricao,
                item_id: item.sku,
                item_variant: item.cor,
                quantity: item.quantidade,
                price: item.valorUnitario,
                seller: getSellerInformation(item.sku).seller,
                seller_type: getSellerInformation(item.sku).seller_type,
              };
            });
          })
          .flat(),
        option: this.paymentMethodSelected,
        step: 3,
        products: this.deliveryGroups
          .map(deliveryGroup => {
            return deliveryGroup.tiposDeEntrega[0].itens.map(item => {
              return {
                name: item.descricao,
                id: item.sku,
                variant: item.cor,
                quantity: item.quantidade,
                price: item.valorUnitario,
                seller: getSellerInformation(item.sku).seller,
                seller_type: getSellerInformation(item.sku).seller_type,
              };
            });
          })
          .flat(),
      });
      this.cartStore = new CartStore();

      datadogLogs.logger.info('Pedido Fechado', { pedidoId: orderId });
    } catch (error) {
      const errorMessage = error.data
        ? error.data.message
        : error.message
        ? error.message
        : 'Ocorreu um problema ao processar o pagamento.';

      this.addErrorMessage(errorMessage, 'error');
    } finally {
      this.setIsLoading(false);
    }
  };

  @action setUserLocation = newLocation => {
    const userLocation = toJS(this.userLocation);

    this.userLocation = {
      ...userLocation,
      ...newLocation,
    };
  };

  @action resetStoreForPickup = () => {
    const prevStoreForPickup = toJS(this.prevStoreForPickup);
    this.storeForPickup = cloneObject(prevStoreForPickup);
  };

  @action setPrevStoreForPickup = () => {
    const storeForPickup = toJS(this.storeForPickup);
    this.prevStoreForPickup = cloneObject(storeForPickup);
  };

  @action setStoreForPickup = (store, deliveryId) => {
    const storeForPickup = toJS(this.storeForPickup);

    this.storeForPickup = {
      ...storeForPickup,
      [deliveryId]: store,
    };
  };

  @action setOrderId = orderId => {
    this.orderId = orderId;
  };

  @action getOrder = async orderId => {
    const header = await this.clientHeader();

    const { data, error } = await getOrderById(orderId, {
      'x-cv-id': header['x-cv-id'],
      'x-client-token': header['x-client-token'],
    });

    if (!error) {
      this.setOrderDetails(data);
    } else {
      throw error;
    }
  };

  @action setOrderDetails = data => {
    this.orderDetails = data;
  };

  @action getInitialData = async () => {
    this.receivedAllInitialData = true;

    await this.getIdentificationData();

    await this.getClientAddresses();

    await this.getClientDeliveries();

    if (this.receivedAllInitialData) {
      await this.getUserPaymentData();
    }
  };

  @action setGiftCard = async () => {
    const { CodigoCartao, Pin } = toJS(this.giftCardData);

    const header = await this.clientHeader();

    this.setIsLoading(true);
    const { error } = await cartModule.addGiftCard(CodigoCartao, Pin, header);

    if (error) {
      this.setGiftCardError(error.data.message);
      this.setIsLoading(false);
      return false;
    }

    await this.getInitialData();
    await this.saveShipping();

    this.setIsLoading(false);
    return true;
  };

  @action removeGiftCard = async () => {
    const header = await this.clientHeader();

    this.setIsLoading(true);
    const { error } = await cartModule.removeGiftCard(header);

    if (error) {
      this.setGiftCardError(error.data.message);
      return;
    }

    await this.getInitialData();

    this.resetGiftCardData();

    this.setIsLoading(false);
  };

  @action resetGiftCardData = () => {
    this.updataGiftCardData({ target: 'CodigoCartao', value: '' });
    this.updataGiftCardData({ target: 'Pin', value: '' });
  };

  @action updataGiftCardData = ({ target, value }) => {
    const giftCardData = toJS(this.giftCardData);

    this.giftCardData = {
      ...giftCardData,
      ...{ [target]: value },
    };
  };

  @action setGiftCardError = value => {
    this.giftCardError = value;
  };

  @action resetGiftCardError = () => {
    this.giftCardError = null;
  };

  @action updateCurrentShippingStep = () => {
    this.currentShippingStep += 1;
  };

  @action decreaseCurrentShippingStep = () => {
    this.currentShippingStep -= 1;
  };

  @action resetCurrentShippingStep = () => {
    this.currentShippingStep = 1;
  };

  @action saveScheduledDelivery = (deliveryId, newScheduledDelivery) => {
    const newScheduledDeliveryFiltered = Object.keys(newScheduledDelivery)
      .filter(key => !!newScheduledDelivery[key])
      .reduce((prev, key) => {
        prev[key] = newScheduledDelivery[key]; // eslint-disable-line
        return prev;
      }, {});

    const scheduledDelivery = toJS(this.scheduledDelivery);

    this.scheduledDelivery = {
      ...scheduledDelivery,
      [deliveryId]: {
        ...scheduledDelivery[deliveryId],
        ...newScheduledDeliveryFiltered,
      },
    };
  };

  @action resetInitialShippingOption = ({ deliveryId }) => {
    const homeDeliveryOption = toJS(this.homeDeliveryOption);
    const initialShippingOption = toJS(this.initialShippingOption);

    this.homeDeliveryOption = {
      ...homeDeliveryOption,
      [deliveryId]: initialShippingOption[deliveryId],
    };
  };

  @action setInitialShippingOption = () => {
    this.initialShippingOption = { ...this.homeDeliveryOption };
  };

  @action setDeliveryOption = ({ deliveryServiceTypeCode, deliveryId }) => {
    const homeDeliveryOption = toJS(this.homeDeliveryOption);

    this.homeDeliveryOption = {
      ...homeDeliveryOption,
      ...{ [deliveryId]: deliveryServiceTypeCode },
    };
  };

  @action setIsLoading = status => {
    this.isLoading = status;
  };

  @action saveCurrentSelectedAddress = () => {
    this.currentSelectedAddress = this.selectedAddress;
  };

  @action restoreSelectedAddress = () => {
    this.selectedAddress = this.currentSelectedAddress;
    this.currentSelectedAddress = null;
  };

  @action setSelectedAddress = async addressId => {
    this.selectedAddress = addressId;

    this.setIsLoading(true);
    await this.getClientDeliveries();
    this.setIsLoading(false);
  };

  organizeClientAddress = addressesList => {
    if (!addressesList || addressesList.length === 0) {
      return [];
    }

    const firstAddressId = this.getFirstAddress(addressesList);
    const clientAddresses = addressesList.filter(address => address.id !== firstAddressId);
    const mainAddress = addressesList.find(address => address.id === firstAddressId);

    clientAddresses.unshift(mainAddress);

    return clientAddresses;
  };

  @action getClientAddresses = async () => {
    const header = await this.clientHeader();

    const { data, error } = await getAddressesList({
      cvId: header['x-cv-id'],
      clientToken: header['x-client-token'],
    });

    if (!error) {
      const clientAddresses = this.organizeClientAddress(data);

      this.setLoadedAddress(true);

      this.setClientAddresses(clientAddresses);
    } else {
      throw error;
    }
  };

  @action setLoadedAddress = data => (this.loadedAddress = data);

  @observable loadedAddress = false;

  @action setClientAddresses = data => {
    this.clientAddresses = data;
  };

  @action resetNewAddressData = () => {
    this.newAddressData = { ...INITIAL_ADDRESS_DATA, ...{} };
  };

  @action setAddressFormData = addressData => {
    const addressDataJS = toJS(addressData);

    this.newAddressData = {
      ...{
        IdEndereco: addressDataJS.id,
        Padrao: addressDataJS.enderecoPadrao,
        Nome: addressDataJS.local,
        Logradouro: addressDataJS.logradouro,
        Numero: addressDataJS.numero,
        Complemento: addressDataJS.complemento,
        Bairro: addressDataJS.bairro,
        Referencia: addressDataJS.referencia || '',
        Cep: addressDataJS.cep,
        Cidade: addressDataJS.cidade,
        UF: addressDataJS.uf,
        Pais: 'Brasil',
        NomeDeQuemIraReceber: addressDataJS.nomeDeQuemIraReceber,
        SobrenomeDeQuemIraReceber: addressDataJS.sobrenomeDeQuemIraReceber,
        Telefone: {
          DDD: addressDataJS.telefone.ddd,
          Numero: addressDataJS.telefone.numero,
        },
        Celular: {
          DDD: addressDataJS.celular.ddd,
          Numero: addressDataJS.celular.numero,
        },
      },
      ...{},
    };
  };

  @action updateNewAddressData = ({ target, value }) => {
    this.newAddressData = {
      ...toJS(this.newAddressData),
      [target]: value,
    };
  };

  @action setZipcodeData = zipcodeData => {
    const newData = {
      Cep: zipcodeData.cep,
      Cidade: zipcodeData.cidade.slice(0, CITY_MAX_LENGTH),
      UF: zipcodeData.uf.slice(0, UF_MAX_LENGTH),
      Bairro: zipcodeData.bairro.slice(0, NEIGHBORHOOD_MAX_LENGTH) || '',
      Logradouro: zipcodeData.logradouro.slice(0, STREET_MAX_LENGTH) || '',
    };

    this.newAddressData = {
      ...toJS(this.newAddressData),
      ...newData,
    };

    this.initialZipcodeData = newData;
  };

  @action updateNewAddressDataPhone = ({ phoneType, target, value }) => {
    const newAddressData = toJS(this.newAddressData);

    this.newAddressData = {
      ...newAddressData,
      [phoneType]: {
        ...newAddressData[phoneType],
        [target]: value,
      },
    };
  };

  @action createNewAddress = async () => {
    const header = await this.clientHeader();

    const { status, error } = await createAddress({
      addressData: toJS(this.newAddressData),
      cvId: header['x-cv-id'],
      clientToken: header['x-client-token'],
    });

    if (status === 200) {
      await this.getInitialData();
    } else {
      throw error;
    }
  };

  @action updateExistingAddress = async () => {
    const header = await this.clientHeader();

    const { status, error } = await updateAddress({
      addressData: toJS(this.newAddressData),
      cvId: header['x-cv-id'],
      clientToken: header['x-client-token'],
    });

    if (status === 200) {
      await this.getClientAddresses();
    } else {
      throw error;
    }
  };

  addErrorMessage = (message, type) => {
    this.errorHandlerStore.setError({
      message,
      type,
    });
  };

  @action setInitialShipping = () => {
    this.deliveryGroups.forEach(item => {
      const hasNormalDelivery = item.tiposDeEntrega.some(
        deliveryType => deliveryType.codigoTipoServicoEntrega === 1,
      );

      const deliveryServiceTypeCode = hasNormalDelivery
        ? 1
        : item.tiposDeEntrega[0].codigoTipoServicoEntrega;

      this.setDeliveryOption({
        deliveryServiceTypeCode,
        deliveryId: item.id,
      });
    });

    this.setInitialShippingOption();
  };

  @action getClientDeliveries = async () => {
    if (!this.mainAddress || !this.mainAddress.id) {
      return;
    }

    const header = await this.clientHeader();

    const { data, error } = await getDeliveriesList({
      cvId: header['x-cv-id'],
      addressId: this.mainAddress.id,
      clientToken: header['x-client-token'],
      televendas: header['x-televendas'],
    });

    if (!error) {
      if (!data) {
        this.receivedAllInitialData = false;

        history.push(cartPath);
      } else {
        if (data.freteOMSContingencia) {
          setABTestFromBackEnd('dimension43', 'abDeliveryContingency', data.freteOMSTesteAB);
        }

        this.setClientDeliveries(data);

        this.setInitialShipping();
      }
    } else {
      throw error;
    }
  };

  @action setClientDeliveries = data => {
    this.clientDeliveries = data;
  };

  @action getIdentificationData = async () => {
    const header = await this.clientHeader();

    const { data, error } = await getClientData({
      cvId: header['x-cv-id'],
      clientToken: header['x-client-token'],
    });

    if (!error) {
      this.setIdentificationData(data);
    } else {
      throw error;
    }
  };

  @action getUserPaymentData = async () => {
    const header = await this.clientHeader();

    const storeId = StoreRepository.getStore();

    const { data, error } = await getPaymentData({
      cvId: header['x-cv-id'],
      ...(storeId ? { xljId: storeId } : {}),
      clientToken: header['x-client-token'],
      televendas: header['x-televendas'],
    });

    if (!error) {
      this.setUserPaymentData(data);
    } else {
      throw error;
    }
  };

  @action setUserPaymentData = data => {
    this.clientPayment = data;
  };

  @action setSelectedInstallments = (installmentQuantity, creditCardId) => {
    const installmentSelected = toJS(this.installmentSelected);

    this.installmentSelected = installmentQuantity;

    this.installmentSelected = {
      ...installmentSelected,
      [creditCardId]: installmentQuantity,
    };

    this.resetAdditionalDataToProcessPayment();

    this.removeNewCreditCardError('installments');
  };

  // PAYMENTLINK

  @observable paymentLinkSelectedInstallment = null;

  @observable paymentLinkError = {};

  @action setPaymentLinkSelectedInstallment = installmentQuantity => {
    this.paymentLinkSelectedInstallment = installmentQuantity;
    this.resetAdditionalDataToProcessPayment();
    this.removePaymentLinkError();
  };

  @action addPaymentLinkError = () => {
    this.paymentLinkError = { ...PAYMENT_LINK_ERROR_MESSAGES };
  };

  @action removePaymentLinkError = () => {
    this.paymentLinkError = {};
  };

  @action resetStoreInstallment = () => {
    const prevStoreInstallment = toJS(this.prevStoreInstallment);
    this.installmentSelected = cloneObject(prevStoreInstallment);
  };

  @action setPrevStoreInstallment = () => {
    const installmentSelected = toJS(this.installmentSelected);
    this.prevStoreInstallment = cloneObject(installmentSelected);
  };

  @action setCreditCardForFuturePurchases = () => {
    const newPaymentDataCreditCard = toJS(this.newPaymentDataCreditCard);

    this.newPaymentDataCreditCard = {
      ...newPaymentDataCreditCard,
      useQuickPurchaseCard: !newPaymentDataCreditCard.useQuickPurchaseCard,
    };
  };

  @action setSelectedCreditCard = creditCardId => {
    this.paymentMethodSelected = PAYMENT_METHODS.creditCard;
    this.creditCardSelected = creditCardId;
    this.setCreditCardsAlreadyAdded({
      creditCardId,
      newCreditCardsAlreadyAdded: toJS(this.creditCardsAlreadyAdded)[creditCardId] || {
        cvc: '',
        installments: null,
      },
    });
  };

  @action setSelectedPaymentMethod = paymentMethod => {
    this.resetAdditionalDataToProcessPayment();
    this.paymentMethodSelected = paymentMethod;

    if (paymentMethod !== PAYMENT_METHODS.creditCard) {
      this.creditCardSelected = 0;
    }
  };

  @action selectNewCreditCardMethod = (value = true) =>
    this.setSelectedPaymentMethod(value ? PAYMENT_METHODS.newCreditCard : null);

  @action selectNewAddedCreditCardMethod = (value = true) =>
    this.setSelectedPaymentMethod(value ? PAYMENT_METHODS.creditCard : null);

  @action selectBankSlipMethod = () => this.setSelectedPaymentMethod(PAYMENT_METHODS.bankSlip);

  @action selectPixMethod = () => this.setSelectedPaymentMethod(PAYMENT_METHODS.pix);

  @action selectPaymentInStoreMethod = () =>
    this.setSelectedPaymentMethod(PAYMENT_METHODS.paymentInStore);

  @action selectCaixaVirtualCardMethod = (value = true) => {
    this.setSelectedPaymentMethod(value ? PAYMENT_METHODS.caixaVirtualCard : null);
  };

  @action selectPaymentLinkMethod = () =>
    this.setSelectedPaymentMethod(PAYMENT_METHODS.paymentLink);

  @computed get hasSelectedPayments() {
    return this.paymentMethodSelected.length !== 0;
  }

  @action createNewPayment = async () => {
    if (this.thePurchaseIsFree) {
      return this.finishFreePurchase();
    }

    switch (this.paymentMethodSelected) {
      case PAYMENT_METHODS.bankSlip:
        return this.createNewPaymentBoleto();

      case PAYMENT_METHODS.pix:
        return this.createNewPaymentPix();

      case PAYMENT_METHODS.paymentInStore:
        return this.createNewPaymentInStore();

      case PAYMENT_METHODS.creditCard:
        return this.createNewPaymentAddedCreditCard();

      case PAYMENT_METHODS.newCreditCard:
        return this.createNewPaymentWithNewCreditCard();

      case PAYMENT_METHODS.caixaVirtualCard:
        return this.createNewPaymentWithCaixaDebitCard();

      case PAYMENT_METHODS.paymentLink:
        return this.createNewPaymentWithPaymentLink();

      default:
        return null;
    }
  };

  resolveDeliveryGroups = () => {
    return this.deliveryGroups
      .map(deliveryGroup =>
        deliveryGroup.tiposDeEntrega
          .filter(
            deliveryType =>
              deliveryType.codigoTipoServicoEntrega === this.homeDeliveryOption[deliveryGroup.id],
          )
          .map(deliveryType => ({
            PeriodoId: this.scheduledDelivery[deliveryGroup.id]
              ? this.scheduledDelivery[deliveryGroup.id].selectedPeriod
              : 0,
            RetiraLojaId:
              this.storeForPickup[deliveryGroup.id] &&
              this.homeDeliveryOption[deliveryGroup.id] === 11
                ? this.storeForPickup[deliveryGroup.id].idLoja
                : null,
            DiasDisponibilidadeRetira:
              (this.storeForPickup[deliveryGroup.id] || {}).disponibilidadeEntrega || 0,
            itens: deliveryType.itens.map(item => ({
              sku: item.sku,
              itemCarrinhoId: item.itemCarrinhoId,
            })),
            TipoEntrega: this.homeDeliveryOption[deliveryGroup.id],
            DataDaEntrega:
              this.scheduledDelivery[deliveryGroup.id] &&
              dayjs(this.scheduledDelivery[deliveryGroup.id].deliveryDay).format('DD/MM/YYYY'),
          })),
      )
      .flat();
  };

  getSpeciesCode = ({ installmentSelected, issuer, savedCard = false }) => {
    const clientPayment = toJS(this.clientPayment);

    const creditCard = clientPayment.cartoesDeCredito.find(
      clientCard =>
        clientCard.seoNome.toLowerCase() ===
        (ISSUERS_DICTIONARY[issuer.toLowerCase()] || '').toLowerCase(),
    );

    const speciesCode = creditCard
      ? creditCard[savedCard ? 'especiesDePagamentoCompraRapida' : 'especiesDePagamento'].find(
          payment => payment.numeroDaParcela === installmentSelected,
        ).codigo
      : '';

    return {
      speciesCode,
      seoName: (creditCard || {}).seoNome || '',
    };
  };

  getDebitSpeciesCode = ({ issuer, savedCard = false, installmentSelected = 1 }) => {
    const clientPayment = toJS(this.clientPayment);

    const debitCard = clientPayment.cartoesDeDebito.find(
      clientCard =>
        clientCard.seoNome.toLowerCase() ===
        (ISSUERS_DICTIONARY_DEBIT[issuer.toLowerCase()] || '').toLowerCase(),
    );

    const speciesCode = debitCard
      ? debitCard[savedCard ? 'especiesDePagamentoCompraRapida' : 'especiesDePagamento'].find(
          payment => payment.numeroDaParcela === installmentSelected,
        ).codigo
      : '';

    return {
      speciesCode,
      seoName: (debitCard || {}).seoNome || '',
    };
  };

  getLinkSpeciesCode = installmentSelected => {
    const clientPayment = toJS(this.clientPayment);
    const speciesCode = clientPayment.linkDePagamento.especiesDePagamento.find(
      payment => payment.numeroDaParcela === installmentSelected,
    ).codigo;
    return speciesCode;
  };

  @action createNewPaymentAddedCreditCard = async () => {
    const clientPayment = toJS(this.clientPayment);

    const creditCard = clientPayment.cartoesClienteOCB.find(
      clientCard => clientCard.id === this.creditCardSelected,
    );

    const { cvc } = this.creditCardsAlreadyAdded[this.creditCardSelected];

    const { speciesCode, seoName } = this.getSpeciesCode({
      installmentSelected: this.installmentSelected[this.creditCardSelected],
      issuer: creditCard.administradora,
      savedCard: true,
    });

    const header = await this.clientHeader();

    const requestBody = {
      AnoCartao: creditCard.anoValidade,
      AnoCartaoCliente: 0,
      CodigoEspecie: speciesCode,
      CodigoSegurancaCartao: cvc.replace(/_/g, ''),
      MesCartao: creditCard.mesValidade,
      MesCartaoCliente: 0,
      NomeCartao: seoName,
      tokenCartao: creditCard.tokenCartao,
      OpcaoPagamento: 'credito',
      TitularCartao: creditCard.nomeTitular,
      UtilizarPagamentoOnline: true,
      UtilizarCartaoCompraRapida: false,
      UtilizarCartao: true,
      sessionId: this.sessionId,
    };

    const additionalDataToProcessPayment = toJS(this.additionalDataToProcessPayment);

    if (!isEmpty(additionalDataToProcessPayment)) {
      requestBody.ClienteId = additionalDataToProcessPayment.clientId;
      requestBody.PedidoTemporarioId = additionalDataToProcessPayment.temporaryOrderId;
      requestBody.NumeroTentativas = additionalDataToProcessPayment.numberOfAttempts;
    }

    const { data, error } = await createPaymentData(requestBody, {
      cvId: header['x-cv-id'],
      clientToken: header['x-client-token'],
    });

    if (error) {
      throw error;
    } else if (data.confirmacaoPagamentoOnline) {
      this.addAdditionalDataToProcessPayment({
        temporaryOrderId: data.pedidoTemporarioId,
        clientId: data.clienteId,
        numberOfAttempts: data.numeroTentativa,
      });
      throw new Error(
        'Não conseguimos processar o seu pagamento. Por favor verifique as informações do cartão',
      );
    }

    this.setOrderId(data.numeroPedido);
  };

  @action createNewPaymentWithNewCreditCard = async () => {
    const newPaymentDataCreditCard = toJS(this.newPaymentDataCreditCard);

    const installmentSelected = toJS(this.installmentSelected);

    const { expiry, name, number, cvc, issuer, useQuickPurchaseCard } = newPaymentDataCreditCard;

    const header = await this.clientHeader();

    const { speciesCode, seoName } = this.getSpeciesCode({
      installmentSelected: installmentSelected.null,
      issuer,
    });

    const [expiryMonth, expiryYear] = expiry.split('/');

    const requestBody = {
      AnoCartao: `20${expiryYear}`,
      AnoCartaoCliente: 0,
      CodigoEspecie: speciesCode,
      CodigoSegurancaCartao: cvc.replace(/_/g, ''),
      MesCartao: expiryMonth,
      MesCartaoCliente: 0,
      NomeCartao: seoName,
      NumeroCartao: number.replace(/_/g, '').replace(/\s/g, ''),
      OpcaoPagamento: 'credito',
      TitularCartao: name,
      UtilizarCartao: useQuickPurchaseCard || false,
      UtilizarCartaoCompraRapida: useQuickPurchaseCard || false,
      UtilizarPagamentoOnline: true,
      sessionId: this.sessionId,
    };

    const additionalDataToProcessPayment = toJS(this.additionalDataToProcessPayment);

    if (!isEmpty(additionalDataToProcessPayment)) {
      requestBody.ClienteId = additionalDataToProcessPayment.clientId;
      requestBody.PedidoTemporarioId = additionalDataToProcessPayment.temporaryOrderId;
      requestBody.NumeroTentativas = additionalDataToProcessPayment.numberOfAttempts;
    }

    const { data, error } = await createPaymentData(requestBody, {
      cvId: header['x-cv-id'],
      clientToken: header['x-client-token'],
    });

    if (error) {
      throw error;
    } else if (data.confirmacaoPagamentoOnline) {
      this.addAdditionalDataToProcessPayment({
        temporaryOrderId: data.pedidoTemporarioId,
        clientId: data.clienteId,
        numberOfAttempts: data.numeroTentativa,
      });
      throw new Error(
        'Não conseguimos processar o seu pagamento. Por favor verifique as informações do cartão',
      );
    }

    this.setOrderId(data.numeroPedido);
  };

  @action createNewPaymentWithCaixaDebitCard = async () => {
    const { expiry, name, number, cvc, issuer } = toJS(this.newPaymentDataCaixaDebitCard);

    const header = await this.clientHeader();

    const [expiryMonth, expiryYear] = expiry.split('/');

    const { speciesCode, seoName } = this.getDebitSpeciesCode({ issuer });

    const requestBody = {
      AnoCartao: `20${expiryYear}`,
      AnoCartaoCliente: 0,
      CodigoEspecie: speciesCode,
      CodigoSegurancaCartao: cvc.replace(/_/g, ''),
      MesCartao: expiryMonth,
      MesCartaoCliente: 0,
      NomeCartao: seoName,
      NumeroCartao: number.replace(/_/g, '').replace(/\s/g, ''),
      OpcaoPagamento: 'debito',
      TitularCartao: name,
      UtilizarCartao: false,
      UtilizarCartaoCompraRapida: false,
      UtilizarPagamentoOnline: true,
      sessionId: this.sessionId,
    };

    const additionalDataToProcessPayment = toJS(this.additionalDataToProcessPayment);

    if (!isEmpty(additionalDataToProcessPayment)) {
      requestBody.ClienteId = additionalDataToProcessPayment.clientId;
      requestBody.PedidoTemporarioId = additionalDataToProcessPayment.temporaryOrderId;
      requestBody.NumeroTentativas = additionalDataToProcessPayment.numberOfAttempts;
    }

    const { data, error } = await createPaymentData(requestBody, {
      cvId: header['x-cv-id'],
      clientToken: header['x-client-token'],
    });

    if (error) {
      throw error;
    } else if (data.confirmacaoPagamentoOnline) {
      this.addAdditionalDataToProcessPayment({
        temporaryOrderId: data.pedidoTemporarioId,
        clientId: data.clienteId,
        numberOfAttempts: data.numeroTentativa,
      });
      throw new Error(
        'Não conseguimos processar o seu pagamento. Por favor verifique as informações do cartão',
      );
    }

    this.setOrderId(data.numeroPedido);
  };

  @action createNewPaymentWithPaymentLink = async () => {
    const header = await this.clientHeader();

    const speciesCode = 'LP1';

    const requestBody = {
      UtilizarCartao: false,
      UtilizarPagamentoOnline: false,
      CodigoEspecie: speciesCode,
      sessionId: this.sessionId,
    };

    this.setPaymentLinkWarning(false);
    this.setPaymentLinkModalWarning(false);

    const { data, error } = await createPaymentData(requestBody, {
      cvId: header['x-cv-id'],
      clientToken: header['x-client-token'],
      televendas: header['x-televendas'],
    });

    if (data && data.avisos && data.avisos[0]) {
      this.setPaymentLinkModalWarning(true);
      this.setPaymentLinkWarning(true);
    }

    if (error) {
      throw error;
    }

    this.setOrderId(data.numeroPedido);
  };

  @action finishFreePurchase = async () => {
    const requestBody = {
      UtilizarCartao: false,
      MesCartao: 0,
      AnoCartao: 0,
    };

    if (this.clientPayment.cartaoPresente) {
      const { codigo } = this.clientPayment.cartaoPresente;

      requestBody.CodigoEspecie = codigo;
      requestBody.OpcaoPagamento = 'Vale Troca';
    } else {
      const { codigo } = this.clientPayment.valeTroca;

      requestBody.CodigoEspecie = codigo;
      requestBody.OpcaoPagamento = 'Gift Card';
    }

    const header = await this.clientHeader();

    const { data, error } = await createPaymentData(requestBody, {
      cvId: header['x-cv-id'],
      clientToken: header['x-client-token'],
    });

    if (error) {
      throw error;
    }

    this.setOrderId(data.numeroPedido);
  };

  @action createNewPaymentBoleto = async () => {
    const header = await this.clientHeader();

    const requestBody = {
      UtilizarCartao: false,
      UtilizarPagamentoOnline: false,
      OpcaoPagamento: 'boleto',
      NomeCartao: 'boleto',
      CodigoEspecie: 'BO',
      sessionId: this.sessionId,
    };

    const { data, error } = await createPaymentData(requestBody, {
      cvId: header['x-cv-id'],
      clientToken: header['x-client-token'],
    });

    if (error) {
      throw error;
    }

    this.setOrderId(data.numeroPedido);
  };

  @action createNewPaymentPix = async () => {
    const header = await this.clientHeader();

    const requestBody = {
      UtilizarCartao: false,
      UtilizarPagamentoOnline: true,
      OpcaoPagamento: 'pix',
      NomeCartao: 'pix',
      CodigoEspecie: 'PI',
      sessionId: this.sessionId,
    };

    const { data, error } = await createPaymentData(requestBody, {
      cvId: header['x-cv-id'],
      clientToken: header['x-client-token'],
    });

    if (error) {
      throw error;
    }

    this.setOrderId(data.numeroPedido);
  };

  @action createNewPaymentInStore = async () => {
    const header = await this.clientHeader();

    const requestBody = {
      UtilizarCartao: false,
      UtilizarPagamentoOnline: false,
      OpcaoPagamento: 'Mega Loja',
      NomeCartao: 'caixa',
      CodigoEspecie: 'EC',
      sessionId: this.sessionId,
    };

    const { error: errorSyncCart } = await putPdvAttributesAsync(header['x-client-token']);
    if (errorSyncCart) {
      throw errorSyncCart;
    }

    const { data, error } = await createPaymentData(requestBody, {
      cvId: header['x-cv-id'],
      clientToken: header['x-client-token'],
      televendas: header['x-televendas'],
      xljId: header['x-lj-id'],
    });

    if (error) {
      throw error;
    }

    this.setOrderId(data.numeroPedido);
  };

  @action resetOpenStep = () => {
    this.openStep = '';
  };

  @observable noAddressFirstTime = true;

  @action setOpenStep = stepPosition => {
    this.noAddressFirstTime = false;

    this.openStep = stepPosition;
  };

  @action setIdentificationData = data => {
    this.identificationData = data;
    this.identificationDataBackup = cloneObject(data);
  };

  @action resetIdentificationData = () => {
    this.identificationData = {
      ...toJS(this.identificationDataBackup),
      ...{},
    };
  };

  @action updateIdentificationData = ({ target, value }) => {
    this.identificationData = {
      ...toJS(this.identificationData),
      [target]: value,
    };
  };

  @action updatePhoneNumber = ({ phoneType, target, value }) => {
    const identificationData = toJS(this.identificationData);

    const updatedPhone = { ...identificationData.endereco[phoneType], [target]: value };
    const updatedAddress = { ...identificationData.endereco, [phoneType]: updatedPhone };

    this.identificationData = { ...identificationData, endereco: updatedAddress };
  };

  @action completeStep = stepName => {
    switch (stepName) {
      case STEP_NAMES.identification:
        this.setOpenStep(STEP_NAMES.shipping);

        this.stepStatus = {
          [STEP_NAMES.identification]: STEP_STATUS_TYPE.success,
          [STEP_NAMES.shipping]: STEP_STATUS_TYPE.dirty,
          [STEP_NAMES.payment]: STEP_STATUS_TYPE.pending,
        };
        break;

      case STEP_NAMES.shipping:
        this.setOpenStep(STEP_NAMES.payment);

        this.stepStatus = {
          [STEP_NAMES.identification]: STEP_STATUS_TYPE.success,
          [STEP_NAMES.shipping]: STEP_STATUS_TYPE.success,
          [STEP_NAMES.payment]: STEP_STATUS_TYPE.dirty,
        };
        break;
      default:
        this.stepStatus = {
          [STEP_NAMES.identification]: STEP_STATUS_TYPE.pending,
          [STEP_NAMES.shipping]: STEP_STATUS_TYPE.pending,
          [STEP_NAMES.payment]: STEP_STATUS_TYPE.pending,
        };
    }
  };

  @action setSessionId = sessionIdValue => (this.sessionId = sessionIdValue);

  clientHeader = async () => {
    return toJS(this.loginStore.clientHeader);
  };

  @observable isCaixaToolTipVisible = true;

  @action setCaixaToolTipVisible = value => (this.isCaixaToolTipVisible = value);
}

export {
  STEP_STATUS_TYPE,
  STEP_NAMES,
  INITIAL_ADDRESS_DATA,
  PAYMENT_METHODS,
  ISSUERS_DICTIONARY,
  ISSUERS_DICTIONARY_DEBIT,
  ACCEPTED_CARDS,
};

export default CheckoutStore;
