import { StyleSheet } from 'aphrodite';

import { grayE, grayP, grayMM } from '../../../../common/colors';

const styles = StyleSheet.create({
  footerContainer: {
    backgroundColor: grayMM,
    textAlign: 'center',
    padding: '24px 0',
  },
  footerTitle: {
    color: grayP,
    fontSize: '12px',
    textTransform: 'uppercase',
    fontWeight: '400',
    lineHeight: '1',
    letterSpacing: '0.21px',
  },
  footerAddress: {
    color: grayP,
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '1',
    letterSpacing: '0.21px',
    marginTop: '8px',
  },
  linkContainer: {
    marginBottom: '18px',
  },
  link: {
    fontSize: '12px',
    lineHeight: 1.33,
    letterSpacing: '0.21px',
    textAlign: 'center',
    color: grayE,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

export default styles;
