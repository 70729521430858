import { getJson, postJson } from './httpClientCentauro';
import { apiDomain, apiDomainTicket } from '../settings';

const exchangeVoucherGetURI = `${apiDomain}/v2/valetroca`;
const exchangeVoucherPostURI = `${apiDomainTicket}/CancellationVT/gerarProtocolo`;

const getExchangeVoucherData = (hash, { cvId, clientToken }) => {
  const hashParam = `?hash=${hash}`;
  return getJson(`${exchangeVoucherGetURI}${hashParam}`, {
    'x-cv-id': cvId,
    'x-client-token': clientToken,
  });
};

const createDataOrbium = (body, { cvId, clientToken }) =>
  postJson(exchangeVoucherPostURI, body, {
    'x-cv-id': cvId,
    'x-client-token': clientToken,
  });

export { getExchangeVoucherData, createDataOrbium };
