/**
 * Config experiments example
 *
 * export const <NAME_OF_THE_EXPERIMENT> = {
 *   id: {string},       // Experiment id
 *   name: {string},     // Experiment name in Optimize
 *   variants: {array},  // Variants list - ['1', '2']
 *   variantX: {string}, // Variant name (any name) and value "variantA: 1"
 * };
 *
 * Example
 * export const PDP_EXPERIMENT_TITLE = {
 *   id: '45o1QO7aT0ydFJNMoGgbsA',
 *   name: 'PDP experiment title',
 *   variants: ['1'],
 *   variantA: '1',
 * };
 */

export const SSO_LOGIN = {
  id: 'pZ9rDLuHTJKBas3hU5-fCA',
  name: 'Login com SSO',
  variants: ['1'],
  variantSSO: '1',
};

export const COOKIE_FIRST = {
  id: '9nn9DQgmQHOvL7rqW5Z1LA',
  name: 'Cookie First',
  variants: ['1'],
  variantCookieFirst: '1',
};

export const DELIVERY_FILTERS_FAKE_DOOR_TOGGLE = {
  id: 'vEZXFHlnQdaOmHBv-eE85Q',
  name: 'Delivery filters with toggle fake door',
  variants: ['1', '2', '3'],
  variantTwoDaysDelivery: '1',
  variantPickTomorrow: '2',
  variantFreeDelivery: '3',
};

export const DELIVERY_FILTERS_FILTER_BAR = {
  id: 'zSbzM9j0TbOnXW7JLk61DQ',
  name: 'Delivery filters in filter bar',
  variants: ['1', '2', '3'],
  variantTwoDaysDelivery: '1',
  variantPickTomorrow: '2',
  variantFreeDelivery: '3',
};

export const NEWSLETTER_PREFERENCES = {
  id: 'TgfUV9DeQtiJT624QLzcjg',
  name: 'Aumentar número de preferências',
  variants: ['1'],
  variantA: '1',
};

export const ASYNC_PRICE = {
  id: 'lMuygudUS-CW-h9V4H7Vmw',
  name: 'Async price without PPA',
  variants: ['1'],
  variantPriceWithoutPPA: '1',
};

export const PDP_SAP_SHIPPING_MAP = {
  id: 'NKKYLL7wQ6GtXfDj3rEEYg',
  name: 'SAP migrate: shipping map in pdp',
  variants: ['1'],
  variantFooterMap: '1',
};

export const STRONG_PASSWORD = {
  id: 'iuXvlIqNSmibHrLTDLZofA',
  name: 'Force Strong Password',
  variants: ['1'],
  variantStrongPassword: '1',
};

export const COUPON_CART = {
  id: 'B0G90lNmSWW2CrNw_e7vAg',
  name: 'show cart discount coupon',
  variants: ['1'],
  variantCouponVisible: '1',
};

export const DELIVERY_LABELS_NAV = {
  id: 'GT0ckdbPT_C6RZTXX6656w',
  name: 'Delivery Labels in Navigation',
  variants: ['1'],
  variantWithLabels: '1',
};

export const FREE_FREIGHT_PDP = {
  id: '3X_ywYAGTSWgKE_-6I5sww',
  name: 'Free freight PDP',
  variants: ['1'],
  variantFreeFreight: '1',
};

export const PIN_SCROLLBAR_PDP = {
  id: 'gOnpK2H9RQuJ3V8Gitj8Sg',
  name: 'Pin Scrollbar PDP',
  variants: ['1'],
  variantPinScrollbar: '1',
};

export const RECOMENDATION_VARIANT = {
  id: 'uwHbIZR6RTCy_GTyyWCZVw',
  name: 'Remove Recomendation Showcase',
  variants: ['1'],
  variantRemoveShowcase: '1',
};
