import { getJson } from './httpClientCentauro';
import { apiDomain } from '../settings';

const clientsUrl = `${apiDomain}/v4.1/clientes`;

const getClientData = ({ clientToken, cvId }) =>
  getJson(clientsUrl, {
    'x-cv-id': cvId,
    'x-client-token': clientToken,
  });

export { getClientData }; // eslint-disable-line
