import { action, observable, toJS, computed } from 'mobx';
import {
  getExchangeVoucherData,
  postUserValidation,
  postMaskVouchers,
} from '../modules/exchangeVoucherModule';

class ExchangeVoucherStore {
  constructor({ loginStore, errorHandlerStore }) {
    this.loginStore = loginStore;
    this.errorHandlerStore = errorHandlerStore;
  }

  @observable exchangeVoucherData = null;

  @observable copiedVoucherCode = null;

  @observable validationStatus = null;

  @observable hasError = false;

  @observable showFeedback = false;

  @observable tryAgainModal = false;

  @observable validationLabels = {
    1: 'success',
    2: 'blocked',
    3: 'tryAgain',
    4: 'maskedByTheUser',
  };

  @action getExchangeVoucherData = async () => {
    const header = await this.clientHeader();

    const { data, error } = await getExchangeVoucherData({
      cvId: header['x-cv-id'],
      clientToken: header['x-client-token'],
    });

    if (error) {
      this.errorHandlerStore.setError({
        message: 'Ocorreu um erro ao acessar seus vales-troca',
        errorType: 'error',
      });
      this.setError(true);
    }

    this.setExchangeVoucherData(data);
  };

  @action postUserValidation = async formValues => {
    const header = await this.clientHeader();

    const formData = {
      cpfOuCnpj: formValues.cpfCnpj,
      dataDeNascimento: formValues.birthday,
    };

    const { data, error } = await postUserValidation(formData, {
      cvId: header['x-cv-id'],
      clientToken: header['x-client-token'],
    });

    if (error) {
      this.errorHandlerStore.setError({
        message: 'Ocorreu um erro durante a validação dos dados',
        errorType: 'error',
      });
    } else {
      this.setShowFeedback(true);
      this.setValidationStatus(data);
    }
  };

  @action maskExchangeVouchers = async () => {
    const header = await this.clientHeader();

    const { error } = await postMaskVouchers({
      cvId: header['x-cv-id'],
      clientToken: header['x-client-token'],
    });

    if (error) {
      this.errorHandlerStore.setError({
        message: 'Ocorreu um erro durante o processo de mascarar',
        errorType: 'error',
      });
    } else {
      this.setValidationStatus(4);
    }
  };

  @action setExchangeVoucherData = data => (this.exchangeVoucherData = data);

  @action setCopiedVoucherCode = code => (this.copiedVoucherCode = code);

  @action setValidationStatus = data => {
    this.validationStatus = this.validationLabels[data];
  };

  @action setError = error => (this.hasError = error);

  @action setShowFeedback = bool => (this.showFeedback = bool);

  @computed get exchangeVouchers() {
    return this.exchangeVoucherData?.listaValeTroca;
  }

  @computed get isLoading() {
    return !this.exchangeVoucherData && !this.hasError;
  }

  @computed get isEmpty() {
    return this.exchangeVouchers?.length === 0;
  }

  @computed get isBlockedUser() {
    return this.exchangeVoucherData?.clienteBloqueado;
  }

  @computed get someBlockedVoucher() {
    return this.exchangeVoucherData?.possuiValeBloqueado;
  }

  @computed get isVerified() {
    return !this.exchangeVoucherData?.numerosMascarados;
  }

  @computed get userData() {
    const { nome, tipoUsuario } = JSON.parse(this.loginStore.userData);
    return {
      firstName: nome.split(' ')[0],
      type: tipoUsuario,
    };
  }

  @computed get hasBirthdayRegistered() {
    return this.exchangeVoucherData.possuiData;
  }

  getVoucherDate = exchangeVoucher => {
    const creationDate = this.getDate(exchangeVoucher.criadoEm);
    const expirationDate = this.getDate(exchangeVoucher.dataExpiracao);
    const todayMiliseconds = Date.now();

    const splittedExperationDate = expirationDate.split('/').map(number => parseInt(number, 10));
    const expirationDateMiliseconds = new Date(
      splittedExperationDate[2],
      splittedExperationDate[1],
      splittedExperationDate[0],
    ).getTime();
    const showExpiredTag =
      expirationDateMiliseconds < todayMiliseconds && !exchangeVoucher.bloqueado;

    return {
      creationDate,
      expirationDate,
      showExpiredTag,
    };
  };

  isUsable = exchangeVoucher => {
    return exchangeVoucher.podeUtilizar && this.isVerified;
  };

  getDate = date => date.split(' ')[0];

  clientHeader = async () => {
    return toJS(this.loginStore.clientHeader);
  };
}

export default ExchangeVoucherStore;
