import React, { useRef, createContext, useCallback } from 'react';
import { WalletProviderProps, WalletContextType } from './WalletContext.types';
import { getBalance, getTransactions } from '../../services/wallet';
import { BalanceData, TransactionsData } from '../../services/wallet/wallet.types';

export const WalletContext = createContext<WalletContextType | null>(null);

export const WalletProvider = (props: WalletProviderProps): JSX.Element => {
  const { children } = props;
  const balanceRef = useRef<BalanceData | undefined>(undefined);
  const transactionsRef = useRef<TransactionsData | undefined>(undefined);

  const handleBalanceService = useCallback(async () => {
    try {
      const requestData = await getBalance();
      balanceRef.current = requestData;
    } catch (error) {
      balanceRef.current = undefined;
    }

    return balanceRef.current;
  }, []);

  const handleTransactionsService = useCallback(async () => {
    try {
      const requestData = await getTransactions();
      transactionsRef.current = requestData;
    } catch (error) {
      transactionsRef.current = undefined;
    }

    return transactionsRef.current;
  }, []);

  const getBalanceData = async () => {
    if (balanceRef.current) {
      return balanceRef.current;
    }

    const newRequestData = await handleBalanceService();

    return newRequestData;
  };

  const getTransactionsData = async () => {
    if (transactionsRef.current) {
      return transactionsRef.current;
    }

    const newRequestData = await handleTransactionsService();
    return newRequestData;
  };

  return (
    <WalletContext.Provider value={{ getBalanceData, getTransactionsData }}>
      {children}
    </WalletContext.Provider>
  );
};
