import { localStorage } from '@/utils/browser';

// desenvolvimento
let apiEnvironment = process.env.API_ENV; // prod, qasfix, qas

if (process.env.IS_PR || process.env.NODE_ENV === 'development') {
  apiEnvironment = localStorage().getItem('API_ENV') || process.env.API_ENV;
}

let apiDomainSet = 'https://apigateway.centauro.com.br/ecommerce'; // prod
let apiTicketDomainSet = 'https://api.gruposbf.com.br/ticketscrm/api'; // prd

const domainGatewayQas = 'https://qasapigateway.centauro.com.br/ecommerce';
const domainTicketQas = 'https://api.dev.gruposbf.com.br/ticketscrm/api';

switch (apiEnvironment) {
  case 'dev':
    apiDomainSet = `${domainGatewayQas}/qasfix`; // dev
    apiTicketDomainSet = `${domainTicketQas}`; // dev

    break;

  case 'qas':
    apiDomainSet = `${domainGatewayQas}/qas`; // qas
    break;

  case 'qas2':
    apiDomainSet = `${domainGatewayQas}/qas2`; // qas5
    break;

  case 'qas4':
    apiDomainSet = `${domainGatewayQas}/qas4`; // qas5
    break;

  case 'qas5':
    apiDomainSet = `${domainGatewayQas}/qas5`; // qas5
    break;

  case 'qas6':
    apiDomainSet = `${domainGatewayQas}/qas6`; // qas5
    break;

  case 'qas5fix':
    apiDomainSet = `${domainGatewayQas}/qas5fix`; // qas5fix
    break;

  default:
    apiDomainSet = 'https://apigateway.centauro.com.br/ecommerce'; // prod
    apiTicketDomainSet = 'https://api.gruposbf.com.br/ticketscrm/api'; // prod
}

const centauroDomain = 'https://www.centauro.com.br';

// SSO environment configuration
const ssoEnvironment = process.env.SSO_ENV; //prd, stg, dev, local
let ssoDomain = 'https://login.gruposbf.com.br'; //prd

switch (ssoEnvironment) {
  case 'local':
    ssoDomain = 'http://localhost:8080';
    break;

  case 'dev':
  case 'stg':
    ssoDomain = `https://keycloak.${ssoEnvironment}.gcp.gruposbf.com.br`;
    break;
}

//Feature Toggle Unleash
let unleashProxyUrl = 'https://feature-toggle-proxy.gruposbf.com.br/proxy';
let unleashClientKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnZpcm9ubWVudCI6InByb2R1Y3Rpb24iLCJzdWIiOiJVbmxlYXNoIFByb3h5IiwibmFtZSI6IkVBSSIsImlhdCI6MTUxNjIzOTAyMn0.wlV24CH_KGXwPLQyzOpq-aVlD6IRqdQt9ysJrdtDRI0';
let unleashEnviroment = 'production';
const unleashAppName = 'ecommerce-front';

// Custom Themes
const siteTheme = 'default'; // skins: default, blackfriday, movemonday
let themeTitleSet = '';
let themeURLSet = '';
let themeCouponRulesSet = {};
let useCartAdviseSet = false;
let useCouponAdviseSet = false;
let footerURLSet = '';
let footerTextSet = '';

switch (siteTheme) {
  case 'blackfriday':
    themeTitleSet = 'Black Friday';
    themeURLSet = '/blackfriday';
    useCartAdviseSet = false;
    footerURLSet = '/blackfriday';
    footerTextSet = 'A Black Friday chegou!';
    break;

  case 'movemonday':
    themeTitleSet = 'Move Monday';
    themeURLSet = '/ppc/lst-move-monday';
    useCartAdviseSet = true;
    footerURLSet = '/blackfriday';
    footerTextSet = 'Prepare-se para a Black Friday';
    break;

  default:
    footerTextSet = 'Compre pelo whatsapp';
    footerURLSet = '/sc/whatsapp-loja';
    break;
}

const themeTitle = themeTitleSet;
const themeURL = themeURLSet;
const themeCouponRules = themeCouponRulesSet;
const useCartAdvise = useCartAdviseSet;
const useCouponAdvise = useCouponAdviseSet;
const footerURL = footerURLSet;
const footerText = footerTextSet;
// End Custom Themes

const asyncPriceStackSize = 8;
const neemuDomain = 'https://api.linximpulse.com';
const sentryDSN = process.env.SENTRY_DSN
  ? process.env.SENTRY_DSN
  : 'https://206042097a2246f592625dd4da5613d0@o1066214.ingest.sentry.io/6058795';

const sentryTracingRate = process.env.SENTRY_RATE ? parseFloat(process.env.SENTRY_RATE) : 0.01;

let personalizationIframe = 'https://personaliza.nike.com.br';
let authHeaderSet =
  'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGlnYXRld2F5LmRjLnNibmV0Iiwic3ViIjoid3d3LmNlbnRhdXJvLmNvbS5iciIsImlhdCI6MTUxNjIzOTAyMiwiY2xpZW50X2lkIjoiZTU3Nzk3ZDYtN2Y3Mi00MzMwLWJjOTItOTM3MWRiNjI0YjQ5In0.Mc2PpOJwltFymE3He95TpGTBPDAbhmxNw-cJEsYTghnGefWwhyiD--EopZquF2uH0bdF7K95SkK5RNaZ05Mh3ShuTbqPtD8D7kSr_zQO80nAyIHleLwQzrqrn5GF9piwVSt7YfVDWxj8rNA5HlXQpdTuu0vEUjHQk4hpapB8MtmE1qg9-bymyhD4Hm7x2XHMa-4AtPxhBotPBUwQKWNTbh3OUbCAETknA2tnwfFCSSS3nXot6Icuwx3hvtnkFTk3XitjPwLZ8xTlIJ5Uyk8MjjdG-poMFHKLrdvF7YzhAHUoNn-9Y91jIaYrSA_hDjgb-1y25Jw7UR_lO5F3ceSimg';
let searchApiDomainSet = 'https://middleware-sli.neemu.com';
let bannersApiDomainSet = `${neemuDomain}/engage/b/v1/recommendations`;
let searchURI = `${neemuDomain}/engage/search/v3`;
let googleTagManagerIdSet = 'GTM-RNCS';
let UACodeSet = 'UA-2243231-1';
let GADebugSet = false;
let GAsiteSpeedRateSet = 100;
let GACustomDimensionNameSet = 'dimension10';
let GACustomDimensionValSet = 'spa';
let chaordicApiKeySet = 'centauro-v5';
let chaordicSnippetSet = 'https://static.chaordicsystems.com/static/loader.js';
let chaordicRecommendationsSet = 'https://recs.chaordicsystems.com/v0/pages/recommendations';
let dooAnalyticsSet =
  '//appsbnet-a.akamaihd.net/p/content/javascript/dooanalytics/dooanalytics.min.js?20171121';
let navPathSet = '/nav';
let dataDogTokenSet = 'pub0ecbb5cc3deec831a72694d55219d2fb';
let searchPathSet = '/busca';
let homePathSet = '/';
let cartPathSet = '/checkouts/carrinho';
let thanksPagePathSet = '/checkouts/confirmacao';
let checkoutPathSet = '/checkouts';
let shippingPathSet = '/checkouts/iniciar';
let attendancePathSet = 'https://atendimento.centauro.com.br';
let customerApiSet = 'https://faq.centauro.com.br';
let hotsitePathSet = '/hotsite';
let ppcPathSet = '/ppc';
let exchangesPathSet = '/trocas-e-devolucoes';
let exchangesUraPathSet = '/trocasedevolucoes';
let loginPathSet = '/slogin';
let registerPathSet = '/registro';
let passwordRecoveryPathSet = '/minha-conta/recuperar-senha';
let msgShippingErrorSet = 'Não foi possível calcular o frete. Tente novamente mais tarde.';
let newsletterApiPathSet = '/v4.2/newsletter';
let personaIsActiveSet = true;
let cancellationPathSet = '/cancelamento';
let myOrdersPathSet = '/minha-conta/meus-pedidos';
let myOrderDetailPathSet = `${myOrdersPathSet}/detalhe`;
let exchangeVoucherPathSet = '/minha-conta/vale-troca';
let exchangeVoucherCancellationPathSet = '/minha-conta/vale-troca/cancelamento';
let staticPagePathSet = '/sc';
// TODO: (Gustavo Fonseca) renomear as rotas de serviço quando área logada terminar de ser migrada para SPA
let emailServicePathSet = '/sc/servicos';
let emailServiceSubscriptionPathSet = '/newsletter/cadastrar';
let emailServiceCancellationPathSet = '/newsletter/cancelar';
let promotionPathSet = '/promocao';
let myFavoritesPathSet = '/minha-conta/meus-favoritos';
let myAddressesPathSet = '/minha-conta/meus-enderecos';
let myRegisterPathSet = '/minha-conta/cadastro';
let selfAttendancePathSet = '/sc/cntautoatendimento';
let linkResponsePathSet = '/link-de-pagamento';
let cookiePolicyPathSet = '/sc/politica-de-cookies';
let newsletterConsentPathSet = '/sc/consentimento';
let appPathSet = '/app';
let tagsAPIPathSet = 'https://tags-sbf.azurewebsites.net/';
let registrationFormPathSet = '/marketplace';
let registrationFormCommercialPathSet = '/marketplace-in';
let centauroMovePathSet = '/move';

// Carrinho
let txtSuportPhoneSet = '4004-6006';
let pdpExtensionSet = '.html';

let apiDomainGCP = 'https://api.gruposbf.com.br';
let apiRecommendationAuthSet =
  'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImNlbnRhdXJvIiwibmJmIjoxNjE5MjA5NjgyLCJleHAiOjE5MzQ3NDI0ODIsImlhdCI6MTYxOTIwOTY4Mn0.cvYlrDGo6flnerxnPljibrm9ud5hbHxMiPtksc5AKXk';

// GCP DOMAIN FOR US
let apiDomainGCPUS = 'https://api.gruposbf.com.br/us';

// coupon flag
const couponAdviseConfigSet = {
  couponEnable: false,
  eventTitle: 'Black Friday',
  eventDateStart: '22/11/21',
  eventDateEnd: '02/12/21',
  eventCouponRules: [
    '• Os cupons ficarão inativos do dia {eventDateStart} a {eventDateEnd};',
    '• Eles serão reativados no dia {eventDateEnd};',
    '• Apenas os cupons referentes a Seleção Centauro, continuarão vigentes;',
    '• Os cupons e vales não são cumulativos entre si, não sendo possível a utilização de dois códigos numa única compra;',
    '• O desconto será concedido sobre o valor do produto, excluindo-se o valor do frete. Em nenhuma hipótese será revertido em dinheiro.',
  ],
};

if (process.env.IS_PR || process.env.NODE_ENV === 'development') {
  pdpExtensionSet = '-html';
  /* cSpell:disable */
  authHeaderSet =
    'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGlnYXRld2F5LmRjLnNibmV0Iiwic3ViIjoid3d3LmNlbnRhdXJvLmNvbS5iciIsImlhdCI6MTUxNjIzOTAyMiwiY2xpZW50X2lkIjoiZTU3Nzk3ZDYtN2Y3Mi00MzMwLWJjOTItOTM3MWRiNjI0YjQ5In0.Mc2PpOJwltFymE3He95TpGTBPDAbhmxNw-cJEsYTghnGefWwhyiD--EopZquF2uH0bdF7K95SkK5RNaZ05Mh3ShuTbqPtD8D7kSr_zQO80nAyIHleLwQzrqrn5GF9piwVSt7YfVDWxj8rNA5HlXQpdTuu0vEUjHQk4hpapB8MtmE1qg9-bymyhD4Hm7x2XHMa-4AtPxhBotPBUwQKWNTbh3OUbCAETknA2tnwfFCSSS3nXot6Icuwx3hvtnkFTk3XitjPwLZ8xTlIJ5Uyk8MjjdG-poMFHKLrdvF7YzhAHUoNn-9Y91jIaYrSA_hDjgb-1y25Jw7UR_lO5F3ceSimg';
  /* cSpell:enable */
  // authHeaderSet = 'Basic TW9iaWxlQXBwTTpjN2I1OTJhNg==';
  searchApiDomainSet = 'https://middleware-sli.neemu.com';
  searchURI = `${neemuDomain}/engage/search/v3`;
  bannersApiDomainSet = `${neemuDomain}/engage/b/v1/recommendations`;
  googleTagManagerIdSet = 'GTM-54P5WPD';
  // googleTagManagerIdSet = 'GTM-RNCS';
  UACodeSet = 'UA-2243231-8';
  GADebugSet = false;
  GAsiteSpeedRateSet = 80;
  GACustomDimensionNameSet = 'dimension2';
  GACustomDimensionValSet = 'spa';
  chaordicApiKeySet = 'centauro-v5';
  chaordicSnippetSet = 'https://static.chaordicsystems.com/static/loader.js';
  chaordicRecommendationsSet = 'https://recs.chaordicsystems.com/v0/pages/recommendations';
  dooAnalyticsSet =
    '//appsbnet-a.akamaihd.net/p/content/javascript/dooanalytics/dooanalytics.min.js?20171121';
  searchPathSet = '/busca';
  navPathSet = '/nav';
  homePathSet = '/';
  cartPathSet = '/checkouts/carrinho';
  checkoutPathSet = '/checkouts';
  thanksPagePathSet = '/checkouts/confirmacao';
  shippingPathSet = '/checkouts/iniciar';
  attendancePathSet = 'https://atendimento.centauro.com.br';
  customerApiSet = 'https://faq-lean-commerce.azurewebsites.net';
  hotsitePathSet = '/hotsite';
  ppcPathSet = '/ppc';
  loginPathSet = '/slogin';
  registerPathSet = '/registro';
  passwordRecoveryPathSet = '/minha-conta/recuperar-senha';
  exchangesPathSet = '/trocas-e-devolucoes';
  exchangesUraPathSet = '/trocasedevolucoes';
  msgShippingErrorSet = 'Não foi possível calcular o frete. Tente novamente mais tarde.';
  newsletterApiPathSet = '/v4.2/newsletter';
  personaIsActiveSet = true;
  cancellationPathSet = '/cancelamento';
  myOrdersPathSet = '/minha-conta/meus-pedidos';
  myOrderDetailPathSet = `${myOrdersPathSet}/detalhe`;
  exchangeVoucherPathSet = '/minha-conta/vale-troca';
  exchangeVoucherCancellationPathSet = '/minha-conta/vale-troca/cancelamento';
  staticPagePathSet = '/sc';
  myFavoritesPathSet = '/minha-conta/meus-favoritos';
  myAddressesPathSet = '/minha-conta/meus-enderecos';
  myRegisterPathSet = '/minha-conta/cadastro';
  dataDogTokenSet = 'pub0ecbb5cc3deec831a72694d55219d2fb';
  emailServicePathSet = '/sc/servicos';
  emailServiceSubscriptionPathSet = '/newsletter/cadastrar';
  emailServiceCancellationPathSet = '/newsletter/cancelar';
  selfAttendancePathSet = '/sc/cntautoatendimento';
  promotionPathSet = '/promocao';
  cookiePolicyPathSet = '/sc/politica-de-cookies';
  appPathSet = '/app';
  tagsAPIPathSet = 'https://dev-tags-sbf.azurewebsites.net/';
  registrationFormPathSet = '/marketplace';
  registrationFormCommercialPathSet = '/marketplace-in';
  //Feature Toggle Unleash
  unleashProxyUrl = 'https://development.feature-toggle-proxy.dev.gcp.gruposbf.com.br/proxy';
  unleashClientKey =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJVbmxlYXNoIFByb3h5IiwibmFtZSI6IkVBSSIsImlhdCI6MTUxNjIzOTAyMn0.iuVK05YonI76FQPco51Na-1oQTljiIXOwNcGOrp3CCk';
  unleashEnviroment = 'development';

  // Carrinho
  txtSuportPhoneSet = '4004-6006';

  apiDomainGCP = 'https://api.dev.gruposbf.com.br';
  apiRecommendationAuthSet =
    'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImNlbnRhdXJvIiwibmJmIjoxNjE3MzEwNDUxLCJleHAiOjE5MzI4NDMyNTEsImlhdCI6MTYxNzMxMDQ1MX0.oPdDH8YzwPRK_ioCgntdsORsdH-BXtSgawevqL2SSNg';
  apiDomainGCPUS = 'https://api.dev.gruposbf.com.br';
}

const googleApiKey = 'AIzaSyBYUjfKfbj-K9bcRQdarOj9lxAvUoxmpAs';
const embedMapKey = 'AIzaSyBWYZFHWS2JyxX8au8SPUf-mEwDbPowvE0';
const authHeaderAsyncPrice =
  'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IkZyb250UmVhY3QiLCJuYmYiOjE1NzQxMjgxNTcsImV4cCI6MTg4OTc0NzM1NywiaWF0IjoxNTc0MTI4MTU3fQ.dlp8B0OfZVP3nKehWa4qk2gOJEU3JBBrBMKNg_9lO8A';
const apiDomain = apiDomainSet;
const apiDomainTicket = apiTicketDomainSet;
const vurdereAPIKey = 'ctot2uicyedk2t8sqldvoza';
const vurdereEcommerceID = 'cto';
const searchApiDomain = searchApiDomainSet;
const googleTagManagerId = googleTagManagerIdSet;
const UACode = UACodeSet;
const GADebug = GADebugSet;
const GAsiteSpeedRate = GAsiteSpeedRateSet;
const GACustomDimensionName = GACustomDimensionNameSet;
const GACustomDimensionVal = GACustomDimensionValSet;
const authHeader = authHeaderSet;
const pdpExtension = pdpExtensionSet;
const chaordicApiKey = chaordicApiKeySet;
const chaordicSnippet = chaordicSnippetSet;
const chaordicRecommendations = chaordicRecommendationsSet;
const dooAnalytics = dooAnalyticsSet;
const navPath = navPathSet;
const searchPath = searchPathSet;
const homePath = homePathSet;
const homePathNoCache = '/previewhome';
const cartPath = cartPathSet;
const checkoutPath = checkoutPathSet;
const thanksPagePath = thanksPagePathSet;
const shippingPath = shippingPathSet;
const hotsitePath = hotsitePathSet;
const ppcPath = ppcPathSet;
const exchangesPath = exchangesPathSet;
const loginPath = loginPathSet;
const registerPath = registerPathSet;
const passwordRecoveryPath = passwordRecoveryPathSet;
const exchangesUraPath = exchangesUraPathSet;
const msgShippingError = msgShippingErrorSet;
const newsletterApiPath = newsletterApiPathSet;
const pdpShippingCalcSwitch = true;
const pdpMapSwitch = true;
// se for ativar pdpMobileMapSwitch precisa rever o componente
const pdpMobileMapSwitch = true;
const pdpBannerSwitch = true;
const deskNavGeolocationSwitch = false;
const mobileNavGeolocationSwitch = false;
const personaApiDomain = 'https://centauro-persona-adm.azurewebsites.net';
const personaApiSwitch = personaIsActiveSet;
const cancellationPath = cancellationPathSet;
const myOrdersPath = myOrdersPathSet;
const myOrderDetailPath = myOrderDetailPathSet;
const exchangeDisabled = true;
const exchangeVoucherPath = exchangeVoucherPathSet;
const exchangeVoucherCancellationPath = exchangeVoucherCancellationPathSet;
const staticPagePath = staticPagePathSet;
const productsCatalogPagePath = '/catalogo-de-produtos';
const myFavoritesPath = myFavoritesPathSet;
const centauroMovePath = centauroMovePathSet;
const myAddressesPath = myAddressesPathSet;
const myRegisterPath = myRegisterPathSet;
const dataDogToken = dataDogTokenSet;
const emailServicePath = emailServicePathSet;
const emailServiceSubscriptionPath = emailServiceSubscriptionPathSet;
const emailServiceCancellationPath = emailServiceCancellationPathSet;
const selfAttendancePath = selfAttendancePathSet;
const promotionPath = promotionPathSet;
const linkResponsePath = linkResponsePathSet;
const cookiePolicyPath = cookiePolicyPathSet;
const appPath = appPathSet;
const storesPagePath = '/sc/whatsapp-loja';
const assitedLoginCustomerPath = '/encomenda-expressa/login-cliente';
const assistedCreateCustomerPath = '/encomenda-expressa/cadastro-simplificado';
const moduleConfigAssistedSalePath = '/encomenda-expressa/iniciar';
const assistedSaleLoginSellerPath = '/login-vendedor';
const assistedSaleLoginSellerOutStorePath = '/login-parceiro';
const assistedSaleStoresUrl = `https://firebasestorage.googleapis.com/v0/b/estoque-estendido.appspot.com/o/bundle%2Fstores.json?alt=media`;
const tagsAPIPath = tagsAPIPathSet;
const isRecommendationDisplay = true;
const registrationFormPath = registrationFormPathSet;
const registrationFormCommercialPath = registrationFormCommercialPathSet;
const logoutPath = '/slogout';
const apiRecommendationAuth = apiRecommendationAuthSet;
const newsletterConsentPath = newsletterConsentPathSet;

const dooSwitch = false;

// Customer
const attendancePath = attendancePathSet;
const apiFaqDomain = customerApiSet;
const apiFaqAuth = 'Basic Q2VudGF1cm9GYXFBcGk6clY0SU9zQU94Zg==';

// Wallet
const walletPath = '/minha-conta/carteira';
const walletExtract = '/minha-conta/extrato-carteira';

// Neemu
const neemuApiKey = 'centauro-v5';
const neemuSecretkey = 'LXthFHg1KQcyN1YTgUY3zg==';
const chaordicSecretKey = 'LXthFHg1KQcyN1YTgUY3zg%3D%3D';
const bannersApiDomain = bannersApiDomainSet;

// Nav
const neemuApiDomain = searchURI;
const cannotRedirect = false;
const geoNavABTest = 100;
const searchSbfBaseUrl = 'https://gateway.plataforma.centauro.com.br/yantar/api';
const searchDefaultScoringProfile = 'scoreByMostClicked';
const searchSbfAuth =
  'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImZyb250LWVuZCBjZW50YXVybyIsIm5iZiI6MTU4OTkxOTgxMywiZXhwIjoxOTA1NDUyNjEzLCJpYXQiOjE1ODk5MTk4MTN9.YeCTBYcWozaQb4MnILtfeKTeyCwApNgLSOfGeVVM8D0';

// Carrinho
const txtSuportPhone = txtSuportPhoneSet;
const txtOnlineSupport = 'segunda a sexta, das 8h às 18h';
const cartFixedResumePosition = 100;

// Cupons
const isDiscountCouponAvailable = true;
const isExchangeCouponAvailable = true;
const couponAdviseConfig = couponAdviseConfigSet;
const isCouponsBannersPreBlackFridayEnabled = false;
const discountCouponAppValue = 10;

// Home
const adSenseSnippet = 'https://www.googletagservices.com/tag/js/gpt.js';

// Domains
const authorizedRedirectDomains = [
  '.centauro.com.br',
  '.facebook.com',
  '.appsbnet.com.br',
  '.parceirossbnet.com.br',
];

// AB Tests
const buyBoxAbTest = '100';
const emergencyAlertAbTestPDP = '0';

// Nav/Busca
const freeShippingFrame = 'https://www.google.com.br';
const onlyNavPathFilters = [
  // Filtros que estão presentes apenas no PATH, não retornam do yantar e devem ser mantidos.
  'personalizado',
  'lojas',
];

// Login Social
const facebookAppId = '762171213909880';
const googleClientId = '906233044774-orlqksbdi6fit45ta5o7fd7uiep9baqf.apps.googleusercontent.com';

// Sac
const sacPhone = '(11) 2322-3730';

// Recuperar senha - ajuda
const whatsapp = 'https://api.whatsapp.com/send?phone=551140048008&text=&source=&data=';

const emergencyAlert = false;
const urlEmergency = '/sc/de-olho-coronavirus';
const emergencyTitle = 'Coronavírus.';
const emergencyLinkText = 'CONFIRA OS NOVOS HORÁRIOS DE ATENDIMENTO';
const emergencyInformation = 'COVID-19 - Informações';
const emergencyTagContent = 'OPERAÇÃO COVID-19';

// Cancelamento
const financialEmail =
  'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=financeiro@centauro.com.br';

// Smart banner
const smartBannerSwitch = true;
const smartBannerDelayRepeat = '7'; // days
const contentSmartBanner = {
  textTop:
    siteTheme !== 'blackfriday' ? 'Ganhe 10% OFF - Cupom: 10APP' : 'Tem Black Friday rolando ',
  textBottom:
    siteTheme !== 'blackfriday' ? 'Na sua primeira compra no App' : 'no app também! Confira',
  smartBannerClosed: 'smart_banner_closed',
  appStoreLink: 'https://click.centauro.com.br/13Nl/7303af83',
};

// Banner aceite do uso de cookie
const showLGPDBanner = true;
const cookieLGPD = 'aceite-uso-cookie';
const expireCookie = 365;

// Banner Popover Centauro Move
const cookieMovePopover = 'move-banner-cookie';
const expireMovePopover = 7;

// Favorite button
const showFavoriteButton = true;

// Natal
const isXmasFreightWarningVisible = false;

// ShowChaordic
const isShowChaordic = true;

// Novo link Busca CEP
const cepSearchLink = 'https://buscacepinter.correios.com.br/app/endereco/index.php';

// Recomendation API SBF
const apiRecommendationDomain = `${apiDomainGCP}/recommendation`;

// Test Search & Recommendation Filter Pickup
const isFilterPickupTestEnabled = true;

//Pickup on store discount
const isPickupDiscountAvailable = true;

export {
  apiDomain,
  apiDomainTicket,
  ssoDomain,
  authHeader,
  authHeaderAsyncPrice,
  googleApiKey,
  embedMapKey,
  UACode,
  GADebug,
  vurdereAPIKey,
  vurdereEcommerceID,
  searchApiDomain,
  neemuApiDomain,
  googleTagManagerId,
  GAsiteSpeedRate,
  GACustomDimensionName,
  GACustomDimensionVal,
  pdpExtension,
  chaordicApiKey,
  chaordicSnippet,
  dooAnalytics,
  searchPath,
  navPath,
  homePath,
  homePathNoCache,
  cartPath,
  attendancePath,
  apiFaqDomain,
  apiFaqAuth,
  checkoutPath,
  thanksPagePath,
  shippingPath,
  hotsitePath,
  ppcPath,
  loginPath,
  registerPath,
  passwordRecoveryPath,
  msgShippingError,
  newsletterApiPath,
  txtSuportPhone,
  txtOnlineSupport,
  cartFixedResumePosition,
  isDiscountCouponAvailable,
  isExchangeCouponAvailable,
  couponAdviseConfig,
  isCouponsBannersPreBlackFridayEnabled,
  discountCouponAppValue,
  pdpMapSwitch,
  pdpMobileMapSwitch,
  pdpShippingCalcSwitch,
  pdpBannerSwitch,
  deskNavGeolocationSwitch,
  mobileNavGeolocationSwitch,
  authorizedRedirectDomains,
  apiEnvironment,
  adSenseSnippet,
  neemuApiKey,
  neemuSecretkey,
  chaordicSecretKey,
  cannotRedirect,
  personaApiDomain,
  personaApiSwitch,
  freeShippingFrame,
  onlyNavPathFilters,
  asyncPriceStackSize,
  bannersApiDomain,
  neemuDomain,
  exchangesPath,
  exchangesUraPath,
  dooSwitch,
  siteTheme,
  themeTitle,
  smartBannerSwitch,
  smartBannerDelayRepeat,
  buyBoxAbTest,
  emergencyAlertAbTestPDP,
  facebookAppId,
  googleClientId,
  geoNavABTest,
  themeURL,
  themeCouponRules,
  useCartAdvise,
  useCouponAdvise,
  footerURL,
  footerText,
  chaordicRecommendations,
  sacPhone,
  whatsapp,
  emergencyAlert,
  urlEmergency,
  emergencyTitle,
  emergencyLinkText,
  emergencyInformation,
  emergencyTagContent,
  centauroDomain,
  searchSbfAuth,
  searchSbfBaseUrl,
  searchDefaultScoringProfile,
  cancellationPath,
  financialEmail,
  myOrdersPath,
  myOrderDetailPath,
  exchangeDisabled,
  exchangeVoucherPath,
  exchangeVoucherCancellationPath,
  staticPagePath,
  productsCatalogPagePath,
  myFavoritesPath,
  centauroMovePath,
  myAddressesPath,
  myRegisterPath,
  dataDogToken,
  emailServicePath,
  emailServiceSubscriptionPath,
  emailServiceCancellationPath,
  promotionPath,
  contentSmartBanner,
  selfAttendancePath,
  cookieLGPD,
  expireCookie,
  expireMovePopover,
  cookieMovePopover,
  showLGPDBanner,
  linkResponsePath,
  showFavoriteButton,
  cookiePolicyPath,
  appPath,
  isXmasFreightWarningVisible,
  isRecommendationDisplay,
  cepSearchLink,
  isShowChaordic,
  storesPagePath,
  assitedLoginCustomerPath,
  assistedCreateCustomerPath,
  moduleConfigAssistedSalePath,
  tagsAPIPath,
  apiRecommendationDomain,
  apiRecommendationAuth,
  registrationFormPath,
  registrationFormCommercialPath,
  assistedSaleLoginSellerPath,
  logoutPath,
  assistedSaleLoginSellerOutStorePath,
  assistedSaleStoresUrl,
  apiDomainGCP,
  apiDomainGCPUS,
  isFilterPickupTestEnabled,
  sentryDSN,
  sentryTracingRate,
  newsletterConsentPath,
  unleashProxyUrl,
  unleashClientKey,
  unleashEnviroment,
  unleashAppName,
  isPickupDiscountAvailable,
  personalizationIframe,
  walletPath,
  walletExtract,
};
