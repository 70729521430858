import { toJS } from 'mobx';
import { getPersonalizationPrice } from '@/components/pdp/personalization/personalization.ts';

const CustomProductServiceSeller = ({ customObjectSeller, customizeType }) => {
  const generateCustomObject = () => {
    const customObject = {
      valorPersonalizacao: JSON.stringify(customObjectSeller),
      tipoPersonalizacao: customizeType,
      precoPersonalizacao: getPersonalizationPrice(customObjectSeller),
    };

    return toJS(customObject);
  };

  return {
    generateCustomObject,
  };
};

export default CustomProductServiceSeller;
