import { action, observable, toJS, computed } from 'mobx';
import {
  getExchangeVoucherData,
  createDataOrbium,
} from '../modules/exchangeVoucherCancellationModule';
import { sendCESAnswer } from '../modules/apiCommons';

import { objectIsEmpty } from '../utils/utils';

class ExchangeVoucherCancellationStore {
  constructor({ loginStore, errorHandlerStore }) {
    this.loginStore = loginStore;
    this.errorHandlerStore = errorHandlerStore;
  }

  addErrorMessage = (message, type) => {
    this.errorHandlerStore.setError({
      message,
      type,
    });
  };

  @observable loading = false;

  @observable bankDataObj = {
    banco: '',
    agencia: '',
    conta: '',
    nome: '',
    cpf: '',
  };

  @observable countType = '';

  @observable exchangeVoucher = {};

  @observable hasError = false;

  @observable hashExchangeVoucher = '';

  @observable requestError = false;

  @observable exchangeVoucherCompleted = false;

  @observable orbiumProtocol = null;

  @observable exchangeVoucherRating = null;

  @observable exchangeVoucherComment = '';

  @observable uniqueProtocol = null;

  @observable reasonCancellation = null;

  @observable observation = '';

  @action getExchangeVoucher = async () => {
    this.setLoading(true);

    const header = await this.clientHeader();

    const { data, error } = await getExchangeVoucherData(this.hashExchangeVoucher, {
      cvId: header['x-cv-id'],
      clientToken: header['x-client-token'],
    });

    this.setLoading(false);

    if (error) {
      this.addErrorMessage('Ocorreu um erro ao tentar acessar seu vale-troca', 'error');
      this.setHasError(error);
    } else {
      this.setExchangeVoucher(data);
    }
  };

  @action sendOrbiumRequest = async () => {
    const objData = {
      hash: this.hashExchangeVoucher,
      motivoCancelamento: this.reasonCancellation,
      observacao: this.observation,
      ...this.bankDataObj,
      tipoConta: this.countType,
    };

    const header = await this.clientHeader();

    this.setLoading(true);

    const { data, error } = await createDataOrbium(objData, {
      cvId: header['x-cv-id'],
      clientToken: header['x-client-token'],
    });

    this.setLoading(false);

    if (error) {
      this.setOrbiumProtocol(null);
      this.setRequestError(true);
    } else {
      this.setOrbiumProtocol(data);
      this.setRequestError(false);
      this.setExchangeVoucherCompleted(true);
    }
  };

  @action sendExchangeVoucherCES = async () => {
    const objCES = {
      PedidoId: this.hashExchangeVoucher,
      Nota: this.exchangeVoucherRating,
      Comentario: this.exchangeVoucherComment,
      Protocolo: this.uniqueProtocol,
      Origem: 'cancelamentovt',
      Campanha: 'ces',
    };

    const header = await this.clientHeader();

    sendCESAnswer(objCES, {
      cvId: header['x-cv-id'],
      clientToken: header['x-client-token'],
    });
  };

  @action setUniqueProtocol = protocol => (this.uniqueProtocol = protocol);

  @action setLoading = loadingState => (this.loading = loadingState);

  @action setExchangeVoucher = data => (this.exchangeVoucher = data);

  @action setHasError = error => (this.hasError = error);

  @action setExchangeVoucherCompleted = value => (this.exchangeVoucherCompleted = value);

  @action setOrbiumProtocol = number => (this.orbiumProtocol = number);

  @action setRequestError = error => (this.requestError = error);

  @action addBankDataObj = ({ target, value }) => {
    const bankDataObj = toJS(this.bankDataObj);

    this.bankDataObj = {
      ...bankDataObj,
      [target]: value,
    };
  };

  @action setCountType = type => (this.countType = type);

  @action setHashExchangeVoucher = hash => (this.hashExchangeVoucher = hash);

  @action setExchangeVoucherRating = rating => (this.exchangeVoucherRating = rating);

  @action setExchangeVoucherComment = ({ target }) => {
    this.exchangeVoucherComment = target.value;
  };

  @action setReasonCancellation = idReason => {
    if (idReason !== this.reasonCancellation) {
      this.reasonCancellation = idReason;
      this.observation = '';
    }
  };

  @action setObservation = ({ target }) => (this.observation = target.value);

  @computed get blockedExchangeVoucher() {
    return objectIsEmpty(this.exchangeVoucher);
  }

  @computed get exchangeInCancellation() {
    return this.exchangeVoucher?.protocolos?.length !== 0;
  }

  clientHeader = async () => {
    return toJS(this.loginStore.clientHeader);
  };
}

export default ExchangeVoucherCancellationStore;
