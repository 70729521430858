import React from 'react';
import PropTypes from 'prop-types';

const EyeIcon = ({ innerFill, width, height, styling }) => (
  <svg
    className={styling}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 10"
    style={{ width: 16, height: 10 }}
  >
    <path
      fill={innerFill}
      fillRule="nonzero"
      d="M8 0C4.51 0 1.486 2.018 0 4.966 1.486 7.913 4.51 9.93 8 9.93s6.514-2.018 8-4.965C14.514 2.018 11.49 0 8 0zm3.945 2.633a7.582 7.582 0 0 1 2.335 2.333 7.582 7.582 0 0 1-2.335 2.332A7.36 7.36 0 0 1 8 8.44a7.36 7.36 0 0 1-3.945-1.143 7.583 7.583 0 0 1-2.334-2.332A7.581 7.581 0 0 1 4.24 2.52C4.085 2.944 4 3.402 4 3.88c0 2.193 1.79 3.972 4 3.972s4-1.779 4-3.973c0-.477-.085-.935-.24-1.359l.185.113zM8 3.383c0 .822-.672 1.49-1.5 1.49S5 4.204 5 3.382c0-.823.672-1.49 1.5-1.49S8 2.56 8 3.383z"
    />
  </svg>
);

EyeIcon.propTypes = {
  styling: PropTypes.string,
  innerFill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

EyeIcon.defaultProps = {
  styling: '',
  innerFill: '#CDCDCD',
  width: '16',
  height: '10',
};

export default EyeIcon;
