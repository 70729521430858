const ACTIVE_EXPERIMENTS_STRING_KEY = 'optimizeActiveExperiments';

/**
 * Returns the current value associated with the given key,
 * or null if the given key does not exist
 *
 * @param {any} storage - The sessionStorage
 * @param {string} storageKey - The name of the active experiments string key
 */

export const getStorageActiveExperiments = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  storage: any = window.sessionStorage,
  storageKey: string = ACTIVE_EXPERIMENTS_STRING_KEY,
) => {
  const storedData = storage.getItem(storageKey);
  return storedData ? JSON.parse(storedData).experiments : [];
};

/**
 * When passed a experiment, will add that key to storage,
 * or update that experiment if it already exists.
 *
 * @param {any} storage - The sessionStorage
 * @param {string} storageKey - The name of the active experiments string key
 * @param {string} experiments - The name of experiments
 */

export const setStoreActiveExperiments = (
  experiments: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  storage: any = window.sessionStorage,
  storageKey: string = ACTIVE_EXPERIMENTS_STRING_KEY,
) => {
  storage.setItem(storageKey, JSON.stringify(experiments));
};
