import { getCookie, setCookie } from './utils';

import { setDimension } from './Analytics';

const getRandNumber = () => Math.floor(Math.random() * 100) + 1;

const setABTest = (dimensionIndex, cookieName, percentage) => {
  if (getCookie(cookieName)) {
    setDimension(dimensionIndex, getCookie(cookieName));
    return getCookie(cookieName);
  }

  const newTest = getRandNumber();
  let cookieVal = '';

  if (newTest <= percentage) {
    cookieVal = 'piloto';
  } else if (newTest <= percentage * 2) {
    cookieVal = 'controle';
  } else {
    cookieVal = 'fora do teste';
  }

  setDimension(dimensionIndex, cookieVal);
  setCookie(cookieName, cookieVal, 7);

  return cookieVal;
};

/**
 * dimensionIndex: Talk with your team about which one they use, it's common to use something like `dimension40`.
 * cookieName: A cookie name to be used to store which variation user was sorted. Something like: `anScoringProfile`
 * percentage: A % of chance for user to be sorted on that variant, it's strongly related to number of cases.
     Examples, considering, obviously, an amount of 100%:
      - percentage 25, number of cases 3: We divide it in 4 parts (3 number of test cases given + control) with 25% of chance in each one.
      - percentage 33, number of cases 3: Same as percentage 25, we truncate percent using number of tests + control (in this example, 4).
      - percentage 20, number of cases 3: 20% of chance for each test case (including control) + 20% of users out of tests.
      - percentage 20, number of cases 2: 20% of chance for each test case (including control) + 40% of users out of tests.
   numberOfCases: Number of test variants (not including control).
 */
const setANTest = (dimensionIndex, cookieName, percentage, numberOfCases) => {
  if (getCookie(cookieName)) {
    setDimension(dimensionIndex, getCookie(cookieName));
    return getCookie(cookieName);
  }

  const numberOfCasesWithControl = numberOfCases + 1;
  const newTest = getRandNumber();

  let cookieVal = '';
  let maxPercentagePerTest = 0;
  let outOfTestPercentage = 101;
  let isControl = false;

  if (numberOfCasesWithControl * percentage > 100) {
    maxPercentagePerTest = 100 / numberOfCasesWithControl;
    isControl = newTest > numberOfCases * maxPercentagePerTest;
  } else {
    maxPercentagePerTest = percentage;
    outOfTestPercentage = numberOfCasesWithControl * percentage;
    isControl =
      newTest > numberOfCases * percentage && newTest <= numberOfCasesWithControl * percentage;
  }

  if (newTest > outOfTestPercentage) {
    cookieVal = 'fora do teste';
  } else if (isControl) {
    cookieVal = 'controle';
  } else {
    cookieVal = `piloto${Math.trunc((newTest - 1) / maxPercentagePerTest)}`;
  }

  setDimension(dimensionIndex, cookieVal);
  setCookie(cookieName, cookieVal, 7);

  return cookieVal;
};

const setABTestFromBackEnd = (dimensionIndex, cookieName, cookieVal) => {
  if (getCookie(cookieName)) {
    setDimension(dimensionIndex, getCookie(cookieName));
  }

  setDimension(dimensionIndex, cookieVal);
  setCookie(cookieName, cookieVal, 7);
};

export { setABTest, getRandNumber, setANTest, setABTestFromBackEnd };
