import { getJson } from './httpClient';
import AppStore from '@/stores/ApplicationStore';
import { apiDomainGCP } from '../settings';
import { getCookie } from '@/utils/utils';
import { isSPA } from '@/helpers/ssrHelper';

interface ProfileType {
  name: string;
  cpf: string;
  activatedIn: Date;
  status: 'FIT' | 'NOT_FIT' | 'MEMBER';
  communicationOptIn: boolean;
  wallet?: boolean;
}

type MoveStatusType = 'LOGGED_OUT' | 'NOT_FIT' | 'FIT' | 'MEMBER';

const getMemberProfile = async (): Promise<ProfileType | undefined> => {
  const isLogged = isSPA && getCookie('siteMetadata.sessaoInfo.usuarioInfo');

  if (isLogged) {
    try {
      const response = await getJson<ProfileType>({
        url: `${apiDomainGCP}/membership/members/profile?communication=true`,
        secondaryHeader: {
          'x-client-token': AppStore.LoginStore.clientHeader['x-client-token'],
        },
      });

      return response.data;
    } catch (error) {
      return undefined;
    }
  }
};

const getMoveType = async (): Promise<MoveStatusType | undefined> => {
  const isLogged = isSPA && getCookie('siteMetadata.sessaoInfo.usuarioInfo');

  if (isLogged) {
    const data = await getMemberProfile();
    if (data?.status) {
      return data?.status;
    }
    return undefined;
  }
  return 'LOGGED_OUT';
};

export { getMemberProfile, getMoveType };
