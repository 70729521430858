import { getJson, postJson } from '@/modules/httpClient';
import { apiDomain } from '@/settings';
import { deviceId } from '@/utils/utils';
import { CustomerData, CepData, PhysicalBody, JuridicalBody } from './registerModule.d';

const POST_CODE_BASE_URL = `${apiDomain}/v2/consultacep`;
const CPF_VALIDATE_URL = `${apiDomain}/v2/validacao/cpf`;
const CNPJ_VALIDATE_URL = `${apiDomain}/v2/validacao/cnpj`;
const EMAIL_VALIDATE_URL = `${apiDomain}/v2/validacao/email`;
const REGISTER_URL = `${apiDomain}/v5.1/clientes`;

const getPostCodeData = (postCode: string) =>
  getJson<CepData>({ url: `${POST_CODE_BASE_URL}/${postCode}` });

const validateCpf = (cpf: string) => postJson({ url: CPF_VALIDATE_URL, body: { cpf } });

const validateCnpj = (cnpj: string) => postJson({ url: CNPJ_VALIDATE_URL, body: { cnpj } });

const validateEmail = (email: string) => postJson({ url: EMAIL_VALIDATE_URL, body: { email } });

const physicalRegister = (body: PhysicalBody) =>
  postJson<CustomerData>({
    body,
    url: REGISTER_URL,
    secondaryHeader: { 'x-cv-id': deviceId },
  });

const juridicalRegister = (body: JuridicalBody) =>
  postJson<CustomerData>({
    body,
    url: REGISTER_URL,
    secondaryHeader: { 'x-cv-id': deviceId },
  });

export {
  getPostCodeData,
  validateCpf,
  validateCnpj,
  validateEmail,
  physicalRegister,
  juridicalRegister,
};
