import { isBrowserEnv, isTestEnv } from '@/utils/env';

export type DataLayerEventName =
  | 'changeRoute'
  | 'ProdutoAdicionado'
  | 'ProdutoRemovido'
  | 'ProcessPayment'
  | 'optimize.callback'
  | 'optimize.activate'
  | 'optimize.activatePDPFreight'
  | 'optimize.activatePDPPersonalizationHightlight';

interface EventData {
  category?: string;
  action?: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
}

interface CardData {
  isCustomizedProductOnCart?: boolean;
}

interface ProductData extends CardData {
  eventSku?: string;
  eventSkuPrice?: string;
  couponCode?: string;
  isCustomizableProductPDP?: boolean;
}

interface TransactionProduct {
  sku: string;
  name: string;
  category?: string;
  price: string;
  quantity: string;
}

export interface DataLayerObject extends EventData {
  event: DataLayerEventName;
}

export interface EcommerceObject extends DataLayerObject, ProductData {
  transactionId?: string;
  transactionTotal?: string;
  transactionAffiliation?: string;
  transactionShipping?: string;
  transactionTax?: string;
  transactionCurrency?: string;
  transactionProducts?: TransactionProduct[];
}

export function sendToGTM(event: EcommerceObject) {
  const willNotHaveDataLayerDefined = !isBrowserEnv && !isTestEnv;
  if (willNotHaveDataLayerDefined) {
    console.error('[GTM] dataLayer is undefined');
    return;
  }
  window.newDataLayer = window.newDataLayer || [];
  window.newDataLayer.push(event);
}
