import LoginStore from './LoginStore';
import SocialLoginStore from './SocialLoginStore';
import CartStore from './CartStore';
import SearchStore from './SearchStore';
import MenuStore from './MenuStore';
import FavoriteStore from './FavoriteStore';
import MobileHomeStore from './MobileHomeStore';
import DeskHomeStore from './DesktopHomeStore';
import NavStore from './NavStore';
import GeolocationStore from './GeolocationStore';
import ProductStore from './ProductStore';
import CheckoutStore from './CheckoutStore';
import GeneralStore from './GeneralStore';
import CustomerStore from './CustomerStore';
import ExchangesStore from './ExchangesStore';
import ErrorHandlerStore from './ErrorHandlerStore';
import CancellationStore from './CancellationStore';
import ExchangeVoucherStore from './ExchangeVoucherStore';
import ExchangeVoucherCancellationStore from './ExchangeVoucherCancellationStore';
import EmailServicesStore from './EmailServicesStore';
import HeaderStore from './HeaderStore';
import SessionSellerStore from './SessionSellerStore';
import SellerSignUpStore from './SellerSignUpStore';

export class ApplicationStore {
  constructor() {
    this.ErrorHandlerStore = new ErrorHandlerStore();

    this.HeaderStore = new HeaderStore();

    this.LoginStore = new LoginStore({ headerStore: this.HeaderStore }, this);

    this.SocialLoginStore = new SocialLoginStore({ loginStore: this.LoginStore });

    this.CartStore = new CartStore(this.LoginStore, this);

    this.SearchStore = new SearchStore();

    this.MenuStore = new MenuStore();

    this.FavoriteStore = new FavoriteStore();

    this.MobileHomeStore = new MobileHomeStore();

    this.DeskHomeStore = new DeskHomeStore();

    this.NavStore = new NavStore();

    this.GeolocationStore = new GeolocationStore();

    this.ProductStore = new ProductStore();

    this.SessionSellerStore = new SessionSellerStore();

    this.CheckoutStore = new CheckoutStore({
      loginStore: this.LoginStore,
      sessionSellerStore: this.SessionSellerStore,
      errorHandlerStore: this.ErrorHandlerStore,
      cartStore: this.CartStore,
    });

    this.GeneralStore = new GeneralStore();

    this.CustomerStore = new CustomerStore();

    this.ExchangesStore = new ExchangesStore({
      loginStore: this.LoginStore,
      errorHandlerStore: this.ErrorHandlerStore,
    });

    this.CancellationStore = new CancellationStore({
      loginStore: this.LoginStore,
      errorHandlerStore: this.ErrorHandlerStore,
    });

    this.ExchangeVoucherStore = new ExchangeVoucherStore({
      loginStore: this.LoginStore,
      errorHandlerStore: this.ErrorHandlerStore,
    });

    this.ExchangeVoucherCancellationStore = new ExchangeVoucherCancellationStore({
      loginStore: this.LoginStore,
      errorHandlerStore: this.ErrorHandlerStore,
    });

    this.EmailServicesStore = new EmailServicesStore({
      errorHandlerStore: this.ErrorHandlerStore,
    });

    this.SellerSignUpStore = new SellerSignUpStore({
      errorHandlerStore: this.ErrorHandlerStore,
    });
  }
}

const store = new ApplicationStore();

store.LoginStore.setUserLogged();
store.LoginStore.getUserLogged();

export default store;
