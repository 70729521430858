import { configure, observable, action, toJS } from 'mobx';
import favoriteModule from '../modules/favoriteModule';

configure({ enforceActions: 'observed' });

class FavoriteStore {
  @observable currentModel = {
    modelo: '',
    cor: '',
    produtoFavoritoId: '',
  };

  @observable favoriteList = [];

  @observable isFavorite = false;

  @observable errorPool = [];

  @observable pendingRequest = false;

  @observable showFavoritedProduct = false;

  @observable favoriteProductObj = {
    name: '',
    urlImage: '',
  };

  listPending = false;

  @action setShowFavoritedProduct = value => (this.showFavoritedProduct = value);

  @action setFavoriteList = data => {
    this.favoriteList = data;
  };

  @action setFavorite = bool => {
    this.isFavorite = bool;
  };

  @action addErrorToPool = err => {
    this.errorPool.push(err);
  };

  @action updatePendingTo = bool => {
    this.pendingRequest = bool;
  };

  @action setCurrentModel = (modelIdStr, colorStr, ProdId) => {
    this.currentModel = {
      modelo: modelIdStr,
      cor: colorStr,
      produtoFavoritoId: ProdId,
    };
  };

  @action setFavoriteProductObj = (name, urlImage) => {
    this.favoriteProductObj = {
      name,
      urlImage,
    };
  };

  @action setAsUserFavorite = async (
    clientHeader,
    modelIdString,
    colorString,
    nameProduct,
    urlImageProduct,
  ) => {
    this.updatePendingTo(true);
    this.setCurrentModel(modelIdString, colorString, '');
    this.listPending = false;

    const { data, error } = await favoriteModule.setFavorite(toJS(this.currentModel), clientHeader);

    if (error) {
      this.addErrorToPool(error);
      return;
    }

    this.checkIfIsUserFavorite(clientHeader, modelIdString, colorString);
    this.setCurrentModel(modelIdString, colorString, data.produtoFavoritoId);
    this.setFavorite(true);
    this.setShowFavoritedProduct(true);
    this.updatePendingTo(false);
    this.setFavoriteProductObj(nameProduct, urlImageProduct);

    setTimeout(() => {
      this.setShowFavoritedProduct(false);
    }, 4000);
  };

  @action unsetAsUserFavorite = async (productId, modelIdString, colorSelected, clientHeader) => {
    const oldValue = this.isFavorite;
    this.listPending = false;

    const { status, error } = await favoriteModule.deleteFavorite(productId, clientHeader);

    if (error) {
      this.addErrorToPool(error);
      this.setFavorite(oldValue);
      this.updatePendingTo(false);
      return;
    }

    if (status === 204) {
      this.checkIfIsUserFavorite(clientHeader, modelIdString, colorSelected);
      this.setCurrentModel('', '', '');
      this.setFavorite(false);
    }

    this.updatePendingTo(false);
  };

  @action getFavoriteListLenght = async clientHeader => {
    const { data, error } = await favoriteModule.getFavoriteList(clientHeader);

    if (error || !data || data.error) {
      return 0;
    }

    this.setFavoriteList(data);
    return data.length;
  };

  @action checkIfIsUserFavorite = async (clientHeader, modelIdString, colorString) => {
    this.updatePendingTo(true);
    const oldValue = this.isFavorite;

    if (!this.listPending) {
      this.listPending = true;
      setTimeout(() => (this.listPending = false), 10000);

      const { data, error } = await favoriteModule.getFavoriteList(clientHeader);

      if (error) {
        this.addErrorToPool(error);
        this.setFavorite(oldValue);
        this.updatePendingTo(false);

        return;
      }

      if (data && !data.error) {
        this.setFavoriteList(data);
      }
    }

    if (this.isThisProdFavorite(modelIdString, colorString)) {
      this.setFavorite(true);
    } else {
      this.setCurrentModel('', '', '');
      this.setFavorite(false);
    }

    this.updatePendingTo(false);
  };

  isThisProdFavorite = (modelIdString, colorString) => {
    const arrLen = this.favoriteList.slice().length;
    let isFav = false;
    for (let i = 0; i < arrLen; i += 1) {
      if (
        this.favoriteList[i].modelo === modelIdString &&
        this.favoriteList[i].cor === colorString
      ) {
        this.setCurrentModel(
          this.favoriteList[i].modelo,
          this.favoriteList[i].cor,
          this.favoriteList[i].produtoFavoritoId,
        );
        isFav = true;
        break;
      }
    }

    return isFav;
  };
}

export default FavoriteStore;
