import { StyleSheet } from 'aphrodite';

import { grayA, grayC, grayD, blackB } from '../../../common/colors';

const styles = StyleSheet.create({
  modalStyle: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    padding: '0',
    WebkitOverflowScrolling: 'touch',
    overflowScrolling: 'touch',
    scrollBehavior: 'smooth',
    '@media (min-width:767px)': {
      height: 'initial',
      width: '688px',
      color: blackB,
    },
  },
  lightBoxHeader: {
    padding: '24px',
    textAlign: 'left',
    borderBottom: `2px solid ${grayD}`,
  },
  lightBoxTitle: {
    color: grayA,
    fontSize: '16px',
    '@media (min-width:767px)': {
      fontSize: '20px',
      color: blackB,
    },
  },
  lightBoxBody: {
    color: grayC,
    padding: '26px',
    textAlign: 'left',
    fontSize: '14px',
    lineHeight: '21px',
    '@media (min-width:767px)': {
      padding: '24px 32px 38px 32px',
    },
  },
  termsText: {
    margin: '8px auto',
    fontSize: '14px',
    lineHeight: '1.29',
    letterSpacing: '0.25px',
    '@media (min-width:767px)': {
      textAlign: 'center',
      fontSize: '16px',
      color: blackB,
      width: '50%',
    },
  },
  termsTitle: {
    textTransform: 'uppercase',
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: '400',
  },
  bold: {
    fontWeight: '700',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: '22px',
    marginBottom: '44px',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  picText: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '1.33',
    letterSpacing: '0.21px',
    textAlign: 'center',
    color: grayA,
    marginTop: '8px',
    '@media (min-width:767px)': {
      fontSize: '14px',
      color: blackB,
    },
  },
  trocaSite: {
    height: '55px',
    width: '64px',
    color: grayA,
  },
  link: {
    color: grayA,
    '@media (min-width:767px)': {
      color: blackB,
    },
  },
  termsDetails: {
    marginTop: '44px',
    fontSize: '12px',
    lineHeight: '1.33',
    letterSpacing: '0.21px',
    '@media (min-width:767px)': {
      textAlign: 'center',
      color: blackB,
    },
  },
});

export default styles;
