import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getStorageActiveExperiments } from '@/utils/googleOptimize';

export const ExperimentsWidgetToggle = styled.button`
  ${({ theme }) => css`
    width: ${theme.spaces[800]};
    height: ${theme.spaces[800]};
    background-color: ${theme.colors.neutral[300]};
    position: fixed;
    bottom: 0;
    right: 90rem;
    z-index: 9999;
    border-top-right-radius: ${theme.spaces[100]};
    border-top-left-radius: ${theme.spaces[100]};
    cursor: pointer;
    border: 2px solid #55d535;
    border-bottom: 0;
  `}
`;

export const ExperimentsWidgetWrapper = styled.div`
  ${({ theme }) => css`
    position: fixed;
    right: ${theme.spaces[100]};
    bottom: 0;
    background: ${theme.colors.neutral[300]};
    border-radius: ${theme.borderRadius.small};
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    z-index: 3002;
    transition: opacity 0.2s ease-out;
    max-width: 328px;
  `}
`;

export const ExperimentsWidgetHeader = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spaces[300]};
  `}
`;

export const ExperimentsWidgetContainer = styled.section`
  ${({ theme }) => css`
    padding: ${theme.spaces[200]} ${theme.spaces[300]};
    max-height: calc(90vh - 100px);
    overflow: auto;
  `}
`;

export const ExperimentsWidgetListContent = styled.div`
  ${({ theme, index }) => css`
    margin-top: ${index ? theme.spaces[300] : '0'};
    padding-top: ${index ? theme.spaces[300] : '0'};
    border-top: ${index ? '2px solid #55d535' : '0'};
  `}
`;

export const ExperimentsWidgetList = styled.ul`
  ${({ theme }) => css`
    padding-left: ${theme.spaces[200]};
  `}
`;

export const ExperimentsWidgetListItem = styled.li`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: ${theme.spaces[200]};
  `}
`;
export default function ExperimentsWidget({
  activeExperiments,
  availableExperiments,
  saveExperimentsOnContext,
  resetExperimentsOnContext,
}) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const persistedExperiments = getStorageActiveExperiments();
    if (persistedExperiments) {
      persistedExperiments.forEach(({ experimentId, variant }) => {
        saveExperimentsOnContext(variant, experimentId);
      });
    }
  }, [saveExperimentsOnContext]);

  return (
    <>
      {visible && (
        <ExperimentsWidgetWrapper>
          <ExperimentsWidgetHeader>
            <p>Experiments</p>
            <button
              title="Fechar widget"
              type="button"
              tabIndex="0"
              onClick={() => setVisible(false)}
            >
              x
            </button>
          </ExperimentsWidgetHeader>

          <ExperimentsWidgetContainer
            tag="section"
            paddingHorizontal="4x"
            paddingBottom="6x"
            paddingTop="2x"
            maxHeight="calc(90vh - 100px)"
            overflow="auto"
          >
            {availableExperiments.map(({ id, variants, name }, index) => {
              const variantsArray = Array.isArray(variants) ? variants : [variants];
              const experimentId = `experiment-${id}`;

              return (
                <ExperimentsWidgetListContent index={index} key={experimentId}>
                  <p>{name}</p>
                  <p>{`(${id})`}</p>
                  <ExperimentsWidgetList>
                    {['0', ...variantsArray].map(variant => {
                      const variantId = `experiment-${id}-variant-${variant}`;
                      const activeExperiment = activeExperiments[id]?.variant || '0';

                      return (
                        <ExperimentsWidgetListItem key={variantId}>
                          <input
                            type="radio"
                            id={variantId}
                            name={id}
                            value={variant}
                            checked={activeExperiment === variant}
                            onChange={() => saveExperimentsOnContext(variant, id)}
                          />
                          <label htmlFor={variantId}>
                            {variant === '0' ? 'Original' : `Variant ${variant}`}
                          </label>
                        </ExperimentsWidgetListItem>
                      );
                    })}
                  </ExperimentsWidgetList>
                </ExperimentsWidgetListContent>
              );
            })}
          </ExperimentsWidgetContainer>

          <footer>
            <button
              title="Adicionar itens"
              type="button"
              tabIndex="0"
              onClick={resetExperimentsOnContext}
            >
              Clear all
            </button>
          </footer>
        </ExperimentsWidgetWrapper>
      )}
      {!visible && (
        <ExperimentsWidgetToggle onClick={() => setVisible(true)}>
          Open A/B Widget
        </ExperimentsWidgetToggle>
      )}
    </>
  );
}

ExperimentsWidget.propTypes = {
  activeExperiments: PropTypes.objectOf(PropTypes.shape({ variant: PropTypes.string })),
  availableExperiments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      variants: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    }),
  ),
  resetExperimentsOnContext: PropTypes.func.isRequired,
  saveExperimentsOnContext: PropTypes.func.isRequired,
};

ExperimentsWidget.defaultProps = {
  activeExperiments: {},
  availableExperiments: [],
};
