import { getThirdPartyJson, getJson } from './httpClientCentauro';
import { getNeemuSecurityParams } from '../utils/utils';
import { neemuApiDomain, neemuApiKey, searchSbfAuth, searchSbfBaseUrl } from '../settings';
import { getSearchScoringProfile } from './apiCommons';

const getSearchApiUrl = (term, category, limit, limitProducts) => {
  const searchParam = `/autocompletes?prefix=${term}`;
  const categoryParam = !category ? '' : '&category=True';
  const limitParam = `&resultsQueries=${limit}&resultsProducts=${limitProducts}`;
  const securityParams = `&${getNeemuSecurityParams()}`;
  const urlToGo = `${neemuApiDomain}${searchParam}${categoryParam}${limitParam}${securityParams}`;

  return urlToGo;
};

const getAutoCompleteFromYantar = term => {
  const basePath = `${searchSbfBaseUrl}/autocomplete?`;
  const termAutoComplete = `terms=${term}`;
  const url = `${basePath}${termAutoComplete}`;

  return getJson(url, null, false, false, searchSbfAuth);
};

const getPreviewFromYantar = (term, resultsPerPage) => {
  const basePath = `${searchSbfBaseUrl}/search/preview?`;
  const termForPreview = `term=${term}`;
  const sorting = '&sorting=relevance';
  const scoringProfile = `&scoringProfile=${getSearchScoringProfile()}`;
  const qttresultsPerPage = `&resultsPerPage=${resultsPerPage}`;
  const url = `${basePath}${termForPreview}${sorting}${scoringProfile}${qttresultsPerPage}`;

  return getJson(url, null, false, false, searchSbfAuth);
};

const getTopTermsFromYantar = () => {
  const basePath = `${searchSbfBaseUrl}/topterms`;
  const url = `${basePath}`;

  return getJson(url, null, false, false, searchSbfAuth);
};

const getSearchData = (term, category = true, limit = 10, limitProducts = 6) => {
  const urlToGo = getSearchApiUrl(term, category, limit, limitProducts);

  return getJson(urlToGo, null, false, false);
};

const getSearchCategoryData = (term, category = true, limit = 10, limitProducts = 6, query) => {
  const urlToGo = query
    ? `${neemuApiDomain}${query.substr(17)}`
    : getSearchApiUrl(term, category, limit, limitProducts);

  return getJson(urlToGo, null, false, false);
};

const getMostedSearchedTerms = () =>
  getThirdPartyJson({
    url: `${neemuApiDomain}/autocompletes/popular?apikey=${neemuApiKey}&origin=autocomplete`,
  });

export default {
  getSearchData,
  getSearchCategoryData,
  getMostedSearchedTerms,
  getAutoCompleteFromYantar,
  getPreviewFromYantar,
  getTopTermsFromYantar,
};
