import React, { useRef, createContext, useCallback } from 'react';
import {
  MembershipProviderProps,
  MembershipContextType,
  MembershipData,
} from './MembershipContext.d';
import { getMemberProfile } from '@/modules/membershipModule';

export const MembershipContext = createContext<MembershipContextType | null>(null);

export const MembershipProvider = (props: MembershipProviderProps): JSX.Element => {
  const { children } = props;
  const dataRef = useRef<MembershipData | undefined>(undefined);

  const handleMembershipService = useCallback(async () => {
    const requestData = await getMemberProfile();

    if (requestData) {
      dataRef.current = requestData;
      return requestData;
    }

    return undefined;
  }, []);

  const getMoveStatus = async () => {
    if (dataRef.current) {
      return dataRef.current.status;
    }

    const newRequestData = await handleMembershipService();

    if (newRequestData) {
      return newRequestData.status;
    }
  };

  const getMemberWalletStatus = async () => {
    if (dataRef.current) {
      return dataRef.current.wallet;
    }

    const newRequestData = await handleMembershipService();

    if (newRequestData) {
      return newRequestData.wallet;
    }
  };

  return (
    <MembershipContext.Provider value={{ getMoveStatus, getMemberWalletStatus }}>
      {children}
    </MembershipContext.Provider>
  );
};
