import currency from 'currency.js';
import { localStorage } from '@/utils/browser';
import { generateHash, cartLocalStorage, isAuthenticated } from '@/utils/utils';
import { isFootballBoots } from '@/helpers/customizationHelper';
import { apiDomain } from '@/settings';
import { getJson, postJson } from './httpClientCentauro';

const STORAGE_KEY = 'carrinho';
const STORAGE_CACHE_KEY = 'carrinho_cache';
const MAX_ITEMS_WITH_SAME_SKU = 10;
const DELIVERY_TYPE_NORMAL = 'normal';
const DAYS_TO_EXPIRES_CART = 3;
const urlInstallments = `${apiDomain}/v5.0/carrinhos/parcelas`;
const urlFreight = `${apiDomain}/v5.0/carrinhos/tiposentrega`;
const urlFreightPdp = `${apiDomain}/v5.0/tiposentrega/pdp`;
const urlCheckWrapper = `${apiDomain}/v5.0/carrinhos/presente`;
const urlDiscountCouponWithoutCart = `${apiDomain}/v5.0/carrinhos/promocode`;
const FRETE_CACHE = 5000;
let FRETE = null;

const money = value =>
  currency(value, {
    decimal: ',',
    symbol: '',
    separator: '',
  });

const moneyWithSymbol = value =>
  currency(value, {
    decimal: ',',
    symbol: 'R$',
    separator: '.',
  });

const invalidateFreightCache = () => {
  FRETE = null;
};

const isExpired = dateStr => {
  const now = new Date();
  const compare = new Date(dateStr);

  return now >= compare;
};

const getExpiresDate = () => {
  const result = new Date();
  result.setDate(result.getDate() + DAYS_TO_EXPIRES_CART);
  return result.toString();
};

const emptyCart = () => ({
  parcelas: null,
  itens: [],
  habilitaEmbrulho: {},
  cartaoPresente: null,
  cupomDeDesconto: null,
  expires: getExpiresDate(),
});

const getCarrinho = () => {
  const carrinho = JSON.parse(localStorage().getItem(STORAGE_KEY));

  if (!carrinho || isExpired(carrinho.expires)) {
    return emptyCart();
  }

  return carrinho;
};

const updateCache = itens => {
  const hash = JSON.stringify(itens);
  localStorage().setItem(STORAGE_CACHE_KEY, hash);
};

const updateLocalStorage = carrinho => {
  /**
   * O cache deve ser atualizado com os itens que estão atualmente no carrinho
   * Sem isso, a aplicação não vai saber por exemplo quando deve forçar a
   * atualização do desconto ou o frete
   */
  updateCache(getCarrinho().itens);

  localStorage().setItem(
    STORAGE_KEY,
    JSON.stringify({
      ...carrinho,
      expires: getExpiresDate(),
    }),
  );
};

const cleanCart = () => updateLocalStorage(emptyCart());

const getEnableGift = async sku => {
  const carrinho = getCarrinho();

  if (sku in carrinho.habilitaEmbrulho) {
    return carrinho.habilitaEmbrulho[sku];
  }

  const { data } = await getJson(`${urlCheckWrapper}/${sku}`);

  return data?.possuiEmbrulho || false;
};

const genCartItems = itens => {
  return itens.map(item => {
    const data = {
      sku: item.sku,
      quantidade: item.quantidade,
    };

    if (item.presente) {
      data.presente = item.presente;
    }

    if (item.codigoModeloVirtual) {
      data.modeloVirtual = item.codigoModeloVirtual;
    }

    if (item.personalizado) {
      data.personalizacao = {
        // deve ser enviado como número ao invés de string
        precoPersonalizacao: money(item.precoPersonalizacao).value,
        tipoPersonalizacao: item.tipoPersonalizacao,
        valorPersonalizacao: item.valorPersonalizacao,
      };
    }

    return data;
  });
};

const genNewProduct = ({
  skuSelected,
  productDetails,
  customized = false,
  customObject = {},
  sellerName = '',
}) => {
  if (!cartLocalStorage()) {
    return customObject;
  }

  const availability = productDetails.disponibilidade;
  const info = productDetails.informacoes.skus.find(obj => obj.sku === skuSelected);

  const customTypeObj = availability.cores.find(obj => skuSelected.substr(6, 2) === obj.codigoCor);
  const codModelo = availability.codigoModelo;
  const skuVirtual = codModelo + skuSelected.substr(6);

  const price = productDetails.precos.find(({ sku }) => sku === skuSelected || sku === skuVirtual);

  return {
    codModelo,
    codCor: info.cor.codigo,
    nome: availability.nome,
    tamanho: info.tamanho.descricao,
    cor: info.cor.descricao,
    urlProduto: availability.urlSiteDesktop,
    valorUnitario: price.valor,
    valorUnitarioDe: price.valorDe,
    nomeSeller: sellerName || productDetails.informacoes.nomeSeller,
    personalizado: customized,
    codigoModeloVirtual: productDetails.informacoes.codigoModeloVirtual,
    mktpEntreguePorCentauro: productDetails.informacoes.mktpEntreguePorCentauro,
    tipoPersonalizacao: customTypeObj.tipoPersonalizacao,

    ...customObject,
  };
};

const add = async dataPost => {
  const carrinho = getCarrinho();

  /**
   * Precisamos de um identificador único para cada item que será
   * inserido no localStorage para que seja possível atualizá-los no futuro
   */
  const hash = generateHash();

  const newItem = {
    sku: dataPost.Sku,
    quantidade: dataPost.Quantidade,
    nome: dataPost.nome,
    tamanho: dataPost.tamanho,
    cor: dataPost.cor,
    itemId: hash,
    id: hash,
    atendeQuantidadeSolicitada: dataPost.atendeQuantidadeSolicitada,
    urlProduto: dataPost.urlProduto,

    codCor: dataPost.codCor,
    codModelo: dataPost.codModelo,
    valorUnitario: dataPost.valorUnitario,
    valorUnitarioDe: dataPost.valorUnitarioDe ? dataPost.valorUnitarioDe : null,
    nomeSeller: dataPost.nomeSeller,
    mktpEntreguePorCentauro: dataPost.mktpEntreguePorCentauro,

    habilitaEmbrulho: false,
    presente: false,

    // usado para a personalização
    personalizado: dataPost.personalizado,
    codigoModeloVirtual: dataPost.codigoModeloVirtual,
    valorPersonalizacao: dataPost.valorPersonalizacao,
    tipoPersonalizacao: dataPost.tipoPersonalizacao,
    precoPersonalizacao: money(dataPost.precoPersonalizacao || 0).format(),
  };

  /**
   * Hoje o mesmo produto pode ser adicionado várias vezes no carrinho, mas existe
   * algumas regras:
   *
   * - Se o item tiver personalização, deve ser inserido um novo item no carrinho
   *
   * - Se o novo item não tiver personalização, ao invés de adicionar um novo item
   *   no carrinho, deve atualizar a quantidade de itens do produto que
   *   tiver o mesmo SKU e não tiver personalização
   *
   */
  const indexOnCart = carrinho.itens.findIndex(
    item =>
      item.sku === newItem.sku &&
      !item.personalizado &&
      item.codigoModeloVirtual === newItem.codigoModeloVirtual,
  );
  const addNewItem = newItem.personalizado || indexOnCart === -1;

  if (addNewItem) {
    newItem.habilitaEmbrulho = await getEnableGift(newItem.sku);

    /**
     * Armazena no localStorage para não ter que fazer a request HTTP novamente
     * quando consultar um produto com o mesmo SKU
     */
    carrinho.habilitaEmbrulho[newItem.sku] = newItem.habilitaEmbrulho;

    carrinho.itens.push(newItem);
  } else {
    const item = carrinho.itens[indexOnCart];

    item.quantidade += newItem.quantidade;
  }

  updateLocalStorage(carrinho);
};

const update = (itemId, dataPost) => {
  const carrinho = getCarrinho();
  const quantidade = dataPost.Quantidade;

  /**
   * Um produto com o mesmo SKU pode ter vários itens diferentes no carrinho
   * caso ele tenha customizações. Por esse motivo, deve ser usado o itemId
   * para atualizar a quantidade de um produto.
   *
   * Se for uzado o SKU, vai atualizar vários
   */
  if (quantidade === 0) {
    carrinho.itens = carrinho.itens.filter(item => item.itemId !== itemId);
  } else {
    const item = carrinho.itens.find(i => i.itemId === itemId);

    item.quantidade = quantidade;
  }

  updateLocalStorage(carrinho);
};

const removeCustomization = itemId => {
  const carrinho = getCarrinho();

  /**
   * Um produto com o mesmo SKU pode ter vários itens diferentes no carrinho
   * caso ele tenha customizações. Por esse motivo, deve ser usado o itemId
   * para remover a customização de um produto.
   *
   * Se for uzado o SKU, vai remover a customiazação de vários itens e não apenas de um
   */
  const item = carrinho.itens.find(i => i.itemId === itemId);

  // usado para a personalização
  item.personalizado = false;
  item.codigoModeloVirtual = null;
  item.precoPersonalizacao = '0,00';

  item.valorPersonalizacao = undefined;
  item.tipoPersonalizacao = undefined;

  updateLocalStorage(carrinho);
};

const toggleGift = (itemId, isGift) => {
  const carrinho = getCarrinho();

  const item = carrinho.itens.find(i => i.itemId === itemId);
  item.presente = isGift;

  updateLocalStorage(carrinho);
};

const addGiftCard = gift => {
  const carrinho = getCarrinho();

  carrinho.cartaoPresente = gift;
  updateLocalStorage(carrinho);
};

const removeGiftCard = () => {
  const carrinho = getCarrinho();

  carrinho.cartaoPresente = null;
  updateLocalStorage(carrinho);
};

const addDiscountCoupon = coupon => {
  const carrinho = getCarrinho();

  carrinho.cupomDeDesconto = coupon;
  updateLocalStorage(carrinho);
};

const removeDiscountCoupon = () => {
  const carrinho = getCarrinho();

  carrinho.cupomDeDesconto = null;
  updateLocalStorage(carrinho);
};

const getDiscountCouponInfo = async (codigoPromocode, header) => {
  const carrinho = getCarrinho();

  const itens = genCartItems(carrinho.itens);

  const { data, error } = await postJson(
    urlDiscountCouponWithoutCart,
    { codigoPromocode, itens },
    header,
    true,
  );

  if (error) {
    return Promise.resolve({
      data,
      error,
    });
  }

  addDiscountCoupon({
    codigoPromocode,
    itens: data.itens,
    valorDoCarrinho: data.valorDoCarrinho,
    descontoDoCarrinho: data.descontoDoCarrinho,
  });

  return Promise.resolve({});
};

const getQuantityBySku = sku =>
  getCarrinho()
    .itens.filter(item => item.sku === sku)
    .reduce((acc, item) => acc + item.quantidade, 0);

const exceedsMaxItems = quantity => quantity > MAX_ITEMS_WITH_SAME_SKU;

const getInstallment = async () => {
  const carrinho = getCarrinho();

  /**
   * Não há grandes variações nos preços das parcelas então estamos
   * armazenando as informações das parcelas no localStorage para não precisar
   * bater no endpoint várias vezes
   */
  if (!carrinho.parcelas) {
    const { data } = await getJson(urlInstallments);
    carrinho.parcelas = data;

    updateLocalStorage(carrinho);
  }

  return Promise.resolve({
    data: carrinho.parcelas,
  });
};

const getInstallmentInfo = async value => {
  const { data } = await getInstallment();

  const moneyValue = moneyWithSymbol(value);
  const minValue = moneyWithSymbol(data.valorMinimoParcela);

  if (minValue.value >= moneyValue.value) {
    return {
      installments: 1,
      value: money(moneyValue.value).format(),
      description: `1x ${moneyValue.format()}`,
    };
  }

  /**
   * Deve ser usado .distribute ao invés de .divide para corresponder as mesmas
   * regras existentes no nosso backend para o cálculo da parcela
   */
  let installments = data.quantidadeMaximaParcela;
  let installmentValue = moneyValue.distribute(installments)[0];

  while (minValue.value > installmentValue.value) {
    installments -= 1;
    [installmentValue] = moneyValue.distribute(installments);
  }

  return {
    installments,
    value: money(installmentValue.value).format(),
    description: `${installments}x ${installmentValue.format()}`,
  };
};

const updateCep = cep => {
  const carrinho = getCarrinho();

  carrinho.cep = cep;
  updateLocalStorage(carrinho);
};

const calcTotalFreight = freteCarrinho =>
  freteCarrinho.reduce((acc, item) => {
    /**
     * Deve ser considerado o valor do tipo de entrega "normal" para calcular o valor do frete
     */
    const value = item.tiposEntregas.find(entrega => entrega.tipoEntrega === DELIVERY_TYPE_NORMAL)
      .valor;

    return money(acc).add(value);
  }, money(0)).value;

const getFreight = (data, header) => postJson(urlFreight, data, header);

const hasValidFreightCached = (currentHash, cep) => {
  return FRETE && FRETE.hash === currentHash && FRETE.cep === cep && Date.now() <= FRETE.expire;
};

const getFreightInfo = async (cep, header) => {
  const carrinho = getCarrinho();

  updateCep(cep);

  const currentHash = localStorage().getItem(STORAGE_CACHE_KEY);

  /**
   * Evitamos fazer requests desnecessárias caso tenhamos
   * a informação em cache respeitando o tempo de expiração
   */
  if (hasValidFreightCached(currentHash, cep)) {
    return Promise.resolve({
      data: FRETE.data,
    });
  }

  const itens = genCartItems(carrinho.itens);

  const { data, error } = await getFreight({ cep, itens }, header);

  if (!error) {
    FRETE = {
      data,
      hash: currentHash,
      cep,
      expire: Date.now() + FRETE_CACHE,
    };

    return Promise.resolve({
      data,
    });
  }

  return Promise.resolve({
    data,
    error,
  });
};

const getFreightInfoPdp = async (sku, cep, header) => {
  const url = `${urlFreightPdp}?sku=${sku}&cep=${cep}&trazerTodasLojas=true`;

  updateCep(cep);

  return getJson(url, header);
};

const genFootballBootsDescription = customize => {
  const description = customize.split(' | ').reduce((acc, item) => {
    const [key, value] = item.split(': ');

    const [name, color, flag] = value.split('-');

    const normalizedKey = key.replace(/\s/g, '');

    acc[normalizedKey] = {
      Tipo: name ? 'nome' : 'bandeira',
      Descricao: name || flag,
      Cor: color || null,
    };

    return acc;
  }, {});

  return JSON.stringify(description);
};

const genCustomizationDescription = (type, customization) => {
  if (!customization) {
    return undefined;
  }

  if (isFootballBoots(type)) {
    return genFootballBootsDescription(customization);
  }

  return customization;
};

const getDiscountBySku = (sku, cupomDeDesconto) =>
  cupomDeDesconto?.itens?.find(item => item.sku === sku);

const genValorUnitario = (item, cupomDeDesconto) => {
  const desconto = getDiscountBySku(item.sku, cupomDeDesconto);

  if (desconto) {
    return desconto.precoVigente;
  }

  return item.valorUnitario;
};

const genValorUnitarioDe = (item, cupomDeDesconto) => {
  const desconto = getDiscountBySku(item.sku, cupomDeDesconto);

  if (desconto) {
    return desconto.precoBase;
  }

  return item.valorUnitarioDe;
};

const genPrecoPersonalizacao = (item, cupomDeDesconto) => {
  const desconto = getDiscountBySku(item.sku, cupomDeDesconto);

  if (item.personalizado && desconto && desconto.personalizacao) {
    return desconto.personalizacao.precoPersonalizacao;
  }

  return item.precoPersonalizacao;
};

const genCartParcelamento = async total => {
  const installmentInfo = await getInstallmentInfo(total);

  return [
    {
      numeroDaParcela: installmentInfo.installments,
      preco: installmentInfo.value,
      parcelaPadrao: false,
    },
  ];
};

const genCartFrete = async (cep, header) => {
  const { data } = await getFreightInfo(cep, header);

  if (!data) {
    return {
      frete: 0,
      freteGratis: false,
    };
  }

  const frete = calcTotalFreight(data.freteCarrinho);

  return {
    frete,
    freteGratis: frete === 0,
  };
};

const genCartTotalItens = itens => {
  return itens.reduce((acc, item) => {
    return acc + item.quantidade;
  }, 0);
};

const genSubTotalValue = (itens, cupomDeDesconto) => {
  if (cupomDeDesconto) {
    return money(cupomDeDesconto.valorDoCarrinho);
  }

  return itens.reduce((acc, item) => {
    const valorUnitario = genValorUnitario(item);
    const precoPersonalizacao = genPrecoPersonalizacao(item);

    const totalValue = money(valorUnitario)
      .add(precoPersonalizacao)
      .multiply(item.quantidade);

    return money(acc).add(totalValue);
  }, money(0));
};

const genSubTotalValueFrom = (itens, cupomDeDesconto) => {
  return itens.reduce((acc, item) => {
    const valorUnitario = genValorUnitario(item, cupomDeDesconto);
    const valorUnitarioDe = genValorUnitarioDe(item, cupomDeDesconto);
    const precoPersonalizacao = genPrecoPersonalizacao(item, cupomDeDesconto);

    const totalValue = money(valorUnitarioDe || valorUnitario)
      .add(precoPersonalizacao)
      .multiply(item.quantidade);

    return money(acc).add(totalValue);
  }, money(0));
};

const genTotalValue = (subtotal, frete, saldoCartaoPresente) => {
  const total = subtotal.add(frete);

  return total.value > saldoCartaoPresente ? total.subtract(saldoCartaoPresente) : money(0);
};

const genGiftCardValue = (subtotal, frete, saldoCartaoPresente) => {
  const total = subtotal.add(frete).value;

  return total > saldoCartaoPresente ? saldoCartaoPresente : total;
};

const genCartItem = (item, cupomDeDesconto) => {
  const valorUnitario = genValorUnitario(item, cupomDeDesconto);
  const valorUnitarioDe = genValorUnitarioDe(item, cupomDeDesconto);
  const precoPersonalizacao = genPrecoPersonalizacao(item, cupomDeDesconto);

  return {
    sku: item.sku,
    id: item.id,
    itemId: item.itemId,

    // TODO: a URL está quebrada
    href: item.urlProduto,
    nome: item.nome,
    modelo: item.nome,
    valorUnitarioDe: valorUnitarioDe ? money(valorUnitarioDe).format() : null,
    valorUnitarioPor: money(valorUnitario).format(),
    total: money(valorUnitario)
      .multiply(item.quantidade)
      .format(),
    precoDeTotal: valorUnitarioDe
      ? money(valorUnitarioDe)
          .multiply(item.quantidade)
          .format()
      : undefined,
    nomeSeller: item.nomeSeller,
    mktpEntreguePorCentauro: item.mktpEntreguePorCentauro,
    cor: item.cor,
    tamanho: item.tamanho,
    quantidade: item.quantidade,

    habilitaEmbrulho: item.habilitaEmbrulho,
    presente: item.presente,

    // personalizacao
    personalizado: item.personalizado,
    tipoPersonalizacao: item.tipoPersonalizacao,
    descricaoPersonalizacao: genCustomizationDescription(
      item.tipoPersonalizacao,
      item.valorPersonalizacao,
    ),
    precoPersonalizacao: money(precoPersonalizacao).format(),

    imagem: `https://imgcentauro-a.akamaihd.net/120x120/${item.codModelo}${item.codCor}.jpg`,
    imagemResumo: `https://imgcentauro-a.akamaihd.net/48x48/${item.codModelo}${item.codCor}.jpg`,
  };
};

const isCacheOutdated = itens => {
  const cachedItems = localStorage().getItem(STORAGE_CACHE_KEY);
  const currentItems = JSON.stringify(itens);

  return cachedItems !== currentItems;
};

const getCartData = async header => {
  let carrinho = getCarrinho();

  /**
   * Quando o carrinho tem um cupom de desconto aplicado,
   * e o cache estiver desatualizado, devemos atualizar o
   * valor dos descontos e forçar a atualização do carrinho
   */
  if (carrinho.cupomDeDesconto && isCacheOutdated(carrinho.itens)) {
    await getDiscountCouponInfo(carrinho.cupomDeDesconto.codigoPromocode, header);
    carrinho = getCarrinho();
  }

  let saldoCartaoPresente = 0;

  const returnData = {
    frete: '0,00',
    freteGratis: false,
    itens: carrinho.itens.map(item => genCartItem(item, carrinho.cupomDeDesconto)),
    atributos: {},
    quantidadeTotalItens: genCartTotalItens(carrinho.itens),
    mensagensCarrinhoStatusSucesso: true,
    mensagensCarrinhoTodos: [],
    promocode: null,
    desconto: '0',
  };

  /**
   * Uma vez que o usuário calculou o frete, devemos
   * sempre atualizar o valor do frete respeitando
   * as regras de cache
   */
  if (carrinho.cep) {
    const { frete, freteGratis } = await genCartFrete(carrinho.cep, header);

    returnData.frete = money(frete).format();
    returnData.freteGratis = freteGratis;
  }

  if (carrinho.cartaoPresente) {
    const { codigoCartao, idConsulta, saldoTotal } = carrinho.cartaoPresente;

    saldoCartaoPresente = saldoTotal;
    returnData.atributos.giftCard = `${codigoCartao}|${idConsulta}|${saldoTotal}`;
  }

  if (carrinho.cupomDeDesconto) {
    returnData.promocode = carrinho.cupomDeDesconto.codigoPromocode;
    returnData.desconto = money(carrinho.cupomDeDesconto.descontoDoCarrinho).format();
  }

  const subTotal = genSubTotalValue(carrinho.itens, carrinho.cupomDeDesconto);
  const subTotalFrom = genSubTotalValueFrom(carrinho.itens, carrinho.cupomDeDesconto);
  const total = genTotalValue(subTotal, returnData.frete, saldoCartaoPresente);
  const valorCartaoPresente = genGiftCardValue(subTotal, returnData.frete, saldoCartaoPresente);

  // TODO: verificar quais são as outras possíveis formas de crédito
  returnData.credito = valorCartaoPresente;
  returnData.valorCartaoPresente = valorCartaoPresente;

  returnData.subTotal = subTotal.format();
  returnData.total = total.format();
  returnData.totalBoleto = total.format();

  returnData.valorEconomizado = money(subTotalFrom)
    .subtract(subTotal)
    .format();

  returnData.parcelamento = await genCartParcelamento(total.value);

  return returnData;
};

const genMiniCartItem = (item, cupomDeDesconto) => {
  const valorUnitario = genValorUnitario(item, cupomDeDesconto);
  const precoPersonalizacao = genPrecoPersonalizacao(item, cupomDeDesconto);

  return {
    sku: item.sku,
    id: item.id,
    itemId: item.itemId,
    nome: item.nome,
    tamanho: item.tamanho,
    cor: item.cor,
    quantidade: item.quantidade,
    valorTotal: money(valorUnitario)
      .add(precoPersonalizacao)
      .multiply(item.quantidade)
      .format(),
    imagem: `https://imgcentauro-a.akamaihd.net/48x48/${item.codModelo}${item.codCor}.jpg`,
    // TODO:essa url não está redirecionando para o produto correto. Falta passar a COR na URL
    urlProduto: item.urlProduto,
    personalizado: item.personalizado,
  };
};

const getMiniCartData = async header => {
  let carrinho = getCarrinho();

  /**
   * Quando o carrinho tem um cupom de desconto aplicado, devemos atualizar o
   * valor dos descontos e forçar a atualização do carrinho
   */
  if (carrinho.cupomDeDesconto) {
    await getDiscountCouponInfo(carrinho.cupomDeDesconto.codigoPromocode, header);
    carrinho = getCarrinho();
  }

  const returnData = {
    quantidadeItensDiferentes: carrinho.itens.length,
    quantidadeTotalItens: genCartTotalItens(carrinho.itens),
    itens: carrinho.itens.map(item => genMiniCartItem(item, carrinho.cupomDeDesconto)),
    mensagensCarrinhoTodos: [],
  };

  const valorTotal = genSubTotalValue(carrinho.itens, carrinho.cupomDeDesconto);
  const installmentInfo = await getInstallmentInfo(valorTotal.value);

  returnData.valorTotal = valorTotal.format();
  returnData.informacaoParcelamento = installmentInfo.description;

  return returnData;
};

const hasPendingSync = () => {
  const carrinho = getCarrinho();

  return isAuthenticated() && !!carrinho.itens.length;
};

export {
  getCarrinho,
  add,
  update,
  removeCustomization,
  money,
  getQuantityBySku,
  exceedsMaxItems,
  getMiniCartData,
  getCartData,
  getInstallmentInfo,
  getFreightInfo,
  updateCep,
  toggleGift,
  addGiftCard,
  removeGiftCard,
  addDiscountCoupon,
  removeDiscountCoupon,
  getDiscountCouponInfo,
  genNewProduct,
  getFreightInfoPdp,
  cleanCart,
  emptyCart,
  getExpiresDate,
  genCartItems,
  invalidateFreightCache,
  updateLocalStorage,
  hasPendingSync,
};
