import ModelStateProps from './../interfaces/ModelStateProps';

function modelStateAdapter(modelState: ModelStateProps): Array<string> | undefined {
  if (!modelState) {
    return undefined;
  }

  const errors = new Array<string>();

  const keys = Object.keys(modelState);

  keys.forEach(key => {
    errors.push(...modelState[key]);
  });

  return errors;
}

export default modelStateAdapter;
