import React, { Component } from 'react';
import { css } from 'aphrodite/no-important';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { window, document } from 'global';

import { grayI } from '../../../common/colors';
import styles from './styles';

class LightBox extends Component {
  componentDidMount = () => {
    this._addEscKeyListener();
    this._disableBodyScroll();
  };

  componentWillUnmount = () => {
    this._removeEscKeyListener();
    this._enableBodyScroll();
  };

  _disableBodyScroll = () => {
    if (document) {
      document.body.style.overflow = 'hidden';
    }
  };

  _enableBodyScroll = () => {
    if (document) {
      document.body.style.overflow = 'auto';
    }
  };

  _escFunction = event => {
    const { onClose } = this.props;

    if (event.keyCode === 27) {
      onClose();
    }
  };

  _addEscKeyListener = () => {
    if (window) {
      window.addEventListener('keydown', this._escFunction, false);
    }
  };

  _removeEscKeyListener = () => {
    if (window) {
      window.removeEventListener('keydown', this._escFunction, false);
    }
  };

  render() {
    const {
      children,
      onClose,
      title,
      closeOut,
      customStyle,
      customTitleStyle,
      closeSize,
      shadowClose,
      removeCloseButton,
      overlayCustomStyle,
      classLightBox,
      classCloseButton,
      closeColor,
      customCloseStyle,
    } = this.props;

    return (
      <div
        className={css(styles.lightBoxBg, overlayCustomStyle !== null && overlayCustomStyle)}
        onClick={onClose}
        role="presentation"
        data-testid="modalOverlay"
      >
        <button
          className={`${classLightBox} ${css(
            styles.lightBox,
            customStyle !== null && customStyle,
          )}`}
          onClick={evt => {
            evt.stopPropagation();
            evt.nativeEvent.stopImmediatePropagation();
            return false;
          }}
          data-id="modal"
          data-testid="modal-container"
        >
          {!removeCloseButton && (
            <button
              type="button"
              className={`${classCloseButton} ${css(
                styles.closeButton,
                customCloseStyle !== null && customCloseStyle,
                closeOut && styles.closeOut,
              )}`}
              onClick={onClose}
              aria-label="Fechar modal"
            >
              <CloseIcon
                style={{
                  fontSize: closeSize,
                  color: closeColor || grayI,
                  filter: shadowClose ? 'drop-shadow(0 1px 3px rgba(0, 0, 0, 0.2))' : '',
                }}
              >
                Fechar
              </CloseIcon>
            </button>
          )}

          {title && title !== '' && (
            <div className={css(styles.lightBoxHeader, removeCloseButton && styles.customHeader)}>
              <h2
                className={css(
                  styles.lightBoxTitle,
                  customTitleStyle !== null && customTitleStyle,
                  removeCloseButton && styles.centeredTitle,
                )}
              >
                {title}
              </h2>
            </div>
          )}

          {children}
        </button>
      </div>
    );
  }
}

LightBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  title: PropTypes.string,
  closeOut: PropTypes.bool,
  customStyle: PropTypes.objectOf(PropTypes.any),
  overlayCustomStyle: PropTypes.objectOf(PropTypes.any),
  customTitleStyle: PropTypes.objectOf(PropTypes.any),
  closeSize: PropTypes.number,
  shadowClose: PropTypes.bool,
  removeCloseButton: PropTypes.bool,
  classLightBox: PropTypes.string,
  classCloseButton: PropTypes.string,
  closeColor: PropTypes.string,
  customCloseStyle: PropTypes.objectOf(PropTypes.any),
};

LightBox.defaultProps = {
  title: '',
  closeOut: false,
  customStyle: null,
  overlayCustomStyle: null,
  customTitleStyle: null,
  closeSize: 32,
  shadowClose: false,
  removeCloseButton: false,
  classLightBox: '',
  classCloseButton: '',
  closeColor: '',
  customCloseStyle: null,
};

export default LightBox;
