import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';
import ExchangePolicyModal from '../../exchangePolicy/ExchangePolicyModal';
import { sendEvent } from '../../../../utils/Analytics';

import styles from './styles';

const CheckoutFooter = ({ abTest }) => {
  const [showExchangeModal, setShowExchangeModal] = useState(false);

  const clickExchangeHandler = () => {
    sendEvent(
      'UI - Checkout - Footer - Política de Troca',
      'Click - Desk - Detalhes Política de Troca',
      'Clicou para abrir o modal Política de Troca',
    );
    setShowExchangeModal(true);
  };

  return (
    <div className={css(styles.footerContainer)}>
      {abTest === 'piloto' && (
        <Fragment>
          <div className={css(styles.linkContainer)}>
            <span role="presentation" className={css(styles.link)} onClick={clickExchangeHandler}>
              Política de Troca e Devolução
            </span>
          </div>
          {showExchangeModal && <ExchangePolicyModal setShowExchangeModal={setShowExchangeModal} />}
        </Fragment>
      )}

      <span className={css(styles.footerTitle)}>
        SBF COMERCIO DE PRODUTOS ESPORTIVOS S.A. CNPJ: 06.347.409/0069-53
      </span>
      <p className={css(styles.footerAddress)}>
        Endereço: Rua Hugo D&lsquo;Antola, nº 200, Lapa, Cidade de São Paulo - SP, CEP 05038-090.
      </p>
    </div>
  );
};

CheckoutFooter.propTypes = {
  abTest: PropTypes.string,
};

export default CheckoutFooter;
