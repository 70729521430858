import { getJson, postJson } from './httpClientCentauro';
import { apiDomain } from '../settings';

const exchangeVoucherURI = `${apiDomain}/v5.0/clientes/vale-trocas/listar`;
const userValidationURI = `${apiDomain}/v5.0/clientes/vale-trocas/validar`;
const maskVouchersURI = `${apiDomain}/v5.0/clientes/vale-trocas/revogar`;

export const getExchangeVoucherData = ({ cvId, clientToken }) =>
  getJson(exchangeVoucherURI, {
    'x-cv-id': cvId,
    'x-client-token': clientToken,
  });

export const postUserValidation = (formData, { cvId, clientToken }) => {
  return postJson(`${userValidationURI}`, formData, {
    'x-cv-id': cvId,
    'x-client-token': clientToken,
  });
};

export const postMaskVouchers = ({ cvId, clientToken }) => {
  return postJson(`${maskVouchersURI}`, null, {
    'x-cv-id': cvId,
    'x-client-token': clientToken,
  });
};
