const metaImageResolution = '900x900';

const sliderImageResolution = '120x120';

const activeImageResolution = '900x900';

const miniatureImageSize = '58x58';

const activeImageResolutionNav = '250x250';

export {
  metaImageResolution,
  sliderImageResolution,
  activeImageResolution,
  miniatureImageSize,
  activeImageResolutionNav,
};
