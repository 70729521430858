import { postJson } from './httpClientCentauro';
import { apiDomain } from '../settings';

const emailServiceSubscriptionURI = `${apiDomain}/v5.0/clientes/newsletter/cadastrar`;
const emailServiceCancellationURI = `${apiDomain}/v5.0/clientes/newsletter/cancelar`;

export const postEmailServiceSubscription = (formData, { cvId }) => {
  return postJson(`${emailServiceSubscriptionURI}`, formData, {
    'x-cv-id': cvId,
  });
};

export const postEmailServiceCancellation = (formData, { cvId }) => {
  return postJson(
    `${emailServiceCancellationURI}`,
    formData,
    {
      'x-cv-id': cvId,
      'Content-Type': 'application/json',
    },
    true,
    [404],
  );
};
