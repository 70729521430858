const bfMock = {
  Esportes: { BFTitle: 'Black Friday Centauro', BFLink: 'https://www.centauro.com.br/blackfriday' },
  Homens: { BFTitle: 'Black Friday 2019', BFLink: 'https://www.centauro.com.br/blackfriday' },
  Mulheres: { BFTitle: 'Black Friday Brasil', BFLink: 'https://www.centauro.com.br/blackfriday' },
  Crianças: {
    BFTitle: 'Aguarde as promoções da Black Friday',
    BFLink: 'https://www.centauro.com.br/blackfriday',
  },
  Calçados: {
    BFTitle: 'Quando é a Black Friday',
    BFLink: 'https://www.centauro.com.br/blackfriday',
  },
  Roupas: {
    BFTitle: 'Aguarde as ofertas da Black Friday',
    BFLink: 'https://www.centauro.com.br/blackfriday',
  },
  Acessórios: {
    BFTitle: 'O que Significa Black Friday',
    BFLink: 'https://www.centauro.com.br/blackfriday#origens-black-friday',
  },
  Equipamentos: {
    BFTitle: 'Black Friday - Compre no site, Retire na loja',
    BFLink: 'https://www.centauro.com.br/blackfriday',
  },
  Marcas: {
    BFTitle: 'As Marcas mais procuradas na Black Friday',
    BFLink: 'https://www.centauro.com.br/blackfriday#as-marcas-mais-procuradas-na-centauro',
  },
  Suplementos: {
    BFTitle: 'Aguarde nossos descontos Black Friday',
    BFLink: 'https://www.centauro.com.br/blackfriday',
  },
  Outlet: {
    BFTitle: 'Black Friday em Números',
    BFLink: 'https://www.centauro.com.br/blackfriday#black-friday-em-numeros',
  },
};

const mergeMockWithEndpoint = menuObject => {
  const menuMap = menuObject.map(menuSection => {
    let menuAppend = {};

    if (bfMock[menuSection.title]) {
      menuAppend = bfMock[menuSection.title];
    }

    const newMenuLine = {
      ...menuSection,
      ...menuAppend,
    };

    return newMenuLine;
  });

  return menuMap;
};

const BfHeaderButtons = menuObject => mergeMockWithEndpoint(menuObject);

export default BfHeaderButtons;
