import { observable, action, decorate } from 'mobx';
import autocompleteModule from '../modules/autocompleteModule';
import { sendEvent } from '../utils/Analytics';

class SearchStore {
  hasFocus = false;

  searchTerm = '';

  lastUpdate = undefined;

  suggestions = { terms: [], products: [] };

  mostSearched = [];

  openSuggestions = false;

  initialScrollYPos = 0;

  hoverTerm = undefined;

  closeDelayId = 0;

  setSearchTerm(searchTerm, isMobile) {
    this.hoverTerm = undefined;
    this.searchTerm = String(searchTerm);
    this.doSearchPreview({ phrase: searchTerm }, false, isMobile);
  }

  setHoverTerm(hoverTerm, isMobile) {
    this.hoverTerm = hoverTerm;

    if (!hoverTerm) {
      this.setHoverSuggestions(undefined, true);
      return;
    }

    this.doSearchPreview(hoverTerm, true, isMobile);
  }

  setScrollYPos(value) {
    this.initialScrollYPos = value;
  }

  getMostSearchedTerms = async () => {
    if (this.mostSearched.length === 0) {
      const { data, error } = await autocompleteModule.getTopTermsFromYantar();

      if (!error) {
        this.setMostSearchedTerms(data.queries);
      }
    }
  };

  setMostSearchedTerms(newValue) {
    this.mostSearched = newValue;
  }

  doSearchPreview = (termData, isHover = false, isMobile = false) => {
    const { category, phrase: term } = termData;

    if (term === '' || term === undefined || term === null || term === false) {
      return;
    }

    clearTimeout(this.lastUpdate);

    this.lastUpdate = setTimeout(async () => {
      sendEvent(
        'UI - Header - Search',
        !isHover
          ? 'Typing - Buscar um termo'
          : category
          ? 'Hover - Buscar um termo dentro de uma categoria'
          : 'Hover - Buscar um termo',
        `Busca por termo: ${term}${category ? `, categoria: ${category}` : ''}`,
      );

      const dataPreview = await this.getPreviewFromYantar(term, !isMobile ? 6 : 10);

      const response = dataPreview;

      if (!isHover) {
        const dataAutoComplete = await this.getAutoCompleteFromYantar(term);
        response.Queries = dataAutoComplete.queries;

        this.setSuggestions(response);
      } else {
        this.setHoverSuggestions(response);
      }
    }, 500);
  };

  setSuggestions(response) {
    // console.log('setSuggestions -> response:', response);
    let terms = response.Queries
      ? response.Queries.map(term => ({
          phrase: term.query,
          id: term.link,
          query: term.link,
          category: term.category,
          categoryId: term.categoryId,
        }))
      : [];

    if (terms.length > 0) {
      const firstTerm = terms[0];
      const firstTerms = [firstTerm];
      const lastTerms = terms.slice(1);

      if (firstTerm.category) {
        firstTerms.unshift({ id: firstTerm.id, phrase: firstTerm.phrase });
        lastTerms.pop();
      }

      terms = firstTerms.concat(lastTerms);
    }

    const products = this.mapProductsFromYantar(response.Products);

    const total = products.length;

    this.suggestions = {
      terms,
      products,
      total,
      hover: [],
      hoverTotal: 0,
    };
  }

  mapProductsFromLinx(products) {
    return (
      products?.map(product => ({
        sku: product.details.sku_list[0],
        title: product.name,
        url: product.url,
        oldPrice: product.oldPrice,
        currentPrice: product.price,
        imageUrl: product.images.default ? product.images.default : product.images[0],
        clickUrl: product.clickUrl,
      })) || []
    );
  }

  mapProductsFromYantar(products) {
    return (
      products?.map(product => ({
        sku: product.Sku,
        title: product.Name,
        url: product.Url,
        oldPrice: product.OldPrice,
        currentPrice: product.Price,
        imageUrl: product.Image,
        clickUrl: product.Url,
      })) || []
    );
  }

  setHoverSuggestions(response, clear) {
    // console.log('setHoverSuggestions -> response: ', response, ', clear: ', clear);

    const { terms, products, total } = this.suggestions;

    if (clear) {
      this.suggestions = {
        terms,
        products,
        total,
        hover: [],
        hoverTotal: 0,
      };

      return;
    }

    const hover = response.Products
      ? response.Products.map(product => ({
          sku: product.Sku,
          title: product.Name,
          url: product.Url,
          oldPrice: product.OldPrice,
          currentPrice: product.Price,
          imageUrl: product.Image,
        }))
      : [];

    const hoverTotal = response.result_meta ? response.result_meta.total : 0;

    this.suggestions = {
      terms,
      products,
      total,
      hover,
      hoverTotal,
      hoverTerm: this.hoverTerm,
    };
  }

  getAutoCompleteFromYantar = async term => {
    const { data, error } = await autocompleteModule['getAutoCompleteFromYantar'](term);

    if (error) {
      this.searchResults = null;
      console.error('ERROR on setSearchTerm: ', error); // eslint-disable-line
      return;
    }

    return data;
  };

  getPreviewFromYantar = async (term, resultsPerPage) => {
    const { data, error } = await autocompleteModule['getPreviewFromYantar'](term, resultsPerPage);

    if (error) {
      this.searchResults = null;
        console.error('ERROR on setSearchTerm: ', error); // eslint-disable-line
      return;
    }

    return data;
  };

  setOpenSuggestions(isOpen, scrollYPosValue) {
    if (!isOpen) {
      this.prepareToCloseSuggestions();
    } else {
      this.openSuggestions = true;
      this.setScrollYPos(scrollYPosValue);
    }
  }

  prepareToCloseSuggestions() {
    clearTimeout(this.closeDelayId);

    this.closeDelayId = setTimeout(() => this.closeSuggestions(), 250);
  }

  closeSuggestions() {
    this.openSuggestions = false;
    this.setScrollYPos(0);
  }

  setHasFocus(value) {
    this.hasFocus = value;
  }
}

decorate(SearchStore, {
  setScrollYPos: action,
  setSearchTerm: action,
  setHoverTerm: action,
  doSearchPreview: action,
  setSuggestions: action,
  setHoverSuggestions: action,
  setOpenSuggestions: action,
  prepareToCloseSuggestions: action,
  closeSuggestions: action,
  getMostSearchedTerms: action,
  setMostSearchedTerms: action,
  mostSearched: observable,
  closeDelayId: observable,
  searchTerm: observable,
  suggestions: observable,
  openSuggestions: observable,
  initialScrollYPos: observable,
  hoverTerm: observable,
  hasFocus: observable,
  setHasFocus: action,
});

export default SearchStore;
