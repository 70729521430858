import { configure, observable, action } from 'mobx';
import mobileHomeModule from '../modules/mobileHomeModule';

configure({ enforceActions: 'observed' });

class MobileHomeStore {
  @observable data = [];

  @observable productListData = [];

  @observable errorPool = [];

  @observable pendingRequest = false;

  @observable isLoading = true;

  @observable pricesList = [];

  @observable pageLoaded = false;

  @observable contentRendered = false;

  @observable chaordicShowcase = null;

  @observable hasLoadedChaordicShowcases = false;

  @action setHasLoadedChaordiShowcases = () => (this.hasLoadedChaordicShowcases = true);

  @action setChaordicShowcase = chaordicShowcaseData => {
    this.chaordicShowcase = chaordicShowcaseData;
    this.setHasLoadedChaordiShowcases();
  };

  @action setData = data => {
    this.data = data;
    this.isLoading = false;
    this.updatePendingTo(false);
  };

  @action updatePageLoad = isPageLoaded => (this.pageLoaded = isPageLoaded);

  @action updateContentRendered = iscontentRendered => (this.contentRendered = iscontentRendered);

  @action setProductDataToList = prodData => {
    this.productListData.push(prodData);
  };

  @action addErrorToPool = err => {
    this.errorPool.push(err);
  };

  @action updatePendingTo = bool => {
    this.pendingRequest = bool;
  };

  @action setPrices(updatedPricesList) {
    this.pricesList = updatedPricesList;
  }

  @action getSkusFromShowcases = chaordicShowcase => {
    const getModelColor = ({ url, id }) => {
      const color = url.split('cor=').pop();
      return `${id}${color}`;
    };

    const showCase = [
      {
        ...chaordicShowcase[0]?.top,
        ...chaordicShowcase[0]?.middle,
        ...chaordicShowcase[0]?.bottom,
      },
    ];
    showCase
      .map(item => {
        const itensColors = item[0]?.displays;
        return itensColors?.recommendations?.map(getModelColor);
      })
      .flat();
  };

  @action getDataFromAPI = async () => {
    this.updatePendingTo(true);
    this.isLoading = true;
    const oldData = this.data;

    const { data, error } = await mobileHomeModule.getHomeData();

    const chaordicData = await mobileHomeModule.getChaordicShowcases();

    if (error) {
      this.addErrorToPool(error);
      this.setData(oldData);
      return;
    }

    const chaordicShowcase = chaordicData.data;

    const skusFromshowcases = this.getSkusFromShowcases([chaordicShowcase]);
    await this.getAsyncPrices(skusFromshowcases);

    if (data && !data.error) {
      this.setData(data.conteudoJson);
    }

    if (!!chaordicData && !chaordicData.error) {
      this.setChaordicShowcase(chaordicData.data);
    }
  };

  @action getProductDataFromAPI = async productCode => {
    const validCode = productCode.substring(0, 6);
    const objInList = this.isThisProdOnList(validCode);

    if (!objInList.val) {
      const { data, error } = await mobileHomeModule.getProductData(validCode);

      if (error) {
        this.addErrorToPool(error);
        return;
      }

      if (data && !data.error) {
        this.setProductDataToList(data);
      }
    }
  };

  @action getAsyncPrices = async modelList => {
    const { data, error } = await mobileHomeModule.getAsyncPrice(modelList);

    if (error) {
      this.addErrorToPool(error);
      return;
    }

    if (data && !data.error) {
      this.setPrices(data);
    }
  };

  getPricesForModel = modelId => {
    const arrLen = this.pricesList.length;
    let priceVal = {};
    for (let i = 0; i < arrLen; i += 1) {
      if (this.pricesList[i].codigoModeloCor === modelId) {
        priceVal = {
          precoDe: this.pricesList[i].valorDe,
          preco: this.pricesList[i].valor,
          numeroParcelas: this.pricesList[i].numeroDeParcelas,
          valorParcela: this.pricesList[i].quantidadePorParcela,
        };
        break;
      }
    }
    return priceVal;
  };

  getProductInfo = productCode => {
    const validCode = productCode.substring(0, 6);
    const objInList = this.isThisProdOnList(validCode);
    if (this.productListData.slice().length > 0) {
      return this.productListData[objInList.index];
    }
    return false;
  };

  isThisProdOnList = modelIdString => {
    const arrLen = this.productListData.length;
    let inList = false;
    let pos = 0;
    if (arrLen > 0) {
      for (let i = 0; i < arrLen; i += 1) {
        if (this.productListData[i].informacoes?.codigo === modelIdString) {
          pos = i;
          inList = true;
          break;
        }
      }
    }

    return {
      val: inList,
      index: pos,
    };
  };
}

export default MobileHomeStore;
