import { isProduction } from '@/utils/env';
import { sendToGTM } from '@/utils/googleTagManager';

import { getStorageActiveExperiments, setStoreActiveExperiments } from './storageExperiments';

// This is the way we found to access experiments and variants IDs
// more info on: https://support.google.com/optimize/answer/9059383?hl=en
function gtag() {
  // eslint-disable-next-line no-undef
  sendToGTM(arguments);
}

export const createPersistenceExperimentsFn = (saveExperiments, getExperimentsList) => (
  variant,
  experimentId,
) => {
  const activeExperimentsList = getExperimentsList();
  const hasExperimentOnStorage = Boolean(
    activeExperimentsList.find(activeExperiment => activeExperiment.experimentId === experimentId),
  );

  if (hasExperimentOnStorage && isProduction()) {
    return;
  }

  saveExperiments({
    experiments: [
      ...activeExperimentsList.filter(
        activeExperiment => activeExperiment.experimentId !== experimentId,
      ),
      {
        experimentId,
        variant,
      },
    ],
  });
};

export const createClearFn = saveExperiments => {
  return () => {
    saveExperiments({ experiments: [] });
  };
};

export const persistExperiments = createPersistenceExperimentsFn(
  setStoreActiveExperiments,
  getStorageActiveExperiments,
);

export const clearPersistedExperiments = createClearFn(setStoreActiveExperiments);

export function persistOptimizeActiveExperiments(callback = persistExperiments) {
  gtag('event', 'optimize.callback', { callback });
}
