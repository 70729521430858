import { window, document } from 'global';
import { clone } from 'ramda';
import ReactGA from 'react-ga';
import currency from 'currency.js';
import { v4 as uuidv4 } from 'uuid';

import {
  homePath,
  hotsitePath,
  navPath,
  neemuApiKey,
  neemuSecretkey,
  ppcPath,
  searchPath,
  smartBannerSwitch,
} from '../settings';
import { isServerSSR, isClientSSR, isSPA } from '../helpers/ssrHelper';
import GlobalObjects from '../components/common/globalObjects/GlobalObjects';

const isDevelopment = process.env.NODE_ENV === 'development';

const isNavigation = window && window.location.host.includes('esportes');

const objectIsEmpty = obj => Object.keys(obj).length === 0;

const cloneObject = obj => clone(obj);

const isMobileDevice =
  window &&
  navigator.userAgent.match(/(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i);

const isIOS = window && navigator.userAgent.match(/(iPhone|iPod|iPad)/);

const isSafari =
  window &&
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') === -1 &&
  navigator.userAgent.indexOf('FxiOS') === -1;

const formatCpf = cpfNumber =>
  cpfNumber && cpfNumber.replace(/(\d{3}|\W{3})(\d{3}|\W{3})(\d{3}|\W{3})/g, '$1.$2.$3-');

const formatCnpj = cnpjNumber =>
  cnpjNumber &&
  cnpjNumber.replace(/(\d{2}|\W{2})(\d{3}|\W{3})(\d{3}|\W{3})(\d{4}|\W{4})/g, '$1.$2.$3/$4-');

const formatZipcode = zipcodeNumber =>
  zipcodeNumber && zipcodeNumber.replace(/(\d{5})(\d{3})/g, '$1-$2');

const openStaticPopup = (a, b) => {
  if (!window) {
    return;
  }

  window.open(a, '', b);
};

const isServer = () =>
  Boolean(typeof process !== 'undefined' && process.release && process.release.name === 'node');

const splitSku = (sku = '') => ({
  model: sku.substr(0, 6),
  color: sku.substr(6, 2),
  size: sku.substr(8, 3),
  verifier: sku.substr(11, 1),
});

const formatCurrency = (number, currency = 'pt-BR') =>
  Number(String(number).replace(',', '.')).toLocaleString(currency, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

const addCurrency = n => {
  if (process.env.NODE_ENV === 'development') {
    /* eslint-disable */
    // console.error('**********************************************************');
    // console.error('*DON\'T USE "addCurrency" METHOD. THIS IS A MADE IN CHINA METHOD. USE "formatCurrency" METHOD INSTEAD');
    // console.error('**********************************************************');
  }

  if (!n) return 'R$ 0,00';
  if (n === '...') return n;

  return `R$ ${Number(String(n).replace(',', '.'))
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+,)/g, '$1.')}`;
};

const formatPriceNumbers = price => {
  if (process.env.NODE_ENV === 'development') {
    /* eslint-disable */
    // console.error('**********************************************************');
    // console.error('*DON\'T USE "formatPriceNumbers" METHOD. THIS IS A MADE IN CHINA METHOD. USE "formatCurrency" METHOD INSTEAD');
    // console.error('**********************************************************');
  }

  return Number(price.toString().replace(',', '.'));
};

// TODO: Refazer isso aqui
const normalizeString = (str, type = 'name', separator = '') => {
  if (!str) return str;

  const alwaysLowerCase = value => ['de', 'da', 'das', 'do', 'dos', 'e'].indexOf(value) !== -1;

  const strArray = str.toLowerCase().split(' ');

  for (let i = 0; i < strArray.length; i += 1) {
    if (i === 0 || !alwaysLowerCase(strArray[i])) {
      const wordArray = strArray[i].split('');

      wordArray[0] = !!wordArray[0] ? wordArray[0].toUpperCase() : '';
      if (separator !== '') {
        const posBar = wordArray.indexOf(separator) + 1;
        if (posBar > -1) wordArray[posBar] = wordArray[posBar].toUpperCase();
      }
      strArray[i] = wordArray.join('');
    }
    if (type === 'text') break;
  }

  return strArray.join(' ');
};

const getCookie = name => {
  if (!document || !document.cookie) return null;
  const match = document.cookie.match(new RegExp(`(^| )${name.toString()}=([^;]+)`));
  if (!match) return null;
  return match[2];
};

const setCookie = (name, value, days) => {
  if (!document) return;

  let expires = '';

  if (days) {
    const d = new Date();
    const tmp = d.getTime() + 24 * 60 * 60 * 1000 * days;
    d.setTime(tmp);
    expires = `expires=${d.toGMTString()}`;
  }

  document.cookie = `${name}=${value};path=/;domain=.centauro.com.br;${expires};secure`;
};

const eraseCookie = cookieName => {
  if (!document) return;

  document.cookie = `${cookieName}=;path=/;domain=.centauro.com.br;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

//TODO: deleteCookie - nao funciona local pois nao tem domain setado, duplicando o cookie
const deleteCookie = cname => {
  if (!window) return;

  const d = new Date();
  d.setTime(d.getTime() - 1000 * 60 * 60 * 24);
  const expires = `expires=${d.toGMTString()}`;
  window.document.cookie = `${cname}=; '${expires}`;
};

const deleteCookiesLogin = () => {
  if (!document) return;
  eraseCookie('ECOMMERCE.CENTAURO.AUTH');
  eraseCookie('centauro.customer');
  eraseCookie('siteMetadata.sessaoInfo.usuarioInfo');
};

const getCurrentISOTime = () => {
  const currentDate = new Date();
  return currentDate.toISOString();
};

const send404 = (file = '?', error = '?') => {
  if (!window) return;

  setTimeout(() => ReactGA.pageview(window.location.pathname), 0);

  if (process.env.NODE_ENV === 'production' && !isClientSSR) {
    window.location.href = 'https://www.centauro.com.br/404';
  } else {
    /* eslint-disable */
    console.log('**********************************************************');
    console.log('*  OCORREU UM ERRO, você receberia um redirect para 404');
    // console.log('*  local:', file);
    error && console.log('*  error:', error);
    console.log('**********************************************************');
    /* eslint-enable */
  }
};

const formatReal = int => {
  if (process.env.NODE_ENV === 'development') {
    /* eslint-disable */
    // console.error('**********************************************************');
    // console.error('*DON\'T USE "formatReal" METHOD. THIS IS A MADE IN CHINA METHOD. USE "formatCurrency" METHOD INSTEAD');
    // console.error('**********************************************************');
  }

  if (Number(int) === 0) return 'Grátis';

  return addCurrency(int);
};

const pushDatalayer = (objDataLayer, newDataLayer) => {
  if (!window) return;
  if (newDataLayer) {
    window?.newDataLayer?.push(objDataLayer);
  } else {
    window?.dataLayer?.push(objDataLayer);
  }
};

const scrollTo = (element, to, duration) => {
  const el = element;
  const start = el.scrollTop;
  const change = to - start;
  let currentTime = 0;
  const increment = 10;

  const animateScroll = () => {
    // código chines trazido para dentro da funcao animaScroll()
    // t = current time b = start value c = change in value  d = duration
    const easeInOutQuad = (t, b, c, d) => {
      let time = t;
      time /= d / 2;
      if (time < 1) return (c / 2) * time * time + b;
      time -= 1;
      return (-c / 2) * (time * (time - 2) - 1) + b;
    };

    currentTime += increment;
    const val = easeInOutQuad(currentTime, start, change, duration);
    el.scrollTop = val;
    if (currentTime < duration) setTimeout(animateScroll, increment);
  };

  animateScroll();
};

const isMobileSSRFunc = () => {
  // Variáveis de ambiente passadas para o Node por parametros pelos scripts no package são Strings.
  // Já as variáveis de ambiente geradas no build do Weppack (envVariables.js) são Booleanas.
  // TODO: Tem como fazer o Node ler corretamente o arquivo envVariables?
  return process.env.IS_MOBILE_SSR === true || process.env.IS_MOBILE_SSR === 'true';
};

const isMobileFunc = () => {
  const isMobileSSR = isMobileSSRFunc();
  if (!window) return isMobileSSR;
  return isMobileSSR || window?.innerWidth < 768;
};

const hasMultiplusCookie = () => getCookie('HabilitaPontosMultiplus') === 'true';

// O método generateHash não deveria ser usado para gerar keys apenas para burlar o eslint.
const generateHash = () => String(new Date().getTime()) + String(Math.random());

const removeDiacriticsAndAccents = value =>
  String(value)
    .replace(/\-|\$|\%|\_|\//g, '')
    .replace(/\s+/g, '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

const removeAccents = value =>
  String(value)
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

const toggleScrollFromBody = action => {
  if (!document) return;
  //ORIGINAL alterado para teste AB (optimize)
  //if (action === 'lock') {
  //  document.body.style.position = 'fixed';
  //  document.body.style.width = '100%';
  //} else document.body.style.position = 'static';

  if (action === 'lock') {
    document.body.style.position = 'relative';
    document.body.style.overflow = 'hidden';
    document.body.style.width = '100%';
  } else {
    document.body.style.position = 'static';
    document.body.style.overflow = 'inherit';
  }
};

const getAllStoresInfos = deliveryData => {
  const retiraNaLoja = deliveryData.find(tipo => tipo.nome === 'Retira na Loja');

  let allStores = [];

  if (retiraNaLoja) {
    retiraNaLoja.lojasRetira.listaEstado[0].listaCidade.forEach(
      cidade => (allStores = [...allStores, ...cidade.listaLojas]),
    );
  }

  return allStores;
};

const getNeemuSecurityParams = () =>
  process.env.NODE_ENV === 'development' || isServerSSR
    ? `apiKey=${neemuApiKey}&secretKey=${neemuSecretkey}`
    : `apiKey=${neemuApiKey}`;

const scrollToTop = () => {
  if (window)
    window?.scroll({
      top: 0,
      behavior: 'smooth',
    });
};

const clearCPFMask = maskedCpf => String(maskedCpf).replace(/\.|\_|\-|\//g, '');

const sendProductToDataLayer = (action, sku, skuPrice) => {
  const eventName = action === 'add' ? 'ProdutoAdicionado' : 'ProdutoRemovido';

  if (window && window?.dataLayer)
    window.dataLayer.push({
      event: eventName,
      eventSku: sku,
      eventSkuPrice: skuPrice,
    });
};

const stateAbbreviations = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
];
export const getCouponFromQueryString = () => {
  const url = window?.location?.search?.toLowerCase?.();

  const queryStringCoupon = new URLSearchParams(url).get('cupomid');

  return queryStringCoupon;
};

const getCouponFromQueryStringOrCookie = () => {
  if (!isSPA) {
    return '';
  }
  const queryStringCoupon = getCouponFromQueryString();
  const cookieCoupon = getCookie('centauro.cp');

  if (queryStringCoupon) {
    if (!cookieCoupon || cookieCoupon !== queryStringCoupon) {
      setCookie('centauro.cp', queryStringCoupon);
    }

    return queryStringCoupon;
  }

  if (cookieCoupon) return cookieCoupon;

  return '';
};

export const callGlobalObjects = async pageTitle => {
  setTimeout(() => {
    const objGlobalInfo = {
      page: pageTitle,
    };
    GlobalObjects(objGlobalInfo);
  }, 0);
};

export const deviceId = isMobileFunc() ? 7 : 1;

const isAuthenticated = () => !!getCookie('siteMetadata.sessaoInfo.usuarioInfo');

const cartLocalStorage = () => !isAuthenticated();

const shouldShowSmartBanner = () => {
  const address = [navPath, hotsitePath, ppcPath, searchPath];

  if (!window || !isMobileFunc() || !smartBannerSwitch) {
    return;
  }

  return address.find(item => {
    return window?.location?.pathname === homePath || window?.location?.href?.includes?.(item);
  });
};

const getCRMUrlTags = () => {
  if (!isSPA) {
    return '';
  }

  const tags = ['utm_source', 'utm_medium', 'utm_campaign', 'origem'];
  const url = window.location.search.toLowerCase();

  return tags
    .map(tag => {
      const utmValue = new URLSearchParams(url).get(tag);
      return utmValue ? `${tag}=${utmValue}` : '';
    })
    .filter(utmValue => utmValue !== '')
    .join('&');
};

const getFreightInfo = (freight, freeFreight) => {
  if (freeFreight) return 0;

  if (!freeFreight && freight === 0) {
    return;
  }

  return freight;
};

export const checkPeriod = ({ start = 'January 1, 2020 00:00:00', end }) => {
  const startDate = new Date(`${start} GMT-03:00`);
  const today = new Date();
  const endDate = new Date(`${end} GMT-03:00`);

  return startDate <= today && today <= endDate;
};

export const lockedCancellation = () => {
  return (
    checkPeriod({ start: 'December 24, 2020 17:00:00', end: 'December 26, 2020 00:00:00' }) ||
    checkPeriod({ start: 'December 31, 2020 15:00:00', end: 'January 2, 2021 00:00:00' })
  );
};

export const moneyFormat = value =>
  currency(value, {
    decimal: ',',
    symbol: 'R$ ',
    separator: '.',
    precision: 2,
  }).format();

export const recommendationStorage = () => window.localStorage.getItem('products_recommendation');

export const checkPageType = () => {
  let pageType = null;

  const validationTerms = {
    '/nav': 'nav',
    '/sc/': 'sc',
    '/hotsite/': 'hotsite',
    '.html': 'product',
    '-html': 'product',
    '/busca': 'search',
    '/carrinho': 'cart',
    '/slogin': 'sign_in',
    '/registro': 'sign_up',
    '/checkouts/iniciar': 'begin_checkout',
    '/checkouts/confirmacao': 'purchase',
    '/minha-conta/': 'account',
    '/trocas-e-devolucoes': 'account',
  };

  Object.keys(validationTerms).find(element => {
    if (window.location.pathname.includes(element)) {
      pageType = validationTerms[element];
    }
  });

  if (!pageType && window.location.pathname === '/') {
    pageType = 'home';
  }

  if (!pageType && window.location.pathname !== '/') {
    pageType = 'generic';
  }

  return pageType;
};

export const getReferralValue = () => {
  const hasGtmEvent = window.newDataLayer.filter(gtmEvent => {
    if (gtmEvent.event_type) {
      return gtmEvent;
    }
  });

  if (hasGtmEvent.length > 0) {
    const actualReferrefer = sessionStorage.getItem('referrer');

    if (actualReferrefer !== window.location.href) {
      sessionStorage.setItem('referrer', window.location.href);
      return actualReferrefer;
    }
  } else {
    sessionStorage.setItem('referrer', window.location.href);
    if (document.referrer !== '') {
      return document.referrer;
    }
  }

  return sessionStorage.getItem('referrer');
};

export const addLatLongAccuracyToDataLayer = () => {
  window.navigator.geolocation.getCurrentPosition(position => {
    const { latitude, longitude, accuracy } = position.coords;
    for (let i = 0; i < window.newDataLayer.length; i++) {
      if (
        window.newDataLayer[i].page_url === window.location.href &&
        window.newDataLayer[i].event_type === 'page_view'
      ) {
        window.newDataLayer[i] = {
          ...window.newDataLayer[i],
          lat_long: `${latitude},${longitude}`,
          lat_long_accuracy: accuracy,
        };
      } else {
        window.newDataLayer[i] = {
          ...window.newDataLayer[i],
          lat_long: `${latitude},${longitude}`,
        };
      }
    }
  });
};

export const currencyToNumber = currency => {
  return Number(currency.replace(/[^0-9\\,]+/g, '').replace(/,/g, '.'));
};

export const getViewCount = () => {
  if (!sessionStorage.getItem('viewCount')) {
    sessionStorage.setItem('viewCount', 0);
  }

  sessionStorage.setItem('viewCount', parseInt(sessionStorage.getItem('viewCount')) + 1);

  return parseInt(sessionStorage.getItem('viewCount'));
};

export const getAnonymousId = () => {
  localStorage.setItem('anonymousid', localStorage.getItem('anonymousid') || uuidv4());
  return localStorage.getItem('anonymousid');
};

export const sendClickToDataLayer = (obj, event = {}) => {
  localStorage.setItem('interactionCount', parseInt(localStorage.getItem('interactionCount')) + 1);

  pushDatalayer(
    {
      event_type: 'click',
      timestamp: new Date(),
      interaction_count: parseInt(localStorage.getItem('interactionCount')),
      event: 'select_content',
      ...obj,
    },
    true,
  );
};

export const getSellerInfo = product => {
  if (!product || !product.details || product.details?.marketplace.length === 0) {
    return {
      seller: [],
      seller_type: '',
    };
  }

  const isMarketplace = product.details.marketplace[0] === 'false' ? false : true;
  const seller = isMarketplace ? product.details?.CodigoSeller : ['Centauro'];
  const sellerType = isMarketplace ? 'third_party' : 'first_party';

  return {
    seller: seller,
    sellerType: sellerType,
  };
};

export const isNumeric = n => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const transformMediaTags= () =>{
if(!isSPA){
  return
}

const transformUtmInU= sessionStorage.getItem('media_tags').replaceAll('utm_','u_').replaceAll('gclid', 'u_gclid');
const mediaTagObject = JSON.parse(transformUtmInU);

Object.keys(mediaTagObject).map((key)=>{
  if(mediaTagObject[key] === ""){
      delete mediaTagObject[key]
  }else {
  return `${key}=${mediaTagObject[key]}`
  }
}).join('&')

return new URLSearchParams(mediaTagObject).toString() ;
}

const parseCustomDetails = customValue => {
  try {
        const parsedValue = JSON.parse(customValue);
        return isNaN(parsedValue) ? parsedValue : String(parsedValue)
      } catch (_) {
    return customValue;
  }
};

export {
  isDevelopment,
  isNavigation,
  objectIsEmpty,
  cloneObject,
  isMobileDevice,
  isSafari,
  openStaticPopup,
  isServer,
  splitSku,
  formatPriceNumbers,
  normalizeString,
  getCookie,
  setCookie,
  deleteCookie,
  eraseCookie,
  deleteCookiesLogin,
  getCurrentISOTime,
  send404,
  formatReal,
  pushDatalayer,
  addCurrency,
  scrollTo,
  generateHash,
  isMobileFunc,
  isMobileSSRFunc,
  hasMultiplusCookie,
  removeDiacriticsAndAccents,
  formatCurrency,
  removeAccents,
  toggleScrollFromBody,
  formatCpf,
  formatCnpj,
  formatZipcode,
  getNeemuSecurityParams,
  getAllStoresInfos,
  scrollToTop,
  clearCPFMask,
  sendProductToDataLayer,
  stateAbbreviations,
  isIOS,
  getCouponFromQueryStringOrCookie,
  isAuthenticated,
  cartLocalStorage,
  shouldShowSmartBanner,
  getCRMUrlTags,
  getFreightInfo,
  parseCustomDetails,
};
