import { action, observable, toJS, computed } from 'mobx';

import { getItemsForCancellation, createCancellationRequest } from '../modules/cancellationModule';

import { lockedCancellation } from '@/utils/utils';

import { sendCESAnswer } from '../modules/apiCommons';

class CancellationStore {
  constructor({ loginStore, errorHandlerStore }) {
    this.loginStore = loginStore;
    this.errorHandlerStore = errorHandlerStore;
  }

  addErrorMessage = (message, type) => {
    this.errorHandlerStore.setError({
      message,
      type,
    });
  };

  @observable pageName = 'Cancelamento';

  @observable loading = false;

  @observable dataForCancellation = {};

  @observable selectedOrder = {
    pedido: '',
    boleto: false,
    itens: [],
  };

  @observable reasonCancellation = null;

  @observable observation = '';

  @observable chargeback = null;

  @observable bankDataObj = {
    banco: '',
    agencia: '',
    conta: '',
    nome: '',
    cpf: '',
  };

  @observable countType = '';

  @observable cancellationRating = null;

  @observable cancellationComment = '';

  @observable hasError = null;

  @observable cancellationCompleted = false;

  @observable orbiumError = false;

  @observable orbiumProtocol = null;

  @observable uniqueProtocol = null;

  @observable unpaidSlip = false;

  @action setLoading = loadingState => (this.loading = loadingState);

  @action getItemsForCancellation = async () => {
    this.setLoading(true);

    const header = await this.clientHeader();
    const { data, error } = await getItemsForCancellation({
      cvId: header['x-cv-id'],
      clientToken: header['x-client-token'],
    });

    this.setLoading(false);

    if (error) {
      this.addErrorMessage('Ocorreu um erro ao tentar acessar seus pedidos', 'error');
      this.setDataForCancellation(data);
      this.setHasError(error);
    } else {
      this.setDataForCancellation(data);
    }
  };

  @action sendCancellationRequest = async () => {
    if (lockedCancellation()) {
      this.setOrbiumProtocol(null);
      this.setOrbiumError(true);
      return;
    }

    const cardOrSplit = this.isSlip ? 'B' : 'C';

    const chargebackCancellation = this.chargeback === 2 ? cardOrSplit : 'VT';

    const objData = {
      motivoCancelamento: this.reasonCancellation,
      observacao: this.observation,
      tipoConta: this.countType,
      meioDeEstorno: chargebackCancellation,
      ...this.selectedOrder,
      ...this.bankDataObj,
    };

    const header = await this.clientHeader();

    this.setLoading(true);

    const { data, error } = await createCancellationRequest(objData, {
      cvId: header['x-cv-id'],
      clientToken: header['x-client-token'],
    });

    this.setLoading(false);

    if (error) {
      this.setOrbiumProtocol(null);
      this.setOrbiumError(true);
    } else {
      this.setOrbiumProtocol(data);
      this.setOrbiumError(false);
      this.setCancellationCompleted(true);
    }
  };

  @action sendCancellationCES = async () => {
    const objCES = {
      PedidoId: this.selectedOrder.pedido,
      Nota: this.cancellationRating,
      Comentario: this.cancellationComment,
      Protocolo: this.uniqueProtocol,
      Origem: 'cancelamento',
      Campanha: 'ces',
    };

    const header = await this.clientHeader();

    sendCESAnswer(objCES, {
      cvId: header['x-cv-id'],
      clientToken: header['x-client-token'],
    });
  };

  @action setUniqueProtocol = protocol => (this.uniqueProtocol = protocol);

  @action setDataForCancellation = data => (this.dataForCancellation = data);

  @action setCancellationCompleted = value => (this.cancellationCompleted = value);

  @action setOrbiumError = error => (this.orbiumError = error);

  @action setOrbiumProtocol = protocolNumber => (this.orbiumProtocol = protocolNumber);

  @action setHasError = error => (this.hasError = error);

  @action setReasonCancellation = id => {
    if (id !== this.reasonCancellation) {
      this.reasonCancellation = id;
      this.observation = '';
    }
  };

  @action setObservation = ({ target }) => (this.observation = target.value);

  @action setQuantItem = (operation, indexItem, maxQuant = null) => {
    if (this.selectedOrder.itens[indexItem].quantidade > 1 && operation === 'sub') {
      this.selectedOrder.itens[indexItem].quantidade -= 1;
    } else if (this.selectedOrder.itens[indexItem].quantidade < maxQuant && operation === 'sum') {
      this.selectedOrder.itens[indexItem].quantidade += 1;
    }
  };

  @computed get lastOrder() {
    return this.dataForCancellation?.length > 0 ? this.dataForCancellation[0] : {};
  }

  @computed get orderId() {
    return this.lastOrder?.pedidoId;
  }

  @computed get isOnlySlip() {
    return this.lastOrder?.formaPagamento === 1;
  }

  @computed get isSlip() {
    return this.lastOrder?.formaPagamento === 1 || this.lastOrder?.formaPagamento === 4;
  }

  @computed get resumeAllItems() {
    const lastOrder = toJS(this.lastOrder);

    const resumeItems = lastOrder?.gruposDeEntrega?.flatMap(deliveryGroup =>
      deliveryGroup.itens
        .filter(selectable => selectable.situacao === 1)
        .map(item => ({
          grupoEntregaId: deliveryGroup.grupoDeEntregaId,
          sku: item.sku,
          quantidade: item.quantidade,
          situacao: item.situacao,
          codigoSeller: item.codigoSeller,
          imagem: item.imagem,
          nome: item.nome,
          valorUnitario: item.valorUnitario,
          freteUnitario: item.freteUnitario,
          cor: item.cor,
          tamanho: item.tamanho,
          vendidoPor: item.vendidoPor,
          entreguePor: item.entreguePor,
        })),
    );

    return resumeItems;
  }

  @computed get totalCancellationItems() {
    const arrayQuantCancellation = this.selectedOrder?.itens?.map(item => item.quantidade);

    const quantCancellation = arrayQuantCancellation?.reduce(
      (quantAcc, currentValue) => quantAcc + currentValue,
      0,
    );

    return quantCancellation;
  }

  @computed get fullOrderSelected() {
    const arrayTotalQuant = this.resumeAllItems?.map(item => item.quantidade);

    const totalQuant = arrayTotalQuant?.reduce(
      (quantAcc, currentValue) => quantAcc + currentValue,
      0,
    );

    return totalQuant === this.totalCancellationItems;
  }

  @computed get allSelectedProducts() {
    return this.selectedOrder?.itens?.length === this.resumeAllItems?.length;
  }

  @action toggleSelectedItems = ({ item = {}, allItems = false, deliveryGroupId = 0 }) => {
    const newItem = {
      grupoEntregaId: deliveryGroupId,
      sku: item.sku,
      quantidade: item.quantidade,
      situacao: item.situacao,
      codigoSeller: item.codigoSeller,
      imagem: item.imagem,
      nome: item.nome,
      valorUnitario: item.valorUnitario,
      freteUnitario: item.freteUnitario,
      cor: item.cor,
      tamanho: item.tamanho,
      vendidoPor: item.vendidoPor,
      entreguePor: item.entreguePor,
    };

    const arrayItems = [...this.selectedOrder.itens];
    const items = !allItems ? [...arrayItems, newItem] : [...this.resumeAllItems];
    const indexItem = arrayItems.findIndex(value => value.sku === newItem.sku);

    if ((allItems && !this.allSelectedProducts) || (!allItems && indexItem === -1)) {
      this.selectedOrder = {
        pedido: this.orderId,
        boleto: this.isSlip,
        itens: items,
      };
    } else {
      const filteredItems = !allItems ? arrayItems.filter((_, index) => index !== indexItem) : [];
      this.selectedOrder = {
        pedido: this.orderId,
        boleto: this.isSlip,
        itens: filteredItems,
      };
    }
  };

  @computed get totalValueProducts() {
    const arraytotalValueProducts = this.selectedOrder?.itens?.map(
      item => item.valorUnitario * item.quantidade,
    );
    return arraytotalValueProducts?.reduce((totalAcc, currentValue) => totalAcc + currentValue, 0);
  }

  @computed get totalFreight() {
    const arrayTotalFreight = this.selectedOrder?.itens?.map(
      item => item.freteUnitario * item.quantidade,
    );
    return arrayTotalFreight?.reduce((freightAcc, currentValue) => freightAcc + currentValue, 0);
  }

  @computed get amountWithFreight() {
    return this.totalValueProducts + this.totalFreight;
  }

  @action setChargeback = id => {
    this.chargeback = id;
    if (this.chargeback !== 2) {
      this.clearBankData();
    }
  };

  @action addBankDataObj = ({ target, value }) => {
    const bankDataObj = toJS(this.bankDataObj);

    this.bankDataObj = {
      ...bankDataObj,
      [target]: value,
    };
  };

  @action setCountType = type => (this.countType = type);

  @action clearBankData = () => {
    this.bankDataObj = {
      banco: '',
      agencia: '',
      conta: '',
      nome: '',
      cpf: '',
    };
    this.setCountType('');
  };

  @computed get cancellationSchedule() {
    return this.lastOrder?.horario;
  }

  @computed get hasItemsForCancellation() {
    return this.lastOrder?.possuiItens;
  }

  @computed get isSingleDeliverySoldByMktp() {
    const delivery = this.lastOrder?.gruposDeEntrega;
    const singleDelivery = delivery?.length === 1;
    const soldByMktp = delivery?.[0].itens[0].vendidoPor?.toLowerCase() !== 'centauro';
    const deliveredByCentauro = delivery?.[0].itens[0].entreguePor?.toLowerCase() == 'centauro';

    return singleDelivery && soldByMktp && deliveredByCentauro;
  }

  @action setCancellationRating = rating => (this.cancellationRating = rating);

  @action setCancellationComment = ({ target }) => {
    this.cancellationComment = target.value;
  };

  @action setUnpaidSlip = value => (this.unpaidSlip = value);

  clientHeader = async () => {
    return toJS(this.loginStore.clientHeader);
  };
}

export default CancellationStore;
