import { getJson, postJson } from './httpClientCentauro';
import { apiDomain } from '../settings';

const deliveriesUrl = `${apiDomain}/v4/checkout/entregas`;
const deliveriesLocationUrl = `${apiDomain}/v4.1/checkout/entregas/localizacao`;

const getDeliveriesList = ({ cvId, addressId, clientToken, televendas }) => {
  const headers = {
    'x-cv-id': cvId,
    'x-client-token': clientToken,
  };
  if (televendas) {
    headers['x-televendas'] = televendas;
  }
  return getJson(`${deliveriesUrl}/${addressId}`, headers);
};

const setDeliveries = ({ cvId, clientToken, deliveryData }) =>
  postJson(`${apiDomain}/v4.1/checkout/entregas`, deliveryData, {
    'x-cv-id': cvId,
    'x-client-token': clientToken,
  });

const getDeliveriesLocation = ({ cvId, clientToken, requestData }) =>
  postJson(deliveriesLocationUrl, requestData, {
    'x-cv-id': cvId,
    'x-client-token': clientToken,
  });

export { getDeliveriesList, setDeliveries, getDeliveriesLocation };
