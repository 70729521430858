import { action, observable, toJS, computed } from 'mobx';

import { getExchangesData, createDataTicketCRM } from '../modules/exchangesModule';

import { sendCESAnswer } from '../modules/apiCommons';

import history from '../history';

import { exchangesPath } from '../settings';

import {
  defaultMessage,
  messageStore,
  messageExchangeSize,
  messageExchangeVoucher,
  messageSlip,
  messageCreditCard,
} from '../components/exchanges/confirmationExchange/nextStepsMessages/NextStepsMessages';

class ExchangesStore {
  constructor({ loginStore, errorHandlerStore }) {
    this.loginStore = loginStore;
    this.errorHandlerStore = errorHandlerStore;
  }

  @observable reasonsList = [
    {
      id: 1,
      title: 'Ficou pequeno',
    },
    {
      id: 2,
      title: 'Ficou grande',
    },
    {
      id: 5,
      title: 'Não gostei',
    },
    {
      id: 3,
      title: 'Defeito de fabricação',
    },
    {
      id: 6,
      title: 'O produto não é igual ao da foto',
    },
    {
      id: 4,
      title: 'Não gostei da cor',
    },
  ];

  @observable valuesInput = {
    1: {
      label: 'Qual outro tamanho deseja?',
      placeholder: 'Digite o tamanho ',
      type: 'text',
      labelProductDetails: 'Tamanho solicitado',
    },
    2: {
      label: 'Qual outro tamanho deseja?',
      placeholder: 'Digite o tamanho ',
      type: 'text',
      labelProductDetails: 'Tamanho solicitado',
    },
    3: {
      label: 'Qual é o defeito?',
      placeholder:
        'Digite brevemente o defeito que encontrou nos produtos. Exemplos: Costura solta, item quebrado, rasgado, sola solta.',
      type: 'text',
      labelProductDetails: 'Sobre o defeito',
    },
    4: {
      label: 'Qual cor você deseja?',
      placeholder: 'Digite aqui a cor que deseja',
      type: 'text',
      labelProductDetails: 'Cor desejada',
    },
    5: {
      label: 'Por que você não gostou?',
      placeholder: 'Digite brevemente o que você não gostou',
      type: 'text',
      labelProductDetails: 'Por que não gostou',
    },
    6: {
      label: 'Qual o seu problema?',
      placeholder: 'Digite brevemente seu problema',
      type: 'text',
      labelProductDetails: 'Descrição do problema',
    },
  };

  @computed get labelProductDetails() {
    return this.valuesInput[this.selectedIdReason].labelProductDetails;
  }

  @observable pageName = 'Trocas e Devoluções';

  @observable loading = false;

  @observable exchangesData = {};

  @observable ticketCRMError = false;

  @observable requestTicketCRMData = {
    orbiumProtocol: null,
    postCode: null,
  };

  @observable selectedReason = '';

  @observable selectedIdReason = null;

  @observable valueObservation = '';

  @observable selectedDataItem = {};

  @observable exchangePlace = '';

  @computed get exchangeStore() {
    return this.exchangePlace === 'L';
  }

  @observable optionExchange = '';

  @observable countType = '';

  @observable bankDataObj = {
    banco: '',
    agencia: '',
    conta: '',
    nome: '',
    cpf: '',
  };

  @observable quantItem = 1;

  @observable exchangesRating = null;

  @observable exchangesComment = '';

  @observable exchangeCompleted = false;

  @observable selectedStoreData = {};

  @observable filterStoreWithStock = false;

  @observable size = '';

  @observable showOtherSize = false;

  @observable sourceParameterValue = '';

  @observable userParameterValue = '';

  @observable uniqueProtocol = null;

  @observable hasError = null;

  @observable showCES = true;

  @observable confirmationExchangeVariables = {
    T: {
      requestType: 'de troca',
      variableTitleCES: 'troca',
      titleProductDetails: 'Seu produto da troca',
      optionProductDetails: 'Fazer uma troca',
      nextStepsMessage: this.selectedIdReason < 3 ? messageExchangeSize : defaultMessage,
    },
    VT: {
      requestType: 'de troca',
      variableTitleCES: 'troca',
      titleProductDetails: 'Produto a ser recebido',
      optionProductDetails: 'Receber um vale-troca',
      nextStepsMessage: messageExchangeVoucher,
    },
    D: {
      requestType: 'de devolução',
      variableTitleCES: 'devolução',
      titleProductDetails: 'Produto a ser devolvido',
      optionProductDetails: 'Devolver o produto',
      nextStepsMessage: this.selectedDataItem.boleto ? messageSlip : messageCreditCard,
    },
    L: {
      requestType: 'de troca/devolução',
      variableTitleCES: 'troca/devolução',
      titleProductDetails: 'Seu pedido de troca/devolução',
      optionProductDetails: 'Fazer uma troca',
      nextStepsMessage: messageStore,
    },
  };

  @computed get confirmationExchangeLabels() {
    return this.exchangePlace === 'L'
      ? this.confirmationExchangeVariables[this.exchangePlace]
      : this.confirmationExchangeVariables[this.optionExchange];
  }

  addErrorMessage = (message, type) => {
    this.errorHandlerStore.setError({
      message,
      type,
    });
  };

  @action getExchangesData = async () => {
    this.setLoading(true);

    const header = await this.clientHeader();

    const { data, error } = await getExchangesData({
      cvId: this.sourceParameterValue === 'app' ? 14 : header['x-cv-id'],
      clientToken:
        this.sourceParameterValue === 'app' ? this.userParameterValue : header['x-client-token'],
    });

    this.setLoading(false);

    if (error) {
      this.addErrorMessage('Ocorreu um erro ao tentar acessar seus pedidos', 'error');
      this.setExchanges(data);
      this.setHasError(true);
    } else {
      this.setExchanges(data);
    }
  };

  @action setExchanges = data => (this.exchangesData = data);

  @action setLoading = loadingState => (this.loading = loadingState);

  @action setHasError = error => (this.hasError = error);

  @action setShowCES = value => (this.showCES = value);

  @action sendTicketCRMData = async (idStore = null) => {
    const cardOrSplit = this.selectedDataItem.boleto ? 'B' : 'C';

    const chargeback = this.optionExchange === 'D' ? cardOrSplit : this.optionExchange;

    const objData = {
      Pedido: this.selectedDataItem.pedidoId,
      Sku: this.selectedDataItem.sku,
      Quantidade: this.quantItem,
      MotivoTrocaDevolucao: this.selectedIdReason,
      LocalTroca: this.exchangePlace,
      Loja: idStore,
      Observacao: this.size || this.valueObservation,
      MeioDeEstorno: chargeback,
      Situacao: this.selectedDataItem.situacao,
      TipoConta: this.countType,
      ...this.bankDataObj,
      CentauroEnvios: this.selectedDataItem.centauroEnvios,
      CodigoSeller: this.selectedDataItem.codigoSeller,
    };

    const header = await this.clientHeader();

    this.setLoading(true);

    const { data, error } = await createDataTicketCRM(objData, {
      cvId: this.sourceParameterValue === 'app' ? 14 : header['x-cv-id'],
      clientToken:
        this.sourceParameterValue === 'app' ? this.userParameterValue : header['x-client-token'],
    });

    this.setLoading(false);

    if (error) {
      this.setShowCES(error?.data?.CES || false);
      this.setRequestTicketCRMData({ orbiumProtocol: null, postCode: null });
      this.setTicketCRMError(true);
    } else {
      this.setRequestTicketCRMData({ orbiumProtocol: data.protocolo, postCode: data.autorizacao });
      this.setTicketCRMError(false);
      this.setExchangeCompleted(true);
    }
  };

  @action sendExchangesCES = async () => {
    const objCES = {
      PedidoId: this.selectedDataItem.pedidoId,
      Nota: this.exchangesRating,
      Comentario: this.exchangesComment,
      Protocolo: this.uniqueProtocol,
      Origem: 'troca',
      Campanha: 'ces',
    };

    const header = await this.clientHeader();

    sendCESAnswer(objCES, {
      cvId: this.sourceParameterValue === 'app' ? 14 : header['x-cv-id'],
      clientToken:
        this.sourceParameterValue === 'app' ? this.userParameterValue : header['x-client-token'],
    });
  };

  @action setUniqueProtocol = protocol => (this.uniqueProtocol = protocol);

  @action setTicketCRMError = error => (this.ticketCRMError = error);

  @action setRequestTicketCRMData = ({ orbiumProtocol, postCode }) => {
    this.requestTicketCRMData = {
      orbiumProtocol,
      postCode,
    };
  };

  @action setExchangeCompleted = value => (this.exchangeCompleted = value);

  @action setSelectedReason = ({ id, title }) => {
    this.selectedReason = title;
    this.selectedIdReason = id;
    this.setFilterStoreWithStock(false);
    this.setOptionExchange('');
    this.valueObservation = '';
    this.setSize('');
    this.setShowOtherSize(false);
  };

  @action setValueObservation = ({ target }) => {
    this.valueObservation = target.value;
    this.setFilterStoreWithStock(false);
    if (this.size !== '') {
      this.setSize('');
    }
  };

  @action setSelectedDataItem = item => {
    this.selectedDataItem = item;
    this.clearExchangeData();
  };

  @action clearBankData = () => {
    this.bankDataObj = {
      banco: '',
      agencia: '',
      conta: '',
      nome: '',
      cpf: '',
    };
    this.setCountType('');
  };

  @action clearExchangeData = () => {
    this.selectedReason = '';
    this.selectedIdReason = null;
    this.valueObservation = '';
    this.clearBankData();
    this.optionExchange = '';
    this.setExchangePlace('');
    this.quantItem = 1;
    this.exchangesComment = '';
    this.setSelectedStoreData({});
    this.setSize('');
    this.setShowOtherSize(false);
    this.setExchangesRating(null);
  };

  @action setExchangePlace = option => (this.exchangePlace = option);

  @action setOptionExchange = option => {
    this.optionExchange = option;
    if (option !== 'D') {
      this.clearBankData();
    }
  };

  @action addBankDataObj = ({ target, value }) => {
    const bankDataObj = toJS(this.bankDataObj);

    this.bankDataObj = {
      ...bankDataObj,
      [target]: value,
    };
  };

  @action setCountType = type => (this.countType = type);

  @action setQuantItem = operation => {
    if (this.quantItem > 1 && operation === 'sub') {
      this.quantItem = this.quantItem - 1;
    } else if (this.quantItem < this.selectedDataItem.quantidade && operation === 'sum') {
      this.quantItem = this.quantItem + 1;
    }
  };

  @action setExchangesComment = ({ target }) => {
    this.exchangesComment = target.value;
  };

  @action setExchangesRating = value => (this.exchangesRating = value);

  @action setSelectedStoreData = store => (this.selectedStoreData = store);

  @action setFilterStoreWithStock = value => (this.filterStoreWithStock = value);

  @action setSize = value => (this.size = value);

  @action setShowOtherSize = value => {
    this.showOtherSize = value;
    this.valueObservation = '';
  };

  @action goToItemsExchange = () => history.push(`${exchangesPath}${window.location.search}`);

  @action goToFormExchange = () =>
    history.push(`${exchangesPath}/${this.selectedDataItem.pedidoId}${window.location.search}`);

  @action goToConfirmationExchange = () =>
    history.push(
      `${exchangesPath}/${this.selectedDataItem.pedidoId}/confirmacao${window.location.search}`,
    );

  @computed get hasItemsForExchange() {
    return this.exchangesData?.length !== 0;
  }

  @action setSourceParameterValue = value => (this.sourceParameterValue = value);

  @action setUserParameterValue = value => (this.userParameterValue = value);

  @action setExceptionItem = () =>
    this.setSelectedReason({ id: 3, title: 'Defeito de fabricação' });

  @action goToItemsExchange = () => history.push(`${exchangesPath}${window.location.search}`);

  @action goToFormExchange = () =>
    history.push(
      `${exchangesPath}/${toJS(this.selectedDataItem.pedidoId)}${window.location.search}`,
    );

  clientHeader = async () => {
    return toJS(this.loginStore.clientHeader);
  };
}

export default ExchangesStore;
