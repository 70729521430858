import ReactGA from 'react-ga';
import { window } from 'global';
import { isSPA } from '../helpers/ssrHelper';

import {
  UACode,
  GADebug,
  GAsiteSpeedRate,
  GACustomDimensionName,
  GACustomDimensionVal,
  dooAnalytics,
  dooSwitch,
} from '../settings';

const setDimension = (dimensionNumber, value) => {
  if (isSPA) {
    ReactGA.set({ [dimensionNumber]: value });
  }
};

const callDooSnippet = () => {
  if (!isSPA || !dooSwitch) {
    return;
  }

  const dooSelector = 'script[src*="dooanalytics.min.js"]';

  if (document.head.querySelector(dooSelector) !== null) {
    document.head.removeChild(document.head.querySelector(dooSelector));
  }

  const dooAScript = document.createElement('script');
  dooAScript.type = 'text/javascript';
  dooAScript.src = dooAnalytics;
  dooAScript.async = false;
  dooAScript.defer = true;

  document.getElementsByTagName('head')[0].appendChild(dooAScript);
};

const setAnalytics = () => {
  if (!isSPA) {
    return;
  }

  ReactGA.initialize(UACode, {
    debug: GADebug,
    gaOptions: {
      siteSpeedSampleRate: GAsiteSpeedRate,
    },
  });

  setDimension(GACustomDimensionName, GACustomDimensionVal);

  callDooSnippet();
};

const sendGAEvent = (category, action, label = '', value = 0, nonInteraction) => {
  if (!isSPA) {
    return;
  }

  // Tentativa de liberar a main tread na VM
  // "O Javascript só tem um tread" - Doug Fanny
  // Apesar do GET ser async, o ato de chamar esse GET é sync no JS por natureza.
  // TODO: funcionou?
  setTimeout(
    ReactGA.event({
      category,
      action,
      label,
      value,
      nonInteraction,
    }),
    0,
  );
};

const sendEvent = (category, action, label = '', value = 0, nonInteraction) => {
  if (!isSPA) {
    return;
  }

  // if (window && window.dataLayer) {
  //   window.dataLayer.push({
  //     event: 'gaEvent',
  //     eventCategory: category,
  //     eventAction: action,
  //     eventLabel: label,
  //     eventValue: value,
  //     eventNonInteraction: nonInteraction,
  //   });
  // }

  sendGAEvent(category, action, label, value, nonInteraction);

  if (window._paq && dooSwitch) {
    setTimeout(() => window._paq.push(['trackEvent', category, action, label, value]), 0);
  }
};

const pageView = (virtualPage = null) => {
  if (!isSPA) {
    return;
  }

  if (window) {
    setTimeout(() => ReactGA.pageview(virtualPage || window.location.pathname, 0));
  }
};

const dooPageView = isMobile => {
  if (!isSPA) {
    return;
  }

  if (!dooSwitch && window.siteMetadata) {
    // pageView();
  } else if (window._paq && window.siteMetadata) {
    const setSiteId = isMobile ? 7 : 1;

    window._paq.push(['setSiteId', setSiteId]);
    window._paq.push(['setTrackerUrl', '//analytics.appsbnet.com.br/doo']);
    window._paq.push(['setCookieDomain', '.centauro.com.br']);
    window._paq.push(['setPageType', window.siteMetadata.paginaInfo.etapa]);
    window._paq.push(['setCustomUrl', `${window.location.pathname}${window.location.search}`]);

    if (
      window.siteMetadata !== 'undefined' &&
      window.siteMetadata.sessaoInfo !== 'undefined' &&
      window.siteMetadata.sessaoInfo.usuarioLogado !== 'undefined'
    ) {
      if (window.siteMetadata.sessaoInfo.usuarioLogado) {
        window._paq.push([
          'setCustomVariable',
          'pI',
          window.siteMetadata.sessaoInfo.usuarioInfo.id,
          'page',
        ]);
        window._paq.push([
          'setCustomVariable',
          'pG',
          window.siteMetadata.sessaoInfo.usuarioInfo.sexo,
          'page',
        ]);
        window._paq.push([
          'setCustomVariable',
          'pA',
          window.siteMetadata.sessaoInfo.usuarioInfo.idade,
          'page',
        ]);
        window._paq.push([
          'setCustomVariable',
          'pS',
          window.siteMetadata.sessaoInfo.usuarioInfo.estado,
          'page',
        ]);
      }
    }

    if (
      window.siteMetadata !== 'undefined' &&
      window.siteMetadata.paginaInfo !== 'undefined' &&
      window.siteMetadata.paginaInfo.etapa !== 'undefined'
    ) {
      if (window.siteMetadata.paginaInfo.etapa === 'DetalheProduto') {
        window._paq.push([
          'setEcommerceView',
          window.siteMetadata.paginaInfo.produtoInfo.modeloCor,
          window.siteMetadata.paginaInfo.produtoInfo.nome,
          window.siteMetadata.paginaInfo.produtoInfo.categoria,
          window.siteMetadata.paginaInfo.produtoInfo.precoBRA,
          window.siteMetadata.paginaInfo.produtoInfo.tipoEstoque,
          '',
        ]);
      }
    }

    window._paq.push(['trackPageView']);

    // pageView();
  } else {
    setTimeout(() => {
      dooPageView(isMobile);
    }, 200);
  }
};

const addTransaction = (id, revenue, shipping) => {
  if (!window) {
    return;
  }

  window.ga('require', 'ecommerce');
  window.ga('ecommerce:addTransaction', {
    id,
    affiliation: 'Centauro',
    revenue,
    shipping,
  });
};

const addItem = (id, name, sku, category, price, quantity) => {
  if (!window) {
    return;
  }

  window.ga('ecommerce:addItem', {
    id,
    name,
    sku,
    category,
    price,
    quantity,
  });
};

const sendTransaction = () => {
  if (!window) {
    return;
  }

  window.ga('ecommerce:send');
};

const sendTransactionToDataLayer = (
  transactionId,
  transactionTotal,
  transactionShipping,
  transactionProducts,
) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      transactionId,
      transactionAffiliation: 'Centauro',
      transactionTotal,
      transactionTax: 0,
      transactionShipping,
      transactionProducts,
    });
  }
};

export {
  setAnalytics,
  sendEvent,
  sendGAEvent,
  pageView,
  setDimension,
  dooPageView,
  addTransaction,
  addItem,
  sendTransaction,
  sendTransactionToDataLayer,
};
