import { observable, action, toJS } from 'mobx';
import { localStorage } from '@/utils/browser';

import { sendEvent } from '../utils/Analytics';
import { isSPA } from '../helpers/ssrHelper';
import { sendRegistration } from '../modules/sellerModule';
import { datadogLogs } from '@datadog/browser-logs';

const STORAGE_KEY = 'seller';

export const STEPS = {
  registration: 1,
  portfolio: 2,
  integration: 3,
  business: 4,
  contacts: 5,
  shipping: 6,
  confirmation: 7,
  success: 8,
  lead: 9, // seller que não vende sports vira apenas um lead
};
class SellerSignUpStore {
  constructor({ errorHandlerStore }) {
    this.errorHandlerStore = errorHandlerStore;
    const parsedSeller = (isSPA && JSON.parse(localStorage().getItem(STORAGE_KEY))) || {};
    this.localData = parsedSeller;
    this.initialStep =
      Object.entries(parsedSeller).filter(([value]) => value.substr(0, 4) === 'step').length + 1;
  }

  @observable step = this.initialStep;

  @action setStep = step => (this.step = step);

  @observable signUp = { ...this.localData };

  @action setSignUp = data => (this.signUp = data);

  @observable maxAllowed = this.initialStep;

  @action setMaxAllowed = data => (this.maxAllowed = Math.max(data, this.maxAllowed));

  sanitizeData = data => {
    const extracted = { ...data };
    if (extracted.cnpj) {
      extracted.cnpj = extracted.cnpj.replace(/\D/g, '');
    }
    if (extracted.cep) {
      extracted.cep = extracted.cep.replace(/\D/g, '');
    }
    return extracted;
  };

  @action setComplete = (nextStep, data) => {
    if (this.step && data) {
      const signUpData = this.sanitizeData(data);
      const newData = { ...toJS(this.signUp), [`step${parseInt(this.step, 10)}`]: signUpData };
      this.setSignUp(newData);
      localStorage().setItem(STORAGE_KEY, JSON.stringify(newData));
    }
    this.setStep(nextStep);
    this.setMaxAllowed(nextStep);

    sendEvent(
      'Ficha Cadastral - Passo completo',
      'Click - Próximo passo',
      'Completou o passo',
      this.step,
    );
  };

  @action clear = () => {
    const newData = { ...toJS(this.signUp) };
    delete newData[`step${parseInt(this.step, 10)}`];
    this.setSignUp(newData);
    localStorage().setItem(STORAGE_KEY, JSON.stringify(newData));
    // TODO: refactor to remove this... probably localStorage async
    const backStep = parseInt(this.step, 10);
    this.setStep(0);
    setTimeout(() => this.setStep(backStep), 0);

    sendEvent('Ficha Cadastral - Limpar página', 'Click - Limpar', 'Limpou a página', this.step);
  };

  @action clearAll = cnpj => {
    localStorage().setItem(STORAGE_KEY, JSON.stringify({ completed: cnpj }));
    this.setSignUp({});
  };

  compose = (list, others, othersMatch = 'Outros') => {
    return list.join(', ') + (list[list.length - 1] === othersMatch ? `: ${others}` : '');
  };

  @action send = ({ step1, step2, step3, step4, step5, step6 }) => {
    const params = new URLSearchParams(); // Seller Data to SalesForce
    params.append('orgid', '00D4P000000kD7a'); // origin id
    params.append('00N4P00000GDn3b', step1.companyName); // nome empresa
    params.append('00N4P00000GDn3v', step1.cnpj); // cnpj
    params.append('00N4P00000GDn9j', step1.site); // site
    params.append('00N3g000000V6mN', step1.sportsSeller === 'Sim'); // Vende produtos de esporte?
    params.append('00N3g000000V6mS', step1.productType || ''); // tipos produtos
    if (step2) {
      params.append('00N3g000000M0w7', this.compose(step2.acessorios, step2.acessoriosOther)); // Acessórios
      params.append('00N3g000000M0wC', this.compose(step2.alimentos, step2.alimentosOther)); // Alimentos
      params.append('00N3g000000M0wH', this.compose(step2.calcados, step2.calcadosOther)); // Calçados
      params.append('00N3g000000M0wM', this.compose(step2.equipamentos, step2.equipamentosOther)); // Equipamentos
      params.append('00N3g000000M0wR', this.compose(step2.vestuario, step2.vestuarioOther)); // Vestuário
      params.append('00N3g000000Sv4B', this.compose(step2.brands, step2.brandsOther, 'Outras')); // Marcas
      params.append(
        '00N4P00000GEdil',
        step3.targetHub === 'Outro' ? `Outro: ${step3.otherTargetHub}` : step3.targetHub,
      ); // Hub Oficial
      params.append('00N4P00000GDnAI', this.compose(step3.hubs, step3.otherhubslist)); // Quais Hubs está integrado?
      params.append('00N4P00000GDn9t', step3.hasOtherMarketplaces || ''); // outros mktplaces?
      params.append('00N4P00000GDn9y', step3.otherMarketplaces || ''); // quais outros mktplaces
      params.append('00N4P00000GEbAm', step4.companyName || ''); // razão social
      params.append('00N4P00000GDxJ0', step4.hasPhisicalStores || ''); // Possui Loja Física?
      params.append('00N4P00000GDxJ5', step4.storeQuantity || ''); // Quantas Lojas Físicas?
      params.append('00N3g000000QVEg', step4.ecommTime); // Há quanto tempo trabalha com ecommerce?
      params.append('00N3g000000QVEl', step4.hasMarketplaceStaff); // Possui um time para marketplace?
      params.append('00N3g000000QVF0', step4.staffQuantity || ''); // Quantas pessoas no time?
      params.append('00N4P00000GDxHT', step4.bankCode); // Número do Banco
      params.append('00N3g000000M1NN', step4.cnpj ? step4.cnpj : step1.cnpj); // CNPJ do repasse
      params.append('00N4P00000GDxHO', step4.bank); // Banco
      params.append('00N4P00000GDxHd', step4.agency); // Agência
      params.append('00N3g000000V6n1', step4.agencyDigit); // Dígito Agência Banco
      params.append('00N4P00000GDxHi', step4.account); // Conta
      params.append('00N3g000000V6n6', step4.accountDigit); // Dígito Conta Banco
      params.append('00N4P00000GDxI7', step4.address); // Endereço da Empresa
      params.append('00N4P00000GDxIC', step4.number); // Número
      params.append('00N4P00000GDxIM', step4.neighborhood); // Bairro
      params.append('00N4P00000GDxIR', step4.city); // Cidade
      params.append('00N3g000000V6on', step4.state); // Estado
      params.append('00N3g000000V6o9', `${step4.cep.substr(0, 5)}-${step4.cep.substr(5, 3)}`); // CEP
      params.append('00N3g000000V6mX', step4.companySize); // Porte
      params.append('00N3g000000V6mc', step4.companyType); // Tipo
      params.append('00N4P00000GDn45', step5['comercial-name']); // Nome do Responsável
      params.append('00N4P00000GDn9e', step5['comercial-email']); // Endereço de Email
      params.append('00N4P00000GDxJj', step5['comercial-phone']); // Telefone Comercial
      params.append('00N4P00000GDxJA', step5['sac-name']); // Nome do Responsável pelo SAC
      params.append('00N4P00000GDxJP', step5['sac-email']); // Email SAC
      params.append('00N4P00000GDxJF', step5['sac-phone']); // Telefone SAC
      params.append('00N3g000000V6nG', step5['financeiro-name']); // Nome Responsável Financeiro
      params.append('00N4P00000GDxK8', step5['financeiro-email']); // Email Financeiro
      params.append('00N4P00000GDxJy', step5['financeiro-phone']); // Telefone Financeiro
      params.append('00N3g000000V6nL', step5['expedicao-name']); // Nome Responsável Expedição
      params.append('00N4P00000GDxKX', step5['expedicao-email']); // Email Expedição de Pedidos
      params.append('00N3g000000V6nQ', step5['expedicao-phone']); // Telefone Expedição
      params.append('00N3g000000V6mh', step5['portal-name']); // Nome Responsável Portal do Seller
      params.append('00N3g000000V6mw', step5['portal-email']); // Email Responsável Portal do Seller
      params.append('00N3g000000V6mr', step5['portal-phone']); // Telefone Responsável Portal do Seller
      params.append('00N3g000000V6oJ', step6.address); // Endereço Despacho
      params.append('00N3g000000V6oO', step6.number); // Número Despacho
      params.append('00N3g000000V6oT', step6.complement || ''); // Complemento Despacho
      params.append('00N3g000000V6oY', step6.neighborhood); // Bairro Despacho
      params.append('00N3g000000V6od', step6.city); // Cidade Despacho
      params.append('00N3g000000V6oi', step6.state); // Estado Despacho
      params.append('00N4P00000GDxIv', `${step6.cep.substr(0, 5)}-${step6.cep.substr(5, 3)}`); // CEP para Despacho
      params.append('00N3g000000LzIz', step6.shippingTypes); // Qual tipo de transportadora?
      params.append('00N3g000000M1Ui', step6.sedex || ''); // Qual o contrato dos Correios
      params.append('00N3g000000M1Un', step6.pac || ''); // Qual o contrato dos Correios
      params.append('00N4P00000GDxHn', step6.shippingTime); // Tempo de Despacho Novos Pedidos
      params.append('00N4P00000GDxI2', step6.shippingOther || ''); // Quais Transportadoras?
      params.append('00N3g000000V6nB', step6.shippingCentauro); // Tem interesse em Centauro Envios?
      params.append('00N3g000000M1NS', true); // Eu concordo com os Termos e Condições
    }

    try {
      sendRegistration(params);
    } catch (error) {
      datadogLogs.logger.error('Ficha cadastral: Erro ao cadastrar seller', {
        error: {
          message: error.toString(),
        },
      });
    }

    this.clearAll(step1.cnpj);

    sendEvent('Ficha Cadastral - Cadastro completo', 'Click - Finalizar', 'Completou o cadastro');
  };
}

export default SellerSignUpStore;
