import CustomerProps from '../interfaces/CustomerProps';

const mapModel = ({
  nome,
  sobrenome,
  cpf,
  email,
  bairro,
  cep,
  cidade,
  ddd,
  complemento,
  referencia,
  logradouro,
  numero,
  numeroTelefone,
  uf,
}: CustomerProps) => {
  return {
    nome,
    sobrenome,
    cpf,
    email,
    newsletter: false,
    tipoCliente: 'PF',
    endereco: {
      cep,
      logradouro,
      numero,
      cidade,
      bairro,
      uf,
      complemento,
      referencia,
      telefoneAdicional: {
        ddd,
        numero: numeroTelefone,
      },
      telefoneFixo: {
        ddd,
        numero: numeroTelefone,
      },
    },
  };
};

export default mapModel;
