import { centauroDomain as domainName } from '@/settings';

export const isTestEnv = process.env.NODE_ENV === 'test';

export const isProdEnv = process.env.NODE_ENV === 'production';

export const isBrowserEnv = typeof window !== 'undefined';

export const isProduction = () => {
  if (!isBrowserEnv) {
    return false;
  }

  const { host } = window.location;
  const domain = new URL(domainName).hostname.replace('www.', '');
  return host === domain || host === `www.${domain}`;
};
