import { datadogLogs } from '@datadog/browser-logs';
import { dataDogToken } from './src/settings';

const envVariables = require('./envVariables');

const datadogInit = isSPA => {
  const serviceName = isSPA ? 'SPA' : 'SSR';

  return datadogLogs.init({
    clientToken: dataDogToken,
    site: 'datadoghq.com',
    service: serviceName,
    forwardErrorsToLogs: true,
    sampleRate: 10,
    env: envVariables.API_ENV,
    version: process.env.BUILD_ID,
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: true,
  });
};
export default datadogInit;
