/**
 * IMPORTANTE: O módulo httpClientCentauro.js deve ser usado
 * exclusivamente para chamadas de APIs Centauro (backend).
 * Não usar este módulo para outros tipos de chamadas (p.e. APIs Google).
 */

import axios from 'axios';

import { authHeader, apiEnvironment } from '../settings';
import { shouldDisableSession } from '@/helpers/sessionManager';
import { sendGAEvent } from '../utils/Analytics';

const formatErrorResponse = (err, apiUrl, verb) => {
  /**
   * IMPORTANTE: Há situações onde existirá apenas a mensagem do axios.
   * p.e. servidor não encontrado, falta de conexão, etc.
   */
  const out = {
    error: {
      axiosMessage: err.toString(),
    },
  };

  if (err.response) {
    /**
     * Aqui são informações vindas do servidor
     */

    const { status, data } = err.response;

    if (status) {
      out.error.status = status;
    }
    if (data) {
      out.error.data = data;
    } // corpo da resposta

    let errorMessage = data;
    if (typeof data === 'object') {
      errorMessage = JSON.stringify(data);
    }

    let errorType = 'Error';

    if (status >= 300 && status < 400) {
      errorType = 'Redirect';
    } else if (status >= 400 && status < 500) {
      errorType = 'Client Error';
    } else if (status >= 500 && status < 600) {
      errorType = 'Server Error';
    }

    sendGAEvent(
      `API - Endpoint retornou ${errorType}`,
      `${verb} - ${apiUrl}`,
      `${apiUrl} - ${errorType}: [${status}] ${errorMessage}`,
      1,
      true,
    );
  } else {
    sendGAEvent(
      'API - Endpoint não respondeu',
      `${verb} - ${apiUrl}`,
      `${apiUrl} - [n/a] ${out.error.axiosMessage}`,
      1,
      true,
    );
  }

  return out;
};

const returnUrl = url => {
  let reqUrl = url;

  if (apiEnvironment === 'dev') {
    reqUrl =
      `${url}${!url.includes('?') ? '?' : '&'}` +
      encodeURIComponent(
        'force-cors=-zY8TnWPyUU6nvQj2ABKz^3K%A@*NkbM!Q6Y83^bh9TTnHL5t@mam!$BUTn=eaZ_',
      );
  }

  return reqUrl;
};

const getThirdPartyJson = async ({ url, responseType = '', headers = {} }) => {
  let response;

  try {
    response = await axios.get(url, { responseType, headers });
  } catch (err) {
    return formatErrorResponse(err, url, 'get');
  }

  // sendGAEvent('API - Endpoint de terceiro com sucesso', `GET - ${url}`, url, 1, true);
  return response;
};

const getJson = async (
  url,
  secondaryHeader,
  withCredentials = false,
  withAuthorization = true,
  otherAuth = '',
) => {
  let response;

  const headers = {
    ...secondaryHeader,
  };

  if (withAuthorization) {
    headers.Authorization = authHeader;
  }

  if (otherAuth !== '') {
    headers.Authorization = otherAuth;
  }

  try {
    response = await axios.get(returnUrl(url), {
      headers,
      withCredentials,
    });
  } catch (err) {
    await shouldDisableSession(err.response?.headers['x-redirect-login'] === 'true');
    return formatErrorResponse(err, url, 'get');
  }

  return response;
};

const isErrorStatusIgnored = (err, ignoredErrorStatusList) => {
  return err.response?.status in ignoredErrorStatusList;
};

const postJson = async (
  url,
  body,
  secondaryHeader,
  withCredentials = true,
  ignoredErrorStatusList = [],
) => {
  let response;

  try {
    response = await axios.post(returnUrl(url), body, {
      headers: {
        Authorization: authHeader,
        ...secondaryHeader,
      },
      withCredentials,
    });
  } catch (err) {
    if (isErrorStatusIgnored(err, ignoredErrorStatusList)) {
      return undefined;
    }
    shouldDisableSession(err.response?.headers['x-redirect-login'] === 'true');
    return formatErrorResponse(err, url, 'post');
  }
  // sendGAEvent('API - Endpoint com sucesso', `POST - ${url}`, url, 1, true);
  return response;
};

const postThirdPartyJson = async (
  url,
  body,
  secondaryHeader,
  withCredentials = true,
  withAuthorization = true,
) => {
  let response;

  const headers = {
    ...secondaryHeader,
  };
  if (withAuthorization) {
    headers.Authorization = authHeader;
  }

  try {
    response = await axios.post(returnUrl(url), body, {
      headers,
      withCredentials,
    });
  } catch (err) {
    return formatErrorResponse(err, url, 'post');
  }

  // sendGAEvent('API - Endpoint com sucesso', `POST - ${url}`, url, 1, true);
  return response;
};

const putJson = async (url, body, secondaryHeader, withCredentials = true) => {
  let response;

  try {
    response = await axios.put(returnUrl(url), body, {
      headers: {
        Authorization: authHeader,
        ...secondaryHeader,
      },
      withCredentials,
    });
  } catch (err) {
    return formatErrorResponse(err, url, 'put');
  }

  // sendGAEvent('API - Endpoint com sucesso', `PUT - ${url}`, url, 1, true);
  return response;
};

const deleteJson = async (url, secondaryHeader, withCredentials = true) => {
  let response;

  try {
    response = await axios.delete(returnUrl(url), {
      headers: {
        Authorization: authHeader,
        ...secondaryHeader,
      },
      withCredentials,
    });
  } catch (err) {
    return formatErrorResponse(err, url, 'delete');
  }

  // sendGAEvent('API - Endpoint com sucesso', `DELETE - ${url}`, url, 1, true);
  return response;
};

export { getThirdPartyJson, getJson, postJson, putJson, deleteJson, postThirdPartyJson };
