import { StyleSheet } from 'aphrodite';

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: '1 1 100%',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    maxHeight: '30px',
    maxWidth: '30px',
  },
});

export default styles;
