/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useCallback } from 'react';
import { postJson, getJson } from '@/modules/httpClientCentauro';
import { eraseCookie, getCookie, setCookie } from '@/utils/utils';
import { apiDomain } from '@/settings';
import { AxiosResponse } from 'axios';
import { SELLER_KEY } from './keySettings';
import { SellerProps } from '@/modules/sellerSessionModule.d';
import { useStores } from '@/stores/AppStoreContext';
import CustomerProps from './interfaces/CustomerProps';
import modelAdapter from './utils/ModelAdapter';
import eraseLoginCookies from './utils/EraseCookies';
import { sendEvent } from '@/utils/Analytics';
import { datadogLogs } from '@datadog/browser-logs';
import ModuleRepository from './repository/ModuleRepository';
import { signSeller, initializeSession } from '@/modules/sellerSessionModule';

import {
  CustomerCredencials,
  ResponseError,
  SellerCredencials,
  AddressResponse,
  AddressRequest,
} from './interfaces/hookInterfaces';
import modelStateAdapter from './utils/ModelStateAdapter';

//TODO: refatorar hook nas próximas versões.
// O hook adquiriu muitas responsabilidades.
const useSessionSeller = () => {
  const { LoginStore, SessionSellerStore } = useStores();
  const seller = SessionSellerStore.seller as SellerProps | undefined;
  const signOutCustomer = useCallback(() => {
    eraseLoginCookies();
    if (LoginStore) {
      LoginStore.setUserLogged();
    }
  }, [LoginStore]);

  const signOut = useCallback(() => {
    eraseCookie(SELLER_KEY);
    eraseCookie('ECOMMERCE.CENTAURO.AUTHtv');
    signOutCustomer();

    SessionSellerStore.setSeller(undefined);
    LoginStore.setTvBody({});
  }, [LoginStore, SessionSellerStore, signOutCustomer]);

  const initSession = useCallback(
    async (token: string) => {
      const [session, error] = await initializeSession(token);

      if (error?.message) {
        datadogLogs.logger.error('EE: Falha em inicializar sessão', {
          error: error.message,
        });

        signOut();
        throw Error('Tivemos um problema em iniciar sessão de vendas, por favor tente mais tarde.');
      }

      const { codigo, nome, campanha, aplicarFreteGratis } = session;
      setCookie(
        'ECOMMERCE.CENTAURO.AUTHtv',
        `codigo=${codigo}&nome=${nome}&campanha=${campanha}&aplicarFreteGratis=${aplicarFreteGratis}`,
      );
    },
    [signOut],
  );

  const signIn = useCallback(
    async ({ login, password, store }: SellerCredencials): Promise<void> => {
      signOutCustomer();
      const [seller, error] = await signSeller({ login, password });

      if (error?.message) {
        datadogLogs.logger.error('EE: Falha ao autenticar o vendedor', {
          error: error.message,
        });
        throw Error(error.message);
      }

      SessionSellerStore.setSeller(seller);
      SessionSellerStore.setStoreId(store);
      await initSession(seller.token);

      sendEvent(
        `UI - Modal vendedor`,
        `Request - Autenticar vendedor com sucesso`,
        `Login Seller - Click no botão entrar`,
      );
    },
    [SessionSellerStore, initSession, signOutCustomer],
  );

  const hasSeller = useCallback((): boolean => {
    return typeof SessionSellerStore.seller !== typeof undefined;
  }, [SessionSellerStore.seller]);

  const hasStoreId = useCallback((): boolean => {
    return typeof SessionSellerStore.storeId !== typeof undefined;
  }, [SessionSellerStore.storeId]);

  const moduleEnabled = useCallback((): boolean => {
    const module = ModuleRepository.getModuleConfig();

    return !!module;
  }, []);

  const signInCustomer = useCallback(
    async ({ login }: CustomerCredencials): Promise<void> => {
      if (!seller) {
        return;
      }
      const televendas = getCookie('ECOMMERCE.CENTAURO.AUTHtv');
      if (!televendas) {
        await initSession(seller.token);
      }

      LoginStore.setUsername(login);
      LoginStore.setPassword('guest');
      await LoginStore.submitLogin();
      if (LoginStore.usernameErrorMsg) {
        datadogLogs.logger.error('EE: Falha no username do cliente', {
          error: LoginStore.usernameErrorMsg,
        });

        throw Error('Cliente não cadastrado.');
      }
    },
    [seller, LoginStore, initSession],
  );

  const storeNewCustomer = useCallback(
    async (customer: CustomerProps): Promise<Array<string> | undefined> => {
      try {
        const customerToStore = modelAdapter(customer);

        const response = (await postJson(
          `${apiDomain}/v4/megaloja/cadastrar-cliente`,
          customerToStore,
          {
            'x-user-token': seller?.token,
          },
        )) as AxiosResponse;

        const errors = response as ResponseError;
        if (errors?.error) {
          if (errors.error.status === 400) {
            return modelStateAdapter(errors.error.data.modelState);
          }
          throw Error(errors.error.data.message);
        }

        await signInCustomer({ login: customerToStore.cpf });
        return undefined;
      } catch (error) {
        throw Error(error);
      }
    },
    [seller?.token, signInCustomer],
  );
  //TODO: transferir para outro hook ou modulo.
  const getAddressByCep = useCallback(async ({ cep }: AddressRequest): Promise<AddressResponse> => {
    try {
      if (!new RegExp(/[0-9]{8}/).test(cep)) {
        throw Error('Verifique se o CEP corresponde a 8 dígitos.');
      }

      const response = (await getJson(`${apiDomain}/v2/consultacep/${cep}`)) as AxiosResponse;

      const errors = response as ResponseError;
      if (errors?.error) {
        throw Error(errors.error.data.message);
      }

      return response.data as AddressResponse;
    } catch (error) {
      throw Error(error);
    }
  }, []);
  return {
    seller,
    signIn,
    hasSeller,
    moduleEnabled,
    hasStoreId,
    signOut,
    signOutCustomer,
    signInCustomer,
    getAddressByCep,
    storeNewCustomer,
  };
};

export default useSessionSeller;
