/**
 * IMPORTANTE: O módulo httpClientCentauro.js deve ser usado
 * exclusivamente para chamadas de APIs Centauro (backend).
 * Não usar este módulo para outros tipos de chamadas (p.e. APIs Google).
 */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import axios, { AxiosResponse } from 'axios';
import { shouldDisableSession } from '@/helpers/sessionManager';

import { authHeader, apiEnvironment } from '../settings';
import { GetParameters, PostParameters, Header } from './httpClient.d';

const returnUrl = (url: string) => {
  let reqUrl = url;

  if (apiEnvironment === 'dev') {
    reqUrl = `${url}${
      !url.includes('?') ? '?' : '&'
    }force-cors=-zY8TnWPyUU6nvQj2ABKz^3K%A@*NkbM!Q6Y83^bh9TTnHL5t@mam!$BUTn=eaZ_`;
  }

  return reqUrl;
};

const getJson = async <T = unknown, R = AxiosResponse<T>>({
  url,
  secondaryHeader = {},
  withCredentials = false,
  withAuthorization = true,
  otherAuth = '',
}: GetParameters): Promise<R> => {
  let response: R;

  const headers: Header = {
    ...secondaryHeader,
  };

  if (withAuthorization) {
    headers.Authorization = otherAuth || authHeader;
  }

  try {
    response = await axios.get<T, R>(returnUrl(url), {
      headers,
      withCredentials,
    });
  } catch (err) {
    await shouldDisableSession(err.response?.headers['x-redirect-login'] === 'true');
    return Promise.reject(err);
  }

  return response;
};

const postJson = <T = unknown, R = AxiosResponse<T>>({
  url,
  body,
  secondaryHeader,
  withCredentials = true,
}: PostParameters) => {
  return axios.post<T, R>(returnUrl(url), body, {
    headers: {
      Authorization: authHeader,
      ...secondaryHeader,
    },
    withCredentials,
  });
};

export { getJson, postJson };
