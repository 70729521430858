const envVariables = {
  IS_SSR: false,
  API_ENV: JSON.stringify('prod'),
  IS_MOBILE_SSR: false,
  IS_PR: false,
  SSO_ENV: JSON.stringify('prd'),
  SSO_PASSWORD_FLOW_ENDPOINT: JSON.stringify('/auth/realms/centauro/protocol/openid-connect/token'),
  SSO_CLIENT_ID: JSON.stringify('centauro-front'),
  SSO_GRANT_TYPE: JSON.stringify('password'),
};

module.exports = envVariables;
