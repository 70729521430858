import { observable, action } from 'mobx';

class GeneralStore {
  @observable lastCartBackUrl = '/';

  @action setLastCartBackUrl = newUrl => {
    this.lastCartBackUrl = newUrl;
  };
}

export default GeneralStore;
