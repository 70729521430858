import {
  metaImageResolution,
  sliderImageResolution,
  activeImageResolution,
  activeImageResolutionNav,
  miniatureImageSize,
} from '../helpers/resolutionHelper';

const ProductImageService = ({
  page,
  images,
  colorSelected,
  videos = null,
  tabSelected = null,
}) => {
  const objActiveColors = images?.find(obj => obj.codigoCor === colorSelected)?.urls;

  const getImage = resolution => {
    if (!objActiveColors && !images) {
      return '';
    }

    if (tabSelected) {
      const activeImage = objActiveColors.find(
        obj => obj.ordem === tabSelected && obj.resolucao === activeImageResolution,
      ).url;
      return activeImage;
    }

    let checkImage = objActiveColors.find(
      obj => obj.principal === true && obj.resolucao === resolution,
    );

    if (!checkImage) {
      checkImage = objActiveColors.find(obj => obj.resolucao === resolution);
    }

    if (checkImage) {
      return checkImage.url;
    }

    return '';
  };

  const getMetaImage = resolution => {
    if (!objActiveColors && !images) {
      return '';
    }

    if (tabSelected) {
      const metaImage = objActiveColors.find(
        obj => obj.ordem === tabSelected && obj.resolucao === metaImageResolution,
      ).url;

      return metaImage;
    }

    let newObjActiveColors;
    let validColorSelected = colorSelected;

    validColorSelected =
      colorSelected && colorSelected !== '' ? colorSelected : images ? images[0].codigoCor : '';

    if (images && validColorSelected !== '') {
      newObjActiveColors = images.find(obj => obj.codigoCor === validColorSelected).urls;
    }

    const metaImageCheck = (newObjActiveColors || objActiveColors).find(
      obj => obj.principal === true && obj.resolucao === resolution,
    );

    return (
      metaImageCheck ||
      (newObjActiveColors || objActiveColors).find(obj => obj.resolucao === resolution) ||
      ''
    );
  };

  const getImageSlider = () => {
    if (!objActiveColors && !images) {
      return '';
    }

    const imageSlider = objActiveColors
      .filter(image => image.resolucao === sliderImageResolution)
      .sort((a, b) => a.ordem - b.ordem);

    if (videos) {
      for (let index = 0; index < videos.length; index += 1) {
        if (videos[index].codigoCor === colorSelected) {
          const videoItem = {
            ...imageSlider[0],
          };

          if (videoItem.ordem <= 0) {
            videoItem.ordem -= 1;
          }

          videoItem.video = videos[index].codigoVideo;

          imageSlider.unshift(videoItem);
        }
      }
    }
    return imageSlider;
  };

  const getHoverImage = resolution => {
    if (!objActiveColors && !images) {
      return '';
    }
    let checkHoverImage = objActiveColors.find(
      obj => obj.ordem === 1 && obj.resolucao === resolution,
    );

    if (!checkHoverImage) {
      checkHoverImage = objActiveColors.find(
        obj => obj.ordem === 2 && obj.resolucao === resolution,
      );
    }

    if (!checkHoverImage) {
      return getImage(resolution);
    }

    return checkHoverImage.url;
  };

  const generateProductPageImageObject = () => ({
    activeImage: getImage(activeImageResolution),
    metaImage: getMetaImage(metaImageResolution),
    ...(!tabSelected && { imageSlider: getImageSlider() }),
  });

  const generateNavigationPageImageObject = () => ({
    activeImage: getImage(activeImageResolutionNav),
    hoverImage: getHoverImage(activeImageResolutionNav),
  });

  const generateProductImageObject = () => {
    switch (page) {
      case 'nav':
        return generateNavigationPageImageObject();
      case 'pdp':
        return generateProductPageImageObject();
      case 'headerFavorite':
        return getImage(miniatureImageSize);
      default:
        return {};
    }
  };

  return {
    generateProductImageObject,
  };
};

export default ProductImageService;
