import { centauro } from '@sbf/fairplay-tokens';
import { siteTheme } from '../settings';

const blueA = '#00AECD';
const blueB = '#114A72';
const blueC = '#00B8D4';
const blueD = '#E0F7FA';
const blueE = '#f5feff';
const blueF = '#00bbd8';
const blueG = '#154b71';
const blueH = '#89a6af';
const blueGoogleButton = '#4285f3';
const blueFacebookButton = '#3a5998';
const blueCaixa = '#005ca9';

const grayA = '#6C6C6C';
const grayB = '#f3f5f6'; // Technically this is a blue tone
const grayC = '#878787';
const grayD = '#E7E7E7';
const grayE = '#4C4C4C';
const grayF = '#f9fafb'; // Technically this is a blue tone
const grayG = '#EAEFF1'; // Technically this is green tone
const grayH = 'rgba(0,0,0,0.7)';
const grayI = '#d1d1d1';
const grayJ = '#f9f9f9';
const grayK = '#c4c4c4';
const grayL = '#2e2e2e';
const grayM = '#1d1d1d';
const grayN = '#d5d5d5';
const grayP = '#aeaeae';
const grayU = '#8fa5ae'; // This is a blue tone, for sure!
const grayZ = '#fafafa';
const grayDD = '#ECEFF1CC';
const grayEE = '#eceff166';
const grayFF = '#ebeff14d';
const grayGG = '#f5f5f5';
const grayHH = '#777777';
const grayII = '#5c5c5c';
const grayJJ = '#a3a3a3';
const grayKK = '#eaeaea';
const grayLL = '#979797';
const grayMM = '#ebeff1';
const grayNN = '#cdcdcd';
const grayOO = '#707070';
const grayPP = '#737373';
const grayQQ = '#1a1a1a';
const grayRR = '#b0b0b0';

const blackfridayGrayA = '#2d2d2d';

const greenA = '#52B12F';
const greenBpackers = '#5AC533';
const greenC = '#AED581';
const greenD = '#f1fbed';
const greenF = '#00b800';
const greenG = '#73CD54';

const redA = centauro.colors.primary;
const redB = '#7c051f';
const redC = '#e10404';
const redD = '#f50404';
const redE = '#ffd9d9';
const redF = '#d63a3a';
const redG = '#f44336';
const redI = '#970014';
const redBlackFriday = '#c12c2a';
const redJ = '#be1033';
const redK = '#A70E13';

const white = '#ffffff';
const black = '#000000';
const blackA = '#4c4c4c';
const blackB = '#1a1a1a';

const yellow = '#ffc400';
const yellowB = '#fff5d9';
const yellowC = '#f7b13b';
const yellowD = '#cb912d';
const yellowE = '#FFC000';
const yellowF = '#f7b13a';
const yellowG = '#fff8eb';
const yellowH = '#ffba47';
const yellowI = '#8c5900';
const yellowJ = '#FFA100';

const customYeallow = '#FFFF00';
const customBlue = '#0000FF';
const customBlueLight = '#AAFFFF';
const customBlueDark = '#0000AA';
const customWhite = '#FFFFFF';
const customCaramel = '#AA5555';
const customGrena = '#660033';
const customGold = '#DBB000';
const customBlack = '#000000';
const customPink = '#FF00AA';
const customGreen = '#00AA00';
const customGreenLight = '#00FF00';
const customOrange = '#FF7F00';

const moveMondayMainColor = '#06adc1';
const moveMondaySecondaryColor = '#038fa0';

export const neutralBlack = '#1A1A1A';
export const neutral200 = '#F7F7F7';
export const neutral300 = '#D6D6D6';
export const neutral400 = '#999999';
export const neutral500 = '#737373';
export const neutral600 = '#4D4D4D';
export const brandPrimary = '#E30000';
export const brandSecondaryDark = '#006611';
export const positivePrimary = '#00AD1D';
export const positiveDark = '#006611';
export const negativePrimary = '#FF0F0F';
export const negativeLight = '#FFDBDB';
export const negativeDark = '#8C0000';
export const alertLightest = '#FFF8EB';
export const alertPrimary = '#FFBA47';
export const alertDark = '#8C5900';
export const informativePrimary = '#47B7FF';
export const informativeLightest = '#DBF1FF';
export const informativeDark = '#00548C';
export const brandLight = '#F56262';

const getThemeColors = () => {
  switch (siteTheme) {
    case 'blackfriday':
      return {
        primaryColor: black,
        secondaryColor: grayN,
        adviseColor: yellowF,
        primaryTextColor: white,
        secondaryTextColor: white,
        backgroundColor: black,

        // Header
        backgroundHeaderColor: black, // Porque o layout da move monday seguiu preto...
        closeHeaderFurtadoColor: white,
        upperMenuColor: grayL,

        // Footer
        footerBackgroundColor: black,
        footerTextColor: white,
        footerLinkHoverColor: grayC,
        footerSectionBorderColor: grayC,
        footerBrandBoxMBackgroundColor: black,

        // News.jsx
        innerNewsHolder: grayL,
        innerNewsHolderBorder: black,
        containerPromoApp: grayL,
        containerPromoAppBorder: black,

        // Optin.jsx
        textLegalColor: grayK,
        subTitleColor: white,
        containerOptinColor: black,
        optinButtomColor: brandPrimary,
        optinButtomTextColor: white,
        feedbackContainer: black,
        errorCustom: redC,

        // Mobile.jsx
        mobileSpacerBackgroundColor: black,

        // Countdown.jsx
        timerTitleColor: white,
        timerColor: redBlackFriday,
      };

    case 'movemonday':
      return {
        primaryColor: moveMondayMainColor,
        secondaryColor: moveMondaySecondaryColor,
        adviseColor: grayL,
        primaryTextColor: white,
        secondaryTextColor: white,
        backgroundColor: black,

        // Header
        backgroundHeaderColor: black, // Porque o layout da move monday seguiu preto...
        closeHeaderFurtadoColor: white,
        upperMenuColor: grayL,

        // Footer
        footerBackgroundColor: black,
        footerTextColor: white,
        footerLinkHoverColor: grayC,
        footerSectionBorderColor: grayC,
        footerBrandBoxMBackgroundColor: black,

        // News.jsx
        innerNewsHolder: grayL,
        innerNewsHolderBorder: black,
        containerPromoApp: grayL,
        containerPromoAppBorder: black,

        // Optin.jsx
        textLegalColor: grayK,
        subTitleColor: white,
        containerOptinColor: black,
        optinButtomColor: redA,
        optinButtomTextColor: white,
        feedbackContainer: black,
        errorCustom: redC,

        // Mobile.jsx
        mobileSpacerBackgroundColor: black,

        // Countdown.jsx
        timerTitleColor: white,
        timerColor: redBlackFriday,
      };

    default:
      return {
        primaryColor: redA,
        secondaryColor: '',
        adviseColor: '',
        primaryTextColor: white,
        secondaryTextColor: grayA,
        backgroundColor: white,

        // Header
        backgroundHeaderColor: redA, // Porque o layout da move monday seguiu preto...
        closeHeaderFurtadoColor: grayH,
        upperMenuColor: redK,

        // Footer
        footerBackgroundColor: grayJ,
        footerTextColor: grayC,
        footerLinkHoverColor: redA,
        footerSectionBorderColor: grayG,
        footerBrandBoxMBackgroundColor: grayG,

        // News.jsx
        innerNewsHolder: grayD,
        innerNewsHolderBorder: grayI,
        containerPromoApp: grayD,
        containerPromoAppBorder: grayI,

        // Optin.jsx
        textLegalColor: grayL,
        subTitleColor: grayL,
        containerOptinColor: grayI,
        optinButtomColor: redA,
        optinButtomTextColor: white,
        feedbackContainer: white,
        errorCustom: redC,

        // Mobile.jsx
        mobileSpacerBackgroundColor: grayG,

        // Countdown.jsx
        timerTitleColor: black,
        timerColor: black,
      };
  }
};

const themeColors = getThemeColors();

export {
  blueA,
  blueB,
  blueC,
  blueD,
  blueE,
  blueF,
  blueG,
  blueH,
  blueGoogleButton,
  blueFacebookButton,
  blueCaixa,
  grayA,
  grayP,
  grayC,
  grayD,
  grayE,
  grayG,
  grayH,
  grayI,
  grayJ,
  grayK,
  grayL,
  grayM,
  grayU,
  grayZ,
  grayDD,
  grayEE,
  grayFF,
  greenA,
  greenBpackers,
  greenC,
  greenD,
  greenF,
  greenG,
  redA,
  redB,
  redC,
  redD,
  redE,
  redF,
  redG,
  redI,
  white,
  black,
  blackA,
  yellow,
  yellowB,
  yellowC,
  yellowD,
  yellowE,
  customYeallow,
  customBlue,
  customBlueLight,
  customBlueDark,
  customWhite,
  customCaramel,
  customGrena,
  customGold,
  customBlack,
  customPink,
  customGreen,
  customGreenLight,
  customOrange,
  grayB,
  grayF,
  grayN,
  redBlackFriday,
  yellowF,
  blackfridayGrayA,
  themeColors,
  grayGG,
  grayHH,
  grayII,
  grayJJ,
  grayKK,
  redJ,
  grayLL,
  grayMM,
  grayNN,
  grayOO,
  blackB,
  grayPP,
  grayQQ,
  grayRR,
  yellowG,
  yellowH,
  yellowI,
  yellowJ,
};
