/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { LoginSeller, ErrorMessage, SellerProps } from './sellerSessionModule.d';
import { postJson, getJson } from '@/modules/httpClient';
import { apiDomain } from '@/settings';
import { SessionResponse } from '@/hooks/sessionSeller/interfaces/hookInterfaces';

export const signSeller = async ({
  login,
  password,
}: LoginSeller): Promise<[SellerProps, ErrorMessage]> => {
  let errorMessage = {} as ErrorMessage;
  let seller = {} as SellerProps;

  try {
    const body = {
      Matricula: login,
      CPF: password,
      GatewayIp: '10.160.117.1',
    };
    const response = await postJson<SellerProps>({
      url: `${apiDomain}/v4/assistentes/login`,
      body,
    });

    seller = response.data;
  } catch (error) {
    errorMessage = {
      message: error.response.data.message,
    };
  }

  return [seller, errorMessage];
};

export const initializeSession = async (
  token: string,
): Promise<[SessionResponse, ErrorMessage]> => {
  let errorMessage = {} as ErrorMessage;
  let session = {} as SessionResponse;

  try {
    const response = await getJson<SessionResponse>({
      url: `${apiDomain}/v5.1/clientes/venda-assistida/session`,
      secondaryHeader: {
        'x-user-token': token,
      },
    });

    session = response.data;
  } catch (error) {
    errorMessage = {
      message: error.response.data.message,
    };
  }

  return [session, errorMessage];
};
