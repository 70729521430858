/**
 * Singularity é um gerador de identificadores únicos.
 * Retorna uma string com conteúdo nunca repetido
 * dentro de seu ciclo de vida.
 */
let singularityNum = 0;

const singularity = () => {
  const newNum = singularityNum;
  singularityNum += 1;

  return newNum.toString(36);
};

export default singularity;
