import React from 'react';
import { css } from 'aphrodite/no-important';

import styles from './styles';
import imgLoading from '../../assets/images/load.gif';

const AppLoader = () => (
  <div className={css(styles.wrapper)}>
    <img
      className={css(styles.image)}
      src={imgLoading}
      width="30"
      alt="Carregando"
      style={{ marginTop: '30px' }}
    />
  </div>
);

export default AppLoader;
