import React from 'react';

const TrocaSite = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="55" viewBox="0 0 64 55" {...props}>
    <defs>
      <filter id="prefix__a">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.423529 0 0 0 0 0.423529 0 0 0 0 0.423529 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" filter="url(#prefix__a)" transform="translate(0 -6)">
      <g>
        <path
          fill="#6C6C6C"
          d="M46.265 54.86c-9.795 0-17.735-7.976-17.735-17.816s7.94-17.817 17.735-17.817C56.06 19.227 64 27.204 64 37.044S56.06 54.86 46.265 54.86m0-33.767c-8.767.004-15.872 7.147-15.869 15.955.003 8.808 7.112 15.946 15.88 15.945 8.767-.002 15.873-7.142 15.873-15.95-.004-8.81-7.114-15.95-15.884-15.95"
          transform="translate(0 6)"
        />
        <path
          fill="#6C6C6C"
          d="M37.544 31.35h11.697c.319.03.626-.122.795-.395.168-.273.168-.618 0-.89-.169-.274-.476-.427-.795-.396H37.544l2.043-2.068c.16-.157.245-.377.232-.601-.013-.225-.123-.432-.3-.569-.168-.162-.395-.248-.627-.236-.233.012-.45.12-.6.298l-3.4 3.432c-.07.07-.07.07-.07.131 0 .062-.07.062-.07.132-.068.187-.068.394 0 .581 0 .062.07.062.07.132s.07.062.07.124l3.4 3.431c.153.176.377.273.61.264.219-.007.432-.074.616-.194.325-.311.355-.822.07-1.17l-2.044-2.006zM52.611 31.621h2.745c.426 0 .771-.347.771-.775 0-.427-.345-.774-.77-.774H52.61c-.426 0-.771.347-.771.774 0 .428.345.775.771.775M56.29 43.62c.01-.11-.014-.221-.07-.317 0-.07-.07-.07-.07-.132s-.069-.062-.069-.132l-3.393-3.424c-.342-.31-.854-.336-1.226-.061-.324.308-.354.816-.07 1.161l2.044 2.069H41.754c-.318-.03-.626.122-.794.395-.169.273-.169.618 0 .89.168.273.476.427.794.396h11.69L51.4 46.533c-.157.157-.24.374-.227.597.014.222.122.428.297.565.177.128.39.196.609.194.235.011.462-.086.617-.264l3.393-3.424c.07-.062.07-.062.07-.124s.068-.07.068-.131c.056-.099.08-.213.07-.326M38.392 43.24h-2.745c-.275 0-.53.148-.668.388-.137.24-.137.535 0 .775.138.24.393.387.668.387h2.745c.426 0 .771-.347.771-.775 0-.427-.345-.774-.77-.774"
          transform="translate(0 6)"
        />
        <path
          d="M43.813 19.42V5.423h-35.2v21.465h23.133c2.814-4.066 7.186-6.771 12.067-7.468z"
          transform="translate(0 6)"
        />
        <path
          d="M6.94 28.538V3.749h38.554v15.493h1.133V2.665c0-.464-.371-.84-.832-.845H5.822c-.461.005-.833.381-.833.845v27.74h24.829c.254-.639.545-1.262.871-1.867H6.94zM26.633 32.086v1.68h-1.658v-1.68H1.658v2.518c0 .463.371.84.833.844H28.6c.107-1.138.318-2.263.632-3.362h-2.599z"
          transform="translate(0 6)"
        />
        <path
          fill="#6C6C6C"
          fillRule="nonzero"
          d="M45.787.14H5.822C4.439.147 3.323 1.275 3.323 2.664v27.74H0v4.199c0 1.388 1.116 2.516 2.498 2.525H28.53v-.07c0-.542 0-1.077.077-1.611H2.491c-.462-.004-.833-.381-.833-.844v-2.518h23.317v1.68h1.658v-1.68h2.599c.166-.57.362-1.131.586-1.681H4.988V2.665c0-.464.373-.84.834-.845h39.965c.461.005.833.381.833.845V19.25c.563 0 1.118.046 1.665.116V2.665c0-1.389-1.116-2.517-2.498-2.526z"
          transform="translate(0 6)"
        />
        <path
          fill="#6C6C6C"
          fillRule="nonzero"
          d="M6.94 3.75v24.788h23.75c.308-.57.65-1.12 1.025-1.65H8.582V5.423h35.231V19.42c.547-.077 1.11-.131 1.673-.155V3.773L6.94 3.749z"
          transform="translate(0 6)"
        />
      </g>
    </g>
  </svg>
);

export default TrocaSite;
