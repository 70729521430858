import { action, observable, runInAction } from 'mobx';

import {
  getCategories,
  getUsualQuestions,
  getSupports,
  getSearchResults,
  postClicksBySection,
} from '../modules/customerModule';

class CustomerStore {
  @observable storeInfo = {};

  @observable loading = false;

  @observable storeCategories = [];

  @observable storeCategoriesApiError = false;

  @observable usualQuestions = [];

  @observable usualQuestionsApiError = false;

  @observable storeSupports = [];

  @observable storeSupportsApiError = false;

  @observable questionsByTerm = [];

  @observable questionsByTermApiError = false;

  @observable questionsByTermApiPages = {};

  @action initCustomerPage = async store => {
    this.loading = true;
    this.storeCategories = [];
    this.storeCategoriesApiError = false;

    const { data, error } = await getCategories(store);

    runInAction(() => {
      if (!error) {
        this.storeInfo.storeId = data.storeId;
        this.storeInfo.storeName = data.storeName;
        this.storeCategories = data.categories;
        this.getQuestionsByCategory(store, data.categories[0].permalink);
      } else {
        this.storeCategoriesApiError = true;
      }

      this.getStoreSupports(store);
      this.loading = false;
    });
  };

  @action getQuestionsByCategory = async (store, permalink) => {
    this.usualQuestions = [];
    this.usualQuestionsApiError = false;

    const { data, error } = await getUsualQuestions(store, permalink);

    runInAction(() => {
      if (!error) {
        this.usualQuestions = data.questions;
      } else {
        this.usualQuestionsApiError = true;
      }
      this.loading = false;
    });
  };

  @action getStoreSupports = async store => {
    this.storeSupports = [];
    this.storeSupportsApiError = false;

    const { data, error } = await getSupports(store);

    runInAction(() => {
      if (!error) {
        this.storeSupports = data.channels;
      } else {
        this.storeSupportsApiError = true;
      }
    });
  };

  @action getQuestionsByTerm = async (store, term, suggestions, page) => {
    this.resetQuestionsByTerm();

    const { data, error } = await getSearchResults(store, term, suggestions, page);

    runInAction(() => {
      if (!error) {
        this.questionsByTermApiPages = data.pageInfo;
        this.questionsByTerm = data.questions;
      } else {
        this.questionsByTermApiError = true;
      }
    });
  };

  @action resetQuestionsByTerm = () => {
    this.questionsByTerm = [];
    this.questionsByTermApiError = false;
    this.questionsByTermApiPages = {};
  };

  @action postClicks = (section, json) => {
    const postJson = { ...this.storeInfo, ...json };
    postClicksBySection(section, postJson);
  };
}

export default CustomerStore;
