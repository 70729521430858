import { getJson, postThirdPartyJson } from './httpClientCentauro';
import { apiFaqDomain, apiFaqAuth as Authorization } from '../settings';

const defaultHeader = { Authorization };

const searchUrl = `${apiFaqDomain}/api/search`;
const categoriesUrl = `${apiFaqDomain}/api/store-categories`;
const usualQuestionsUrl = `${apiFaqDomain}/api/categories`;
const supportsUrl = `${apiFaqDomain}/api/store-supports`;
const clicksUrl = `${apiFaqDomain}/click`;

const getCategories = store => getJson(categoriesUrl, { ...defaultHeader, store }, false, false);

const getUsualQuestions = (store, category) =>
  getJson(
    `${usualQuestionsUrl}?category=${category}`,
    { ...defaultHeader, store, category },
    false,
    false,
  );

const getSearchResults = (store, termo, suggestions = false, page = 1) =>
  getJson(
    `${searchUrl}?termo=${termo}&sugestoes=${suggestions}&pagina=${page}`,
    { ...defaultHeader, store },
    false,
    false,
  );

const getSupports = store => getJson(supportsUrl, { ...defaultHeader, store }, false, false);

const postClicksBySection = (section, json) =>
  postThirdPartyJson(`${clicksUrl}/${section}`, json, defaultHeader, false, false);

export { getCategories, getUsualQuestions, getSearchResults, getSupports, postClicksBySection };
