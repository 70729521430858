/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { InputHTMLAttributes, useCallback, useState } from 'react';
import { css } from 'aphrodite/no-important';
import KeyIcon from '@/common/icons/Key';
import EyeIcon from '@/common/icons/Eye';
import styles from './styles';
import { grayL, grayK } from '@/common/colors';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  handleOnInput(value: string): void;
  error?: string;
}

const InputPassword = ({ handleOnInput, error, ...rest }: InputProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const _handleOnInput = useCallback(
    event => {
      handleOnInput(event.target.value);
    },
    [handleOnInput],
  );

  return (
    <div className={css(styles.loginInputContainer)}>
      <KeyIcon styling={css(styles.keyIcon)} innerFill={grayK} />
      <input
        className={css(styles.loginUserInput)}
        onInput={_handleOnInput}
        type={isPasswordVisible ? 'text' : 'password'}
        {...rest}
      />
      <button
        className={css(styles.buttonPasswordVisible)}
        type="button"
        onClick={togglePasswordVisibility}
        aria-label={isPasswordVisible ? 'Ocultar senha' : 'Visualizar senha'}
        data-testid="visibleButton"
      >
        <EyeIcon width="16" height="10" innerFill={isPasswordVisible ? grayL : grayK} />
      </button>

      {error && (
        <div data-testid="errorMessagePassword" className={css(styles.errorMessage)}>
          {error}
        </div>
      )}
    </div>
  );
};

export default InputPassword;
