import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import Loadable from 'react-loadable';
import { Provider } from 'mobx-react';
import { LayoutContextProvider } from '@sbf/fairplay-core';
import { ThemeProvider } from '@sbf/fairplay-centauro';
import { ContextStoreProvider } from './stores/AppStoreContext';
import { MembershipProvider } from './context/MembershipContext';
import { WalletProvider } from '@/modules/wallet/context/WalletContext';

import history from './history';
import Routes from './Routes';
import { isDevelopment, isServer } from './utils/utils';
import ApplicationStore from './stores/ApplicationStore';
import { QueryClient, QueryClientProvider, Hydrate, DehydratedState } from 'react-query';
import { isSPA } from './helpers/ssrHelper';
import datadogInit from '../datadog';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { apiEnvironment, sentryDSN, sentryTracingRate } from './settings';
import {
  ExperimentsContextProvider,
  getAvailableExperiments,
} from './components/Experiment/ExperimentsContext';
import { ToggleProvider } from './components/Toggle/ToggleProvider';
import * as experimentsConfig from '@/experiments';

import 'mobx-react-lite/batchingForReactDom';
import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.iterator';
import 'focus-visible/dist/focus-visible';
import { UNLEASH_CONFIG } from './components/Toggle/ToggleConfig';
import * as serviceWorkerRegistration from './registerServiceWorker';

Sentry.init({
  dsn: sentryDSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: apiEnvironment,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: sentryTracingRate,
});

const queryClient = new QueryClient();

const availableExperiments = getAvailableExperiments(experimentsConfig);

const dehydratedState = window.__REACT_QUERY_STATE__ as DehydratedState;

if (!isServer()) {
  if (!isDevelopment) {
    datadogInit(isSPA);
  }
  Loadable.preloadReady()
    .then(() => {
      if (isSPA) {
        ReactDOM.render(
          <QueryClientProvider client={queryClient}>
            <ThemeProvider useFontLoader={false}>
              <LayoutContextProvider>
                <ContextStoreProvider>
                  <ExperimentsContextProvider availableExperiments={availableExperiments}>
                    <Provider AppStore={ApplicationStore} pdpState={{}}>
                      <ToggleProvider toggleConfig={UNLEASH_CONFIG}>
                        <MembershipProvider>
                          <WalletProvider>
                            <Router history={history}>
                              <Routes />
                            </Router>
                          </WalletProvider>
                        </MembershipProvider>
                      </ToggleProvider>
                    </Provider>
                  </ExperimentsContextProvider>
                </ContextStoreProvider>
              </LayoutContextProvider>
            </ThemeProvider>
          </QueryClientProvider>,
          document.getElementById('root'),
        );
        return;
      }
      ReactDOM.hydrate(
        <QueryClientProvider client={queryClient}>
          <Hydrate state={dehydratedState}>
            <LayoutContextProvider>
              <ThemeProvider useFontLoader={false}>
                <ContextStoreProvider>
                  <Provider AppStore={ApplicationStore} pdpState={{}}>
                    <Router history={history}>
                      <Routes />
                    </Router>
                  </Provider>
                </ContextStoreProvider>
              </ThemeProvider>
            </LayoutContextProvider>
          </Hydrate>
        </QueryClientProvider>,
        document.getElementById('root'),
      );
    })
    .catch(err => console.log(err));
}

serviceWorkerRegistration.register();
