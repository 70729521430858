import axios from 'axios';

const salesForceUrl = 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8';

const sendRegistration = salesForceData => {
  axios.post(salesForceUrl, salesForceData, {
    headers: {
      ['Content-Type']: 'application/x-www-form-urlencoded',
    },
  });
};

export { sendRegistration };
