import { observable, action, decorate } from 'mobx';

import { getApiMenu } from '../modules/apiCommons';
import BfHeaderButtons from '../modules/mocks/bfHeaderButtons';
import {
  siteTheme,
  myOrdersPath,
  myRegisterPath,
  myFavoritesPath,
  myAddressesPath,
  centauroMovePath,
  exchangeVoucherPath,
  emailServicePath,
  emailServiceSubscriptionPath,
  emailServiceCancellationPath,
  logoutPath,
} from '../settings';

class MenuStore {
  menuLinks = [];

  getApiMenu = async () => {
    if (this.menuLinks && this.menuLinks.length === 0) {
      const { data, error } = await getApiMenu();

      if (!error) {
        this.setApiMenu(data);
      }
    }
  };

  loggedAreaLinks = [
    {
      title: 'Meus pedidos',
      path: myOrdersPath,
      showForSeller: true,
    },
    {
      title: 'Meu cadastro',
      path: myRegisterPath,
      isScoobyPath: true,
      showForSeller: false,
    },
    {
      title: 'Meus favoritos',
      path: myFavoritesPath,
      isScoobyPath: true,
      showForSeller: false,
    },
    {
      title: 'Meus endereços',
      path: myAddressesPath,
      isScoobyPath: true,
      showForSeller: false,
    },
    {
      title: 'Centauro Move',
      path: `${centauroMovePath}?utm_source=meu-perfil&utm_medium=minha-conta&utm_campaign=touchs&utm_id=move`,
      isScoobyPath: false,
      showForSeller: false,
    },
    {
      title: 'Vale-troca',
      path: exchangeVoucherPath,
      isScoobyPath: false,
      showForSeller: true,
    },
    {
      title: 'Serviços de e-mail',
      path: emailServicePath,
      showForSeller: true,
    },
    {
      title: 'Ofertas exclusivas',
      path: emailServiceSubscriptionPath,
      showForSeller: true,
    },
    {
      title: 'Cancelar assinatura',
      path: emailServiceCancellationPath,
      showForSeller: true,
    },
    {
      title: 'Sair',
      path: logoutPath,
      isScoobyPath: true,
      showForSeller: true,
    },
  ];

  setApiMenu(newValue) {
    this.menuLinks = siteTheme === 'blackfriday' ? BfHeaderButtons(newValue) : newValue;
  }
}

decorate(MenuStore, {
  getApiMenu: action,
  setApiMenu: action,
  menuLinks: observable,
  loggedAreaLinks: observable,
});

export default MenuStore;
