import { FormState, FieldState } from 'formstate';
export default class FormValidator {
  login: FieldState<string>;
  password: FieldState<string>;
  form: FormState<{
    login: FieldState<string>;
    password: FieldState<string>;
  }>;

  constructor(login: string, password: string) {
    this.login = new FieldState(login);
    this.password = new FieldState(password);

    this.form = new FormState({
      login: this.login,
      password: this.password,
    });

    this.initValidations();
  }

  private initValidations() {
    this.login.validators(
      (value: string) => !value && 'O login precisa ser preenchido',
      (value: string) => value.length < 4 && 'O login precisa conter no mínimo 4 caracteres',
      (value: string) => value.length > 6 && 'O login precisa conter no máximo 6 caracteres',
    );
    this.password.validators((value: string) => !value && 'Senha deve ser preenchida');
  }

  async verifyErrors(): Promise<boolean> {
    const valid = await this.form.validate();

    return valid.hasError;
  }
}
