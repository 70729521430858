import { toJS } from 'mobx';
import { getJson, postJson } from './httpClientCentauro';
import { apiDomain } from '../settings';

const googleRoute = '/v4.2/clientes/login-com-google?accessToken=';
const facebbokRoute = '/v4.2/clientes/login-com-facebook?accessToken=';
const cpfProviderRoute = '/v4.2/clientes/validar-cpf-provider';
const linkPasswordSocialLogin = '/v4.2/clientes/vinculo-senha-login-social';
const routeGetClient = '/v3/clientes';

const socialLoginUser = (userCredencials, socialMedia, clientHeader) => {
  let route;

  if (socialMedia.toLowerCase() === 'facebook') {
    route = `${apiDomain}${facebbokRoute}${userCredencials}`;
  } else if (socialMedia.toLowerCase() === 'google') {
    route = `${apiDomain}${googleRoute}${userCredencials}`;
  }

  return getJson(route, {
    ...toJS(clientHeader),
  });
};

const validateCPFinOurDatabase = (userData, clientHeader) => {
  const route = `${apiDomain}${cpfProviderRoute}`;
  return postJson(route, userData, {
    ...toJS(clientHeader),
  });
};

const linkPasswordToSocialLogin = (userData, clientHeader) => {
  const route = `${apiDomain}${linkPasswordSocialLogin}`;
  return postJson(route, userData, {
    ...toJS(clientHeader),
  });
};

const emailValidation = (userData, clientHeader) =>
  validateCPFinOurDatabase(userData, clientHeader);

const getCustomer = (token, clientHeader) =>
  getJson(`${apiDomain}${routeGetClient}`, {
    ...clientHeader,
    'x-client-token': token,
  });

export {
  socialLoginUser,
  validateCPFinOurDatabase,
  linkPasswordToSocialLogin,
  emailValidation,
  getCustomer,
};
