import React, { useContext, createContext, useState, useEffect, useCallback } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { window } from 'global';

import { isProduction } from '@/utils/env';
import {
  getActiveExperimentFromQueryParameter,
  persistOptimizeActiveExperiments,
  persistExperiments,
  clearPersistedExperiments,
} from '@/utils/googleOptimize';
import ExperimentsWidget from './components/ExperimentsWidget';
import { ExperimentType, ExperimentContext } from './ExperimentsContext.types';

export const ExperimentsContext = createContext<ExperimentContext>({ experiments: {} });

export const useExperimentsList = () => {
  const { experiments } = useContext(ExperimentsContext);
  return experiments;
};

export const useExperimentInfo = (experimentId: string, allowedRoutes?: string[]) => {
  const isAllowedRoute = allowedRoutes ? allowedRoutes.includes(window?.location?.pathname) : true;

  const { experiments } = useContext(ExperimentsContext);

  return isAllowedRoute && experiments[experimentId]
    ? experiments[experimentId]
    : { variant: null };
};

export const getAvailableExperiments = (experiments: Record<string, ExperimentType>) => {
  return Object.values(experiments)
    .filter(value => typeof value === 'object')
    .filter(
      object => Object.keys(object).includes('id') && Object.keys(object).includes('variants'),
    );
};

export type ExperimentsContextProviderProps = {
  value?: ExperimentContext;
  availableExperiments: ExperimentType[];
};

export const ExperimentsContextProvider: React.FC<ExperimentsContextProviderProps> = ({
  value,
  availableExperiments,
  children,
}) => {
  const [contextValue, setContextValue] = useState<ExperimentContext>(
    value ? { ...value } : { experiments: {} },
  );

  const saveExperimentsOnContext = useCallback(
    (variant, experimentId) => {
      setContextValue(prevContextValue => ({
        ...prevContextValue,
        experiments: {
          ...prevContextValue.experiments,
          [experimentId]: { variant },
        },
      }));

      persistExperiments(variant, experimentId);
    },
    [setContextValue],
  );

  const resetExperimentsOnContext = () => {
    setContextValue({ ...contextValue, experiments: {} });
    clearPersistedExperiments();
  };

  useEffect(() => {
    const activeExperimentFromQueryParameter = getActiveExperimentFromQueryParameter();
    if (!activeExperimentFromQueryParameter) {
      return;
    }

    const { experimentId, variant } = activeExperimentFromQueryParameter;
    persistExperiments(variant, experimentId);
  }, []);

  useEffect(() => {
    persistOptimizeActiveExperiments(saveExperimentsOnContext);
  }, [saveExperimentsOnContext]);

  return (
    <ExperimentsContext.Provider value={{ ...value, ...contextValue }}>
      {children}
      {!isProduction() && availableExperiments?.length > 0 && (
        <ExperimentsWidget
          activeExperiments={contextValue.experiments}
          availableExperiments={availableExperiments}
          saveExperimentsOnContext={saveExperimentsOnContext}
          resetExperimentsOnContext={resetExperimentsOnContext}
        />
      )}
    </ExperimentsContext.Provider>
  );
};
