import crypto from 'crypto';

const algorithm = 'aes-256-cbc';
const key = '15cc41e9f5b16add83a308f9ff38f0f097429d26d7382539ab552bce576c34a7';
const iv = 'Bda691138c52f714dd0dc2dba9034be3';

const encrypt = text => {
  const cipher = crypto.createCipheriv(algorithm, Buffer.from(key, 'hex'), Buffer.from(iv, 'hex'));
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return encrypted.toString('hex');
};

const decrypt = text => {
  const ivText = Buffer.from(iv, 'hex');
  const encryptedText = Buffer.from(text, 'hex');
  const decipher = crypto.createDecipheriv(algorithm, Buffer.from(key, 'hex'), ivText);
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
};

export { encrypt, decrypt };
