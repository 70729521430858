import { getJson, postJson, putJson, deleteJson } from './httpClientCentauro';
import { apiDomain } from '../settings';
import axios from 'axios';
import {
  removeCustomization,
  update,
  add,
  exceedsMaxItems,
  getQuantityBySku,
  getMiniCartData,
  getCartData as getCartDataFromLocalStorage,
  getCarrinho,
  cleanCart,
  toggleGift,
  addGiftCard as addGiftCardLocalStorage,
  removeGiftCard as removeGiftCardLocalStorage,
  getDiscountCouponInfo,
  removeDiscountCoupon as removeDiscountCouponLocalStorage,
  genCartItems as genCartItemsFromLocalStorage,
} from './cartLocalStorage';

import { cartLocalStorage } from '../utils/utils';
import { sendEvent } from '../utils/Analytics';
import { datadogLogs } from '@datadog/browser-logs';

const urlMinicart = `${apiDomain}/v4.2/minicarrinhos`;
const urlCart = `${apiDomain}/v5.0/carrinhos`;

const urlCartData = `${urlCart}?avisospi=true`;
const urlExchangeCoupon = `${urlCart}/valetrocas`;
const urlDiscountCoupon = `${urlCart}/promocodes`;
const urlNestleCoupon = `${urlCart}/habilitarpromocodenestle`;
const urlAddGiftCard = `${urlCart}/aplicar-gift-card`;
const urlRemoveGiftCard = `${urlCart}/remover-gift-card`;
const urlGift = `${urlCart}/habilitarpresente`;
const urlCustomization = `${urlCart}/personalizacao`;
const urlBalanceGiftCard = `${apiDomain}/v5.0/carrinhos/gift-card`;
const urlSyncCart = `${apiDomain}/v5.0/carrinhos/sincronizar`;
const urlMigrateCart = `${apiDomain}/v5.0/checkout/migrar-carrinho`;

const limitExceededMock = () =>
  Promise.resolve({
    data: {},
    error: {
      status: 400,
      data: {
        message:
          'A quantidade máxima de itens permitida por produto é 10. Para maiores quantidades, entre em contato através do telefone 4004 6006',
      },
    },
  });

const getMinicartData = async headerCart => {
  if (!cartLocalStorage()) {
    return getJson(urlMinicart, headerCart, true);
  }

  // se não tiver produtos a request falha
  // if() {
  // return Promise.resolve({
  //   error: {}, // não sei exatamente o que precisa ser retornado aqui
  // });
  // }

  const data = await getMiniCartData(headerCart);

  return Promise.resolve({
    data,
  });
};

const getCartData = async headerCart => {
  if (!cartLocalStorage()) {
    return getJson(urlCartData, headerCart, true);
  }

  const data = await getCartDataFromLocalStorage(headerCart);

  return new Promise(resolve => {
    resolve({
      data,
    });
  });
};

const addCartData = async (dataPost, headerCart) => {
  if (!cartLocalStorage()) {
    return postJson(urlCart, dataPost, headerCart, true);
  }

  const newQuantityOfItems = getQuantityBySku(dataPost.Sku) + 1;

  if (exceedsMaxItems(newQuantityOfItems)) {
    return limitExceededMock();
  }

  await add(dataPost);

  return Promise.resolve({
    data: {
      tokenClienteAnonimo: 'f4448fd9-5f27-49f9-9f56-999d46f8d5a1', // esse é o retorno do postjson
    },
  });
};

const updateCartData = (itemId, dataPost, headerCart) => {
  if (!cartLocalStorage()) {
    return putJson(`${urlCart}/${itemId}`, dataPost, headerCart, true);
  }

  if (exceedsMaxItems(dataPost.Quantidade)) {
    return limitExceededMock();
  }

  update(itemId, dataPost);

  return Promise.resolve({
    status: 204,
    data: {},
  });
};

const addExchangeCoupon = (valeTrocas, headerCart) =>
  postJson(urlExchangeCoupon, { valeTrocas }, headerCart, true);

const removeExchangeCoupon = (valeTrocas, headerCart) =>
  deleteJson(`${urlExchangeCoupon}/${valeTrocas}`, headerCart, true);

const addDiscountCoupon = async (codigoPromocode, headerCart) => {
  if (!cartLocalStorage()) {
    return postJson(urlDiscountCoupon, { codigoPromocode }, headerCart, true);
  }

  return getDiscountCouponInfo(codigoPromocode, headerCart);
};

const removeDiscountCoupon = (codigoPromocode, headerCart) => {
  if (!cartLocalStorage()) {
    return deleteJson(`${urlDiscountCoupon}/${codigoPromocode}`, headerCart, true);
  }

  removeDiscountCouponLocalStorage();

  return Promise.resolve({});
};

const addGiftCard = async (CodigoCartao, Pin, headerCart) => {
  if (!cartLocalStorage()) {
    return postJson(urlAddGiftCard, { CodigoCartao, Pin }, headerCart, true);
  }

  const { data, error } = await postJson(urlBalanceGiftCard, {
    codigoCartao: CodigoCartao,
    pin: Pin,
  });

  if (error) {
    return Promise.resolve({
      data,
      error,
    });
  }

  addGiftCardLocalStorage({
    ...data,
    codigoCartao: CodigoCartao,
  });

  return Promise.resolve({});
};

const removeGiftCard = headerCart => {
  if (!cartLocalStorage()) {
    return postJson(urlRemoveGiftCard, {}, headerCart, true);
  }

  removeGiftCardLocalStorage();

  return Promise.resolve({});
};

const setGiftData = (dataPost, headerCart) => {
  if (!cartLocalStorage()) {
    return postJson(urlGift, dataPost, headerCart, true);
  }

  toggleGift(dataPost.itemId, dataPost.MarcaPresente);

  return Promise.resolve({});
};

const removeCustomCart = (itemId, headerCart) => {
  if (!cartLocalStorage()) {
    return deleteJson(`${urlCustomization}/${itemId}`, headerCart, true);
  }

  removeCustomization(itemId);

  return Promise.resolve({});
};

const addNestleCoupon = (codigoPromocode, headerCart) =>
  postJson(urlNestleCoupon, { codigoPromocode }, headerCart, true);

const syncCart = async header => {
  const carrinho = getCarrinho();

  if (!carrinho.itens.length) {
    return;
  }

  const body = {};

  body.itens = genCartItemsFromLocalStorage(carrinho.itens);

  if (carrinho.cep) {
    body.cep = carrinho.cep;
  }

  if (carrinho.cupomDeDesconto) {
    body.cupom = carrinho.cupomDeDesconto.codigoPromocode;
  }

  if (carrinho.cartaoPresente) {
    body.giftCard = {
      codigoCartao: carrinho.cartaoPresente.codigoCartao,
      idConsulta: carrinho.cartaoPresente.idConsulta,
    };
  }

  sendEvent(
    'UI - Carrinho - Sync do carrinho anônimo',
    'Sync do carrinho anônimo',
    `Sincronizou os itens no carrinho anônimo`,
  );

  const { data } = await postJson(urlSyncCart, body, header);
  cleanCart();
  return data?.mensagensCarrinhoStatusSucesso;
};

const migrateCart = async (anonymousClient, loggedClient) => {
  const data = {
    anonymousClient,
    loggedClient,
  };
  try {
    await axios.patch(urlMigrateCart, data);
  } catch (error) {
    datadogLogs.logger.error(`Migrate Cart in Login Sso Request > ${error}`);
  }
};

export default {
  getMinicartData,
  getCartData,
  addCartData,
  updateCartData,
  addExchangeCoupon,
  removeExchangeCoupon,
  addDiscountCoupon,
  removeDiscountCoupon,
  addGiftCard,
  removeGiftCard,
  setGiftData,
  removeCustomCart,
  addNestleCoupon,
  syncCart,
  migrateCart,
};
