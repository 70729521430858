/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import { window } from 'global';
import SellerRepository from './hooks/sessionSeller/repository/SellerRepository';
import { getCookie } from './utils/utils';
import AppLoader from './components/appLoader/AppLoader';
import { isSPA } from './helpers/ssrHelper';
import { setAnalytics } from './utils/Analytics';
import useRouteChange from './hooks/useRouteChange';
import { eventTriggerOptimize } from '@/utils/googleOptimize';

import {
  pdpExtension,
  homePath,
  homePathNoCache,
  cartPath,
  searchPath,
  navPath,
  loginPath,
  registerPath,
  passwordRecoveryPath,
  thanksPagePath,
  checkoutPath,
  hotsitePath,
  ppcPath,
  exchangesPath,
  exchangesUraPath,
  cancellationPath,
  exchangeVoucherPath,
  exchangeVoucherCancellationPath,
  staticPagePath,
  productsCatalogPagePath,
  emailServicePath,
  emailServiceSubscriptionPath,
  emailServiceCancellationPath,
  promotionPath,
  linkResponsePath,
  myOrdersPath,
  myOrderDetailPath,
  assitedLoginCustomerPath,
  assistedCreateCustomerPath,
  moduleConfigAssistedSalePath,
  registrationFormPath,
  registrationFormCommercialPath,
  assistedSaleLoginSellerPath,
  assistedSaleLoginSellerOutStorePath,
  walletPath,
  walletExtract,
} from './settings';
import LoginSeller from './components/assistedSale/loginSeller/LoginSeller';

const MasterPageContainer = Loadable({
  loader: () => import('./viewControllers/MasterPageContainer'),
  loading: AppLoader,
});

const LoggedAreaWrapper = Loadable({
  loader: () => import('./components/loggedAreaWrapper/LoggedAreaWrapper'),
  loading: AppLoader,
});

const Pdp = Loadable({
  loader: () => import('./viewControllers/PdPController'),
  loading: AppLoader,
});

const Nav = Loadable({
  loader: () => import('./views/nav/Nav'),
  loading: AppLoader,
});

const Home = Loadable({
  loader: () => import('./views/home/desktop/Desktop'),
  loading: AppLoader,
});

const Cart = Loadable({
  loader: () => import('./viewControllers/CartController'),
  loading: AppLoader,
});

const AddToCart = Loadable({
  loader: () => import('./viewControllers/AddToCartController.jsx'),
  loading: AppLoader,
});

const createCheckoutContainer = () =>
  Loadable({
    loader: () => import('./viewControllers/checkoutContainer/CheckoutContainer'),
    loading: AppLoader,
  });

const createThanksPageContainer = () =>
  Loadable({
    loader: () => import('./viewControllers/checkoutContainer/ThanksPageContainer'),
    loading: AppLoader,
  });

const Login = Loadable({
  loader: () => import('./viewControllers/LoginContainer'),
  loading: AppLoader,
});

const Register = Loadable({
  loader: () => import('./viewControllers/RegisterContainer'),
  loading: AppLoader,
});

const PasswordRecovery = Loadable({
  loader: () => import('./viewControllers/PasswordRecoveryContainer'),
  loading: AppLoader,
});

const ItemsExchange = Loadable({
  loader: () =>
    import('./viewControllers/exchangesContainer/itemsExchangeContainer/ItemsExchangeContainer'),
  loading: AppLoader,
});

const FormExchange = Loadable({
  loader: () =>
    import('./viewControllers/exchangesContainer/formExchangeContainer/FormExchangeContainer'),
  loading: AppLoader,
});

const ConfirmationExchange = Loadable({
  loader: () =>
    import(
      './viewControllers/exchangesContainer/confirmationExchangeContainer/ConfirmationExchangeContainer'
    ),
  loading: AppLoader,
});

const Cancellation = Loadable({
  loader: () =>
    import('./viewControllers/cancellationContainer/listItemsContainer/ListItemsContainer'),
  loading: AppLoader,
});

const ReasonCancellation = Loadable({
  loader: () =>
    import('./viewControllers/cancellationContainer/reasonStepContainer/ReasonStepContainer'),
  loading: AppLoader,
});

const ChargebackCancellation = Loadable({
  loader: () =>
    import('./viewControllers/cancellationContainer/chargebackContainer/ChargebackContainer'),
  loading: AppLoader,
});

const DataConfirmationCancellation = Loadable({
  loader: () =>
    import(
      './viewControllers/cancellationContainer/dataConfirmationContainer/DataConfirmationContainer'
    ),
  loading: AppLoader,
});

const CancellationRequest = Loadable({
  loader: () =>
    import(
      './viewControllers/cancellationContainer/cancellationRequestContainer/CancellationRequestContainer'
    ),
  loading: AppLoader,
});

const ExchangeVoucher = Loadable({
  loader: () => import('./viewControllers/exchangeVoucherContainer/ExchangeVoucherContainer'),
  loading: AppLoader,
});

const WalletView = Loadable({
  loader: () => import('./modules/wallet/views/WalletContainer'),
  loading: AppLoader,
});

const WalletExtract = Loadable({
  loader: () => import('./modules/wallet/components/WalletExtract'),
  loading: AppLoader,
});

const ExchangeVoucherCancellation = Loadable({
  loader: () =>
    import(
      './viewControllers/exchangeVoucherCancellationContainer/ExchangeVoucherCancellationContainer'
    ),
  loading: AppLoader,
});

const RequestExchangeVoucherCancellation = Loadable({
  loader: () =>
    import(
      './viewControllers/exchangeVoucherCancellationContainer/requestExchangeVoucherCancellationContainer/RequestExchangeVoucherCancellationContainer'
    ),
  loading: AppLoader,
});

const StaticPage = Loadable({
  loader: () => import('./viewControllers/StaticPageContainer.jsx'),
  loading: AppLoader,
});

const ProductsCatalog = Loadable({
  loader: () => import('./viewControllers/ProductsCatalog'),
  loading: AppLoader,
});

const RegistrationForm = Loadable({
  loader: () => import('./viewControllers/RegistrationForm'),
  loading: AppLoader,
});

const EmailServicesContainer = Loadable({
  loader: () => import('./viewControllers/emailServicesContainer/EmailServicesContainer'),
  loading: AppLoader,
});

const ServiceList = Loadable({
  loader: () => import('./components/emailServices/ServiceList/ServiceList'),
  loading: AppLoader,
});

const EmailServiceSubscription = Loadable({
  loader: () => import('./components/emailServices/ServiceOptions/EmailServiceSubscription'),
  loading: AppLoader,
});

const EmailServiceCancellation = Loadable({
  loader: () => import('./components/emailServices/ServiceOptions/EmailServiceCancellation'),
  loading: AppLoader,
});

const Promotion = Loadable({
  loader: () => import('./viewControllers/PromotionContainer'),
  loading: AppLoader,
});

const LinkResponse = Loadable({
  loader: () => import('./viewControllers/LinkResponseContainer'),
  loading: AppLoader,
});

const MyOrdersContainer = Loadable({
  loader: () => import('./viewControllers/myOrdersContainer/MyOrdersContainer'),
  loading: AppLoader,
});

const MyOrderDetails = Loadable({
  loader: () => import('./viewControllers/myOrderDetails/MyOrderDetailsContainer'),
  loading: AppLoader,
});

const CustomerLoginAssistedSale = Loadable({
  loader: () => import('./components/assistedSale/loginCustomer/LoginCustomer'),
  loading: AppLoader,
});

const CustomerCreateAssistedSale = Loadable({
  loader: () => import('./components/assistedSale/createCustomer/CreateCustomer'),
  loading: AppLoader,
});

const ModuleConfigAssistedSale = Loadable({
  loader: () => import('./viewControllers/encomendaExpressaContainer/EncomendaExpressa'),
  loading: AppLoader,
});

const LoginSellerInStore = Loadable({
  loader: () => import('./viewControllers/loginSellerInStoreContainer/LoginSellerInStoreContainer'),
  loading: AppLoader,
});

const PrivateRoute = ({ component: LocalComponent, ...props }) => {
  const {
    location: { pathname },
  } = props;

  const urlParams = new URLSearchParams(window.location.search);
  const userParameter = urlParams.get('user');
  const isLogged = getCookie('siteMetadata.sessaoInfo.usuarioInfo') || userParameter;
  const seller = SellerRepository.getActiveSeller();
  const loginRouter = seller ? assitedLoginCustomerPath : '/slogin';
  const renderComponent = isLogged ? (
    <LocalComponent {...props} />
  ) : (
    <Redirect
      to={{
        pathname: loginRouter,
        search: `?ReturnUrl=${pathname}`,
      }}
    />
  );
  return <Route {...props} render={() => renderComponent} />;
};

const RedirectTo404 = () => (
  <Redirect
    to={{
      pathname: '/404',
    }}
  />
);

PrivateRoute.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

PrivateRoute.defaultProps = {
  location: {
    pathname: '',
  },
};

const renderMaster = (ChildComponent, props) => (
  <MasterPageContainer>
    <ChildComponent {...props} />
  </MasterPageContainer>
);

const renderMasterHome = (ChildComponent, props) => (
  <MasterPageContainer homeProps={props}>
    <ChildComponent {...props} />
  </MasterPageContainer>
);

const renderLoggedArea = (ChildComponent, props) => {
  return (
    <MasterPageContainer>
      <LoggedAreaWrapper {...props}>
        <ChildComponent />
      </LoggedAreaWrapper>
    </MasterPageContainer>
  );
};
const renderEmailServices = (ChildComponent, props) => {
  return (
    <MasterPageContainer>
      <LoggedAreaWrapper {...props}>
        <EmailServicesContainer>
          <ChildComponent />
        </EmailServicesContainer>
      </LoggedAreaWrapper>
    </MasterPageContainer>
  );
};

const tempRedirect = () => {
  if (!isSPA) {
    return null;
  }

  const {
    location: { host, pathname, search },
  } = window;

  window.location.href = `https://${host}${pathname}${search}`;

  return <div />;
};

const moveToTop = () => {
  if (isSPA) {
    window.scrollTo(0, 0);
  }
};

function Routes() {
  useEffect(() => {
    setAnalytics();
  }, []);

  // Event to trigger Optimize to GA
  eventTriggerOptimize();

  useRouteChange(() => {
    eventTriggerOptimize();

    let urlOrigin = JSON.parse(sessionStorage.getItem('urlOrigin'));
    urlOrigin.push(window.location.pathname);
    sessionStorage.setItem('urlOrigin', JSON.stringify(urlOrigin));
  });

  moveToTop();

  const pdpPath = `/:pdpUrl${pdpExtension}`;

  return (
    <Switch>
      {isSPA && (
        <Route
          exact
          path={emailServicePath}
          component={props => renderEmailServices(ServiceList, props)}
        />
      )}
      {isSPA && (
        <Route
          exact
          path={emailServiceSubscriptionPath}
          component={props => renderEmailServices(EmailServiceSubscription, props)}
        />
      )}
      {isSPA && (
        <Route
          exact
          path={emailServiceCancellationPath}
          component={props => renderEmailServices(EmailServiceCancellation, props)}
        />
      )}
      {isSPA && (
        <PrivateRoute
          exact
          path={exchangeVoucherPath}
          component={props => renderLoggedArea(ExchangeVoucher, props)}
        />
      )}
      {isSPA && (
        <PrivateRoute
          exact
          path={myOrderDetailPath}
          component={props => renderLoggedArea(MyOrdersContainer, props)}
        />
      )}
      {isSPA && (
        <PrivateRoute
          exact
          path={`${myOrderDetailPath}/:orderId`}
          component={props => renderLoggedArea(MyOrderDetails, props)}
        />
      )}
      {isSPA && (
        <PrivateRoute
          exact
          path={myOrdersPath}
          component={props => renderLoggedArea(MyOrdersContainer, props)}
        />
      )}
      {isSPA && (
        <PrivateRoute
          exact
          path={exchangeVoucherPath}
          component={props => renderLoggedArea(ExchangeVoucher, props)}
        />
      )}
      {isSPA && (
        <PrivateRoute
          exact
          path={walletPath}
          component={props => renderLoggedArea(WalletView, props)}
        />
      )}
      {isSPA && (
        <PrivateRoute
          exact
          path={walletExtract}
          component={props => renderLoggedArea(WalletExtract, props)}
        />
      )}
      {isSPA && (
        <PrivateRoute
          exact
          path={`${exchangeVoucherCancellationPath}/:hashExchangeVoucher`}
          component={props => renderMaster(ExchangeVoucherCancellation, props)}
        />
      )}
      {isSPA && (
        <PrivateRoute
          exact
          path={`${exchangeVoucherCancellationPath}/:hashExchangeVoucher/confirmacao`}
          component={props => renderMaster(RequestExchangeVoucherCancellation, props)}
        />
      )}
      {isSPA && (
        <PrivateRoute
          exact
          path={cancellationPath}
          component={props => renderMaster(Cancellation, props)}
        />
      )}
      {isSPA && (
        <Route
          exact
          path={`${cancellationPath}/motivo`}
          component={props => renderMaster(ReasonCancellation, props)}
        />
      )}
      {isSPA && (
        <Route
          exact
          path={`${cancellationPath}/estorno`}
          component={props => renderMaster(ChargebackCancellation, props)}
        />
      )}
      {isSPA && (
        <Route
          exact
          path={`${cancellationPath}/confirmar-dados`}
          component={props => renderMaster(DataConfirmationCancellation, props)}
        />
      )}
      {isSPA && (
        <Route
          exact
          path={`${cancellationPath}/:orderId`}
          component={props => renderMaster(CancellationRequest, props)}
        />
      )}
      <PrivateRoute
        exact
        path={exchangesPath}
        component={props => renderLoggedArea(ItemsExchange, props)}
      />
      <PrivateRoute
        exact
        path={exchangesUraPath}
        component={props => renderLoggedArea(ItemsExchange, props)}
      />
      <PrivateRoute
        exact
        path={`${exchangesPath}/:orderId`}
        component={props => renderLoggedArea(FormExchange, props)}
      />
      <PrivateRoute
        exact
        path={`${exchangesPath}/:orderId/confirmacao`}
        component={props => renderLoggedArea(ConfirmationExchange, { ...props, withMenu: false })}
      />
      <Route
        exact
        path={navPath}
        component={props => renderMaster(Nav, { ...props, isNav: true })}
      />
      <Route
        path={`${navPath}/:searchValue`}
        component={props => renderMaster(Nav, { ...props, isNav: true })}
      />
      <Route
        exact
        path={searchPath}
        component={props => renderMaster(Nav, { ...props, isNav: false })}
      />
      <Route
        path={`${searchPath}/:searchValue`}
        component={props => renderMaster(Nav, { ...props, isNav: false })}
      />
      <Route exact path={hotsitePath} component={RedirectTo404} />
      <Route
        path={`${hotsitePath}/:hotsiteValue`}
        component={props => renderMaster(Nav, { ...props, isNav: false, isHotsite: true })}
      />
      <Route
        path={`${ppcPath}/:hotsiteValue`}
        component={props => renderMaster(Nav, { ...props, isNav: false, isHotsite: true })}
      />
      <Route path={`${cartPath}/adicionar`} component={props => renderMaster(AddToCart, props)} />
      <Route path={cartPath} component={props => renderMaster(Cart, props)} />
      {isSPA && (
        <PrivateRoute
          exact
          path={`${checkoutPath}/:checkoutStep`}
          component={props => renderMaster(createCheckoutContainer(), props)}
        />
      )}
      {isSPA && (
        <PrivateRoute
          exact
          path={`${thanksPagePath}/:orderId`}
          component={props => renderMaster(createThanksPageContainer(), props)}
        />
      )}
      <Route path={pdpPath} component={props => renderMaster(Pdp, props)} />
      <Route path="/:id([PMABC]?[0-9]+.*)" component={props => renderMaster(Pdp, props)} />
      <Route path={promotionPath} component={props => renderMaster(Promotion, props)} />
      <Route exact path={homePath} component={props => renderMasterHome(Home, props)} />
      <Route exact path={homePathNoCache} component={props => renderMasterHome(Home, props)} />
      <Route exact path={staticPagePath} component={RedirectTo404} />
      {isSPA && (
        <Route
          path={`${staticPagePath}/:staticPageValue`}
          component={props => renderMaster(StaticPage, props)}
        />
      )}
      {isSPA && (
        <Route
          path="/blackfriday"
          component={props =>
            renderMaster(StaticPage, {
              ...props,
              match: { params: { staticPageValue: 'blackfriday' } },
            })
          }
        />
      )}
      {isSPA && (
        <Route
          path="/app"
          component={props =>
            renderMaster(StaticPage, {
              ...props,
              match: { params: { staticPageValue: 'app' } },
            })
          }
        />
      )}
      {isSPA && (
        <Route
          exact
          path={assitedLoginCustomerPath}
          component={props =>
            renderMaster(CustomerLoginAssistedSale, {
              ...props,
            })
          }
        />
      )}
      {isSPA && (
        <Route
          exact
          path={assistedCreateCustomerPath}
          component={props =>
            renderMaster(CustomerCreateAssistedSale, {
              ...props,
            })
          }
        />
      )}
      {isSPA && (
        <Route
          exact
          path={moduleConfigAssistedSalePath}
          component={props =>
            renderMaster(ModuleConfigAssistedSale, {
              ...props,
            })
          }
        />
      )}
      {isSPA && (
        <Route
          exact
          path={assistedSaleLoginSellerOutStorePath}
          component={props =>
            renderMaster(LoginSeller, {
              ...props,
            })
          }
        />
      )}
      {isSPA && <Route exact path={assistedSaleLoginSellerPath} component={LoginSellerInStore} />}
      <Route
        exact
        path={productsCatalogPagePath}
        component={props => renderMaster(ProductsCatalog, props)}
      />
      <Route
        exact
        path={registrationFormPath}
        component={props => renderMaster(RegistrationForm, props)}
      />
      {isSPA && (
        <Route
          exact
          path={registrationFormCommercialPath}
          component={props => renderMaster(RegistrationForm, props)}
        />
      )}
      <Route exact path={loginPath} component={props => renderMaster(Login, props)} />
      <Route exact path={registerPath} component={props => renderMaster(Register, props)} />
      <Route
        exact
        path={passwordRecoveryPath}
        component={props => renderMaster(PasswordRecovery, props)}
      />
      <Route exact path={linkResponsePath} component={LinkResponse} />
      <Route component={tempRedirect} />
    </Switch>
  );
}

export default Routes;
