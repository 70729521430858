import { putJson } from './httpClientCentauro';
import { apiDomain } from '@/settings';
import StoreRepository from '@/hooks/sessionSeller/repository/StoreRepository';
import { AxiosResponse } from 'axios';

type ErrorMessage = {
  error: {
    axiosMessage: unknown;
  };
};

export const putPdvAttributesAsync = async (
  clientToken: string,
): Promise<ErrorMessage | AxiosResponse<unknown>> => {
  const storeId = StoreRepository.getStore();

  const body = {
    atributos: {
      MegaLojaId: storeId,
      MegaLojaExperiencia: 'Tablet',
    },
  };

  return await putJson(`${apiDomain}/v4/carrinhos/atributos`, body, {
    'x-lj-id': storeId,
    'x-cv-id': 28,
    'x-client-token': clientToken,
  });
};
