import { getCookie, setCookie } from '@/utils/utils';
import { STORE_KEY } from '../keySettings';
import { encrypt, decrypt } from '@/utils/Cryptography';
class StoreRepository {
  save(storeId: string): void {
    if (!storeId) {
      return;
    }
    setCookie(STORE_KEY, encrypt(storeId));
  }

  getStore(): string | undefined {
    const storeId = getCookie(STORE_KEY);

    if (storeId) {
      return decrypt(storeId);
    }

    return undefined;
  }
}

export default new StoreRepository();
